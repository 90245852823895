import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterResults from "react-filter-search";
import $ from "jquery";
const queryString = require("query-string");

class ManageCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      campaigns: [],
      selectedCampaign: "",
      selectedChannel: "",
      qLinkId: "",
      hash: "",
      manageBitlinkCampaignLoader: true,
    };
  }

  componentWillMount = () => {
    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "manage_bitlink_campaign") {
        splited.pop();
        splited.pop();
        let newHash = splited.join("&").replace("#", "");
        window.location.hash = newHash;
        $("#manage_bitlink_campaign").hide();
        $("#manage_bitlink_channels").hide();
      }
    }
  };

  getCampaignsFunction(channel_id = null) {
    this.setState({ selectedChannel: channel_id });
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(
      window.APIURL +
      "campaign/user-channel-campaigns?userId=" +
      userId +
      "&userChannel=" +
      channel_id
    )
      .then(response => response.json())
      .then(result => {
        this.setState({ manageBitlinkCampaignLoader: false, campaigns: result.campaigns });
      });
  }

  handleChangeCampaign = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  getLinkDataHash = hash => {
    this.setState({ hash, manageBitlinkCampaignLoader: true });
    fetch(window.APIURL + "q-link/searchHash?customizeBackHalf=" + hash, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        this.setState({ manageBitlinkCampaignLoader: false })
        if (result.status === true) {
          console.log('Here....')
          if (this.state.selectedCampaign === "") {
            swal('Error', 'Please select a campaign', 'error')
          } else {
            this.setState({ qLinkId: result.qlink._id }, this.updateLink);
          }
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  updateLink = () => {
    this.setState({
      manageBitlinkCampaignLoader: true
    })
    fetch(window.APIURL + "q-link/edit", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        _id: this.state.qLinkId,
        customizeBackHalf: this.state.hash,
        campaignId: this.state.selectedCampaign,
        channelId: this.state.selectedChannel,
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        this.setState({ manageBitlinkCampaignLoader: false })
        console.log("result");
        if (result.status === true) {
          swal('Success', 'Campaign has been added to FLO.ink successfully', 'success')
          this.props.updateDashBoard(true);
          this.props.newLinkIdSet();
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  handleLinksCampaignForm = e => {
    e.preventDefault();
    var parsed = queryString.parse(window.location.search);
    if (parsed.link !== undefined && parsed.link !== "undefined") {
      let link = parsed.link;
      this.getLinkDataHash(link);
    }
  };

  radioChangeHandler = (id, event) => {
    this.setState({ selectedCampaign: id });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="20"
          style={{ display: "none " }}
          id="manage_bitlink_campaign"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="channelsList"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <a>
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>
              </a>
              <h4 className="action-sheet--header-text">Select Campaign</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              <form onSubmit={this.handleLinksCampaignForm}>
                <div className="dynamic-checklist--MAIN">
                  <div className="search--ALTERNATE">
                    <input
                      type="search"
                      maxLength="100"
                      className="search--input-field"
                      placeholder="Search campaigns"
                      value={this.state.value}
                      onChange={this.handleChangeCampaign}
                    />

                    <FilterResults
                      value={this.state.value}
                      data={this.state.campaigns}
                      renderResults={results => (
                        <div>
                          {results.length > 0 && this.state.manageBitlinkCampaignLoader !== true ? (
                            results.map((el, index) => {
                              return (
                                <div className="radio" key={index}>
                                  <label className="cur-poi">
                                    <input
                                      type="radio"
                                      value={el._id}
                                      checked={
                                        this.state.selectedCampaign === el._id
                                      }
                                      onChange={event =>
                                        this.radioChangeHandler(el._id, event)
                                      }
                                    />
                                    {el.title}
                                  </label>
                                </div>
                              );
                            })
                          ) :
                            this.state.manageBitlinkCampaignLoader == true ?
                              <div className="text-center">
                                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                              </div>
                              :
                              (
                                <div>
                                  <span>No campaign against given channel found</span>
                                </div>
                              )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="submit"
                    tabIndex="0"
                    id=""
                  >
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageCampaign;
