import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import { createBrowserHistory } from "history";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { ENV } from '../../config/config';

import DashBoardLinksListing from "../dashboard-components/dashboard-listing/DashBoardLinksListing";
import DashboardChart from "../dashboard-components/dashboard-charts/DashboardCharts";
var CryptoJS = require("crypto-js");


// import InfiniteScroll from 'react-infinite-scroller';
const queryString = require("query-string");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      endDate: moment(),
      more: false,
      redirect: false,
      search: false,
      isChecked: false,
      subscriptionDetails: {},
      query: "",
      userID: '',
      floInks: [],
      floInks1: [],
      linkstats: {},
      activeUrlLink: "",
      start: true,
      clipCheck: false,
      customLinkCheck: false,
      total: 0,
      page: 1,
      offset: 0,
      limit: 0,
      pages: 0,   // infinit scroll,
      domainName: '',
      showPaddingWhileLoader: false
    };
    this.linksAndStats = React.createRef();
    this.chartsChild = React.createRef();
  }

  componentWillMount() {
    let redirectURl = localStorage.getItem("redirectionURL");
    let subscriptionPlanDetails = localStorage.getItem("spd");
    if (!subscriptionPlanDetails) {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("LoginSession");
      localStorage.removeItem("loginTime");
      window.location.href = "/web/userLogin";
      return;
    }
    let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
    decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));

    if (decryptedSubscriptionPlanDetails)
      this.setState({
        linkFiltering: decryptedSubscriptionPlanDetails.linkFiltering,
        linkHistory: parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1,
        customLinkHistory: parseInt(decryptedSubscriptionPlanDetails.customLinkHistory) - 1
      }, () => {
        let splittedDomain = window.location.search.split("?dd=");
        let { linkHistory, customLinkHistory } = this.state;
        // set start date & end date according to history limits
        let days = splittedDomain[1] ? customLinkHistory : linkHistory;
        let startDate = moment().subtract(days, 'days');
        let endDate = moment();

        this.setState({ startDate, endDate });
      });
    if (redirectURl && redirectURl !== null) {
      let temp = redirectURl;
      localStorage.removeItem("redirectionURL");
      this.props.history.push(temp);
    } else if (localStorage.getItem("loginToken")) {
      this.setState({ redirect: false });
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      this.setState(
        {
          userID: id,
          redirect: false
        },
        () => {
          this.getAllLinks();
          this.checkUserSession(id)
          // this.checkUser(id);
        }
      );
    } else {
      this.setState({ redirect: true });
    }
  }

  checkUserSession = (id) => {
    fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
        } else if (result.status === false) {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', result.message, 'error')
        } else {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  sendSearchQueryToFloink = searchValue => {
    if (searchValue !== "") {
      // console.log('tis......////')
      this.setState(
        {
          query: searchValue,
          search: true
        },
        () => {
          this.getAllLinks(true);
        }
      );
    } else {
      this.setState(
        {
          search: false
        },
        () => {
          this.clearSearch();
        }
      );
    }
  }

  getSearchProps = searchValue => {

    if (searchValue !== "") {
      // console.log(".../")
      this.setState(
        {
          query: searchValue,
          search: true
        },
        () => {
          this.getAllLinks();
        }
      );
    } else {
      this.setState(
        {
          search: false
        },
        () => {
          this.clearSearch();
        }
      );
    }
  };

  handleEvent = (e, picker) => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.setState(
        {
          dateFlag: true,
          startDate: picker.startDate,
          endDate: picker.endDate,
          search: true
        },
        () => {
          let totalDays = this.state.linkHistory && this.state.linkHistory !== undefined ? this.state.linkHistory : 60;
          let totalCLDays = this.state.customLinkHistory && this.state.customLinkHistory !== undefined ? this.state.customLinkHistory : 60;
          let startdate = moment().subtract(this.state.customLinkCheck ? totalCLDays : totalDays, "days").format("YYYY/MM/DD");
          let endDate = moment().format("YYYY/MM/DD");
          let date = moment(picker.startDate).format("YYYY/MM/DD");
          let range = moment(date).isBetween(startdate, endDate, undefined, '[]');

          if (range) {
            this.getAllLinks(true);
          } else {
            this.setState({
              dateFlag: false,
              startDate: moment()
                .subtract(1, "months")
                .add(1, "days"),
              endDate: moment(),
              search: false,
            })

            swal('Error', 'Your selected is not in range.', 'error')
          }
          // this.getAllLinks(true);
        }
      );
    }, 1000);
  };

  callChildFunction = (value) => {
    this.linksAndStats.current.setAllStates(
      this.state.floInks,
      this.state.linkstats,
      this.state.activeUrlLink,
      this.state.more,    // infinit scroll
      (value && value !== undefined) ? true : false
      // this.state.ClipCheck
    );
    this.chartsChild.current.setAllStates(
      this.state.floInks,
      this.state.linkstats,
      this.state.more,    // infinit scroll
      (value && value !== undefined) ? true : false
      // this.state.ClipCheck
    );
  };

  // checkUser = (id) => {
  //   fetch(window.APIURL + "member/getUser?id=" + id, {
  //     method: "get",
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then(res => {
  //       if (res.status === 200) {
  //         return res.json();
  //       } else if (res.status === 400) {
  //         return res.json();
  //       } else if (res.status === 422) {
  //         return res.json();
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then(result => {
  //       if (result.status === true) {
  //         console.log('OK')
  //       } else {
  //         localStorage.removeItem("loginToken");
  //         localStorage.removeItem("LoginSession");
  //         localStorage.removeItem("loginTime");
  //         window.location.href = "/a/userLogin";
  //       }
  //     })
  // }

  getAllLinks = (value, updateData = undefined, fromInfinite = 0) => {
    if (fromInfinite) {
      this.setState({ showPaddingWhileLoader: true });
    }
    if (updateData) {
      let floInksCurrent = this.state.floInks;
      let updateIndex = floInksCurrent.findIndex((obj => obj._id.toString() === updateData._id.toString()));
      if (updateIndex > -1) {
        floInksCurrent[updateIndex].title = updateData.title;
        floInksCurrent[updateIndex].customizeBackHalf = updateData.customizeBackHalf;
        floInksCurrent[updateIndex].tags = updateData.tags;
        floInksCurrent[updateIndex].domain = updateData.domain;
        this.setState({ floInks: floInksCurrent });
      }
      return;
    }
    if (this.state.start || (value && value !== undefined)) {
      this.props.changeLoaderStatus(true);
    }
    let history = createBrowserHistory();
    let splittedDomain = window.location.search.split("?dd=");
    let domainName = "";
    if (splittedDomain[1]) {
      // this.state({
      //   customLinkCheck: true,
      // })
      console.log(splittedDomain[1]);
      domainName = atob(splittedDomain[1]);
      this.setState({ domainName });
    }
    let totalLinksDays = this.state.linkHistory;
    let totalCLinksDays = this.state.customLinkHistory;

    this.setState({
      // limit: this.state.limit + 10,
      customLinkCheck: splittedDomain[1] ? true : false,
      // offset: this.state.start ? this.state.offset : (value && value !== undefined) ? 0 : this.state.offset + 10,
      page: this.state.start ? this.state.page : (value && value !== undefined) ? 1 : this.state.page + 1,
      start: (value && value !== undefined) ? true : false,
      clipCheck: true,
      more: true
    }, () => {
      fetch(window.APIURL + "q-link/user-links", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: this.state.userID,
          query: this.state.query === "" ? undefined : this.state.query,
          startDate: (!this.state.dateFlag && this.state.customLinkCheck == false) ? moment().subtract(totalLinksDays && totalLinksDays !== undefined ? totalLinksDays : 10, "days").format("YYYY/MM/DD") : (!this.state.dateFlag && this.state.customLinkCheck) ? moment().subtract(totalCLinksDays, "days").format("YYYY/MM/DD") : moment(this.state.startDate).format("YYYY/MM/DD"),
          endDate: !this.state.dateFlag ? moment().format("YYYY/MM/DD") : moment(this.state.endDate).format("YYYY/MM/DD"),
          hiddenLink: this.state.isChecked,
          // size: this.state.limit,  // infinit scroll,
          pageNo: this.state.page,
          // offset: this.state.offset,
          domainName: this.state.domainName
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          this.setState({ showPaddingWhileLoader: false })
          if (result.status === true) {
            let data = result.qLinks;
            let data1 = result.result;
            let urlLink = "";
            if (data.length < 10) {
              this.setState({
                more: false
              })
            }   // infinit scroll
            // return;
            if (data.length > 0) {
              if (this.state.page === 1) {
                var parsed = queryString.parse(window.location.search);
                if (parsed.link !== undefined && parsed.link !== "undefined") {

                  let validationIndex = data.findIndex((obj => obj.customizeBackHalf === parsed.link))
                  if (validationIndex >= 0) {
                    urlLink = parsed.link;
                  } else {
                    urlLink = data[0].customizeBackHalf;
                  }
                } else {
                  urlLink = data[0].customizeBackHalf;
                }
                this.linksAndStats.current.setChart(urlLink);
                if (this.state.query === "") {
                  let prevHash = window.location.hash;
                  history.push({
                    pathname: "/web/dashboard",
                    search: "?link=" + urlLink
                  });
                  window.location.hash = prevHash;
                } else {
                  let prevHash = window.location.hash;
                  history.push({
                    pathname: "/web/dashboard",
                    search:
                      "?link=" + urlLink + "?query=" + this.state.query + "&filterActive=true"
                  });
                  window.location.hash = prevHash;
                }

                this.setState({
                  activeUrlLink: urlLink, linkstats: data1
                })
              }
            }
            let allLinksArray = this.state.floInks;
            let final = allLinksArray.concat(data);
            this.setState({
              // page: this.state.page + 1,
              // activeUrlLink: urlLink, 
              floInks: this.state.start ? data : final,
              // linkstats: data1,
              start: false, clipCheck: false,
              // search: false,
            }, () => {
              this.callChildFunction(value);
            });
            this.props.changeLoaderStatus(false);

          } else if (result.status === false) {
            if (result.redirection === true) {
              this.setState({ redirect: true });
              swal('Error', result.message, 'error')
            }
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    })  // infinit scroll
  };

  refresh = () => {
    this.setState({
      start: true,
      page: 1,
      offset: 0,
      more: false,
      floInks: [],
      linkstats: {}
    }, () => {
      this.getAllLinks()
    })
  }

  editClick = hash => {
    this.props.editClick(hash);
  };

  addBulkTags = selectedIds => {
    console.log('ids..', selectedIds)
    this.props.addBulkTags(selectedIds);
  }

  toggleChange = () => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.setState(
        {
          isChecked: !this.state.isChecked,

        },
        () => {
          if (this.state.isChecked) {
            this.setState({ search: true })
          } else {
            this.setState({ search: false })
          }
          this.getAllLinks(true);
        }
      );
    }, 1000);
  };

  clearSearch = () => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.props.clearSearchInput();
      let { linkHistory, customLinkHistory } = this.state;
      // set start date & end date according to history limits
      let days = this.state.domainName && this.state.domainName !== '' ? customLinkHistory : linkHistory;
      let startDate = moment().subtract(days, 'days');
      let endDate = moment();

      this.setState(
        {
          // search: !this.state.search,
          search: false,
          isChecked: false,
          query: "",
          dateFlag: false,
          startDate: startDate,
          endDate: endDate
        },
        () => {
          this.getAllLinks(true);
        }
      );
    }, 1000);
    // this.setState({ search: !this.state.search });
  };

  shareHandler = (hash, dom) => {
    this.props.shareLink(hash, dom)
  }

  showfilter = () => {
    this.setState({ search: !this.state.search });
  }

  createLink = () => {
    this.props.createLink();
  }

  changeLoaderStatus = (state) => {
    this.props.changeLoaderStatus(state);
  }

  render() {
    let totalDays = this.state.linkHistory && this.state.linkHistory !== undefined ? this.state.linkHistory : 60;
    let totalCLDays = this.state.customLinkHistory && this.state.customLinkHistory !== undefined ? this.state.customLinkHistory : 60;
    // let minDate = moment().subtract(1, "days");
    let minDate = moment().subtract(this.state.customLinkCheck ? totalCLDays : totalDays, "days");
    let maxDate = moment();

    let dateRange = {
      'Today': [moment(), moment()]
    };

    if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
      dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

    if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
      dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

    if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
      dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

    if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
      dateRange['This Month'] = [moment().startOf('month'), maxDate];

    if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
      dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

    const loader = <div className="loader">Loading ...</div>;
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/userLogin" />;
    }
    return (
      <React.Fragment>

        <div className="main-content">
          {
            this.state.linkFiltering ?
              <React.Fragment>
                {/* {this.state.search === false ? ( */}
                <div className="filters-bar">
                  <div className="filter-list--FILTER">
                    <div className="filter-tobbar-holder">
                      <span className="filter-list--label">Filters</span>
                      <div className="float-left">
                        {<DateRangePicker
                          id="selectDate"
                          autoApply={false}
                          locale={{ format: 'MMMM D, YYYY' }}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onApply={this.handleEvent}
                          minDate={minDate}
                          maxDate={maxDate}
                          style={{ float: "left" }}
                          ranges={dateRange}
                        >
                          <input
                            type="button"
                            name="daterange"
                            className="form-control"
                            value={
                              this.state.startDate.format("MMMM D, YYYY") +
                              " - " +
                              this.state.endDate.format("MMMM D, YYYY")
                            }
                            className="date-input"
                          />
                        </DateRangePicker>}
                      </div>
                    </div>
                    <div className="filter-tobbar-holder">
                      <div className="filter-list--BLUE">
                        <span className="hidden-links">

                          <input
                            id="legal"
                            type="checkbox"
                            name="hidden-bitlinks"
                            className="checkbox--input cur-poi"
                            checked={this.state.isChecked}
                            onChange={this.toggleChange}
                          />{" "}
                          Show Hidden Links
                        </span>
                      </div>
                      {
                        this.state.query != "" ?
                          <div className="filter-pill--ALTERNATE">
                            <div className="filter-pill--label">{this.state.query}</div>
                            <a
                              onClick={() => this.clearSearch()}
                              className="close-icon"
                              alt="close-icon"
                            >
                              <i className="fa fa-times" />
                            </a>
                          </div>

                          :
                          ""
                      }
                      {
                        this.state.search ?
                          <span className="cur-poi filter-list--clear" onClick={this.clearSearch}>
                            Clear All
                          </span>
                          :
                          ""
                      }
                    </div>
                    {/* <span className="btn-refresh-floinks cur-poi"><i class="fas fa-sync"></i>Refresh</span> */}
                  </div>
                </div>
                {/* // )
                //   :
                //   (
                //     ""
                //   )
                // } */}
              </React.Fragment>
              :
              ""
          }


          <section
            className={
              this.state.search === false ? "links-list" : "links-list full"
            }
          >
            {/* <InfiniteScroll
              pageStart={0}
              //loadMore={this.getAllLinks()}
              hasMore={this.state.more}
              loader={<div className="loader" key={0}>Loading ...</div>}
              //useWindow={true}
            > */}
            <DashBoardLinksListing
              isHiddenChecked={this.state.isChecked}
              clipLoader={this.state.clipCheck}
              updateDashboard={this.getAllLinks}
              changeLoaderStatus={this.changeLoaderStatus}
              createLink={this.createLink}
              showfilter={this.clearSearch}
              shareHandler={this.shareHandler}
              editClick={this.editClick}
              addBulkTags={this.addBulkTags}
              refresh={this.refresh}
              ref={this.linksAndStats}
              showPaddingWhileLoader={this.state.showPaddingWhileLoader}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
            {/* </InfiniteScroll> */}
          </section>
          <section className="top-chart">
            {/* {this.state.search === false ? ( */}
            <DashboardChart ref={this.chartsChild} />
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
