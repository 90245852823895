module.exports = {
    ENV: {
        // Local url
        // url: 'http://localhost:4000/',
        // server_ip_address: "127.0.0.1",

        // DEV url
        // url: 'https://3.9.139.70/',
        // server_ip_address: "3.9.139.70",

        // LIVE url
        url: 'https://flo.ink/',
        server_ip_address: "3.129.171.117",

        TokSecCrpt: 'cAmFiLknEopopiInKFoL'
    }
}