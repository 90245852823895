import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

class MainFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      settings: {},
      resources: [],
      allResources: {},
    }
  }

  componentDidMount() {
    this.setState({ isLoader: true });
    this.props.getSiteSetting();
    this.getResources();
  }

  getResources = () => {
    fetch(window.APIURL + "cms-pages/getAllResources", {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(result => {
        if (result.status === true) {
          // console.log('......result', result.result)
          this.setState({
            resources: result.result
          })
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact admin', 'error')
        }
      });
  }

  render() {
    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <React.Fragment>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-lg-4">
                {
                  settings ?
                    <img src={settings.siteLogo}
                      alt="Site Logo"
                      className="img-fluid footer-logo"
                    /> : ''
                }
                <p>
                  FLO.ink empowers businesses to amplify their digital impact through short,
                  personalized, recognizable, and fully branded links.
                  <br />
                  {/* <a href="#" className="readmore">
                    <i className="fas fa-angle-right" /> Read more
                  </a> */}
                </p>

              </div>
              <div className="col-md-4 col-lg-2 ">
                <div className="links">
                  <span className="title">Company</span>
                  <ul>
                    {/* <li>
                      <Link to='/'>
                        <i className="fas fa-angle-right" /> Our Team
                      </Link>
                    </li>
                    <li>
                      <Link to='/'>
                        <i className="fas fa-angle-right" /> Careers
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to='/'>
                        <i className="fas fa-angle-right" /> Press
                      </Link>
                    </li> */}
                    <li>
                      <Link to='/contact-us'>
                        <i className="fas fa-angle-right" /> Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="links">
                  <span className="title">Solutions</span>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Social Media
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Digital Marketing
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Customer Service
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-md-4 col-lg-2">
                {/* <div className="links">
                  <span className="title">Resources</span>
                  <ul>
                    {/* <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Blog
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Resource Library
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Developers
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Support
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="links">
                  <span className="title">Legal</span>
                  <ul>
                    {
                      this.state.resources.length > 0 ?
                        this.state.resources.map((val, key) => {
                          return (
                            <li>
                              <Link to={`/resources/${val.slug}`}>
                                <i className="fas fa-angle-right" /> {val.title}
                              </Link>
                            </li>
                          )
                        })
                        :
                        ''
                    }
                    {/* <li>
                      <Link to='/resources/terms-of-services'>
                        <i className="fas fa-angle-right" /> Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link to='/resources/privacy'>
                        <i className="fas fa-angle-right" /> Privacy Policy
                      </Link>
                    </li>
                    {/* <li>
                      <Link to='/resources/support'>
                        <i className="fas fa-angle-right" /> Support
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-lg-2">
                <div className="links">
                  <span className="title">
                    Why FLO.ink
                  </span>
                  <ul>
                    {/* <li>
                      <a href="#">
                        <i className="fas fa-angle-right" /> Floink 101
                      </a>
                    </li> */}
                    {/* <li>
                      <Link to="/">
                        <i className="fas fa-angle-right" /> Integrations & API
                      </Link>
                    </li> */}
                    <li>
                      <NavLink to='/solutions'>
                        <i className="fas fa-angle-right" /> Solutions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to='/features'>
                        <i className="fas fa-angle-right" /> Features
                      </NavLink>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <i className="fas fa-angle-right" /> Enterprise Class
                      </Link>
                    </li> */}
                    <li>
                      <NavLink to='/pricing'>
                        <i className="fas fa-angle-right" /> Pricing
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </div>
              {
                settings ?
                  settings.fbSocial || settings.twSocial || settings.liSocial ?
                    <div className="col-md-4 col-lg-2">
                      <div className="socail-links">
                        <span className="title">Follow Us</span>
                        <ul>
                          {
                            settings.fbSocial ?
                              <li>
                                <a href={settings.fbSocial} target="_blank">
                                  <span className="fab fa-facebook-f" />
                                </a>
                              </li> : ""
                          }
                          {
                            settings.twSocial ?
                              <li>
                                <a href={settings.twSocial} target="_blank">
                                  <span className="fab fa-twitter" />
                                </a>
                              </li> : ""
                          }
                          {
                            settings.liSocial ?
                              <li>
                                <a href={settings.liSocial} target="_blank">
                                  <span className="fab fa-linkedin-in" />
                                </a>
                              </li> : ""
                          }
                        </ul>
                      </div>
                      <Link className="footeSupportLink d-block text-center" to="mailto:support@flo.ink"><i className="fas fa-headset"></i>support@flo.ink</Link>
                    </div> : ''
                  : ''
              }
            </div>
          </div>
          <div className="bottom-copyrights">
            <div className="container">
              <div className="row">
                <p className="copyrights">
                  <span className="float-left">Copyright 2020 © FLO.ink | All Rights Reserved</span>
                  <span className="float-right">A product of <a style={{ 'color': '#f53c2c' }} href="https://www.arhamsoft.com" target="_blank">Arhamsoft.</a></span>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MainFooter));