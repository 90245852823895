module.exports = {
    countryCodes: [
        {
            "value": "93",
            "label": "Afghanistan (\u202bافغانستان\u202c\u200e) (+93)"
        },
        {
            "value": "358",
            "label": "Åland Islands (+358)"
        },
        {
            "value": "355",
            "label": "Albania (Shqipëri) (+355)"
        },
        {
            "value": "213",
            "label": "Algeria (\u202bالجزائر\u202c\u200e) (+213)"
        },
        {
            "value": "1684",
            "label": "American Samoa (+1684)"
        },
        {
            "value": "376",
            "label": "Andorra (+376)"
        },
        {
            "value": "244",
            "label": "Angola (+244)"
        },
        {
            "value": "1264",
            "label": "Anguilla (+1264)"
        },
        {
            "value": "672",
            "label": "Antarctica (+672)"
        },
        {
            "value": "1268",
            "label": "Antigua and Barbuda (+1268)"
        },
        {
            "value": "54",
            "label": "Argentina (+54)"
        },
        {
            "value": "374",
            "label": "Armenia (Հայաստան) (+374)"
        },
        {
            "value": "297",
            "label": "Aruba (+297)"
        },
        {
            "value": "61",
            "label": "Australia (+61)"
        },
        {
            "value": "43",
            "label": "Austria (Österreich) (+43)"
        },
        {
            "value": "994",
            "label": "Azerbaijan (Azərbaycan) (+994)"
        },
        {
            "value": "1242",
            "label": "Bahamas (+1242)"
        },
        {
            "value": "973",
            "label": "Bahrain (\u202bالبحرين\u202c\u200e) (+973)"
        },
        {
            "value": "880",
            "label": "Bangladesh (বাংলাদেশ) (+880)"
        },
        {
            "value": "1246",
            "label": "Barbados (+1246)"
        },
        {
            "value": "375",
            "label": "Belarus (Беларусь) (+375)"
        },
        {
            "value": "32",
            "label": "Belgium (België) (+32)"
        },
        {
            "value": "501",
            "label": "Belize (+501)"
        },
        {
            "value": "229",
            "label": "Benin (Bénin) (+229)"
        },
        {
            "value": "1441",
            "label": "Bermuda (+1441)"
        },
        {
            "value": "975",
            "label": "Bhutan (འབྲུག) (+975)"
        },
        {
            "value": "591",
            "label": "Bolivia (+591)"
        },
        {
            "value": "387",
            "label": "Bosnia and Herzegovina (Босна и Херцеговина) (+387)"
        },
        {
            "value": "267",
            "label": "Botswana (+267)"
        },
        {
            "value": "47",
            "label": "Bouvet Island (+47)"
        },
        {
            "value": "55",
            "label": "Brazil (Brasil) (+55)"
        },
        {
            "value": "246",
            "label": "British Indian Ocean Territory (+246)"
        },
        {
            "value": "1284",
            "label": "British Virgin Islands (+1284)"
        },
        {
            "value": "673",
            "label": "Brunei (+673)"
        },
        {
            "value": "359",
            "label": "Bulgaria (България) (+359)"
        },
        {
            "value": "226",
            "label": "Burkina Faso (+226)"
        },
        {
            "value": "257",
            "label": "Burundi (Uburundi) (+257)"
        },
        {
            "value": "855",
            "label": "Cambodia (កម្ពុជា) (+855)"
        },
        {
            "value": "237",
            "label": "Cameroon (Cameroun) (+237)"
        },
        {
            "value": "1",
            "label": "Canada (+1)"
        },
        {
            "value": "238",
            "label": "Cape Verde (Kabu Verdi) (+238)"
        },
        {
            "value": "599",
            "label": "Caribbean Netherlands (+599)"
        },
        {
            "value": "1345",
            "label": "Cayman Islands (+1345)"
        },
        {
            "value": "236",
            "label": "Central African Republic (République centrafricaine) (+236)"
        },
        {
            "value": "235",
            "label": "Chad (Tchad) (+235)"
        },
        {
            "value": "56",
            "label": "Chile (+56)"
        },
        {
            "value": "86",
            "label": "China (中国) (+86)"
        },
        {
            "value": "61",
            "label": "Christmas Island (+61)"
        },
        {
            "value": "61",
            "label": "Cocos (Keeling) Islands (+61)"
        },
        {
            "value": "57",
            "label": "Colombia (+57)"
        },
        {
            "value": "269",
            "label": "Comoros (\u202bجزر القمر\u202c\u200e) (+269)"
        },
        {
            "value": "243",
            "label": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo) (+243)"
        },
        {
            "value": "242",
            "label": "Congo (Republic) (Congo-Brazzaville) (+242)"
        },
        {
            "value": "682",
            "label": "Cook Islands (+682)"
        },
        {
            "value": "506",
            "label": "Costa Rica (+506)"
        },
        {
            "value": "225",
            "label": "Côte d’Ivoire (+225)"
        },
        {
            "value": "385",
            "label": "Croatia (Hrvatska) (+385)"
        },
        {
            "value": "53",
            "label": "Cuba (+53)"
        },
        {
            "value": "599",
            "label": "Curaçao (+599)"
        },
        {
            "value": "357",
            "label": "Cyprus (Κύπρος) (+357)"
        },
        {
            "value": "420",
            "label": "Czech Republic (Česká republika) (+420)"
        },
        {
            "value": "45",
            "label": "Denmark (Danmark) (+45)"
        },
        {
            "value": "253",
            "label": "Djibouti (+253)"
        },
        {
            "value": "1767",
            "label": "Dominica (+1767)"
        },
        {
            "value": "1",
            "label": "Dominican Republic (República Dominicana) (+1)"
        },
        {
            "value": "593",
            "label": "Ecuador (+593)"
        },
        {
            "value": "20",
            "label": "Egypt (\u202bمصر\u202c\u200e) (+20)"
        },
        {
            "value": "503",
            "label": "El Salvador (+503)"
        },
        {
            "value": "240",
            "label": "Equatorial Guinea (Guinea Ecuatorial) (+240)"
        },
        {
            "value": "291",
            "label": "Eritrea (+291)"
        },
        {
            "value": "372",
            "label": "Estonia (Eesti) (+372)"
        },
        {
            "value": "251",
            "label": "Ethiopia (+251)"
        },
        {
            "value": "500",
            "label": "Falkland Islands (Islas Malvinas) (+500)"
        },
        {
            "value": "298",
            "label": "Faroe Islands (Føroyar) (+298)"
        },
        {
            "value": "679",
            "label": "Fiji (+679)"
        },
        {
            "value": "358",
            "label": "Finland (Suomi) (+358)"
        },
        {
            "value": "33",
            "label": "France (+33)"
        },
        {
            "value": "594",
            "label": "French Guiana (Guyane française) (+594)"
        },
        {
            "value": "689",
            "label": "French Polynesia (Polynésie française) (+689)"
        },
        {
            "value": "262",
            "label": "French Southern and Antarctic Lands (+262)"
        },
        {
            "value": "241",
            "label": "Gabon (+241)"
        },
        {
            "value": "220",
            "label": "Gambia (+220)"
        },
        {
            "value": "995",
            "label": "Georgia (საქართველო) (+995)"
        },
        {
            "value": "49",
            "label": "Germany (Deutschland) (+49)"
        },
        {
            "value": "233",
            "label": "Ghana (Gaana) (+233)"
        },
        {
            "value": "350",
            "label": "Gibraltar (+350)"
        },
        {
            "value": "30",
            "label": "Greece (Ελλάδα) (+30)"
        },
        {
            "value": "299",
            "label": "Greenland (Kalaallit Nunaat) (+299)"
        },
        {
            "value": "1473",
            "label": "Grenada (+1473)"
        },
        {
            "value": "590",
            "label": "Guadeloupe (+590)"
        },
        {
            "value": "1671",
            "label": "Guam (+1671)"
        },
        {
            "value": "502",
            "label": "Guatemala (+502)"
        },
        {
            "value": "44",
            "label": "Guernsey (+44)"
        },
        {
            "value": "224",
            "label": "Guinea (Guinée) (+224)"
        },
        {
            "value": "245",
            "label": "Guinea-Bissau (Guiné Bissau) (+245)"
        },
        {
            "value": "592",
            "label": "Guyana (+592)"
        },
        {
            "value": "509",
            "label": "Haiti (+509)"
        },
        {
            "value": "672",
            "label": "Heard Island and McDonald Islands (+672)"
        },
        {
            "value": "504",
            "label": "Honduras (+504)"
        },
        {
            "value": "852",
            "label": "Hong Kong (香港) (+852)"
        },
        {
            "value": "36",
            "label": "Hungary (Magyarország) (+36)"
        },
        {
            "value": "354",
            "label": "Iceland (Ísland) (+354)"
        },
        {
            "value": "91",
            "label": "India (भारत) (+91)"
        },
        {
            "value": "62",
            "label": "Indonesia (+62)"
        },
        {
            "value": "98",
            "label": "Iran (\u202bایران\u202c\u200e) (+98)"
        },
        {
            "value": "964",
            "label": "Iraq (\u202bالعراق\u202c\u200e) (+964)"
        },
        {
            "value": "353",
            "label": "Ireland (+353)"
        },
        {
            "value": "44",
            "label": "Isle of Man (+44)"
        },
        {
            "value": "972",
            "label": "Israel (\u202bישראל\u202c\u200e) (+972)"
        },
        {
            "value": "39",
            "label": "Italy (Italia) (+39)"
        },
        {
            "value": "1876",
            "label": "Jamaica (+1876)"
        },
        {
            "value": "81",
            "label": "Japan (日本) (+81)"
        },
        {
            "value": "44",
            "label": "Jersey (+44)"
        },
        {
            "value": "962",
            "label": "Jordan (\u202bالأردن\u202c\u200e) (+962)"
        },
        {
            "value": "7",
            "label": "Kazakhstan (Казахстан) (+7)"
        },
        {
            "value": "254",
            "label": "Kenya (+254)"
        },
        {
            "value": "686",
            "label": "Kiribati (+686)"
        },
        {
            "value": "383",
            "label": "Kosovo (+383)"
        },
        {
            "value": "965",
            "label": "Kuwait (\u202bالكويت\u202c\u200e) (+965)"
        },
        {
            "value": "996",
            "label": "Kyrgyzstan (Кыргызстан) (+996)"
        },
        {
            "value": "856",
            "label": "Laos (ລາວ) (+856)"
        },
        {
            "value": "371",
            "label": "Latvia (Latvija) (+371)"
        },
        {
            "value": "961",
            "label": "Lebanon (\u202bلبنان\u202c\u200e) (+961)"
        },
        {
            "value": "266",
            "label": "Lesotho (+266)"
        },
        {
            "value": "231",
            "label": "Liberia (+231)"
        },
        {
            "value": "218",
            "label": "Libya (\u202bليبيا\u202c\u200e) (+218)"
        },
        {
            "value": "423",
            "label": "Liechtenstein (+423)"
        },
        {
            "value": "370",
            "label": "Lithuania (Lietuva) (+370)"
        },
        {
            "value": "352",
            "label": "Luxembourg (+352)"
        },
        {
            "value": "853",
            "label": "Macau (澳門) (+853)"
        },
        {
            "value": "389",
            "label": "Macedonia (FYROM) (Македонија) (+389)"
        },
        {
            "value": "261",
            "label": "Madagascar (Madagasikara) (+261)"
        },
        {
            "value": "265",
            "label": "Malawi (+265)"
        },
        {
            "value": "60",
            "label": "Malaysia (+60)"
        },
        {
            "value": "960",
            "label": "Maldives (+960)"
        },
        {
            "value": "223",
            "label": "Mali (+223)"
        },
        {
            "value": "356",
            "label": "Malta (+356)"
        },
        {
            "value": "692",
            "label": "Marshall Islands (+692)"
        },
        {
            "value": "596",
            "label": "Martinique (+596)"
        },
        {
            "value": "222",
            "label": "Mauritania (\u202bموريتانيا\u202c\u200e) (+222)"
        },
        {
            "value": "230",
            "label": "Mauritius (Moris) (+230)"
        },
        {
            "value": "262",
            "label": "Mayotte (+262)"
        },
        {
            "value": "52",
            "label": "Mexico (México) (+52)"
        },
        {
            "value": "691",
            "label": "Micronesia (+691)"
        },
        {
            "value": "373",
            "label": "Moldova (Republica Moldova) (+373)"
        },
        {
            "value": "377",
            "label": "Monaco (+377)"
        },
        {
            "value": "976",
            "label": "Mongolia (Монгол) (+976)"
        },
        {
            "value": "382",
            "label": "Montenegro (Crna Gora) (+382)"
        },
        {
            "value": "1664",
            "label": "Montserrat (+1664)"
        },
        {
            "value": "212",
            "label": "Morocco (\u202bالمغرب\u202c\u200e) (+212)"
        },
        {
            "value": "258",
            "label": "Mozambique (Moçambique) (+258)"
        },
        {
            "value": "95",
            "label": "Myanmar (Burma) (မြန်မာ) (+95)"
        },
        {
            "value": "264",
            "label": "Namibia (Namibië) (+264)"
        },
        {
            "value": "674",
            "label": "Nauru (+674)"
        },
        {
            "value": "977",
            "label": "Nepal (नेपाल) (+977)"
        },
        {
            "value": "31",
            "label": "Netherlands (Nederland) (+31)"
        },
        {
            "value": "687",
            "label": "New Caledonia (Nouvelle-Calédonie) (+687)"
        },
        {
            "value": "64",
            "label": "New Zealand (+64)"
        },
        {
            "value": "505",
            "label": "Nicaragua (+505)"
        },
        {
            "value": "227",
            "label": "Niger (Nijar) (+227)"
        },
        {
            "value": "234",
            "label": "Nigeria (+234)"
        },
        {
            "value": "683",
            "label": "Niue (+683)"
        },
        {
            "value": "672",
            "label": "Norfolk Island (+672)"
        },
        {
            "value": "850",
            "label": "North Korea (조선 민주주의 인민 공화국) (+850)"
        },
        {
            "value": "1670",
            "label": "Northern Mariana Islands (+1670)"
        },
        {
            "value": "47",
            "label": "Norway (Norge) (+47)"
        },
        {
            "value": "968",
            "label": "Oman (\u202bعُمان\u202c\u200e) (+968)"
        },
        {
            "value": "92",
            "label": "Pakistan (\u202bپاکستان\u202c\u200e) (+92)"
        },
        {
            "value": "680",
            "label": "Palau (+680)"
        },
        {
            "value": "970",
            "label": "Palestine (\u202bفلسطين\u202c\u200e) (+970)"
        },
        {
            "value": "507",
            "label": "Panama (Panamá) (+507)"
        },
        {
            "value": "675",
            "label": "Papua New Guinea (+675)"
        },
        {
            "value": "595",
            "label": "Paraguay (+595)"
        },
        {
            "value": "51",
            "label": "Peru (Perú) (+51)"
        },
        {
            "value": "63",
            "label": "Philippines (+63)"
        },
        {
            "value": "64",
            "label": "Pitcairn Islands (+64)"
        },
        {
            "value": "48",
            "label": "Poland (Polska) (+48)"
        },
        {
            "value": "351",
            "label": "Portugal (+351)"
        },
        {
            "value": "1",
            "label": "Puerto Rico (+1)"
        },
        {
            "value": "974",
            "label": "Qatar (\u202bقطر\u202c\u200e) (+974)"
        },
        {
            "value": "262",
            "label": "Réunion (La Réunion) (+262)"
        },
        {
            "value": "40",
            "label": "Romania (România) (+40)"
        },
        {
            "value": "7",
            "label": "Russia (Россия) (+7)"
        },
        {
            "value": "250",
            "label": "Rwanda (+250)"
        },
        {
            "value": "590",
            "label": "Saint Barthélemy (Saint-Barthélemy) (+590)"
        },
        {
            "value": "290",
            "label": "Saint Helena (+290)"
        },
        {
            "value": "1869",
            "label": "Saint Kitts and Nevis (+1869)"
        },
        {
            "value": "1758",
            "label": "Saint Lucia (+1758)"
        },
        {
            "value": "590",
            "label": "Saint Martin (Saint-Martin (partie française)) (+590)"
        },
        {
            "value": "508",
            "label": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon) (+508)"
        },
        {
            "value": "1784",
            "label": "Saint Vincent and the Grenadines (+1784)"
        },
        {
            "value": "685",
            "label": "Samoa (+685)"
        },
        {
            "value": "378",
            "label": "San Marino (+378)"
        },
        {
            "value": "239",
            "label": "São Tomé and Príncipe (São Tomé e Príncipe) (+239)"
        },
        {
            "value": "966",
            "label": "Saudi Arabia (\u202bالمملكة العربية السعودية\u202c\u200e) (+966)"
        },
        {
            "value": "221",
            "label": "Senegal (Sénégal) (+221)"
        },
        {
            "value": "381",
            "label": "Serbia (Србија) (+381)"
        },
        {
            "value": "248",
            "label": "Seychelles (+248)"
        },
        {
            "value": "232",
            "label": "Sierra Leone (+232)"
        },
        {
            "value": "65",
            "label": "Singapore (+65)"
        },
        {
            "value": "1721",
            "label": "Sint Maarten (+1721)"
        },
        {
            "value": "421",
            "label": "Slovakia (Slovensko) (+421)"
        },
        {
            "value": "386",
            "label": "Slovenia (Slovenija) (+386)"
        },
        {
            "value": "677",
            "label": "Solomon Islands (+677)"
        },
        {
            "value": "252",
            "label": "Somalia (Soomaaliya) (+252)"
        },
        {
            "value": "27",
            "label": "South Africa (+27)"
        },
        {
            "value": "500",
            "label": "South Georgia and the South Sandwich Islands (+500)"
        },
        {
            "value": "82",
            "label": "South Korea (대한민국) (+82)"
        },
        {
            "value": "211",
            "label": "South Sudan (\u202bجنوب السودان\u202c\u200e) (+211)"
        },
        {
            "value": "34",
            "label": "Spain (España) (+34)"
        },
        {
            "value": "94",
            "label": "Sri Lanka (ශ්\u200dරී ලංකාව) (+94)"
        },
        {
            "value": "249",
            "label": "Sudan (\u202bالسودان\u202c\u200e) (+249)"
        },
        {
            "value": "597",
            "label": "Suriname (+597)"
        },
        {
            "value": "47",
            "label": "Svalbard and Jan Mayen (+47)"
        },
        {
            "value": "268",
            "label": "Swaziland (+268)"
        },
        {
            "value": "46",
            "label": "Sweden (Sverige) (+46)"
        },
        {
            "value": "41",
            "label": "Switzerland (Schweiz) (+41)"
        },
        {
            "value": "963",
            "label": "Syria (\u202bسوريا\u202c\u200e) (+963)"
        },
        {
            "value": "886",
            "label": "Taiwan (台灣) (+886)"
        },
        {
            "value": "992",
            "label": "Tajikistan (+992)"
        },
        {
            "value": "255",
            "label": "Tanzania (+255)"
        },
        {
            "value": "66",
            "label": "Thailand (ไทย) (+66)"
        },
        {
            "value": "670",
            "label": "Timor-Leste (+670)"
        },
        {
            "value": "228",
            "label": "Togo (+228)"
        },
        {
            "value": "690",
            "label": "Tokelau (+690)"
        },
        {
            "value": "676",
            "label": "Tonga (+676)"
        },
        {
            "value": "1868",
            "label": "Trinidad and Tobago (+1868)"
        },
        {
            "value": "216",
            "label": "Tunisia (\u202bتونس\u202c\u200e) (+216)"
        },
        {
            "value": "90",
            "label": "Turkey (Türkiye) (+90)"
        },
        {
            "value": "993",
            "label": "Turkmenistan (+993)"
        },
        {
            "value": "1649",
            "label": "Turks and Caicos Islands (+1649)"
        },
        {
            "value": "688",
            "label": "Tuvalu (+688)"
        },
        {
            "value": "1340",
            "label": "U.S. Virgin Islands (+1340)"
        },
        {
            "value": "256",
            "label": "Uganda (+256)"
        },
        {
            "value": "380",
            "label": "Ukraine (Україна) (+380)"
        },
        {
            "value": "971",
            "label": "United Arab Emirates (\u202bالإمارات العربية المتحدة\u202c\u200e) (+971)"
        },
        {
            "value": "44",
            "label": "United Kingdom (+44)"
        },
        {
            "value": "1",
            "label": "United States (+1)"
        },
        {
            "value": "1",
            "label": "United States Minor Outlying Islands (+1)"
        },
        {
            "value": "598",
            "label": "Uruguay (+598)"
        },
        {
            "value": "998",
            "label": "Uzbekistan (Oʻzbekiston) (+998)"
        },
        {
            "value": "678",
            "label": "Vanuatu (+678)"
        },
        {
            "value": "39",
            "label": "Vatican City (Città del Vaticano) (+39)"
        },
        {
            "value": "58",
            "label": "Venezuela (+58)"
        },
        {
            "value": "84",
            "label": "Vietnam (Việt Nam) (+84)"
        },
        {
            "value": "681",
            "label": "Wallis and Futuna (+681)"
        },
        {
            "value": "212",
            "label": "Western Sahara (+212)"
        },
        {
            "value": "967",
            "label": "Yemen (\u202bاليمن\u202c\u200e) (+967)"
        },
        {
            "value": "260",
            "label": "Zambia (+260)"
        },
        {
            "value": "263",
            "label": "Zimbabwe (+263)"
        }
    ]
}