import { DOWNLOAD_INVOICE, BEFORE_DOWNLOAD_INVOICE } from '../../../../redux/types';

const initialState = {
    downloadInvoiceRes: {},
    downloadInvoiceAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DOWNLOAD_INVOICE:
            return {
                ...state,
                downloadInvoiceRes: action.payload,
                downloadInvoiceAuth: true
            }
        case BEFORE_DOWNLOAD_INVOICE:
            return {
                ...state,
                downloadInvoiceAuth: false
            }
        default:
            return {
                ...state
            }
    }
}
