import React, { Component } from 'react';
// import Swal from 'sweetalert2';
import { scrollTop } from '../../shared/common-functions';
import CircleLoader from 'react-spinners/CircleLoader';
import swal from 'sweetalert';
import { css } from '@emotion/core';

const override = css`
    display: block;
    position:absolute;
    left:50%;
    top:50%;
    margin:-60px 0 0 -60px;
    border-color: blue;
`;

class ResourcePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: "",
            pageData: "",
            title: "",
            mainLoader: true,
            description: "",
        }
    }

    componentWillMount = () => {
        scrollTop();
        this.setState({ slug: this.props.match.params.param });
        this.getData(this.props.match.params.param);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.param !== prevProps.match.params.param) {
            scrollTop();
            this.setState({ slug: this.props.match.params.param, mainLoader: true });
            this.getData(this.props.match.params.param);
          // call the fetch function again
        }
      }

    // componentDidCatch () {
    //     scrollTop();
    //     this.setState({ slug: this.props.match.params.param });
    //     this.getData(this.props.match.params.param);
    // }

    getData = (param) => {
        fetch(window.APIURL + "cms-pages/get-by-slug?slug=" + param, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                return res.json();
            })
            .then(result => {
                if (result.status === true) {
                    this.setState({ title: result.result.title, description: result.result.description, mainLoader: false });
                    this.refs.description.innerHTML = result.result.description;
                } else if (result.status === false) {
                    // swal('Error', result.message, 'error')
                    this.props.history.push('/error404');
                    // this.setState({ mainLoader: false })
                } else {
                    swal('Error', 'Contact admin' , 'error')
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.mainLoader === true ?
                        <div className="main-loader" >
                            <CircleLoader
                                css={override}
                                sizeUnit={"px"}
                                size={120}
                                color={'#007bff'}
                                loading={this.state.mainLoader}
                            />
                        </div>
                        :
                        this.state.title && this.state.description && this.state.mainLoader === false ?
                        <div className="main-content">
                            <div className="cms-content">
                                <div className="container">
                                    {
                                        this.state.title ?
                                            <h1 className="title mb-4">{this.state.title}</h1>
                                            : 'No title available'
                                    }
                                </div>
                                <div className="container">
                                    {
                                        this.state.description ?
                                            <p className="text" ref="description"></p>
                                            :
                                            'No content available'
                                    }

                                </div>
                            </div>
                        </div>
                        :
                        <div className="main-content">
                            <div className="cms-content" style={{ marginTop: '0' }}>
                                <div className="container-fluid">
                                    {/* <h1 className="title">{"No Content"}</h1> */}
                                    <div className="row">
                                        <div className="coming-soon-block">
                                            <img className="img-fluid" src="https://flo.ink/uploads/coming-soon-banner.jpg" alt="coming Soon Image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                }
                
            </React.Fragment>
        );
    }
}

export default ResourcePage;