import React, { Component } from "react";

class NavbarCreateBitlink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="create">
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarcreate"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              CREATE
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarcreate">
              <span
                className="dropdown-item checklist-menu-item cur-poi"
                data-id="create_bitlink"
                id="crt_bit"
              >
                Floink
              </span>
              {
                this.props.subscriptionDetails && this.props.subscriptionDetails.campaigns ?
                <div>
                  <span
                    className="dropdown-item checklist-menu-item cur-poi"
                    data-id="create_campaign"
                    onClick={this.props.createDashboardCampaign}
                  >
                    Campaign{" "}
                  </span>
                </div>
                :
                ''
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NavbarCreateBitlink;
