//All layouts....
import Default from "../layouts/Default";
import NoNavbar from "../layouts/NoNavbar";
import DashboardLayout from "../layouts/Dashboard";
import NoFooterNoNav from "../layouts/NoFooterNoNav";
import AccountNav from "../layouts/rightNavbarAccounts";
import SimpletLayout from "../layouts/simpleLayout";
import NoFooter from "../layouts/NoFooter";

//All import components.......
import LandingPage from "../components/landing/Landing";
import Login from "../components/login/Login";
import Logout from "../components/logout/Logout";
import Register from "../components/register/Register";
import QRCode from "../components/qr-code/QRCode";
import whyQuick from "../components/why-quick/WhyQuick";
import Solutions from "../components/solutions/Solutions";
import Features from "../components/features/Features";
import Pricing from "../components/pricing/Pricing";
import Resources from "../components/resources/Resources";
import Dashboard from "../components/dashboard/Dashboard";
import ForgotPassword from "../components/forget-password/ForgotPassword";
import ResetPassword from "../components/forget-password/ResetPassword";
import AccountActivation from "../components/account-activation/AcountActivation";
import EmailVerify from "../components/account-activation/emailresponse/emailVerify";
import EmailNotVerify from "../components/account-activation/emailresponse/emailNotVerify";
import VerifyToken from "../components/verify-token/VerifyToken";
import AccountDetails from "../components/manage-accounts/account-details/accountDetails"; //By Uzair Hassan
import Plans from "../components/upgarde/planns/plans"; //By Uzair Hassan
import UpgradePackage from "../components/upgarde/upgrade-package/upgradePackage"; //By Uzair Hassan
import CustomDomains from "../components/manage-accounts/custom-domains/customDomains"; //By Uzair Hassan
import LinkReports from "../components/advanced-reporting/LinkReports";
import PurchaseDomain from "../components/manage-accounts/custom-domains/purchaseDomain";
import DomainPayment from "../components/manage-accounts/custom-domains/domainPayment";
import Invoice from "../components/manage-accounts/invoice/invoice"; //By Uzair Hassan
import PrivacyPolicy from "../components/company-policies/privacy-policy/privacyPolicy"; //By Uzair Hassan
import TermCondition from "../components/company-policies/term-condition/termCondition"; //By Uzair Hassan
import StatsDashboard from "../components/stats/Stats"; // paid user stats
import CampaignListing from "../components/campaign-listing/CampaignListing"; // paid user stats
import ResourcePage from "../components/resources-pages/ResourcePage"; // Resources pages
import ErrorPage from "../components/error-page/ErrorPage"; // Error pages
import ContactPage from "../components/contact-page/contactPage"; // Contact pages
import GetQuote from "../components/contact-page/getQuote"; // Get quote
import PaymentSuccess from '../components/upgarde/paymentResponse/paymentSuccess';
// import paymentCancel from '../components/upgarde/paymentResponse/paymentCancel';
import PaymentCancel from "../components/upgarde/paymentResponse/paymentCancel";
import CsvReport from "../components/layout-files/csvReport";

export default [
  {
    // landing page
    path: "/",
    exact: true,
    layout: Default,
    component: LandingPage
  },
  {
    // landing page
    path: "/web",
    exact: true,
    layout: Default,
    component: LandingPage
  },
  {
    // Login page
    path: "/userLogin/:token?",
    exact: true,
    layout: NoFooterNoNav,
    component: Login
  },
  {
    // Logout
    path: "/logout",
    exact: true,
    layout: NoFooterNoNav,
    component: Logout
  },
  {
    // Register page
    path: "/userRegister",
    exact: true,
    layout: NoFooterNoNav,
    component: Register
  },
  {
    // Why Quick page
    path: "/why-floink",
    exact: true,
    layout: Default,
    component: whyQuick
  },
  {
    // Why Resources page
    path: "/resources",
    exact: true,
    layout: Default,
    component: Resources
  },
  {
    // Why Solutions page
    path: "/solutions",
    exact: true,
    layout: Default,
    component: Solutions
  },
  {
    // Why Features page
    path: "/features",
    exact: true,
    layout: Default,
    component: Features
  },
  {
    // Why Pricing page
    path: "/pricing",
    exact: true,
    layout: Default,
    component: Pricing
  },
  {
    // Forget password
    path: "/ForgotPassword",
    exact: true,
    layout: NoFooterNoNav,
    component: ForgotPassword
  },
  {
    path: "/accounts/member/reset-password/:resetoken",
    layout: NoFooterNoNav,
    component: ResetPassword
  },
  {
    path: "/accounts/member/activation/:activationToken/:userId",
    layout: NoFooterNoNav,
    component: AccountActivation
  },
  {
    path: "/email/verified",
    layout: SimpletLayout,
    component: EmailVerify
  },
  {
    path: "/email/isVerified",
    layout: SimpletLayout,
    component: EmailNotVerify
  },
  {
    // Dashboard
    path: "/dashboard",
    exact: true,
    layout: DashboardLayout,
    component: Dashboard
  },
  {
    // Verify token
    path: "/verifyCode",
    exact: true,
    layout: NoFooterNoNav,
    component: VerifyToken
  },
  {
    // Account Details
    path: "/organization/detail",
    exact: true,
    layout: AccountNav,
    component: AccountDetails
  },
  {
    // Account Custom Domains...........
    path: "/organization/domain",
    exact: true,
    layout: AccountNav,
    component: CustomDomains
  },
  {
    // Account Custom Domains...........
    path: "/organization/purchase",
    exact: true,
    layout: AccountNav,
    component: PurchaseDomain
  },
  {
    // Account Custom Domains...........
    path: "/organization/purchase/:domainHash",
    exact: true,
    layout: AccountNav,
    component: DomainPayment
  },
  {
    // Advanced Reportings
    path: "/reports",
    exact: true,
    layout: AccountNav,
    component: LinkReports
  },
  {
    path: "/csv/report",
    exact: true,
    layout: AccountNav,
    component: CsvReport
  },
  {
    // Payment Invoiceee...........
    path: "/organization/invoice/:paymentId",
    exact: true,
    layout: NoFooterNoNav,
    component: Invoice
  },
  {
    // list of all plans
    path: "/organization/subscriptions/:type",
    exact: true,
    layout: SimpletLayout,
    component: Plans
  },
  {
    // list of all plans
    path: "/paymentSuccess/:secret?",
    exact: true,
    layout: SimpletLayout,
    component: PaymentSuccess
  },
  {
    // list of all plans
    path: "/paymentCancel",
    exact: true,
    layout: SimpletLayout,
    component: PaymentCancel
  },
  {
    // upgrade plan
    path: "/organization/subscription",
    exact: true,
    layout: SimpletLayout,
    component: UpgradePackage
  },

  {
    // paid users stats page
    path: "/dashboard/stats",
    exact: true,
    layout: DashboardLayout,
    component: StatsDashboard
  },

  {
    // paid users stats page
    path: "/dashboard/campaigns",
    exact: true,
    layout: DashboardLayout,
    component: CampaignListing
  },

  {
    // Privacy Policy Page...............
    path: "/privacy-policy",
    exact: true,
    layout: Default,
    component: PrivacyPolicy
  },

  {
    // Terms and Conditions Page...............
    path: "/term-conditions",
    exact: true,
    layout: Default,
    component: TermCondition
  },
  {
    // Terms and Conditions Page...............
    path: "/resources/:param",
    exact: true,
    layout: Default,
    component: ResourcePage
  },
  {
    // Contact Page...............
    path: "/contact-us",
    exact: true,
    layout: Default,
    component: ContactPage
  },
  {
    path: "/get-quote",
    exact: true,
    layout: Default,
    component: GetQuote
  },
  {
    // Terms and Conditions Page...............
    path: "/error404",
    exact: true,
    layout: NoFooterNoNav,
    component: ErrorPage
  },
  {
    // QR Code
    path: "/qr-code",
    exact: true,
    layout: NoFooterNoNav,
    component: QRCode
  },
  {
    // Terms and Conditions Page...............
    path: "/*",
    exact: true,
    layout: NoFooterNoNav,
    component: ErrorPage
  },
];
