import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import InfiniteScroll from 'react-infinite-scroll-component';
import $ from "jquery";
import { ENV } from './../../../config/config';
import Notifications, { notify } from 'react-notify-toast';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";
import { css } from '@emotion/core';
const override = css`
	display: block;
	margin: 0 auto;
	border-color: rgba(10,110,180);
`;     // infinit scroll
var CryptoJS = require("crypto-js");

class DashboarLinksListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idList: [],
			toggelList: [],
			userID: "",
			activeUrlLink: "",
			floInks: [],
			hasMore: false,  // infinit scroll
			// hasMore: false,
			linkstats: {},
			selectedTotalClicks: 0,
			slectedNowTotalClicks: 0,
			chartOptions: {
				chart: {
					zoomType: 'x'
				},
				title: {
					text: 'Number of Clicks per day - Last 14 Days'
				},
				subtitle: {
					text: document.ontouchstart === undefined ?
						'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
				},
				xAxis: {
					type: 'datetime'
				},
				yAxis: {
					title: {
						text: 'Clicks Count'
					},
					min: 0,
					tickInterval: 2
				},
				legend: {
					enabled: false
				},
				plotOptions: {
					area: {
						fillColor: {
							linearGradient: {
								x1: 0,
								y1: 0,
								x2: 0,
								y2: 1
							},
							stops: [
								[0, Highcharts.getOptions().colors[0]],
								[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
							]
						},
						marker: {
							radius: 2
						},
						lineWidth: 1,
						states: {
							hover: {
								lineWidth: 1
							}
						},
						threshold: null
					}
				},

				series: [{
					type: 'area',
					cursor: 'pointer',
					name: 'No. of Clicks',
					data: []
				}],
			},
			hoverData: null,
			isLoader: false,
			topDevices: [],
			locationOptions: {
				chart: {
					type: 'column'
				},
				title: {
					text: 'Top Locations'
				},
				accessibility: {
					announceNewData: {
						enabled: true
					}
				},
				xAxis: {
					type: 'category'
				},
				yAxis: {
					title: {
						text: 'No. of clicks'
					},
					min: 0,
					tickInterval: 2
				},
				legend: {
					enabled: false
				},
				plotOptions: {
					series: {
						cursor: 'pointer',
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.y}'
						}
					}
				},

				tooltip: {
					headerFormat: '<span style="font-size:14px">{series.name}</span><br>',
					pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> clicks<br/>'
				},

				series: [
					{
						name: "Location",
						cursor: 'pointer',
						colorByPoint: true,
						data: []
					}
				]
			},
			showLocations: false, // show top locations chart
			channelsOptions: {
				chart: {
					type: "pie",
					size: 300
				},
				title: {
					text: 'Top Performing Campaigns & Channels'
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.y}</b>'
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.y}'
						}
					}
				},
				series: [
					{
						name: 'No. of clicks',
						cursor: 'pointer',
						colorByPoint: true,
						data: []
					}
				]
			},
			showChannels: false, // show top campaigns & channels chart
			topSocialPlatforms: []
		};
	}

	componentWillMount() {
		let subscriptionPlanDetails = localStorage.getItem("spd");
		if (!subscriptionPlanDetails) {
			localStorage.removeItem("loginToken");
			localStorage.removeItem("LoginSession");
			localStorage.removeItem("loginTime");
			window.location.href = "/web/userLogin";
			return;
		}
		let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
		decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
		if (decryptedSubscriptionPlanDetails) {
			this.setState({
				bulkLinkTagging: parseInt(decryptedSubscriptionPlanDetails.bulkLinkTagging),
				socialPosting: decryptedSubscriptionPlanDetails.socialPosting,
				qrCodeGenerate: decryptedSubscriptionPlanDetails.qrCodeGenerate
			});
		}

	}

	setHoverData = e => {
		this.setState({ hoverData: e.target.category });
	};

	updateRoute = backHalf => {
		let history = createBrowserHistory();
		history.push({
			pathname: "/web/dashboard",
			search: "?link=" + backHalf
		});
		this.setChart(backHalf);
	};

	setChart = backHalf => {
		let allLink = this.state.floInks
		allLink.forEach((key, index) => {
			$("#floink_" + key.customizeBackHalf).removeClass("activeCheckBox")
		});
		this.setState({ idList: [], selectedTotalClicks: 0, isLoader: true });

		this.props.changeLoaderStatus(true);
		fetch(window.APIURL + "q-link/user-link-stats", {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				customizeBackHalf: backHalf,
				startDate: moment(this.props.startDate).format('YYYY/MM/DD'),
				endDate: moment(this.props.endDate).format('YYYY/MM/DD')
			})
		})
			.then(res => {
				if (res.status === 200) {
					return res.json();
				} else if (res.status === 400) {
					return res.json();
				} else if (res.status === 403) {
					return res.json();
				} else if (res.status === 422) {
					return res.json();
				} else if (res.status === 404) {
					return res.json();
				} else {
					return res.json();
				}
			})
			.then(result => {
				if (result.status === true) {
					let data = result.qlink;
					let clickPerDay = {};

					if (data.length && data[0].linkstats)
						data.forEach(element => {
							let keyVar =
								moment(element.linkstats.createdAt).format("MMMM DD YYYY");
							let valueVar = element.linkstats.count;
							clickPerDay[keyVar] = valueVar;
						});

					let chartDays = 13;
					let datesForChart = [];
					for (var i = chartDays; i >= 0; i--) {
						datesForChart.push(
							moment()
								.subtract(i, "days")
								.format("MMMM DD YYYY")
						);
					}
					let dataForChart = [];
					datesForChart.forEach(element => {
						if (clickPerDay[element] != undefined) {
							dataForChart.push(clickPerDay[element]);
						} else {
							dataForChart.push(0);
						}
					});

					var chartOptions = { ...this.state.chartOptions };
					chartOptions.xAxis.categories = datesForChart;
					chartOptions.series[0].data = dataForChart;

					var locationOptions = { ...this.state.locationOptions };
					locationOptions.series[0].data = result.topLocations;

					var channelsOptions = { ...this.state.channelsOptions };
					channelsOptions.series[0].data = result.topChannels;

					let currentThis = this;

					this.setState({
						chartOptions, activeUrlLink: backHalf,
						topDevices: result.topDevices,
						locationOptions, channelsOptions,
						showLocations: result.topLocations && result.topLocations.length ? true : false,
						showChannels: result.topChannels && result.topChannels.length ? true : false,
						topSocialPlatforms: result.topSocialPlatforms
					}, () => {
						currentThis.setState({ isLoader: false });

						let activeLink = this.state.floInks.find((elem) => { return elem.customizeBackHalf === backHalf });
						if (activeLink)
							this.setState({ activeLink });
					});
				} else {
					swal('Error', result.message, 'error')
				}
			});
		this.props.changeLoaderStatus(false);

	};

	copyLink = link => {
		var fullLink = document.createElement("input");
		document.body.appendChild(fullLink);
		fullLink.value = `https://${link}/`;
		fullLink.select();
		document.execCommand("copy");
		let myColor = { background: '#cce5ff', text: "##004085" };
		notify.show('Copied to clipboard!', "custom", 3000, myColor);
		// $(".clipboard").show();
		// setTimeout(() => {
		//   $(".clipboard").hide();
		// }, 1800
		// )
		fullLink.remove();
	};

	editLink = hash => {
		window.location.hash = "#?action=edit_bitlink";
		this.props.editClick(hash);
	};

	addBulkTags() {
		let selectedIds = this.state.idList;
		let { bulkLinkTagging } = this.state;

		if (selectedIds.length <= bulkLinkTagging) {
			window.location.hash = "#?action=bulk_tags_links";
			this.props.addBulkTags(selectedIds);
		} else {
			swal('Error', 'Oops, you selected more than your plan\'s bulk tagging limit', 'error');
		}
	}


	setAllStates(links, stats, urlLink, more, value) {
		let allLink = this.state.floInks;
		let temp = this.state.toggelList;
		let clicks = this.state.slectedNowTotalClicks;
		if (temp.length < 1) {
			allLink.forEach((key, index) => {
				$("#floink_" + key.customizeBackHalf).removeClass("activeCheckBox")
			});
		}

		if (value) {
			temp = []
			this.setState({ toggelList: [] })
		}
		this.setState({
			selectedTotalClicks: 0,
		});
		this.setState({
			floInks: links,
			linkstats: stats,
			activeUrlLink: urlLink,
			hasMore: more,   // infinit scroll
			idList: temp.length > 0 ? temp : [],
		}, () => {
			let activeLink = this.state.floInks.find((elem) => { return elem.customizeBackHalf === urlLink });
			if (activeLink)
				this.setState({ activeLink });
		});
	}

	shareLink = (hash, dom) => {
		this.props.shareHandler(hash, dom);
	}

	generateQR = (shortUrl) => {
		window.open(`/web/qr-code?shortUrl=${shortUrl}`, '_blank');
	}

	gotoReport = (link) => {
		window.open(`/web/reports?link=${link}`, '_blank');
	}

	toggleFilter = () => {
		let allLink = this.state.floInks
		allLink.forEach((key, index) => {
			$("#floink_" + key.customizeBackHalf).removeClass("activeCheckBox")
		});
		this.setState({ idList: [], selectedTotalClicks: 0 });
		this.props.showfilter()
	}

	createLink = () => {
		this.props.createLink();
	}

	idListFun = (_id, flo_index, clicks) => {
		let allClicks = 0;
		let temp = this.state.idList;
		var index = temp.indexOf(_id);
		if (index > -1) {
			temp.splice(index, 1);
		} else {
			temp.push(_id)
		}

		$("#floink_" + flo_index).toggleClass("activeCheckBox");

		let allLink = this.state.floInks
		allLink.forEach((key, index) => {
			if (temp.includes(key._id)) {
				allClicks = allClicks + key.totalClicks
			}
		});
		this.setState({ idList: temp, toggelList: temp, selectedTotalClicks: allClicks, slectedNowTotalClicks: allClicks });

	}

	hideIdList = () => {
		fetch(window.APIURL + "q-link/hide-multiple", {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				multipleIds: this.state.idList
			}),
		})
			.then(res => { console.log(res); return res.json(); })
			.then(result => {
				if (result.status === true) {
					this.props.updateDashboard(true);
					this.setState({ idList: [], toggelList: [] })
					swal('Success', result.message, 'success');
				} else {
					swal('Error', result.message, 'error')
				}
			});
	}

	showList = () => {
		fetch(window.APIURL + "q-link/show-multiple", {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				multipleIds: this.state.idList
			}),
		})
			.then(res => { console.log(res); return res.json(); })
			.then(result => {
				if (result.status === true) {
					this.props.updateDashboard(true);
					this.setState({ idList: [], toggelList: [] })
					swal('Success', result.message, 'success')
				} else {
					swal('Error', result.message, 'error')
				}
			});
	}

	render() {
		const { chartOptions, hoverData, topDevices, topSocialPlatforms, locationOptions, channelsOptions, showLocations, showChannels } = this.state;
		const { floInks, linkstats } = this.state;
		return (
			<React.Fragment>
				<Notifications />
				<div className="row">
					<div className={`col-md-4 infinteScrollParent ${this.props.showPaddingWhileLoader ? '' : 'allDataLoaded'}`}>
						{linkstats && linkstats.totalDocs !== undefined ?
							<InfiniteScroll
								dataLength={this.state.floInks.length}
								next={() => this.props.updateDashboard(false, undefined, 1)}
								hasMore={this.state.hasMore}
								scrollableTarget="v-pills-tab"
								loader={
									<ClipLoader
										css={override}
										sizeUnit={"px"}
										size={50}
										color={'#123abc'}
										loading={this.props.clipLoader}
									/>
								}
							>
								{/* <div className="text-center pb-2 pt-2 filters-button" style={{ "borderBottom": "1px solid #b3b3b3" }}>
				<button onClick={this.toggleFilter} className="btn btn-solid">
				Show Filters
				</button>
			</div> */}
								<div className="nav flex-column nav-pills scroller" id="v-pills-tab" role="tablist" aria-orientation="vertical">

									<div className="all-info">
										<span className="float-left pt-1">{this.state.floInks.length} FLO.inks</span>
										<span className="ml-1 mb-1 refresh-icon cur-poi" onClick={this.props.refresh}><i className="fas fa-sync"></i></span>
										<span className="float-right">Clicks all time</span>
									</div>
									{this.state.floInks && this.state.floInks.length > 0
										?
										// <div className="dsbhrd-left-content-holder">{ // by uzair
										this.state.floInks.map((val, index) => {
											return (
												<React.Fragment key={index}>
													<span
														className="link-checkBox"
														id={"floink_" + val.customizeBackHalf}
														style={{ border: "1px solid #0b6eb4", position: "relative", cursor: 'pointer', float: "left" }}
														onClick={() => this.idListFun(val._id, val.customizeBackHalf, val.totalClicks)}>
													</span>
													<a style={{ width: "calc(100% - 18px)", marginLeft: "-10px" }}
														className={
															val.customizeBackHalf === this.state.activeUrlLink ? "nav-link active" : "nav-link"
														}
														key={index}
														id={"Unique" + index + "-tab"}
														data-toggle="pill"
														href={"#" + "Unique" + index}
														role="tab"
														aria-controls={"Unique" + index}
														onClick={() => this.updateRoute(val.customizeBackHalf)}
														aria-selected="true"
													>
														<time
															className="bitlink-item--created-date"
															dateTime="2019-07-30"
														>
															{moment(val.createdAt)
																.format("MMM DD")
																.toUpperCase()}
														</time>
														<div className="bitlink-item--title text-truncate ml-0">
															{(val.title === undefined || val.title === "") ? val.link : val.title}
														</div>
														<div>
															<div className="bitlink--MAIN ml-0" tabIndex="-1">
																{val.domain + "/"}
																<span className="bitlink--hash">
																	{val.customizeBackHalf}
																</span>
															</div>
															<span className="click-count--MAIN">
																{val.totalClicks}
																<span className="clicks-icon " alt="clicks-icon">
																	<i className="fas fa-signal" />
																</span>
															</span>
														</div>
													</a>
												</React.Fragment>
											);
										})
										//   } // by uzair
										// </div> // by uzair
										: <div className="no-link-found text-center">No Links Found</div>}
								</div>
								{/* </div> */}
							</InfiniteScroll>
							:
							<React.Fragment>
								{/* in case of no linkstats.totalDocs */}
								<div className="nav flex-column nav-pills scroller" id="v-pills-tab" role="tablist" aria-orientation="vertical">
									<div className="all-info">
										<span className="float-left pt-1">{this.state.floInks.length} FLO.inks</span>
										<span className="ml-1 mb-1 refresh-icon cur-poi" onClick={this.props.refresh}><i className="fas fa-sync"></i></span>
										<span className="float-right">Clicks all time</span>
									</div>
									<div className="no-link-found text-center">No Links Found</div>
								</div>
							</React.Fragment>
						}
						{/* </div> */}
					</div>
					{/* // infinit scroll */}
					<div className="col-md-8">
						{this.state.idList.length > 0 ?
							<div className="total-selected-main">
								<div>
									<span className="selected-links">
										{"Selected Links: " + this.state.idList.length}
									</span>


								</div>
								<img src={require("../../../images/webpage-to-png-5.png")} style={{ width: "150px" }} />
								<div><span className="clicks">
									{"Total Clicks: " + this.state.selectedTotalClicks}
								</span></div>
								<div className="item--action-buttons" >
									{
										this.state.bulkLinkTagging ?
											<button type="button" onClick={() => this.addBulkTags()} className="button--ACTION btn btn-solid">
												Tag
					</button>
											: ''
									}
									{this.props.isHiddenChecked ?
										<React.Fragment>
											<button type="button" onClick={this.showList} className="button--ACTION btn btn-solid">
												Show
					</button>
										</React.Fragment>
										:
										<React.Fragment>
											<button type="button" onClick={this.hideIdList} className="button--ACTION btn btn-solid">
												Hide
					</button>
										</React.Fragment>
									}
								</div>
							</div>
							:
							<div className="dsbhrd-right-content-holder scroller">
								<div className="tab-content" id="v-pills-tabContent">
									{this.state.floInks && this.state.floInks.length > 0 && this.state.activeLink
										?
										// this.state.floInks.map((val, index) => {
										//   return (
										<div
											// key={index}
											className={
												this.state.activeLink.customizeBackHalf === this.state.activeUrlLink ? "tab-pane fade show active" : "tab-pane fade show"
											}
											// id={"Unique" + index}
											role="tabpanel"
										// aria-labelledby={
										//   "Unique" + index
										// }
										>
											<div>
												<span className="item-detail--back-nav">
													<a
														href="#"
														className="close-icon"
														alt="close-icon"
													/>
												</span>
												<div>
													<div>
														<time
															className="item-detail--created-date"
															dateTime="2019-07-30"
														>
															<span>Created at: </span>
															{moment(this.state.activeLink.createdAt)
																.format("MMM DD hh:mm A")
																.toUpperCase()}
														</time>
														<div className="item-detail--title text-truncate ml-0">
															{this.state.activeLink.title === undefined || this.state.activeLink.title === ""
																? this.state.activeLink.link
																: this.state.activeLink.title}
														</div>
														<div>
															<a
																className="item-detail--url ml-0"
																href={this.state.activeLink.link}
																target="_blank"
															>
																{this.state.activeLink.link}
															</a>
														</div>
													</div>
													<div className="addedTagsHoler">
														{
															this.state.activeLink.tags.map((tag, ind) => {
																return <span key={ind}>{tag}</span>
															})
														}
													</div>
													<div>
														{this.state.activeLink.channel.length > 0 ? (
															<div className="associated">
																<span className="title">Associated Channel: </span>
																<span> {this.state.activeLink.channel[0].title} </span> <br />
																<span className="title">Associated Campaign: </span>
																<span> {this.state.activeLink.campaignData[0] && this.state.activeLink.campaignData[0].title ? this.state.activeLink.campaignData[0].title : 'N/A'} </span>
																{/* //This is not a good sollution */}
															</div>
														) : (
																""
															)}
													</div>
													<div className="bitlink--copy-wrapper ml-0">
														<div className="bitlink--copy-tooltip">
															<input
																tabIndex="-1"
																defaultValue={this.state.activeLink.customizeBackHalf}
																readOnly
															/>
															<span className="bitlink--copy-tooltip-text">
																Press Ctrl-c to copy
								</span>
														</div>
														<div className="bitlink--copy-interface pl-0">
															<div className="d-block">
																<a
																	href={'http://' + this.state.activeLink.domain + "/" + this.state.activeLink.customizeBackHalf}
																	className="bitlink--copyable-text"
																	target="_blank"
																>
																	<span className="bitlink--MAIN" tabIndex="-1">
																		{this.state.activeLink.domain + "/"}
																		<span className="bitlink--hash">
																			{this.state.activeLink.customizeBackHalf}
																		</span><span className="clipboard">Copied to cilpboard!</span>
																	</span>
																</a>
															</div>
															<div className="item--action-buttons ml-0">
																<button
																	className="button--ACTION"
																	type="button"
																	tabIndex="0"
																	data-clipboard-text={this.state.activeLink.domain + "/" + this.state.activeLink.customizeBackHalf}
																	id=""
																	onClick={() =>
																		this.copyLink(
																			this.state.activeLink.domain + "/" + this.state.activeLink.customizeBackHalf
																		)
																	}
																>
																	COPY
									</button>
																{
																	this.state.socialPosting ?
																		<button className="button--ACTION" type="button" tabIndex="0" id="" onClick={() => this.shareLink(this.state.activeLink.customizeBackHalf, this.state.activeLink.domain)}>
																			SHARE
										</button> : ''
																}
																<button
																	className="button--ACTION"
																	type="button"
																	tabIndex="0"
																	id=""
																	onClick={() =>
																		this.editLink(this.state.activeLink.customizeBackHalf)
																	}
																>
																	EDIT
									</button>
																{
																	this.state.qrCodeGenerate ?
																		<button
																			className="button--ACTION"
																			type="button"
																			tabIndex="0"
																			id=""
																			onClick={() =>
																				this.generateQR(`${this.state.activeLink.domain}/${this.state.activeLink.customizeBackHalf}`)
																			}
																		>
																			QR CODE
										</button>
																		:
																		""
																}
																<button
																	className="button--ACTION"
																	type="button"
																	tabIndex="0"
																	id=""
																	onClick={() =>
																		this.gotoReport(`${this.state.activeLink._id}`)
																	}
																>
																	REPORT
										</button>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="graph-loader">
												{
													!this.state.isLoader ?
														<HighchartsReact
															highcharts={Highcharts}
															options={chartOptions}
														/>
														:
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isLoader}
														/>
												}
											</div>
										</div>
										//   );
										// })
										:
										<React.Fragment>
											<div className="no-content-holder">
												<div>
													<h3 className="text-center mt-5">
														<span className="fas fa-exclamation" style={{ fontSize: "150px", color: "#bbbbbb" }}>
														</span>
														<br />
														<br />
							No FLO.ink record found
							</h3>
													<hr />
													<div className="text-center">
														<button
															className="btn btn-solid"
															type="button"
															tabIndex="0"
															id=""
															onClick={() => this.createLink()}
														>
															Create FLO.ink
						</button>
													</div>
												</div>
											</div>
										</React.Fragment>
									}
								</div>
								<div className="floInkDetailBlock">
									<div className="info-wrapper--DARK">
										<div className="row mb-3">
											<div className="col-12">
												<div className="topThreeFloinkHolder h-100">
													<div className="info-wrapper--heading-holder">
														<h4 className="info-wrapper--header">Top Performing Campaigns & Channels</h4>
													</div>
													{
														showChannels ?
															<PieChart highcharts={Highcharts} options={channelsOptions} />
															:
															<div className="noDataHolder text-center">
																<div className="noDataInner">
																	<span className="d-inline-block my-3">No Data Found</span>
																</div>
															</div>
													}
												</div>
											</div>
										</div>
										<div className="topThreeFloinkHolder mb-3">
											<div className="info-wrapper--heading-holder">
												<h4 className="info-wrapper--header">Top Locations</h4>
											</div>
											{
												showLocations ?
													<HighchartsReact highcharts={Highcharts} options={locationOptions} />
													:
													<div className="noDataHolder text-center">
														<div className="noDataInner">
															<span className="d-inline-block my-3">No Location Found</span>
														</div>
													</div>
											}
										</div>
										<div className="row">
											<div className="col-md-6 col-12">
												<div className="topThreeFloinkHolder mb-3 h-100">
													<div className="info-wrapper--heading-holder">
														<h4 className="info-wrapper--header">Top Devices</h4>
													</div>
													<div className="topDevicesHolder">
														{
															topDevices && topDevices.length ?
																topDevices.map((device, key) => {
																	let deviceColor = device._id === 'desktop' ? 'turquoise' : device._id === 'mobile' || device._id === 'phone' ? 'amethyst' : device._id === 'tablet' ? 'nephritis' : 'turquoise';

																	let deviceImage = device._id === 'desktop' ? 'computer' : device._id === 'mobile' || device._id === 'phone' ? 'smartphone' : device._id === 'tablet' ? 'tablet' : 'computer';

																	return (
																		<div key={key} className={`deviceHolder text-white ${deviceColor}`}>
																			<div className="iconHolder text-center">
																				<img src={require(`../../../images/${deviceImage}.svg`)} alt="Device Image" className="img-fluid" />
																			</div>
																			<div className="detailHolder">
																				<strong className="deviceName text-capitalize">{device._id}</strong>
																				<hr></hr>
																				<p>No. of Clicks: <strong>{device.clicks}</strong></p>
																			</div>
																		</div>
																	)
																})
																:
																<div className="noDataHolder text-center">
																	<div className="noDataInner">
																		<span className="d-inline-block my-3">No Device Found</span>
																	</div>
																</div>
														}
													</div>
												</div>
											</div>
											<div className="col-md-6 col-12">
												<div className="topThreeFloinkHolder mb-3 h-100">
													<div className="info-wrapper--heading-holder">
														<h4 className="info-wrapper--header">Top Performing Social Platforms</h4>
													</div>
													<div className="topDevicesHolder">
														{
															topSocialPlatforms && topSocialPlatforms.length ?
																topSocialPlatforms.map((platform, key) => {
																	let platformColor = platform._id === 'Facebook' ? 'facebook' : platform._id === 'Twitter' ? 'twitter' : '';

																	let platformImage = platform._id === 'Facebook' ? 'facebook-f' : platform._id === 'Twitter' ? 'twitter' : '';

																	return (
																		<div key={key} className={`deviceHolder text-white ${platformColor}`}>
																			<div className="iconHolder text-center">
																				<i className={`fab fa-${platformImage}`}></i>
																			</div>
																			<div className="detailHolder">
																				<strong className="deviceName text-capitalize">{platform._id}</strong>
																				<hr></hr>
																				<p>No. of Clicks: <strong>{platform.clicks}</strong></p>
																			</div>
																		</div>
																	)
																})
																:
																<div className="noDataHolder text-center">
																	<div className="noDataInner">
																		<span className="d-inline-block my-3">No social platform found</span>
																	</div>
																</div>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default DashboarLinksListing;
