import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { ENV } from '../../config/config';
var CryptoJS = require("crypto-js");

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {
    var token = localStorage.getItem("loginToken");
    if (token) {
      this.setState({ redirect: true });
      //TODO : time check login
      //     var loginTime = parseInt(localStorage.getItem('loginTime')) / 1000;
      //     var timeNow = (new Date().getTime()) / 1000;
      //     var timeDifference = timeNow - loginTime;
      //     var timeDifferenceInMinutes = timeDifference / 60;
      //     if (timeDifferenceInMinutes < window.loginExpiresAfter) {
      //         this.setState({ redirect: true });
      //     }
      //     else {
      //         this.setState({ redirect: false });
      //     }
      // }
      // else {
      //     this.setState({ redirect: false });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    const { email, username, firstName, lastName, password, rePassword } = this.state;
    e.preventDefault();

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,50}$/;
    const lenthReg = /^.{8,50}$/;
    const lowerReg = /^(?=.*[a-z])/;
    const upperReg = /^(?=.*[A-Z])/;
    const digitReg = /^(?=.*\d)/;
    const specialReg = /^(?=.*[!@.#$%^&*])/;

    if (username === "") {
      this.setState({ errors: "Username is Required" });
      return;
    }
    if (firstName === "") {
      this.setState({ errors: "First name is Required" });
      return;
    }
    if (lastName === "") {
      this.setState({ errors: "Last name is Required" });
      return;
    }
    if (email === "") {
      this.setState({ errors: "Email is Required" });
      return;
    } else if (!email.match(regexp)) {
      this.setState({ errors: "Email is Invalid" });
      return;
    } else if (password === "" || rePassword === "") {
      this.setState({ errors: "Both password fields are required" });
      return;
    } else if (!password.match(lenthReg)) {
      this.setState({ errors: "Password must have minimum eight characters" });
      return;
    }
    else if (!password.match(lowerReg)) {
      this.setState({ errors: "Password must have one Lower-case letter." });
      return;
    }
    else if (!password.match(upperReg)) {
      this.setState({ errors: "Password must have one Upper-case letter." });
      return;
    }
    else if (!password.match(digitReg)) {
      this.setState({ errors: "Password must have one number." });
      return;
    }
    else if (!password.match(specialReg)) {
      this.setState({ errors: "Password must have one Special Character." });
      return;
    }
    else if (password !== rePassword) {
      this.setState({ errors: "Password doesn't match." });
      return;
    }
    // else if (password === rePassword && password.length < 6) {
    //   this.setState({ errors: "Password should be greater than 6 chracters" });
    //   return;
    // } 
    else if (username === password) {
      this.setState({ errors: "Username and Password cannot be same." });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/register", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          primaryEmail: email,
          username: username,
          firstName: firstName,
          lastName: lastName,
          password: password
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            let subscriptionPlanDetails = result.defaultSubscriptionPackage;
            delete result.defaultSubscriptionPackage;
            let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
            localStorage.setItem("spd", encSubscriptionPlanDetails);
            localStorage.setItem("LoginSession", JSON.stringify(result));
            localStorage.setItem("loginToken", result.data.loginToken);
            localStorage.setItem("loginTime", new Date().getTime());
            this.setState({ redirect: true });
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  }

  componentDidMount = () => {
    this.props.getSiteSetting();
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/dashboard" />;
    }

    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <React.Fragment>
        <div style={{ height: '100vh', display: 'flex', padding: '25px 0', overflowY: 'auto' }}>
          <div className="container-box" style={{ margin: 'auto' }}>
            <h1 className="header-logo">
              <Link to="/" rel="nofollow">
                {
                  settings ?
                    <img src={settings.siteLogo}
                      alt="Site Logo"
                    /> : ''
                }
              </Link>
            </h1>
            <form id="sign-up" onSubmit={this.handleSubmit}>
              <div className="header">
                <h3 className="tagline">Create a free account</h3>
              </div>
              <div className="susi-fields-wrapper">
                <fieldset>
                  <label for="username">
                    <input
                      className="text"
                      type="text"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      placeholder="Username"
                    />
                    <span className="error-message" />
                  </label>
                  <label for="firstName">
                    <input
                      className="text"
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      placeholder="First Name"
                    />
                    <span className="error-message" />
                  </label>
                  <label for="lastName">
                    <input
                      className="text"
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      placeholder="Last Name"
                    />
                    <span className="error-message" />
                  </label>
                  <label for="email">
                    <input
                      className="text"
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Email address"
                    />
                    <span className="error-message" />
                  </label>
                  <label for="password" className="password-block">
                    <input
                      className="text"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Password"
                    />
                    <span className="error-message" />
                  </label>
                  <label for="rePassword">
                    <input
                      className="text"
                      type="password"
                      name="rePassword"
                      value={this.state.rePassword}
                      onChange={this.handleChange}
                      placeholder="Password again"
                    />
                    <span className="error-message" />
                  </label>
                  <div className="switch to-sign-in password-tip">
                    <span className="gray-link">
                      {/* <span>Password must contain:</span> */}
                      <ul className="list-unstyled">
                        {/* <li>- Minimum of 8 characters</li>
                        <li>- Minimum of 1 digit</li>
                        <li>- Atleast 1 special character</li>
                        <li>- Upper and Lower case letters.</li> */}
                        <li>Password should be minimum of 8 characters and must include at least : An uppercase letter, a lowercase letter, a number and a special character.</li>
                      </ul>
                      {/* Password should be 6 or more characters at least one numeric digit, one uppercase and one lowercase letter. */}
                    </span>
                  </div>
                  {Object.keys(this.state.errors).length > 0 &&
                    this.state.errors ? (
                      <div className="alert alert-danger">{this.state.errors}</div>
                    ) : (
                      ""
                    )}
                  <input
                    id="submit"
                    type="submit"
                    className="button button-primary sign-up-in"
                    value="Create account"
                    tabIndex="8"
                  />
                </fieldset>
              </div>
            </form>
            <p className="separator t-center">
              <span>Or</span>
            </p>
            <div className="social-sign-in">
              <span className="susi-with">SIGN IN WITH:</span>
              <a
                rel="nofollow"
                href={window.APIURL + "auth/google"}
                className="susi-btn google-susi-btn button"
                data-network="google"
              >
                <img
                  src={require("../../images/google.svg")}
                  alt="Google"
                  width="20"
                />
              </a>
              <a
                rel="nofollow"
                href={window.APIURL + "auth/facebook"}
                className="susi-btn facebook-susi-btn button"
                data-network="facebook"
              >
                <img
                  src={require("../../images/facebook.svg")}
                  alt="Facebook"
                  width="20"
                />
              </a>
              <a
                rel="nofollow"
                href={window.APIURL + "auth/twitter"}
                className="susi-btn twitter-susi-btn button"
                data-network="twitter"
              >
                <img
                  src={require("../../images/twitter.svg")}
                  alt="Twitter"
                  width="22"
                />
              </a>
            </div>
            <div className="text-center">
              <div className="switch to-sign-in terms-and-conditions">
                <span className="gray-link">
                  By creating an account, you agree to <br />FLO.ink's
                <Link to="/resources/terms-of-services"> Terms of Service</Link> and{" "}
                  <Link to="/resources/privacy">Privacy Policy.</Link>
                </span>
              </div>
              <div className="switch to-sign-in">
                <span className="gray-link">Already have an account?</span>{" "}
                <Link to="userLogin">Sign In</Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Register));