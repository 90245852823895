import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';

class VerifyToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      token: "",
      errors: "",
      redirect: false
    };
    // this.componentWillMount = this.componentWillMount.bind(this);
  }

  componentWillMount = () => {
    if (localStorage.getItem("_id")) {
      this.setState({ userId: localStorage.getItem("_id") });
    } else {
      this.props.history.push("/web/userLogin");
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    const { userId, token } = this.state;
    e.preventDefault();
    if (token === "") {
      this.setState({ errors: "Token is Required" });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/two-step-auth", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          _id: userId,
          token: token
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            localStorage.setItem("LoginSession", JSON.stringify(result));
            localStorage.setItem("loginToken", result.data.loginToken);
            localStorage.setItem("loginTime", new Date().getTime());
            this.setState({ redirect: true });
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      // return <Redirect to="/dashboard" />;
      // alert('tis...')
      this.props.history.push("/dashboard")
    }
    return (
      <React.Fragment>
        <div style={{ height: '100vh', display: 'flex', padding: '25px 0', overflowY: 'auto' }}>
          <div className="container-box" style={{ margin: 'auto' }}>
            <h1 className="header-logo">
              <Link to="/web/">
                <img alt="" src={require("../../images/logo.png")} />
              </Link>
            </h1>
            <form id="sign-in" onSubmit={this.handleSubmit}>
              <div className="header">
                <h3 className="tagline">Verification Code</h3>
              </div>

              <div className="susi-fields-wrapper">
                <fieldset>
                  <label htmlFor="token">
                    <input
                      className="text"
                      type="text"
                      name="token"
                      value={this.state.token}
                      onChange={this.handleChange}
                      placeholder="Verification Code"
                    />
                  </label>
                  {Object.keys(this.state.errors).length > 0 &&
                    this.state.errors ? (
                      <div className="alert alert-danger">{this.state.errors}</div>
                    ) : (
                      ""
                    )}
                  <input
                    type="submit"
                    className="button button-primary sign-up-in"
                    value="Sign In"
                    tabIndex="5"
                  />
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default VerifyToken;
