import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      isLoader: false,
      userId: '',
      settings: {}
    };
  }

  componentWillMount = () => {
    console.log('MainNavbar')
    if (localStorage.getItem("loginToken")) {
      this.setState({
        showLogin: false,
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
      }, () => {
        this.checkUserSession(this.state.userId)
      });
    }
  }

  checkUserSession = (id) => {
    fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
        } else if (result.status === false) {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', result.message, 'error')
        } else {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  render() {
    let settings = this.props.settings.siteSettingsRes ? this.props.settings.siteSettingsRes.site : {}

    return (
      <React.Fragment>
        <header>
          <div className="main-header">
            <div className="container">
              <div className="row">
                <nav
                  className="navbar navbar-expand-lg navbar-light "
                  id="mainNav"
                >
                  <Link
                    className="navbar-brand js-scroll-trigger logo"
                    to='/'
                  >
                    {
                      settings ?
                        <img src={settings.siteLogo}
                          alt="Site Logo"
                          className="img-fluid"
                        /> : ''
                    }
                  </Link>
                  <button
                    className="navbar-toggler navbar-toggler-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarResponsive"
                  >
                    <ul className="navbar-nav ml-auto my-2 my-lg-0">
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link js-scroll-trigger"
                          to='/why-floink'
                        >
                          Why Flo.ink?
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link js-scroll-trigger"
                          to='/solutions'
                        >
                          Solutions
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link js-scroll-trigger"
                          to='/features'
                        >
                          Features
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link js-scroll-trigger"
                          to='/pricing'
                        >
                          Pricing
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link js-scroll-trigger"
                          to='/resources'
                        >
                          Resources
                        </NavLink>
                      </li>

                      {this.state.showLogin === true ?
                        <React.Fragment>
                          <li className="nav-item upper">
                            <Link
                              to="/userLogin"
                              className="nav-link js-scroll-trigger btn btn-white"
                            >
                              LOGIN
                        </Link>
                          </li>
                          <li className="nav-item colr">
                            <Link
                              className="nav-link js-scroll-trigger btn btn-orange"
                              to="/userRegister"
                            >
                              SIGNUP
                        </Link>
                          </li>

                        </React.Fragment>
                        :
                        ""
                      }

                      {/* <li className="nav-item button">
                        <Link
                          className="nav-link js-scroll-trigger"
                          to='/pricing'
                        >
                          Get Enterprise
                        </Link>
                      </li> */}
                      {this.state.showLogin != true ?

                        <React.Fragment>
                          <li className="nav-item colr">
                            <Link
                              to="/"
                              className="nav-link js-scroll-trigger"
                            >
                              My Dashboard
                        </Link>
                          </li>
                        </React.Fragment>
                        :
                        ""
                      }
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MainNavbar));