import { ADD_CONTACT, BEFORE_CONTACT, ADD_QUOTE, BEFORE_QUOTE, } from '../../../redux/types';

const initialState = {
    contactUsRes: {},
    getQuoteRes: {},
    contactUsAuth: false,
    getQuoteAuth: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CONTACT:
            return {
                ...state,
                contactUsRes: action.payload,
                contactUsAuth: true
            }
        case BEFORE_CONTACT:
            return {
                ...state,
                contactUsAuth: false
            }
        case ADD_QUOTE:
            return {
                ...state,
                getQuoteRes: action.payload,
                getQuoteAuth: true
            }
        case BEFORE_QUOTE:
            return {
                ...state,
                getQuoteAuth: false
            }
        default:
            return {
                ...state
            }
    }
}
