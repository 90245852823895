import React, { Component } from 'react';
import { Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { ENV } from '../../../config/config';
import countriesCities from "countries-cities";
import moment from 'moment';
import swal from 'sweetalert';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery'

require('bootstrap-select');

class DomainPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchedDomains: [],
            domainName: '',
            findingDomain: false,
            isError: false,
            errorMessage: '',
            findingDomainPrice: false,
            requestMessage: '',
            requestInProgress: false,
            ///////////////////////
            fullName: '',
            country: '',
            zipCode: '',
            email: '',
            phoneNumber: '',
            cardNumber: '',
            expiryMonth: '',
            expiryYear: '',
            CVV: '',
            cardType: 1,
            countries: [],
            years: [],
            isChecked: false,
            errors: '',
            paypalClicked: false,
        }
    }

    componentWillMount() {
        let encryptedDomain = this.props.match.params.domainHash;
        let domainName = atob(encryptedDomain);
        this.setState({ domainName: domainName });

        let Contry = countriesCities.getCountries();
        var start = moment(new Date()).format("YYYY");
        let yearArray = [];
        for (let year = 1; year < 40; year++) {
            yearArray.push(start);
            start = parseInt(start) + 1;
        }
        this.setState({
            years: yearArray,
            countries: Contry
        },
            (() => {
                setTimeout(() => {
                    $('.my-select').selectpicker();
                }, 1000);
            })
        )
        this.getDomainPrice(domainName);
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    onChange = (e) => {
        let regex = new RegExp("^[0-9]*$");
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'CVV') {
            if (!regex.test(value)) {
                // valid = false;
                e.preventDefault()
                e.nativeEvent.preventDefault()
            } else {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    getDomainPrice = (domainName) => {
        let body = {
            domainName: domainName
        }
        if (domainName && domainName !== '') {
            this.setState({ isError: false, errorMessage: '', requestInProgress: true, requestMessage: 'Calculating price. Please wait...', findingDomainPrice: true });
            fetch(ENV.url + 'custom-domain/domain-price', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.status === true) {
                    let domainPrice = result.data;
                    this.setState({ domainPrice: domainPrice.price, domainName: domainPrice.domain, isError: false, errorMessage: '', requestInProgress: false, requestMessage: '', findingDomainPrice: false });
                } else if (result.status === false) {
                    swal('Error', result.message, 'error');
                    this.setState({ isError: false, errorMessage: '', requestInProgress: false, requestMessage: '', findingDomainPrice: false });
                } else {
                    swal('Error', 'Contact Admin', 'error');
                    this.setState({ isError: false, errorMessage: '', requestInProgress: false, requestMessage: '', findingDomainPrice: false });
                }
            });
        }
        else {
            this.setState({ isError: true, errorMessage: "Please select domain name to proceed.", requestInProgress: false, requestMessage: '', findingDomainPrice: false });
        }
    }

    completPurchase = () => {
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        if (id === '' || id === undefined || id === 0 || id === null) {
            this.props.history.push('/userLogin');
        }
        this.setState({ errors: '' });
        const { fullName, phoneNumber, country, zipCode, email, cardNumber, expiryMonth, expiryYear, CVV, cardType } = this.state;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (fullName === '') {
            this.setState({ errors: 'Full Name is required' })
        }
        else if (country === '') {
            this.setState({ errors: 'Country is required' });
            return;
        }
        else if (zipCode === '') {
            this.setState({ errors: 'Zip Code is required' });
            return;
        }
        // else if (zipCode && zipCode.length !== 5) {
        //     this.setState({ errors: 'Zip Code must be five digits' });
        //     return;
        // }
        else if (!email.match(regexp)) {
            this.setState({ errors: 'Email is Invalid' });
            return;
        }
        else if (phoneNumber === '') {
            this.setState({ errors: 'Phone Number is required' })
        }
        else if (cardNumber === '') {
            this.setState({ errors: 'Card Number is required' })
        }
        else if (cardNumber.replace(/\s/g, '').length !== 16) {
            this.setState({ errors: 'Card Number is not valid' })
        }
        else if (cardType === '') {
            this.setState({ errors: 'Card Type is required' })
        }
        else if (expiryMonth === '') {
            this.setState({ errors: 'Expire Month is required' })
        }
        else if (expiryYear === '') {
            this.setState({ errors: 'Expire Year is required' })
        }
        else if (CVV === '') {
            this.setState({ errors: 'CVV is required' })
        }
        else {
            this.setState({ errors: '', isError: false, errorMessage: '', requestInProgress: true, requestMessage: 'Processing Payment. Please wait!', buyingDomain: true });
            fetch(window.APIURL + 'custom-domain/buy-domain', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "domainName": this.state.domainName,
                    "userId": id,
                    "fullName": fullName,
                    "country": country,
                    "zipCode": zipCode,
                    "email": email,
                    "phoneNumber": phoneNumber,
                    "cardNumber": cardNumber.replace(/\s/g, ''),
                    "expiryMonth": expiryMonth,
                    "expiryYear": expiryYear,
                    "CVV": CVV,
                    "cardType": cardType,
                    "amount": this.state.domainPrice
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                this.setState({ errors: '', isError: false, errorMessage: '', requestInProgress: false, requestMessage: '', buyingDomain: false });
                if (result.status === true) {
                    var span = document.createElement("span");
                    span.innerHTML = "<h4>It will be activated and available after verification.</h4><p><strong>NOTE:</strong> Domain verification may take upto 24 - 48 hours. So you'll be able to use it once it is verified.</p>";
                    // console.log("Overe....")
                    swal('Success', result.message, 'success')
                        .then(() => {
                            swal({
                                content: span,
                            });
                        });
                    // console.log("Here....")
                    this.props.history.push('/organization/domain');
                } else if (result.status === false) {
                    swal('Error', result.message, 'error')
                } else {
                    swal('Error', 'Contact Admin', 'error')
                }
            });
        }
    }

    onSubmitPaypal(e) {
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        e.preventDefault();
        let { domainName, domainPrice } = this.state;
        // console.log('.../Billing', billingMethod)

        let url = window.APIURL + 'custom-domain/paypal/authenticate';

        this.setState({ paypalClicked: true })
        fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "domainName": domainName,
                "userId": id,
                "amount": domainPrice
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                this.setState({ paypalClicked: false })
                window.location.href = result.link;
                // }
            } else if (result.status === false) {
                this.setState({ paypalClicked: false })
                swal('Error', result.message, 'error')
            } else {
                this.setState({ paypalClicked: false })
                swal('Error', 'Contact Admin', 'error')
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {/* <div className="admin-section--MAIN">
                    <div className="admin-section--top">
                        <h2 className="admin-section--header">Purchase Domain</h2>
                        <Link className="ml-2" to='/organization/purchase'>Go Back</Link>
                        <div className="admin-section--item" style={{borderBottom:'none'}}></div>
                    </div>
                </div> */}
                <React.Fragment>
                    <div className="container">
                        <div className="subscription-payment--layout">
                            <form>
                                <div className="row">
                                    <div className="col-12">
                                        <Link to='/organization/purchase' className="go-back mb-3">
                                            <span className="back_btn-icon" alt="back_btn-icon">
                                                <i className="fas fa-chevron-left"></i>
                                            </span> Go Back
                                    </Link>
                                        <h2>Purchase Domain</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="subscription-payment--left-panel">
                                            <h3>Enter Payment Details</h3>
                                            <section className="options options-contact row">
                                                <div className="row col-12">
                                                    <div className="col">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN   ">
                                                                <label className="input-field--label">Full Name</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <input
                                                                        className="input-field--input"
                                                                        type="text"
                                                                        name="fullName"
                                                                        value={this.state.fullName}
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12">
                                                    <div className="col-md-6">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN   ">
                                                                <label className="input-field--label">Country</label>
                                                                <select className="my-select" name="country" value={this.state.country} onChange={this.onChange}>
                                                                    <option value="">Select Country</option>
                                                                    {
                                                                        this.state.countries.length > 0 ?
                                                                            this.state.countries.map((val, key) => {
                                                                                return (
                                                                                    <option key={key} value={val}>{val}</option>
                                                                                )
                                                                            }) : <option value="">No Record Found</option>
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN   ">
                                                                <label className="input-field--label">Zip Code</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <input
                                                                        className="input-field--input"
                                                                        autoComplete="false"
                                                                        type="text"
                                                                        min="0"
                                                                        maxLength="8"
                                                                        name="zipCode"
                                                                        value={this.state.zipCode}
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12">
                                                    <div className="col-md-6">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN   ">
                                                                <label className="input-field--label">Email Address</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <input
                                                                        className="input-field--input"
                                                                        autoComplete="false"
                                                                        type="text"
                                                                        name="email"
                                                                        value={this.state.email}
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN   ">
                                                                <label className="input-field--label">Phone Number</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <input
                                                                        className="input-field--input"
                                                                        autoComplete="false"
                                                                        type="text"
                                                                        name="phoneNumber"
                                                                        value={this.state.phoneNumber}
                                                                        onChange={this.onChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <h3>Enter Credit Card Information</h3>
                                            <section className="row options options-cc ">
                                                <div className="row col-12">
                                                    <div className=" col-sm-12 col-md-6">
                                                        <div className="col pl-0 pr-0">
                                                            <div className="input-field--wrapper">
                                                                <div className="input-field--MAIN false undefined">
                                                                    <label className="input-field--label">Credit Card Number</label>
                                                                    <div className="input-field--input-wrapper">
                                                                        <div className="StripeElement StripeElement--empty">
                                                                            <div className="__PrivateStripeElement" style={{ margin: "0px !important", padding: "0px !important", border: "none !important", display: "block !important", background: "transparent !important", position: "relative !important", opacity: "1 !important" }}>
                                                                                <InputMask
                                                                                    mask="9999 9999 9999 9999"
                                                                                    maskChar={null}
                                                                                    alwaysShowMask={false}
                                                                                    name="cardNumber"
                                                                                    value={this.state.cardNumber}
                                                                                    onChange={this.onChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN false undefined">
                                                                <label className="input-field--label">Card Type</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <div className="StripeElement StripeElement--empty">
                                                                        <div className="__PrivateStripeElement"
                                                                            style={{
                                                                                margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                                display: "block !important", background: "transparent !important", position: "relative !important",
                                                                                opacity: "1 !important"
                                                                            }}>
                                                                            <select className="my-select" name="cardType" value={this.state.cardType} onChange={this.onChange}>
                                                                                <option value="">Select type of card</option>
                                                                                <option value="1">Visa</option>
                                                                                <option value="2">Mastercard</option>
                                                                                <option value="3">American Express</option>
                                                                                <option value="4">Discover</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row col-12">

                                                    <div className="col-md-4  ">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN false undefined">
                                                                <label className="input-field--label">Exp Month</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <div className="StripeElement StripeElement--empty">
                                                                        <div className="__PrivateStripeElement"
                                                                            style={{
                                                                                margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                                display: "block !important", background: "transparent !important", position: "relative !important",
                                                                                opacity: "1 !important"
                                                                            }}>
                                                                            <select className="my-select" id="exampleSelect" name="expiryMonth" value={this.state.expiryMonth} onChange={this.onChange}>
                                                                                <option value="">MM</option>
                                                                                <option value="01">01</option>
                                                                                <option value="02">02</option>
                                                                                <option value="03">03</option>
                                                                                <option value="04">04</option>
                                                                                <option value="05">05</option>
                                                                                <option value="06">06</option>
                                                                                <option value="07">07</option>
                                                                                <option value="08">08</option>
                                                                                <option value="09">09</option>
                                                                                <option value="10">10</option>
                                                                                <option value="11">11</option>
                                                                                <option value="12">12</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 pr-0 pl-0">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN false undefined">
                                                                <label className="input-field--label">Exp Year</label>
                                                                <div className="input-field--input-wrapper">
                                                                    <div className="StripeElement StripeElement--empty">
                                                                        <div className="__PrivateStripeElement"
                                                                            style={{
                                                                                margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                                display: "block !important", background: "transparent !important", position: "relative !important",
                                                                                opacity: "1 !important"
                                                                            }}>
                                                                            <select className="my-select" name="expiryYear" value={this.state.expiryYear} onChange={this.onChange}>
                                                                                <option value="">YYYY</option>
                                                                                {
                                                                                    this.state.years.length > 0 ?
                                                                                        this.state.years.map((val, key) => {
                                                                                            return (
                                                                                                <option key={key} value={val}>{val}</option>
                                                                                            )
                                                                                        }) : <option value="">No Year Available</option>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="input-field--wrapper">
                                                            <div className="input-field--MAIN false undefined">


                                                                <div className="input-field--input-wrapper">
                                                                    <div className="StripeElement StripeElement--empty">
                                                                        <div className="__PrivateStripeElement"
                                                                            style={{
                                                                                margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                                display: "block !important", background: "transparent !important", position: "relative !important",
                                                                                opacity: "1 !important"
                                                                            }}>
                                                                            <label className="input-field--label">CVV <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Your CVV code is 3-digit number on the back of your card."></i></label>

                                                                            <ReactTooltip />

                                                                            <input
                                                                                className="__PrivateStripeElement-input"
                                                                                aria-hidden="true"
                                                                                aria-label=""
                                                                                type="text"
                                                                                maxLength="3"
                                                                                min="0"
                                                                                name="CVV"
                                                                                value={this.state.CVV}
                                                                                onChange={this.onChange}
                                                                                autoComplete="false" maxLength="4" style={{
                                                                                    border: "none !important", display: "block !important",
                                                                                    position: "absolute !important", height: "1px !important", top: "0px !important", left: "0px !important",
                                                                                    padding: "0px !important", margin: "0px !important", width: "100% !important", opacity: "0 !important",
                                                                                    background: "transparent !important", pointerEvents: "none !important", fontSize: "16px !important"
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>
                                            <div className="btn-paypal-holder">
                                                <div className="or-divider text-center">
                                                    <span className="d-inline-block align-top">OR</span>
                                                </div>
                                                <h3>Pay With Paypal</h3>
                                                <button className="btn-paypal" disabled={this.state.paypalClicked} type="button" onClick={(e) => this.onSubmitPaypal(e)} >
                                                    <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" alt="Check out with PayPal" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="subscription-payment--right-panel">
                                            <div>
                                                <h3>Purchase Domain</h3>
                                                <p className="subscription-payment--description">{this.state.domainName && this.state.domainName !== '' ? this.state.domainName : ''}</p>
                                            </div>
                                            <div>
                                                {
                                                    <div>
                                                        {
                                                            this.state.domainPrice ?
                                                                <p className="subscription-payment--price">
                                                                    Billed Now: ${this.state.domainPrice}
                                                                </p>
                                                                : ''
                                                        }
                                                        {
                                                            this.state.requestInProgress && this.state.requestMessage !== '' ?
                                                                <div className="alert alert-primary">{this.state.requestMessage}</div>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                }
                                                <div className="subscription-payment--legal">
                                                    <div className="subscription-payment--legal-checkbox ">
                                                        <div className="checkbox--ALTERNATE" id="legal">
                                                            <input id="legal" type="checkbox" className="checkbox--input" checked={this.state.isChecked} onChange={this.toggleChange} />
                                                            <label tabIndex="7" htmlFor="legal" className="checkmark-icon checkbox-icon"></label>
                                                        </div>
                                                        <p>By clicking here, you agree to the FLO.ink
                                                <Link to='/resources/terms-of-services' target="_blank" > Terms of Service</Link>, <Link to='/resources/privacy' target="_blank" >Privacy Policy</Link> . If you do not agree to these properties, you may not access the FLO.ink paid service offerings.</p>
                                                    </div>
                                                </div>

                                                {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                                                    <span className="alert alert-danger">
                                                        {this.state.errors}
                                                    </span> : ''}

                                                <button disabled={!this.state.isChecked || this.state.requestInProgress || !this.state.domainPrice} className="button--SOLID-SECONDARY my-4" type="button" onClick={() => this.completPurchase()} tabIndex="11" id="">Complete Purchase</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            </React.Fragment >
        )
    }
}

export default DomainPayment;