import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import swal from "sweetalert";

var _ = require("lodash");
var fp = require("lodash/fp");

class DashboardCharts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      floInks: [],
      linkstats: {},
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: 'Number of Links created per day - Last 1 Month'
        },
        subtitle: {
          text: document.ontouchstart === undefined ?
            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'Created Links Count'
          },
          min: 0,
          tickInterval: 2
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },

        series: [{
          type: 'area',
          cursor: 'pointer',
          name: 'No. of Links',
          data: []
        }],
      },
      hoverData: null,
      isLoader: true
    };
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("LoginSession"));
    if (user)
      this.getChartData(user.data._id); // get past 30 days stats
  }

  setHoverData = e => {
    this.setState({ hoverData: e.target.category });
  };

  setAllStates(links, stats) {
    // Not needed this func. anymore
    // this.setChart(links);
    this.setState({
      floInks: links,
      linkstats: stats
    });
  }

  setChart = links => {
    var dataToDisplay = fp.map(fp.pick(["createdAt"]), links);
    _.forEach(dataToDisplay, function (obj) {
      _.set(obj,
        "createdAt",
        moment(obj.createdAt).format("MMMM DD YYYY"));
      _.set(obj, "date", moment(obj.createdAt).date());
    });

    var resultByDate = _.groupBy(dataToDisplay, function (data) {
      return data.createdAt;
    });

    let chartDays = 29;
    let datesForChart = [];
    for (var i = chartDays; i >= 0; i--) {
      datesForChart.push(
        moment()
          .subtract(i, "days")
          .format("MMMM DD YYYY")
      );
    }

    let countsForChar = [];
    datesForChart.forEach(element => {
      if (resultByDate[element] !== undefined) {
        countsForChar.push(resultByDate[element].length);
      } else {
        countsForChar.push(0);
      }
    });

    var chartOptions = { ...this.state.chartOptions };
    chartOptions.xAxis.categories = datesForChart;
    chartOptions.series[0].data = countsForChar;
    this.setState({ chartOptions });
  };

  getChartData(userId) {
    fetch(`${window.APIURL}q-link/month-stats?userId=${userId}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(result => {
        if (result.status) {
          var chartOptions = { ...this.state.chartOptions };
          chartOptions.xAxis.categories = result.monthLinks ? result.monthLinks.datesForChart : [];
          chartOptions.series[0].data = result.monthLinks ? result.monthLinks.dataForChart : [];

          this.setState({
            isLoader: false,
            chartOptions
          });
        } else
          swal('Error', 'Contact Admin', 'error')
      });
  }

  render() {
    const { chartOptions, hoverData } = this.state;
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default DashboardCharts;
