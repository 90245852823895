import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import $ from "jquery";
import { ENV } from '../../config/config';
import ReactTooltip from 'react-tooltip'
import Notifications, { notify } from 'react-notify-toast';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

var CryptoJS = require("crypto-js");

class StatsDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            userId: JSON.parse(localStorage.getItem('LoginSession')).data._id,
            startDate: moment(),
            endDate: moment(),
            statsLoader: true,
            enterprise: false,
            dashboardStats: {},
            topPerformers: [],
            topDevices: [],
            locationOptions: {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Top Locations'
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                xAxis: {
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: 'No. of clicks'
                    },
                    min: 0,
                    tickInterval: 2
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:14px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> clicks<br/>'
                },

                series: [
                    {
                        name: "Location",
                        cursor: 'pointer',
                        colorByPoint: true,
                        data: []
                    }
                ]
            },
            showLocations: false, // show top locations chart
            channelsOptions: {
                chart: {
                    type: "pie",
                    size: 300
                },
                title: {
                    text: 'Top Performing Campaigns & Channels'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.y}'
                        }
                    }
                },
                series: [
                    {
                        name: 'No. of clicks',
                        cursor: 'pointer',
                        colorByPoint: true,
                        data: []
                    }
                ]
            },
            showChannels: false, // show top campaigns & channels chart
            topSocialPlatforms: []
        }
    }

    componentWillMount() {
        // let user = JSON.parse(localStorage.getItem('LoginSession'));
        // let userId = user.data._id;
        let subscriptionPlanDetails = localStorage.getItem("spd");
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        if (!subscriptionPlanDetails) {
            localStorage.removeItem("loginToken");
            localStorage.removeItem("LoginSession");
            localStorage.removeItem("loginTime");
            window.location.href = "/web/userLogin";
            return;
        }
        let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
        decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
        if (!decryptedSubscriptionPlanDetails.dashboardView) {
            this.props.history.push("/organization/detail");
            return;
        }
        if (decryptedSubscriptionPlanDetails)
            this.setState({
                linkFiltering: decryptedSubscriptionPlanDetails.linkFiltering,
                linkHistory: parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1,
            }, () => {
                // set start date & end date according to history limits
                let { linkHistory } = this.state;
                let startDate = moment().subtract(linkHistory, 'days');
                let endDate = moment();

                this.setState({ startDate, endDate }, () => {
                    this.getDashboardStats();
                });
            });
        this.setState({
            analyticsDataExport: decryptedSubscriptionPlanDetails.analyticsDataExport,
            socialPosting: decryptedSubscriptionPlanDetails.socialPosting,
            qrCodeGenerate: decryptedSubscriptionPlanDetails.qrCodeGenerate,
        });
        // this.getDashboardStats();
        this.getEnterpriseCheck();
        this.checkUserSession(id);
        setTimeout(() => {
            this.hideSearch();
        }, 200)
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                } else if (result.status === false) {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal('Error', result.message, 'error')
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal('Error', 'Contact Admin', 'error')
                }
            });
    }

    hideSearch = () => {
        this.props.hideSearch("off");
    }

    getEnterpriseCheck = () => {
        fetch(window.APIURL + 'subscription-package/check-enterprise', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                this.setState({
                    enterprise: result.result.isEnterprise
                });
            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        })
    }

    getDashboardStats() {
        this.props.changeLoaderStatus(true);

        let { userId, startDate, endDate } = this.state;

        fetch(window.APIURL + 'dashboard/data', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId,
                startDate: moment(startDate).format('YYYY/MM/DD'),
                endDate: moment(endDate).format('YYYY/MM/DD')
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                return res.json();
                // this.setState({ errors: 'Incorrect user Id' });
                // return 'InvalidCredentialsProvided';
            }
        }).then((result) => {
            var locationOptions = { ...this.state.locationOptions };
            locationOptions.series[0].data = result.topLocations;

            var channelsOptions = { ...this.state.channelsOptions };
            channelsOptions.series[0].data = result.topChannels;

            if (result.status) {
                this.setState({
                    dashboardStats: result.dashboardStats,
                    statsLoader: false,
                    topPerformers: result.qLinks,
                    topDevices: result.topDevices,
                    locationOptions, channelsOptions,
                    showLocations: result.topLocations && result.topLocations.length ? true : false,
                    showChannels: result.topChannels && result.topChannels.length ? true : false,
                    topSocialPlatforms: result.topSocialPlatforms
                });
                this.props.changeLoaderStatus(false);

            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        })
    }

    handleEvent = (e, picker) => {
        this.setState(
            {
                dateFlag: true,
                startDate: picker.startDate,
                endDate: picker.endDate
            },
            () => {
                this.getDashboardStats();
            }
        );
    };

    // handleChange(e) {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     });
    // }

    exportStats = () => {
        let { userId } = this.state;
        window.location.href = window.APIURL + `csv/flo.ink_dashboard_data_${userId}.csv`;
    }

    editClick = (hash) => {
        this.props.editClick(hash)
    }

    shareHandler = (hash) => {
        this.props.shareLink(hash, false)
    }

    generateQR = (shortUrl) => {
        window.open(`/web/qr-code?shortUrl=${shortUrl}`, '_blank');
    }

    copyLink = (key, link) => {
        var fullLink = document.createElement("input");
        document.body.appendChild(fullLink);
        fullLink.value = `https://${link}/`;
        fullLink.select();
        document.execCommand("copy");
        let myColor = { background: '#cce5ff', text: "##004085" };
        notify.show('Copied to clipboard!', "custom", 3000, myColor);
        // $(".clipboard-" + key).show();
        // setTimeout(() => {
        //     $(".clipboard-" + key).hide();
        // }, 1800
        // )
        fullLink.remove();
    };

    upgradeToEnterprise = () => {
        this.props.history.push('/contact-us')
    }

    clearSearch() {
        let linkHistory = this.state.linkHistory;
        let startDate = moment().subtract(linkHistory, 'days');
        let endDate = moment();
        this.setState({ startDate, endDate, dateFlag: false }, () => {
            this.getDashboardStats();
        });
    }

    render() {
        const { dashboardStats, topDevices, topSocialPlatforms, startDate, endDate, userId, redirect, locationOptions, channelsOptions, showLocations, showChannels } = this.state;
        if (redirect) {
            return <Redirect to="/userLogin" />;
        }

        let totalDays = this.state.linkHistory ? this.state.linkHistory : 60;
        let minDate = moment().subtract(totalDays, "days");
        let maxDate = moment();

        let dateRange = {
            'Today': [moment(), moment()]
        };

        if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
            dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

        if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
            dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

        if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
            dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

        if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
            dateRange['This Month'] = [moment().startOf('month'), maxDate];

        if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
            dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        return (
            <React.Fragment>
                <Notifications />
                <div className="main-content">
                    <section className="top-history">
                        <div className="performance-summary-dashboard--MAIN">
                            <div className="top-info">
                                <div className="filter-list--DARK">
                                    {
                                        this.state.dateFlag ?
                                            <span className="cur-poi filter-list--clear mr-1 mb-1 " onClick={() => this.clearSearch()}>
                                                Clear All
                                        </span> : ""
                                    }
                                    {
                                        this.state.analyticsDataExport ?
                                            <button className="button--MAIN" tabIndex="0" onClick={this.exportStats}>Export</button>
                                            : ''
                                    }
                                    <div className="float-right">
                                        <a style={{ display: 'block' }}>
                                            <DateRangePicker
                                                id="selectDate"
                                                autoApply={false}
                                                locale={{ format: 'MMMM D, YYYY' }}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                onApply={this.handleEvent}
                                                minDate={minDate}
                                                maxDate={maxDate}
                                                style={{ display: 'block' }}
                                                ranges={dateRange}
                                                opens={"left"}
                                            >
                                                <input
                                                    type="button"
                                                    name="daterange"
                                                    className="form-control"
                                                    value={
                                                        this.state.startDate.format("MMMM D, YYYY") +
                                                        " - " +
                                                        this.state.endDate.format("MMMM D, YYYY")
                                                    }
                                                    className="date-input"
                                                    style={{ display: 'block', width: '100%' }}
                                                />
                                            </DateRangePicker>
                                        </a>
                                    </div>
                                </div>
                                {/* <h3 className="performance-summary--title"> {moment(startDate).format('MMMM DD')}&nbsp;<span className="performance-summary--utc-label">data in UTC</span></h3> */}
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-4 mb-3">
                                    <div className="performance-summary--total orange">
                                        <div className="left-content" ><h6 className="performance-summary--total-clicks">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : dashboardStats.totalClicks}</h6><h5 className="performance-summary--channels-header">Total Clicks</h5>
                                            <hr></hr>
                                            <h6 className="performance-summary--total-clicks">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : dashboardStats.totalQLinks}</h6><h5 className="performance-summary--channels-header">Floinks Created</h5></div>
                                        <span className="bitlinks_created-icon icon" alt="bitlinks_created-icon"><i className="fas fa-link"></i></span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-3">
                                    <div className="performance-summary--total green">
                                        <div className="left-content" ><h6 className="performance-summary--total-clicks">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : dashboardStats.totalCampaigns}</h6><h5 className="performance-summary--channels-header">Total Campaigns</h5>
                                            <hr></hr>
                                            <h6 className="performance-summary--total-clicks">{this.state.statsLoader ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : dashboardStats.totalChannels}</h6><h5 className="performance-summary--channels-header">Total Channels</h5></div>
                                        <span className="audience-icon icon" alt="audience-icon"><i className="fab fa-chromecast"></i></span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-3">
                                    <div className="performance-summary--total blue">

                                        <div className="performance-summary--upsell-wrapper left-content">
                                            <div className="performance-summary--upsell-title">Add a FLO.ink Network Domain</div>
                                            <div className="performance-summary--upsell-text">Learn about who's sharing your content</div>
                                            <hr></hr>
                                            {this.state.enterprise ?
                                                <div style={{ fontSize: "12px" }}> <button className="performance-summary--upsell-link mr-2" onClick={this.upgradeToEnterprise} >Upgrade to Enterprise
                                            </button>
                                                    {/* Coming Soon */}
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <span className="network-icon icon" alt="network-icon">
                                            <i className="fas fa-wifi"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="all-features">
                        <div className="row">
                            <div className="col-12">
                                <div className="info-wrapper--DARK">
                                    <div className="row mb-3">
                                        <div className="col-md-6 col-12">
                                            <div className="topThreeFloinkHolder h-100">
                                                <div className="info-wrapper--heading-holder">
                                                    <h4 className="info-wrapper--header">Top Performing FLO.inks</h4>
                                                    {/* <span style={{ margin: "25px 25px 0 10px", float: "left", fontSize: "11px" }}>{"(Last 30 Days)"}</span> */}
                                                    <Link to="/dashboard" className="float-right mt-3"><span className="info-wrapper--link">View All</span></Link>
                                                </div>
                                                <div className="All-links">
                                                    {
                                                        this.state.topPerformers && this.state.topPerformers.length > 0 ?
                                                            this.state.topPerformers.map((val, key) => {
                                                                return (
                                                                    <div className="items" key={key}>
                                                                        <h5>{val.title !== '' ? val.title : val.link}</h5>
                                                                        {/* <span className="clicks-icon" alt="clicks-icon">
                                                                    <i className="fas fa-signal"></i>
                                                                </span>
                                                                <h4 className="empty-state--header">No click data to report today</h4> */}
                                                                        <ReactTooltip />
                                                                        <div className="bitlink--copy-interface pl-0 pr-0">
                                                                            <div className="d-block">
                                                                                <a href={'http://' + val.domain + "/" + val.customizeBackHalf} className="bitlink--copyable-text" target="_blank">
                                                                                    <span className="bitlink--MAIN" tabIndex="-1">
                                                                                        {val.domain + "/" + val.customizeBackHalf}
                                                                                        <br className="showOnMobile" />
                                                                                        <span className={"clipboard clipboard-" + key}>Copied to cilpboard!</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                            <div className="item--action-buttons dashboardActionButtons">
                                                                                <button onClick={() => this.copyLink(key, val.domain + "/" + val.customizeBackHalf)} className="button--ACTION far fa-copy" type="button" tabIndex="0" data-clipboard-text={val.domain + "/" + val.customizeBackHalf} id="" data-effect="float" data-tip="Copy"></button>
                                                                                {
                                                                                    this.state.socialPosting ?
                                                                                        <button onClick={() => this.shareHandler(val.customizeBackHalf)} className="button--ACTION far fa-share-square" type="button" tabIndex="0" id="" data-effect="float" data-tip="Share"></button>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                                <button onClick={() => this.editClick(val.customizeBackHalf)} className="button--ACTION fas fa-edit" type="button" tabIndex="0" id="" data-effect="float" data-tip="Edit"></button>
                                                                                {
                                                                                    this.state.qrCodeGenerate ?
                                                                                        <button onClick={() => this.generateQR(`${val.domain}/${val.customizeBackHalf}`)} className="button--ACTION fas fa-qrcode" type="button" tabIndex="0" id="" data-effect="float" data-tip="QR Code"></button>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="date"><time className="item-detail--created-date" dateTime="2019-07-30">
                                                                            {
                                                                                moment(val.createdAt).format("dddd") +
                                                                                " " +
                                                                                moment(val.createdAt).date()
                                                                            }
                                                                        </time></div>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="empty-state--DARK noStatsRecordFound">
                                                                <div className="empty-state--gradient-wrapper" >
                                                                    <span>No Record Found</span>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="topThreeFloinkHolder h-100">
                                                <div className="info-wrapper--heading-holder">
                                                    <h4 className="info-wrapper--header">Top Performing Campaigns & Channels</h4>
                                                </div>
                                                {
                                                    showChannels ?
                                                        <PieChart highcharts={Highcharts} options={channelsOptions} />
                                                        :
                                                        <div className="noDataHolder text-center">
                                                            <div className="noDataInner">
                                                                <span className="d-inline-block my-3">No Data Found</span>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="topThreeFloinkHolder mb-3">
                                        <div className="info-wrapper--heading-holder">
                                            <h4 className="info-wrapper--header">Top Locations</h4>
                                        </div>
                                        {
                                            showLocations ?
                                                <HighchartsReact highcharts={Highcharts} options={locationOptions} />
                                                :
                                                <div className="noDataHolder text-center">
                                                    <div className="noDataInner">
                                                        <span className="d-inline-block my-3">No Location Found</span>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="topThreeFloinkHolder mb-3 h-100">
                                                <div className="info-wrapper--heading-holder">
                                                    <h4 className="info-wrapper--header">Top Devices</h4>
                                                </div>
                                                <div className="topDevicesHolder">
                                                    {
                                                        topDevices && topDevices.length ?
                                                            topDevices.map((device, key) => {
                                                                let deviceColor = device._id === 'desktop' ? 'turquoise' : device._id === 'mobile' || device._id === 'phone' ? 'amethyst' : device._id === 'tablet' ? 'nephritis' : 'turquoise';

                                                                let deviceImage = device._id === 'desktop' ? 'computer' : device._id === 'mobile' || device._id === 'phone' ? 'smartphone' : device._id === 'tablet' ? 'tablet' : 'computer';

                                                                return (
                                                                    <div key={key} className={`deviceHolder text-white ${deviceColor}`}>
                                                                        <div className="iconHolder text-center">
                                                                            <img src={require(`../../images/${deviceImage}.svg`)} alt="Device Image" className="img-fluid" />
                                                                        </div>
                                                                        <div className="detailHolder">
                                                                            <strong className="deviceName text-capitalize">{device._id}</strong>
                                                                            <hr></hr>
                                                                            <p>No. of Clicks: <strong>{device.clicks}</strong></p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="noDataHolder text-center">
                                                                <div className="noDataInner">
                                                                    <span className="d-inline-block my-3">No Device Found</span>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="topThreeFloinkHolder mb-3 h-100">
                                                <div className="info-wrapper--heading-holder">
                                                    <h4 className="info-wrapper--header">Top Performing Social Platforms</h4>
                                                </div>
                                                <div className="topDevicesHolder">
                                                    {
                                                        topSocialPlatforms && topSocialPlatforms.length ?
                                                            topSocialPlatforms.map((platform, key) => {
                                                                let platformColor = platform._id === 'Facebook' ? 'facebook' : platform._id === 'Twitter' ? 'twitter' : '';

                                                                let platformImage = platform._id === 'Facebook' ? 'facebook-f' : platform._id === 'Twitter' ? 'twitter' : '';

                                                                return (
                                                                    <div key={key} className={`deviceHolder text-white ${platformColor}`}>
                                                                        <div className="iconHolder text-center">
                                                                            <i className={`fab fa-${platformImage}`}></i>
                                                                        </div>
                                                                        <div className="detailHolder">
                                                                            <strong className="deviceName text-capitalize">{platform._id}</strong>
                                                                            <hr></hr>
                                                                            <p>No. of Clicks: <strong>{platform.clicks}</strong></p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="noDataHolder text-center">
                                                                <div className="noDataInner">
                                                                    <span className="d-inline-block my-3">No social platform found</span>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default StatsDashboard;
