import React, { Component } from "react";
import { Link } from "react-router-dom";
import { scrollTop } from '../../shared/common-functions';

class Features extends Component {
	componentDidMount() {
		scrollTop();
	}
	render() {
		return (
			<React.Fragment>
				<div className="staticPageHeader text-white">
					<div className="container">
						<h1>Features</h1>
					</div>
				</div>
				<section className="contentSection">
					<div className="container">
						<div className="sectionHeadingBlock text-center lessBottomPadding">
							<h2>Link Shortening</h2>
							<small className="d-block">Low-Cost Solution to Reinforce Brand Awareness</small>
						</div>
						<div className="sectionIntroText text-center">
							<p>Increase Brand Exposure -- Everything you need to track and manage campaigns within a single platform</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle bgLightBlue">
								<h3>Multiply Click-Throughs</h3>
								<p>Attract more clicks and build a consistent branding impression by controlling how your links look, starting with identifiable back-halves that show where audience will be navigated after they click. Create custom domains by replacing “FLO.ink” with a domain name that perfectly fits your brand.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Monitor Links Performance</h3>
								<p>Manage links easily and effectively. With several data points on shortened URL you create with FLO.ink, you can share more content to increase brand engagement. Easily monitor your links performance, demographics, click-throughs, and top referring platforms, etc., in one reliable interface.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Shorten Endless Number of Links</h3>
								<p>With FLO.ink’s open source, flexible API, you can quickly shorten as many URLs as you want. Share unique URLs at scale via email or SMS. Use custom referral links to empower your brand’s top influencers. Leverage endless possibilities for advertising to your users directly. Build Secure &amp; Reliable Links FLO.ink platform is secure, agile and incredibly flexible to power your digital and print marketing. All URLs under HTTPS with 100% private and secure click data and reporting. Intelligent data encryption guards shortened links against third-party breach and tampering.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="contentSection bgLightBlue">
					<div className="container">
						<div className="sectionHeadingBlock text-center lessBottomPadding">
							<h2>Branded Links</h2>
							<small className="d-block">Get Custom Domains</small>
						</div>
						<div className="sectionIntroText text-center">
							<p>Start Branding Your Links -- Setup multiple domains and subdomains in seconds and start sharing your branded links</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle">
								<h3>Scale up your Clicks</h3>
								<p>Have full control over your content. Branded Short Domains replace “FLO.ink” in your links with the brand name of your choice. It strengthens audience trust in your shortened links they click. Claiming your domain is a good investment when clicks go up.</p>
							</article>
							<article className="contentArticle">
								<h3>Branded Short Domains, for Free</h3>
								<p>FLO.ink combines custom domain setup with intelligent link management, giving you a scalable brand-building platform. With branded short domains, smart link routing, and real-time reporting, you’ll have unprecedented control to build, optimize, manage, and track your links.</p>
							</article>
							<article className="contentArticle">
								<h3>Amplify Your Links CTR by 35% &amp; More!</h3>
								<p>Drive CTR with branded short domains. Businesses that share custom links see an incredible boost in click through rates. Your audience is more likely to engage with your content when you give them a reason to click on your links by adding custom domains in your shortened links.</p>
							</article>
							<article className="contentArticle">
								<h3>Create Recognizable Shortlinks</h3>
								<p>Edit Short URL destination anytime. Take a step further with custom back-halves that you can edit to give your audience a preview of the destination they will be navigated after clicking the branded link. It builds trust and boosts click-through rates.</p>
							</article>
							<article className="contentArticle">
								<h3>Auto-Branding to Build Authority</h3>
								<p>Empower your marketing links by providing a strong quality signal through FLO.ink’s auto-branding about the content you share across every channel. Any shortened link using FLO.ink will be automatically converted into a branded link including your custom domain name.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="contentSection">
					<div className="container">
						<div className="sectionHeadingBlock text-center lessBottomPadding">
							<h2>Mobile Links</h2>
							<small className="d-block">Create Meaningful Mobile Experiences</small>
						</div>
						<div className="sectionIntroText text-center">
							<p>Create a Seamless User Experience -- Direct your audience to the right destination through unique mobile communications</p>
						</div>
						<div className="contentArticlesHolder">
							{/* <article className="contentArticle bgLightBlue">
								<h3>SMS Messaging</h3>
								<p>Access dedicated FLO.ink API endpoints to create high quantities of links at a very fast speed that can be used to initiate unique SMS communications for customer support updates, appointment reminders, service delays or changes and location-based promotions, etc. You can save per-character fees in SMS Messaging since you use short links.</p>
							</article> */}
							<article className="contentArticle bgLightBlue">
								<h3>Deep Link URLs</h3>
								<p>Use FLO.ink to create and use deep links to drive users directly into a specific point within your app or to route them to the app store for download. Deep link URLs create a seamless transition to mobile, drive app engagement, and encourage app installs.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>QR Codes</h3>
								<p>Generate and download QR code with every link shortened. Engage and inform your audience with smart, secure, next-gen QR codes and drive them from print to digital experiences. FLO.ink makes it simple to create, manage and track QR codes to visualize consumer engagement.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Campaign Management &amp; Analytics</h3>
								<p>An infrastructure that is set up to grow with you. FLO.ink’s real-time, interactive dashboard gives you a full view of metrics like app opens, downloads, site visits across every device, platform and geolocation. It helps you determine what’s working, and where and how your audience is engaging with your brand.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Powerful Integrations</h3>
								<p>Incorporate branded links into your existing web processes, workflows, tools, and tech stack with ease using powerful FLO.ink API. Create mobile deep links right from the tools you’re already using, like Salesforce, Hootsuite, Sprinklr, and more.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="contentSection bgLightBlue">
					<div className="container">
						<div className="sectionHeadingBlock text-center lessBottomPadding">
							<h2>Campaign Management &amp; Analytics</h2>
							<small className="d-block">Track and Optimize Every Digital Initiative</small>
						</div>
						<div className="sectionIntroText text-center">
							<p>Increase Campaigns Performance for Better -- Monitor every touchpoint to customize campaigns using FLO.ink’s analytics tools</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle">
								<h3>Say Goodbye to Worksheets</h3>
								<p>Build, schedule and share real-time reports based on the traffic your custom branded links receive. With FLO.ink, you can easily build custom links and track customers journey on each link and channel from a single, interactive dashboard. No hopping from one spreadsheet to another to check and evaluate campaign performance metrics.</p>
							</article>
							<article className="contentArticle">
								<h3>Capture 20+ Data Points</h3>
								<p>Fast, real-time visualization of high-frequency streams in web and mobile environments. FLO.ink empowers you 20+ real-time data points on every click, tap, and swipe so you’re armed with the information you need to share more of the content your audience is most excited about.</p>
							</article>
							<article className="contentArticle">
								<h3>Track Organic Clicks &amp; Shares</h3>
								<p>Perfect when driving traffic to sites and track customer engagement pertaining to content’s organic clicks and shares. FLO.ink helps you focus your attention on key metrics that matter to analyze traffic sources and improve the efficiency of your marketing activities.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
export default Features;