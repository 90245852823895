import React, { Component } from "react";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterResults from "react-filter-search";
import { Redirect } from "react-router-dom";


class ManageApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            apps: [],
            logOutRedirect: false,
            manageApplLoader: true,
        };
    }

    componentWillMount = () => {
        let hash = window.location.hash;
        if (hash !== "" && hash.split("&").length > 0) {
            let splited = hash.split("&");
            let last = splited[splited.length - 1].split("=")[1];
            if (last === "manage_apps") {
                this.getAppsFunction();
            }
        }
    };

    getAppsFunction() {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(window.APIURL + "integration/user-apps?userId=" + userId)
            .then(response => response.json())
            .then(result => {
                this.setState({ manageApplLoader: false, apps: result.apps });
            });
    }

    handleChangeApps = event => {
        const { value } = event.target;
        this.setState({ value });
    };

    addApp = () => {
        let { apps } = this.state;
        let { subscriptionDetails } = this.props;
        console.log('apps.length',apps.length)
        console.log('subscriptionDetails.integrations',subscriptionDetails.integrations)
        if (subscriptionDetails && subscriptionDetails.integrations) {
            if (apps.length < parseInt(subscriptionDetails.integrations)) {
                this.props.changeLoaderStatus(true);
                let { value } = this.state;
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                this.setState({ manageApplLoader: true })
                fetch(window.APIURL + "integration/create/app", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        appName: value,
                        userId: userId
                    })
                })
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        this.setState({ manageApplLoader: false })
                        if (result.status === true) {
                            console.log('result...//////', result);
                            let createdApps = { appName: result.app.appName, _id: result.app._id };
                            this.setState({
                                apps: [...this.state.apps, createdApps],
                                value: "",
                            });
                            this.props.updateAppsFromChild(createdApps);
                            swal('Success', result.message, 'success')
                            this.props.changeLoaderStatus(false);
                        } else if (result.status === false) {

                            if (result.redirection === true) {
                                this.setState({ logOutRedirect: true });
                                swal('Error', result.message, 'error')
                            }

                            swal('Error', result.message, 'error')
                        } else {
                            swal('Error', 'Contact Admin', 'error')
                        }
                    });
            } else {
                swal('Error', 'Upgrade your package to add more apps.', 'error')
            }
        }
    };

    deleteApp = _id => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this app?",
            showCancelButton: true,
            // customClass: "conformations",
            confirmButtonColor: '#0a6eb4',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                this.props.changeLoaderStatus(true);

                let appsBackup = this.state.apps;
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                this.setState({ manageApplLoader: true })
                fetch(window.APIURL + "integration/delete?_id=" + _id + "&userId=" + userId)
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        this.setState({ manageApplLoader: false })
                        if (result.status === true) {
                            swal('Success', result.message, 'success')
                            const index = appsBackup.findIndex(
                                item => item._id === _id
                            );
                            appsBackup.splice(index, 1);
                            this.props.updateAppsFromChild(appsBackup);
                            this.setState({
                                apps: appsBackup
                            });
                            this.props.changeLoaderStatus(false);

                        }
                        else {
                            swal('Error', result.message, 'error')
                        }
                    });
            }
        })
    };

    dynamicApps = (_id, index, e) => {
        if (e.target.value === "") {
            swal('Error', "App name is required", 'error');
            return;
        }
        else {
            let apps = [...this.state.apps];
            apps[index].appName = e.target.value;
            this.setState({ apps });
            let appName = e.target.value;
            setTimeout(() => {
                fetch(window.APIURL + "integration/edit", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        _id: _id,
                        appName: appName,
                        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
                    })
                })
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 400) {
                            return res.json();
                        } else if (res.status === 403) {
                            return res.json();
                        } else if (res.status === 422) {
                            return res.json();
                        } else if (res.status === 404) {
                            return res.json();
                        } else {
                            return res.json();
                        }
                    })
                    .then(result => {
                        if (result.status === false) {
                            if (result.redirection === true) {
                                this.setState({ logOutRedirect: true });
                                swal('Error', result.message, 'error')
                            }
                            swal('Error', result.message, 'error')
                        }
                    });
            }, 500);
        }
    };

    render() {
        const { logOutRedirect } = this.state;
        if (logOutRedirect) {
            return <Redirect to="/logout" />;
        }
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="20"
                    style={{ display: "none " }}
                    id="manage_apps"
                >
                    <div
                        className="action-sheet--MAIN"
                        data-selected-sheet="appsList"
                        style={{
                            animation: "600ms ease 0ms 1 normal forwards running open-sheet "
                        }}
                    >
                        <div className="action-sheet--header">
                            <a>
                                {" "}
                                <span className="back_btn-icon" alt="back_btn-icon">
                                    <FontAwesomeIcon icon="chevron-left" />
                                </span>{" "}
                            </a>
                            <h4 className="action-sheet--header-text">Manage Apps</h4>
                            <span>
                                <a>
                                    <span className="close-icon" alt="close-icon" />
                                </a>
                            </span>
                        </div>
                        <div className="action-sheet--content-wrapper">
                            <div className="dynamic-checklist--MAIN">
                                <div className="search--ALTERNATE   ">
                                    <input
                                        type="search"
                                        maxLength="100"
                                        className="search--input-field"
                                        placeholder="Search and create apps"
                                        value={this.state.value}
                                        onChange={this.handleChangeApps}
                                    />

                                    <FilterResults
                                        value={this.state.value}
                                        data={this.state.apps}
                                        renderResults={results => (
                                            <div>
                                                {results.length > 0 && this.state.manageApplLoader !== true ? (
                                                    results.map((el, index) => {
                                                        console.log('elelelel..///', el)
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="dynamic-checklist--item "
                                                            >
                                                                <div className="input-field--wrapper manageAppWrapper">
                                                                    <div className="input-field--ALTERNATE   ">
                                                                        <label className="input-field--label" />
                                                                        <div className="input-field--input-wrapper manageAppHolder">
                                                                            <input
                                                                                type="text"
                                                                                className="input-field--input"
                                                                                maxLength="100"
                                                                                value={el.appName}
                                                                                disabled
                                                                                // onChange={event =>
                                                                                //     this.dynamicApps(
                                                                                //         el._id,
                                                                                //         index,
                                                                                //         event
                                                                                //     )
                                                                                // }
                                                                            />
                                                                            <span className="icon-cross" onClick={() => this.deleteApp(el._id)}> X </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) :
                                                    this.state.manageApplLoader == true ?
                                                        <div className="text-center">
                                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                        :
                                                        (
                                                            <div>
                                                                {this.state.value === "" ? (
                                                                    ""
                                                                ) : (
                                                                        <div>
                                                                            <span>
                                                                                {"There is no result against '" +
                                                                                    this.state.value +
                                                                                    "'"}
                                                                            </span>
                                                                            <br />
                                                                            <a className="create-suggestion" onClick={this.addApp}>
                                                                                <span> Create '{this.state.value}' </span>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageApps;
