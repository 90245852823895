import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: "",
      redirect: false,
      isLoader: false,
      settings: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const { email } = this.state;
    e.preventDefault();

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === "") {
      this.setState({ errors: "Email is Required" });
      return;
    } else if (!email.match(regexp)) {
      this.setState({ errors: "Email is Invalid" });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/forgot-password", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            swal('Success', result.message, 'success')
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount = () => {
    this.props.getSiteSetting();
  };

  render() {
    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <React.Fragment>
        <div style={{height:'100vh', display:'flex', padding:'25px 0', overflowY:'auto'}}>
          <div className="container-box" style={{margin:'auto'}}>
            <h1 className="header-logo">
              <Link to="/" rel="nofollow">
                {
                  settings ?
                    <img src={settings.siteLogo}
                      alt="Site Logo"
                    /> : ''
                }
              </Link>
            </h1>
            <div className="header">
              <h3 className="tagline">Forgot Your Password?</h3>
            </div>
            <p className="gray-link">
              Just enter the email you signed up with and we'll let you reset it.
            </p>
            <form onSubmit={this.handleSubmit}>
              <div className="susi-fields-wrapper">
                <fieldset>
                  <label className="gray-link" for="email">
                    Email Address:
                  </label>
                  <div className="authFormFieldContainer">
                    <input
                      type="text"
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="email"
                      name="email"
                      size="30"
                      className="text"
                    />
                  </div>
                  {Object.keys(this.state.errors).length > 0 &&
                    this.state.errors ? (
                      <div className="alert alert-danger">{this.state.errors}</div>
                    ) : (
                      ""
                    )}
                  <input
                    type="submit"
                    id="password_reset_button"
                    className="button button-primary submit"
                    name="submit"
                    value="Reset"
                  />
                </fieldset>
              </div>
            </form>
            <p className="separator t-center">
              <span>Or</span>
            </p>
            <div className="text-center">
              <div className="switch to-sign-up">
                <span className="gray-link">Back To</span>{" "}
                <Link to="/userLogin">Sign In</Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgetPassword));