import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTags from "react-tag-autocomplete";
// import Swal from 'sweetalert2'
import swal from 'sweetalert';
import $ from 'jquery'

 require('bootstrap-select');

class GenericAccessToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            userId: '',
            genericToken: '',
            copyToken: false,
            errors: '',
            apps: [],
            suggestions: [],
            tags: [],
            completeAppData: "",
            appName: "",
            // domainName: "",
            getaccessToken: "null",
            currentApp: "",
            appCount: 0,
            viewAllApps: false,
        };

    }

    getAppsForGenericToken = () => {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        fetch(window.APIURL + "integration/user-apps?userId=" + userId)
            .then(response => response.json())
            .then(result => {
                let appNames = [];
                result.apps.forEach(app => {
                    appNames.push({ name: app.appName });
                });
                this.setState({
                    suggestions: appNames,
                    completeAppData: result.apps
                }, () => {
                    setTimeout(()=>{
                        $('.my-select').selectpicker();
                    },1000);
                });
            });
    };

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('LoginSession'));
        let id = user.data._id;
        this.setState({ userId: id, copyToken: false })
        this.getAppsForGenericToken();
    }

    onChange = (e) => {
        if (e.target.name == 'currentApp') {
            if (e.target.value == "") {
                this.setState({
                    getaccessToken: ""
                })
            } else if (e.target.value == "null") {
                this.setState({
                    getaccessToken: "null"
                })
            }
            else {
                this.getToken(e.target.value)
            }
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getToken = (id) => {
        fetch(window.APIURL + 'integration/single/get/token?_id=' + id, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                let data = result.app;
                this.setState({
                    copyToken: false,
                    password: "",
                    getaccessToken: data.accessToken,
                })
            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        });
    }

    genericToken = () => {
        const { userId, currentApp } = this.state;
        fetch(window.APIURL + 'integration/update/generic-access-token', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "userId": userId,
                "accessToken": true,
                // "domainName": domainName,
                "_id": currentApp,
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                let data = result.result;
                swal('Success', result.message, 'success')
                this.setState({
                    copyToken: true,
                    genericToken: data.accessToken,
                    getaccessToken: "",
                })
            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        });
    }

    onCreateGenericAccessToken = (e) => {
        e.preventDefault();
        const { password, userId, currentApp } = this.state;
        if (password === '') {
            swal('Error', "Please enter your password to continue.", 'error')
            return;
        }
        else if (currentApp == "") {
            swal('Error', "App is required.", 'error')
            return;
        }
        else {
            fetch(window.APIURL + 'accounts/member/verify-password', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "_id": userId,
                    "password": password,
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.status === true) {
                    this.genericToken()
                } else if (result.status === false) {
                    swal('Error', result.message, 'error')
                } else {
                    swal('Error', 'Contact Admin', 'error')
                }
            });
        }
    }

    viewAllAppsFun = () => {
        this.setState({ viewAllApps: !this.state.viewAllApps });
    }

    render() {
        const { userName } = this.props;
        return (
            <React.Fragment>
                <div className="action-sheet--header">
                    <a>
                        {" "}
                        <span className="back_btn-icon" alt="back_btn-icon">
                            <FontAwesomeIcon icon="chevron-left" />
                        </span>{" "}
                    </a>
                    <h4 className="action-sheet--header-text">
                        Generic Access Token
                    </h4>
                    <span>
                        <a>
                            <span className="close-icon" alt="close-icon" />
                        </a>
                    </span>
                </div>
                <div className="action-sheet--content-wrapper">
                    <form onSubmit={this.onCreateGenericAccessToken} autoComplete="off">
                        <p className="action-sheet--text">
                            Many of FLO.ink's API methods require an OAuth access token
                            for authentication. You can generate a generic access token
                            by confirming your password below.
                        </p>
                        <div className="input-field--wrapper">
                            <div className="input-field--MAIN">
                                <label className="input-field--label" htmlFor="password">Password</label>
                                <div className="input-field--input-wrapper">
                                    <input
                                        type="password"
                                        name="password"
                                        className="input-field--input"
                                        autoComplete="off"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* ... */}



                        <div className="dynamic-search-list--ALTERNATE whiteColoredSelect">
                            <h5 className="dynamic-search-list--header">
                                SELECT APP
                            </h5>
                            <div className="select-item--MAIN">
                                <select
                                    className="my-select" //selectpicker
                                    // data-show-subtext="true"
                                    // data-live-search="true"
                                    value={this.state.currentApp}
                                    name="currentApp"
                                    onChange={this.onChange}
                                >
                                    <option value="null">Select App</option>

                                    {this.state.completeAppData.length > 0 ? 
                                        this.state.completeAppData.map((app, index) => {
                                            return (
                                                <option key={index} value={app._id}>
                                                    {app.appName}
                                                </option>
                                            );
                                        })
                                     : 
                                            <option value="">No Record Found</option>
                                        }
                                </select>
                            </div>
                        </div>

                        <span
                            className="action-sheet--link-channel checklist-menu-item"
                            data-id="manage_apps"
                            onClick={this.props.manageAppsFun}
                        >
                            Manage Apps
                        </span>

                        <div className="select-item--MAIN">
                            {
                                this.state.getaccessToken !== undefined && this.state.getaccessToken !== 'null' && this.state.getaccessToken !== '' && this.state.copyToken !== true ?
                                    <React.Fragment>
                                        <div className="input-field--MAIN   " style={{ marginBottom: "1em" }}>
                                            <label className="input-field--label" htmlFor="userId">User ID:</label>
                                            <div className="input-field--input-wrapper">
                                                <input
                                                    type="text"
                                                    name="userId"
                                                    className="input-field--input"
                                                    autoComplete="false"
                                                    value={this.state.userId}
                                                />
                                            </div>
                                        </div>

                                        <div className="input-field--MAIN   " style={{ marginBottom: "1em" }}>
                                            <label className="input-field--label" htmlFor="getaccessToken">Access Token:</label>
                                            <div className="input-field--input-wrapper">
                                                <input
                                                    type="text"
                                                    name="getaccessToken"
                                                    className="input-field--input"
                                                    autoComplete="false"
                                                    value={this.state.getaccessToken}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    this.state.copyToken == true ?
                                        ""
                                        :
                                        this.state.getaccessToken == 'null' ?
                                            ""
                                            :
                                            <h6 style={{ color: "red", marginTop: "1em" }}>No Token Found.</h6>
                            }

                            <span className="select-item--secondary-label" />
                            <span className="select-item--third-label " />
                            <span className="select-item--fourth-label" />
                            <span className="selector-icon" alt="selector-icon" />
                        </div>


                        {/* ....//// */}
                        <p className="action-sheet--text">
                            Your FLO.ink account has access to multiple groups for which
                            you can create links and view data. Please select which
                            group you'd like to associate with your access token.
                        </p>

                        {this.state.copyToken === true ?
                            <div className="input-field--wrapper">
                                <div className="input-field--MAIN   ">
                                    <label className="input-field--label" htmlFor="genericToken">User ID:</label>
                                    <div className="input-field--input-wrapper">
                                        <div className="input-field--input-wrapper">
                                            <input
                                                type="text"
                                                name="userId"
                                                className="input-field--input"
                                                autoComplete="false"
                                                value={this.state.userId}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-field--MAIN   " style={{ marginBottom: "5em" }}>
                                    <label className="input-field--label" htmlFor="genericToken">Access Token:</label>
                                    <div className="input-field--input-wrapper">
                                        <input
                                            type="text"
                                            name="genericToken"
                                            className="input-field--input"
                                            autoComplete="false"
                                            value={this.state.genericToken}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }

                        <div className="action-sheet--button-wrapper">
                            <button
                                className="button--SOLID-SECONDARY"
                                type="submit"
                                tabIndex="0"
                            >
                                GENERATE TOKEN
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}
export default GenericAccessToken;
