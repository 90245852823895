import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from 'sweetalert';
import { createBrowserHistory } from "history";

class NavbarSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: '',
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  clearChildInputField = () => {
    this.setState({
      search: ''
    })
  }

  onClickSearch = (e) => {
    e.preventDefault();
    if (this.state.search !== '') {
      var sanitizedSearch = this.state.search
          .replace(/^https\:\/\//, '') // remove the leading http:// (temporarily)
          .replace(/^http\:\/\//, '') // remove the leading http:// (temporarily)
          .replace(/\/+/g, '/') // replace consecutive slashes with a single slash
          .replace(/\/+$/, ''); // remove trailing slashes
      
      this.props.changeLoaderStatus(true);
        
      setTimeout(()=>{
        this.props.searchFunc(sanitizedSearch);
      },1500);

    } else {
      swal('Error', 'Search is empty', 'error');
    }
  }

  render() {
    return (
      <React.Fragment>
        <form className="form-inline " onSubmit={this.onClickSearch}>
          <input
            className="form-control mr-sm-2"
            type="text"
            name="search"
            value={this.state.search}
            onChange={this.onChange}
            placeholder="Search"
            aria-label="Search"
          />
          <button className="btn" type="submit">
            <FontAwesomeIcon icon="search" />
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default NavbarSearch;