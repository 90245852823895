import React, { Component } from 'react';
import swal from 'sweetalert';
import { ENV } from '../../../config/config';

class PurchaseDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchedDomains : [],
            findingDomain : false,
            isError: false,
            errorMessage: '',
            findingDomainPrice: false,
            requestMessage: '',
            requestInProgress: false
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    searchDomain = () => {
        let domainName = this.state.domainName;
        let body = {
            domainName:domainName
        }
        if(domainName && domainName!== '')
        {
            this.setState({isError: false, errorMessage: '', requestInProgress:true, requestMessage: 'Looking up for Domain. Please hold on...', findingDomain:true});
            fetch(ENV.url + 'custom-domain/search-domain', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body:JSON.stringify(body)
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.status === true) {
                    let searchedDomains = result.data;
                    this.setState({searchedDomains: searchedDomains, isError: false, errorMessage: '', requestInProgress:false, requestMessage: '', findingDomain:false});
                 } else if (result.status === false) {
                    swal('Error', result.message, 'error');
                    this.setState({isError: false, errorMessage: '', requestInProgress:false, requestMessage: '', findingDomain:false});
                 } else {
                    swal('Error', 'Contact Admin', 'error');
                    this.setState({isError: false, errorMessage: '', requestInProgress:false, requestMessage: '', findingDomain:false});
                 }
            });
        }
        else
        {
            this.setState({isError: true, errorMessage: "Please write domain name to search.", requestInProgress:false, requestMessage: '', findingDomain:false});
        }
    }
    
    selectDomain = (domainName) => {
        let encrypted = btoa(domainName)
        this.props.history.push("/organization/purchase/"+encrypted);
    }


    render() {
        return (
            <React.Fragment>
                <div className="admin-section--MAIN">
                    <div className="admin-section--top">
                        <h2 className="admin-section--header">Purchase Domain</h2>
                        <div className="admin-section--item">
                            {
                                this.state.isError && this.state.errorMessage && this.state.errorMessage !== '' ?
                                    <div className="alert alert-danger">{this.state.errorMessage}</div>
                                    :
                                    ''
                            }
                            {
                                this.state.requestInProgress && this.state.requestMessage !== '' ?
                                    <div className="alert alert-primary">{this.state.requestMessage}</div>
                                    :
                                    ''
                            }
                            <p className="marketing-module--text">
                                <input type="text" className="marketing-module--upsell-text" name="domainName" onChange={(e)=>this.onChange(e)}/>
                                <a className="admin-section--cta">
                                    <button className="button--SOLID-PRIMARY" disabled={this.state.findingDomain ? true : false} onClick={()=>this.searchDomain()}>
                                        {
                                            this.state.findingDomain ? "Searching..." : "Search"
                                        }
                                    </button>
                                </a>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="empty-state--BITLINKS">
                        <div className="empty-state--gradient-wrapper">
                            {
                                this.state.searchedDomains && this.state.searchedDomains.length ? 
                                    <React.Fragment>
                                        <h4 className="empty-state--header">Select an available domain to set up.</h4>
                                        <div className="resulted-domains">
                                            {
                                                this.state.searchedDomains.map((domain, index)=>{
                                                    return <div key={index} className="resulted-domain">
                                                        <span className="float-left resulted-domain-name pt-2">{domain.domain}</span>
                                                        {
                                                            domain.price ? 
                                                            <span>
                                                                {
                                                                    "$"+domain.price
                                                                }
                                                            </span>
                                                            : ''
                                                        }
                                                        <span className="float-right">
                                                            <button disabled={domain.status === "available" ? false : true } className={domain.status === "available" ? "domainName available btn btn-success" : "domainName notAvailable btn btn-danger"} onClick={()=>this.selectDomain(domain.domain)}>
                                                                {domain.status !== "available" ? 'Not Available' : 'Select' }
                                                            </button>
                                                        </span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </React.Fragment>
                                :
                                <React.Fragment>
                                    <h4 className="empty-state--header">Search For a Domain</h4>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaseDomain;