import React, { Component } from "react";
// import Swal from 'sweetalert2'
import swal from 'sweetalert';

class RegisteredOauthAppSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.generateSecret = this.generateSecret.bind(this);
    }

    generateSecret = (rCode, id) => {
        fetch(window.APIURL + 'register-oauth-app/edit', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "_id": id,
                "registrationCode": rCode,
                "generateClientSecret": true,
            })
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                swal('Success', result.message,  'success')
                let data = result.result;
                let from = 'editSecret';
                this.props.theOauthFunction(from, data)
            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        });
    }

    render() {
        let { appDetail } = this.props;
        return (
            <React.Fragment>
                <form action="#" className="button-visible">
                    <div className="action-sheet--content-wrapper">
                        <div className="action-sheet--content-header">{appDetail.appName}</div>
                        <div className="action-sheet--text">
                            <a target="_blank" href="#">
                                {appDetail.appLink}
                            </a>
                            .
                        </div>
                        <div className="action-sheet--text">{appDetail.appDescription}</div>
                        <div className="action-sheet--seperator" />
                        <div className="action-sheet--content-header">Client ID</div>
                        <div className="action-sheet--text-sm">
                            {appDetail.clientId}
                        </div>
                        <div className="action-sheet--content-header">
                            Client Secret
                        </div>
                        <div className="action-sheet--text-sm">
                            {appDetail.clientSecret}
                        </div>
                        <div
                            className="action-sheet--button-wrapper two-buttons"
                            style={{
                                animation:
                                    "700ms ease 0ms 1 normal forwards running show-apply-button-2 "
                            }}
                        >
                            <button
                                className="button--GHOST-SECONDARY"
                                tabIndex="0"
                                id=""
                                type="button"
                                onClick={() => this.generateSecret(appDetail.registrationCode, appDetail._id)}
                            >
                                Regenerate
                            </button>
                            <button
                                className="button--SOLID-SECONDARY checklist-menu-item"
                                data-id="edit_app"
                                tabIndex="0"
                                type="button"
                                id=""
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default RegisteredOauthAppSecond;