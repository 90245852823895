import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from "jquery";
var Twit = require('twit');
var OAuth = require('oauth');

class ShareComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: "",
            userId: "",
            domain: "",
            url: "",
            twitterAccounts: [],
        }
    }

    shareTwitter = (id) => {
        fetch(window.APIURL + "accounts/member/share-on-twitter", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                _id: id,
                hash: this.state.hash,
                domain: this.state.domain
            })
        }).then(res => {
            return res.json();
        }).then(result => {
            if (result.status === true) {
                swal('Success', result.message, "success")
            } else {
                swal('Error', result.message, 'error')
            }
        });
    }

    shareMediaTwitter = (id) => {
        const canvas = document.getElementById("qrId");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        fetch(window.APIURL + "accounts/member/share-media-on-twitter", {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                _id: id,
                hash: this.state.hash,
                url: this.state.url,
                img: pngUrl
            })
        }).then(res => {
            return res.json();
        }).then(result => {
            if (result.status === true) {
                swal('Success', result.message, "success")
            } else {
                swal('Error', result.message, 'error')
            }
        });
    }

    shareLinkFunc = (hash, domain, userId) => {
        console.log('dom4', domain)
        console.log('hash4', hash)
        // let url = `https://${this.state.domain}/${this.state.hash}`;
        this.setState({ domain, hash, userId, url: `https://${domain}/${hash}` })

        fetch(window.APIURL + "accounts/member/get-twitter-accounts", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                _id: userId
            })
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        twitterAccounts: result.data
                    });

                    $("#nav").addClass("show");
                    $(".menu").addClass("clicked");
                    $("#account_info").hide();
                    $("#share_link").show();

                } else if (result.status === false) {
                    swal('Error', result.message, 'error')
                } else {
                    swal('Error', 'Contact Admin', 'error')
                }
            });
    }
    render() {
        const { subscriptionDetails } = this.props;
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="25"
                    style={{ display: "none " }}
                    id="share_link"
                >
                    <div className="action-sheet--MAIN" data-selected-sheet="share_link"
                        style={{
                            animation: "600ms ease 0ms 1 normal forwards running open-sheet "
                        }} >

                    </div>
                    <div className="action-sheet--header">
                        <a>
                            {" "}
                            <span className="back_btn-icon" alt="back_btn-icon">
                                <FontAwesomeIcon icon="chevron-left" />
                            </span>{" "}
                        </a>
                        <h4 className="action-sheet--header-text">Connected Accounts</h4>
                        <span>
                            <a>
                                <span className="close-icon" alt="close-icon" />
                            </a>
                        </span>
                    </div>
                    <div className="action-sheet--content-wrapper">
                        <div className="connected-account--sub-header">
                            Share on Social Media
                     </div>
                        {this.state.twitterAccounts.length > 0
                            ? this.state.twitterAccounts.map((data, index) => (
                                <React.Fragment>
                                    <div style={{ cursor: "pointer" }} key={index} onClick={() => this.shareTwitter(data._id)} className="connected-account--MAIN">
                                        <span className="social-icon"> <img className="user-account--image" width="25" src={require("../../images/twitter.svg")} /></span>
                                        <span className="share-icon"><FontAwesomeIcon icon="share-alt" /></span>
                                        <div className="connected-account--account-name">
                                            {data.displayName}
                                        </div>
                                        <div className="connected-account--date">
                                            connected on {Date(data.createdAt).slice(4, 15)}
                                        </div>
                                    </div>
                                    <div style={{ cursor: "pointer" }} key={index} onClick={() => this.shareMediaTwitter(data._id)} className="connected-account--MAIN">
                                        <span className="social-icon"> <img className="user-account--image" width="25" src={require("../../images/twitter.svg")} /></span>
                                        <span className="share-icon"><FontAwesomeIcon icon="share-alt" /></span>
                                        <div className="connected-account--account-name">
                                            {data.displayName}
                                        </div>
                                        <div className="connected-account--date">
                                            connected on {Date(data.createdAt).slice(4, 15)}
                                        </div>
                                    </div>
                                    <QRCode id="qrId" value={this.state.url} renderAs="canvas" size={180} level="H" includeMargin={true} />
                                </React.Fragment>
                            ))
                            :
                            <span>
                                No social account found <br />
                                Please add social account from account settings
                            </span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ShareComponent;