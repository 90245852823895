import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SelectGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="action-sheet--content-wrapper pt-0">
                    <div className="action-sheet--organization">
                        <div className="action-sheet--content-header">
                            <span className="personal-icon" alt="personal-icon">
                                <FontAwesomeIcon icon="user" />
                            </span>
                            <span className="action-sheet--org-name">Personal</span>
                            <span className="action-sheet--text" />
                        </div>
                        <div className="checklist--wrapper">
                            <div className="checklist--wrapper ">
                                <div className="checklist--items">
                                    <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="token">
                                        <div className="checklist-row--text">
                                            {" "}
                                            <span className='account-type-name text-capital'>{this.props.type} Account</span>
                                            <span className="checklist-row--secondary-label account-type-value">
                                                {this.props.name !== '' ? this.props.name : 'N/A'}
                                            </span>
                                        </div>
                                        <span className="checklist-row--icon">
                                            <div
                                                className="radio-check--LARGE"
                                                id="Free-Account"
                                            >
                                                <input
                                                    id="Free Account"
                                                    type="radio"
                                                    name="Bj7f8bfydVC"
                                                    className="checkbox--input"
                                                    defaultValue="Free Account"
                                                    defaultChecked=""
                                                />
                                                <label
                                                    htmlFor="Free Account"
                                                    className="checkmark-icon"
                                                >
                                                    <FontAwesomeIcon icon="check" />
                                                </label>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SelectGroup;