import React, { Component } from "react";
import NavbarDropdown from "./dashboard-navbar/NavbarDropdown";
import NavbarSearch from "./dashboard-navbar/NavbarSearch";
import NavbarCreateBitlink from "./dashboard-navbar/NavbarCreateBitlink";
import GenericAccessToken from "../navbar-components/generic-access-token/genericAccessToken"; //By UZair Hassan
import SelectGroup from "../navbar-components/generic-access-token/selectGroup"; //By UZair Hassan
import Notification from "../navbar-components/notifications/notification"; //By UZair Hassan
import Personal from "../navbar-components/notifications/personal"; //By UZair Hassan
import GetRegistrationCode from "../navbar-components/registered-oauth-applications/getRegistrationCode"; //By UZair Hassan
import RegisterOauthApp from "../navbar-components/registered-oauth-applications/registerOauthApp"; //By UZair Hassan
import RegisteredOauthApps from "../navbar-components/registered-oauth-applications/registeredOuthApps"; //By UZair Hassan
import RegisteredOauthAppSecond from "../navbar-components/registered-oauth-applications/registeredOauthAppSecond"; //By UZair Hassan
import RegisterOauthAppEdit from "../navbar-components/registered-oauth-applications/registerOauthAppEdit"; //By UZair Hassan
import SetDefaultApi from "../navbar-components/default-api-group/setDefaultApi"; //By UZair Hassan
import AddCustomDomain from "../navbar-components/custom-domain/addCustomDomain"; //By UZair Hassan
import Settings from "../navbar-components/settings/Settings"; //By Shoaib
import SocialNetworks from "../navbar-components/settings/social-networks/SocialNetworks"; //By Shoaib
import TwoWayAuth from "../navbar-components/security-two-way-auth/TwoWayAuth"; //By Shoaib
import LoginHistory from "../navbar-components/login-history/loginHistory"; //By Shoaib
import DeleteAccount from "../navbar-components/delete-account/deleteAccount";
import CreateBitlink from "../navbar-components/create-bitlink/CreateBitlink";
import BulkTagLink from "../navbar-components/bulk-tags/BulkTags";
import EditLink from "../navbar-components/edit-link/EditLink";
import CreateCampaign from "../navbar-components/create-campaign/CreateCampaign";
import EditCampaign from "../navbar-components/edit-campaign/EditCampaign";
import CreateFloInkCampaign from "../navbar-components/create-floink-campaign/CreateFloinkCampaign";
import ManageChannels from "../navbar-components/manage-channels/ManageChannels";
import ManageApps from "../navbar-components/manage-apps/ManageApps";
import ManagaeBitlinkChannel from "../navbar-components/manage-bitlink-channel/ManagaeBitlinkChannel";
import ManageBitlinkCampaign from "../navbar-components/manage-bitlink-campaign/ManageBitlinkCampaign";
import ShareLink from "../share-link/ShareLink";
// import ShareMedia from "../share-media/ShareMedia";
import ResourcesComponent from "../navbar-components/resources/Resources"
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { ENV } from '../../config/config';
require('bootstrap-select');

class DashboardNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      channelsData: [],
      selectedCampaign: "",
      selectedChannel: "",
      linkChannelIdToCampaignValue: "",
      redirect: false,
      username: "",
      accountType: "",
      fullName: "",
      userId: "",
      email: "",
      socialSigninPasswordCheck: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isVerified: "",
      otherEmails: "",
      secondEmailToShow: "",
      phoneNumber: "",
      numberCheckCall: false,
      secondEmailToShowStatus: true,
      codeNumber: "",
      verifiedEmails: [],
      ////register Oauth statessss
      oauthAppList: [],
      oauthRegistrationCode: "",
      appDetails: {},
      createdQlinkId: "",
      hideSearch: "",
      settings: {},
      csvfile: '',
      csvLog: [],
      csvReport: false,
      uploading: false,
    };
    this.manageChildSearchField = React.createRef(); //by Uzair
    this.manageResourcesChild = React.createRef();
    this.manageShareChild = React.createRef();
    // this.manageShareMediaChild = React.createRef();
    this.manageChannelsChild = React.createRef();
    this.manageAppsChild = React.createRef(); //by Uzair
    this.manageBitlinkChannelsChild = React.createRef();
    this.manageBitlinkCampaignsChild = React.createRef();
    this.manageCampaignChild = React.createRef();
    this.createfloinkCampaign = React.createRef();
    this.editCampaignChild = React.createRef();
    this.loginHistoryChild = React.createRef();
    this.editLinkChild = React.createRef();
    this.addBulkTagChild = React.createRef();
    this.manageChildInGeneric = React.createRef();
    this.deleteEmail = this.deleteEmail.bind(this);
    this.makePrimaryEmail = this.makePrimaryEmail.bind(this);
    this.secondaryEmailHandler = this.secondaryEmailHandler.bind(this);
  }

  // onClickSecurity = () => {
  //   this.loginHistoryChild.current.getLoginHistory( this.state.email );
  // };

  componentWillMount = () => {
    // console.log('DashboardNavbar')
    if (localStorage.getItem("loginToken")) {
      var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
      var timeNow = new Date().getTime() / 1000;
      var timeDifference = timeNow - loginTime;

      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        this.setState({
          username: JSON.parse(localStorage.getItem("LoginSession")).data.username,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id,
          redirect: false
        }, () => {
          // this.checkUserSession(this.state.userId)
        });
        // this.setState({
        //   userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        // });
        // this.setState({ redirect: false });
      } else {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("LoginSession");
        localStorage.removeItem("loginTime");
        this.setState({ redirect: true });
      }
    } else {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("LoginSession");
      localStorage.removeItem("loginTime");
      this.setState({ redirect: true });
    }
  };

  // checkUserSession = (id) => {
  //   fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then(res => {
  //       if (res.status === 200) {
  //         return res.json();
  //       } else if (res.status === 403) {
  //         return res.json();
  //       } else if (res.status === 422) {
  //         return res.json();
  //       } else if (res.status === 404) {
  //         return res.json();
  //       } else {
  //         return res.json();
  //       }
  //     })
  //     .then(result => {
  //       if (result.status === true) {
  //       } else if (result.status === false) {
  //         localStorage.removeItem("loginToken");
  //         localStorage.removeItem("LoginSession");
  //         localStorage.removeItem("loginTime");
  //         this.setState({ redirect: true });
  //         // swal('Error', result.message, 'error')
  //       } else {
  //         localStorage.removeItem("loginToken");
  //         localStorage.removeItem("LoginSession");
  //         localStorage.removeItem("loginTime");
  //         this.setState({ redirect: true });
  //         // swal('Error', 'Contact Admin', 'error')
  //       }
  //     });
  // }

  clearStates = () => { // for clear states in forms
    this.manageCampaignChild.current.clearStates();
  }

  removeSearch = () => {
    this.setState({
      hideSearch: 'off'
    })
  }

  clearInputField = () => {
    this.manageChildSearchField.current.clearChildInputField();
  }

  newLinkIdSetFunction = passedId => {
    this.setState({ createdQlinkId: passedId });
    this.editLinkChild.current.getLinkDataHash(passedId);

    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#edit_bitlink").show();
  };

  addBulkTagsFunction = selectedIds => {
    this.setState({ selectedIdsForBulk: selectedIds });
    this.addBulkTagChild.current.getCurrentTags(selectedIds);
    // //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $(".data-state").hide();
    $("#bulk_tags_links").show();
  };

  updateDashBoard = (value, updateData = undefined) => {

    // $(".back_btn-icon").trigger("click");
    // $(".back_btn-icon").trigger('click')
    // $(".menu").removeClass("clicked");
    if (value && value !== undefined)
      this.props.updateDashboard(value, updateData);
    else
      this.props.updateDashboard(false, updateData);
  };

  componentDidMount = () => {
    // this.props.getSiteSetting();
    this.getSiteSetting();
    this.jqueryCode();
    this.getUser();
    this.getRegisteredOauthApps();
    this.getCampaignsForSelect();
  };

  getCampaignsForSelect = () => {
    fetch(
      window.APIURL + "campaign/select/camapaigns?userId=" + this.state.userId,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => {
        return res.json();
      })
      .then(result => {
        if (result.status === true) {
          let data = result.campaignsData;
          this.setState({ data: result.campaignsData });
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  getRegisteredOauthApps = () => {
    if (localStorage.getItem("LoginSession") != null) {
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      fetch(window.APIURL + "register-oauth-app/user-apps?userId=" + id, {
        method: "get",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            let data = result.oAuthApps;

            this.setState({
              oauthAppList: data
            });
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  getSiteSetting = () => {
    fetch(`${ENV.url}site-settings/get-site-settings`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token
      }
    })
      .then(res => { return res.json() }).then(data => {
        if (data.status) {
          this.setState({
            settings: data.site
          });
        } else
          console.log('Settings error', data)
      }).catch(errors => {
        console.log('Settings catch error', errors)
      })
  }

  delNumber(value) {
    this.setState({ phoneNumber: '' })
  }

  makePrimaryEmail = email => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to make this email as your primary email",
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes  '
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/make-email-primary", {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email,
            _id: this.state.userId
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              this.setState({ email: result.data.newPrimaryEmail });
              let localData = JSON.parse(localStorage.getItem("LoginSession"));
              localData.data.primaryEmail = result.data.newPrimaryEmail;
              localStorage.removeItem("LoginSession");

              localStorage.setItem("LoginSession", JSON.stringify(localData));
              this.setState({ secondEmailToShow: result.data.oldPrimaryEmail });
              this.otherEmailGetter();
              swal('Success', result.message, 'success')
            } else if (result.status === false) {
              swal('Error', result.message, 'error')
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      }
    });
  };

  logOut = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("LoginSession");
    localStorage.removeItem("loginTime");
    let id = this.state.userId;
    fetch(window.APIURL + "accounts/logout?userId=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {

      });
    this.setState({ redirect: true });
  };

  getUser = () => {
    let id = this.state.userId;
    fetch(window.APIURL + "accounts/member/profile/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          this.setState({
            fullName: result.member.firstName + " " + result.member.lastName,
            username: result.member.username,
            accountType: result.member.accountType,
            subscriptionDetails: result.member.subscriptionDetails ? result.member.subscriptionDetails : {}
          });
          this.setState({ email: result.member.primaryEmail });
          this.setState({
            socialSigninPasswordCheck: result.member.isSetSocialSigninPassword
          });
          this.setState({ isVerified: result.member.isVerified });
          this.setState({ phoneNumber: result.member.phoneNumber });

          this.otherEmailGetter();
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  onChange = e => {
    let now = this;
    this.setState({
      [e.target.name]: e.target.value
    });
    if (e.target.name == 'selectedCampaign' && e.target.value !== "") {
      this.showChannel(e.target.value);
    }
    else if (e.target.name == 'selectedCampaign' && e.target.value == "") {
      now.setState({
        channelsData: [],
        selectedChannel: ""
      })
    }
  };

  showChannel = (id) => {
    // console.log('id....', id)
    // let count = 1;
    this.state.data.map((data, index) => {
      // console.log('data.id..',data.id)
      if (data._id === id) {
        // alert('yahoo')
        this.setState({
          channelsData: data.channel
        }, () => {
          setTimeout(() => {
            $('.my-select').selectpicker();
          });
        })
      }
      // count++
      // console.log('count..',count                                                                                             )
    })

  }

  handleFullnameSubmit = e => {
    e.preventDefault();
    let { fullName } = this.state;

    if (fullName === "") {
      swal('Error', "Full name is Required", 'error')
      return;
    } else {
      let firstN,
        lastN = "";
      let nameStrings = fullName.split(/ (.+)/);
      if (nameStrings.length > 1) {
        firstN = nameStrings[0];
        lastN = nameStrings[1];
        // debugger
      } else {
        firstN = nameStrings[0];
        lastN = "";
        // debugger
      }
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/update", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: firstN,
          lastName: lastN,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            swal('Success', result.message, 'success')
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  codeVerificationForm = e => {
    e.preventDefault();
    let { codeNumber } = this.state;

    if (codeNumber === "") {
      swal('Error', "Code number is required", 'error')

      return;
    } else {
      fetch(window.APIURL + "accounts/member/update-mobile-number", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          codeNumber: codeNumber,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            this.setState({ phoneNumber: result.data });
            $("#security").show();
            $("#security_phone").hide();
            swal('Success', result.message, 'success')
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  passwordHandle = e => {
    e.preventDefault();
    let {
      currentPassword,
      newPassword,
      confirmPassword,
      socialSigninPasswordCheck,
      userId
    } = this.state;

    if (socialSigninPasswordCheck != true && currentPassword === "") {
      swal('Error', 'Current password field is required', 'error')
      return;
    } else if (newPassword === "") {
      swal('Error', 'New password field is required', 'error')
      return;
    } else if (confirmPassword === "") {
      swal('Error', 'Confirm password field is required', 'error')
      return;
    } else if (confirmPassword !== newPassword) {
      swal('Error', 'Password mismatch', 'error')
      return;
    } else {
      fetch(window.APIURL + "accounts/member/update", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          password: newPassword,
          currentPassword: currentPassword,
          userPasswordChange: true,
          socialSigninPasswordCheck: socialSigninPasswordCheck,
          _id: userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            this.setState({
              socialSigninPasswordCheck: result.member.socialSigninPasswordCheck,
              currentPassword: "",
              newPassword: "",
              confirmPassword: ""
            })
            swal('Success', result.message, 'success')
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  deleteEmail = email => {
    let otherEmailsBackup = this.state.otherEmails;
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove this email",
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/other-email-delete", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email,
            _id: this.state.userId
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              const index = otherEmailsBackup.findIndex(
                item => item.email === email
              );
              otherEmailsBackup.splice(index, 1);
              this.setState({
                otherEmails: otherEmailsBackup
              });
              $("#email_status_secondary").hide();
              $("#edit_email").show();

              swal('Success', result.message, 'success')
            } else if (result.status === false) {
              swal('Error', result.message, 'error')
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      }
    })
  };

  verificationForm = email => {
    // let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "accounts/member/verification-email", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        // userId: userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          swal('Success', result.message, 'success')
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  otherEmailFormHandler = e => {
    e.preventDefault();
    let otherEmailsBackup = this.state.otherEmails;
    let { otherEmail } = this.state;

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (otherEmail === "") {
      swal('Error', 'Email is required', 'error')
      return;
    } else if (!otherEmail.match(regexp)) {
      swal('Error', 'Email is invalid', 'error')
      return;
    }

    fetch(window.APIURL + "accounts/member/other-email", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        otherEmail: otherEmail,
        _id: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          otherEmailsBackup.push(result.data);
          this.setState({ otherEmails: otherEmailsBackup, otherEmail: "" });
          swal('Success', result.message, 'success')
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  otherEmailGetter = () => {
    fetch(window.APIURL + "accounts/member/get-other-email", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          let tempVerifiedEmails = [];
          let data = result.data;
          data.map((data, index) => {
            if (data.isActivated === true) {
              let temp = {
                email: data.email,
                isNotification: data.isNotification,
                type: "other"
              };
              tempVerifiedEmails.push(temp);
            }
          });
          let index = tempVerifiedEmails.findIndex(
            x => x.isNotification === true
          );
          if (index === -1) {
            tempVerifiedEmails.push({
              email: this.state.email,
              isNotification: true,
              type: "primary"
            });
          } else {
            tempVerifiedEmails.push({
              email: this.state.email,
              isNotification: false,
              type: "primary"
            });
          }
          this.setState({ verifiedEmails: tempVerifiedEmails });
          this.setState({
            otherEmails: result.data
          });
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  jqueryCode = () => {
    let location = window.location;

    if (location.hash !== "" && location.hash.split("&").length > 0) {
      var locationHash = location.hash.split("&");
      locationHash = locationHash[locationHash.length - 1].split("=")[1];
      if (locationHash !== undefined && locationHash !== "") {
        $(".menu, .overlay, #group_info, #crt_bit,#clossse,#campaign").click();
        display_menu(locationHash, true);
      }
    }
    $(".menu, .overlay, #group_info, #crt_bit,#clossse,#campaign").click(
      function () {
        if (!$(".menu").hasClass("clicked")) {
          display_menu("account_info", false);
        } else {
          $(".menu").removeClass("clicked");
          $("#nav").removeClass("show");
          $("#account_info").hide();
          $(".action-sheet--wrapper-MAIN").hide();
          location.hash = "";
        }
      }
    );

    $("body").on("click", ".checklist-menu-item", function () {
      // alert('noww')
      display_menu($(this).attr("data-id"), false);
    });

    $(".back_btn-icon").click(function () {
      var hashLocation = location.hash.replace("#", "").split("&");
      var open_div = hashLocation[hashLocation.length - 2];
      var replace = hashLocation[hashLocation.length - 1];
      hashLocation = hashLocation.join("&").replace("&" + replace, "");
      location.hash = hashLocation;
      if (open_div !== undefined && open_div !== "") {
        open_div = open_div.split("=");
        if (open_div[1] !== undefined && open_div[1] !== "") {
          open_div = open_div[1];
          display_menu(open_div, true);
        } else {
          $(".menu").removeClass("clicked");
          $("#nav").removeClass("show");
          $("#account_info").hide();
          $(".action-sheet--wrapper-MAIN").hide();

          location.hash = "";
        }
      } else {
        $(".menu").removeClass("clicked");
        $("#nav").removeClass("show");
        $("#account_info").hide();
        $(".action-sheet--wrapper-MAIN").hide();
        location.hash = "";
      }
    });

    function display_menu(data_url_id = "", is_back = false) {
      // console.log('....Hit...')
      // alert('This')
      $(".menu").addClass("clicked");
      $("#nav").addClass("show");
      $(".data-state").hide();
      $("#" + data_url_id).show();
      if (!is_back) {
        if (location.hash === "") {
          location.hash = "?action=" + data_url_id;
        } else {
          let lsplit = location.hash.split('&action=');
          if (lsplit[lsplit.length - 1] != data_url_id) {
            location.hash = location.hash + "&action=" + data_url_id;
          }

        }
      }
    }
  };

  secondaryEmailHandler = (email, status) => {
    $("#edit_email").hide();
    this.setState({
      secondEmailToShow: email,
      secondEmailToShowStatus: status
    });

    $("#email_status_secondary").show();
  };

  updateData(value) {
    this.getUser();
  }

  getDetailApp() {
    let rCode = this.state.oauthRegistrationCode;
    fetch(window.APIURL + "register-oauth-app/get?registerationCode=" + rCode, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          let data = result.oAuthApp;
          this.setState({
            appDetails: data
          });
          $("#app_details").show();
          $("#app_list").hide();
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  childToParent(from, data) {
    if (from === "appList") {
      this.setState(
        {
          oauthRegistrationCode: data
        },
        () => {
          this.getDetailApp();
        }
      );
    } else if (from === "editOauth") {
      this.setState(
        {
          appDetails: data
        },
        () => {
          $("#app_details").show();
          $("#edit_app").hide();
        }
      );
    } else if (from === "editSecret") {
      this.setState({
        appDetails: data
      });
    }
  }

  getSearchQuery(searchValue) {
    if (searchValue !== undefined) {
      this.props.fromParent(searchValue); //passing value to dashboard Layout
    }
  }

  getChannelsParentCall = () => {
    this.manageCampaignChild.current.getChannelsForCampaign();
  };

  manageChannelsFun = () => {
    this.manageChannelsChild.current.getChannelsFunction();
  };

  manageAppsFunc = () => {
    this.manageAppsChild.current.getAppsFunction();
  };

  callChildHistoryFunction = () => {
    this.loginHistoryChild.current.getLoginHistory(this.state.email);
  };

  manageBitlinkChannelsChildFun = () => {
    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let newHash = hash + "&action=manage_bitlink_channels";
      window.location.hash = newHash;
      $("#edit_bitlink").hide();
      $("#manage_bitlink_channels").show();
    }

    this.manageBitlinkChannelsChild.current.getChannelsFunction();
  };

  linkChannelIdToCampaign = id => {
    this.setState({ linkChannelIdToCampaignValue: id });
    this.manageBitlinkCampaignsChild.current.getCampaignsFunction(id);
  };

  shareLink = (hash, dom) => {
    // console.log('check...', check)
    let urlHash = window.location.hash;
    var splittedHash = urlHash.split("=");

    if (splittedHash.length > 1) {
      urlHash = urlHash + "&action=share_link"
    } else {
      urlHash = urlHash + "?action=share_link"
    }
    window.location.hash = urlHash;
    console.log('dom3', dom)
    console.log('hash3', hash)
    this.manageShareChild.current.shareLinkFunc(hash, dom, this.state.userId);
  }

  updateChannelsFromChild = (created) => {
    this.manageCampaignChild.current.getChannelsForCampaign(created);
    this.manageBitlinkChannelsChild.current.getChannelsFunction();
  }

  updateAppsFromChild = (created) => {
    console.log('called')
    this.manageChildInGeneric.current.getAppsForGenericToken(created);
  }

  editCampaignFunction = (param) => {
    this.editCampaignChild.current.channelData(param);

    window.location.hash = "?action=edit_campaign"
    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#edit_campaign").show();
  }

  reloadDashboard = (value, updateData = undefined) => {
    if (value && value !== undefined) {
      this.props.reloadDashboard(value, updateData);
    } else {
      this.props.reloadDashboard(false, updateData);
    }
  }

  newFloinkCamapignFunction = (param) => {
    this.createfloinkCampaign.current.campaignChannelData(param);
    window.location.hash = "#?action=create_floink_campaign"
    //open bar when clicked from dashboard
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_floink_campaign").show();
  }

  getResourcesData = () => {
    this.manageResourcesChild.current.getData();
  }

  createDashboardLink = () => {
    // alert('I am over here')
    window.location.hash = "#?action=account_info&action=create_bitlink"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_bitlink").show();
  }

  createDashboardCampaign = () => {
    window.location.hash = "#?action=create_campaign"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#account_info").hide();
    $("#create_campaign").show();
    this.manageCampaignChild.current.getChannelsForCampaign();
  }

  createSidebarLink = () => {
    window.location.hash = "#?action=create_bitlink"
    $("#nav").addClass("show");
    $(".menu").addClass("clicked");
    $("#create_campaign").hide();
    $("#create_bitlink").show();
    this.manageCampaignChild.current.getChannelsForCampaign();
  }

  goToBilling = () => {
    if (this.props.history) {
      this.props.history.push("/organization/detail");
    }
    else {
      window.location.href = "/web/organization/detail";
    }
  }

  onChangeFile = (e) => {
    let file = e.target.files[0];
    console.log('file...', file)
    if (file)
      if (file.type.includes('csv') || file.type.includes('application/vnd.ms-excel'))
        this.setState({ csvfile: file })
  }

  onCancel = () => {
    this.setState({ csvfile: '' })
    this.csvupload.value = ''
  }

  onUplaod = () => {
    if (this.state.csvfile == '') {
      swal('Error', 'Select csv file', 'error')
      return;
    } else if (this.state.selectedCampaign !== "" && this.state.selectedChannel === "") {
      swal('Error', 'Channel id is required', 'error')
      return;
    } else {
      var formData = new FormData();
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      formData.append("csvfile", this.state.csvfile);
      formData.append("userID", id)
      if (this.state.selectedCampaign !== "")
        formData.append("campaignId", this.state.selectedCampaign)
      if (this.state.selectedChannel !== "")
        formData.append("channelId", this.state.selectedChannel)
      this.setState({
        uploading: true
      }, () => {
        fetch(window.APIURL + "q-link/csvimport", {
          method: "post",
          headers: {},
          body: formData
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              console.log('result.data...', result.data)
              let data = result.data;
              console.log(data)
              this.updateDashBoard(true);
              // setTimeout(() => {
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", csvLog: data.length > 0 ? data : "", csvReport: data.length > 0 ? true : false, uploading: false });
              // }, 1000
              // )
              this.csvupload.value = '';
              swal('Success', result.message, 'success')
            } else if (result.status === false) {
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", uploading: false });
              this.csvupload.value = '';
              swal('Error', result.message, 'error')
            } else {
              this.setState({ csvfile: '', selectedCampaign: "", selectedChannel: "", uploading: false });
              this.csvupload.value = '';
              swal('Error', 'Contact Admin', 'error')
            }
          })
      });
    }

  }

  render() {

    const { redirect, email, notificationEmail, userId } = this.state;
    let { fromAccountDetails, fromStatsDashboard } = this.props;
    if (redirect) {
      return <Redirect to="/userLogin" />;
    }

    // let settings;

    // if (this.props.settings.siteSettingsAuth) {
    //   settings = this.props.settings.siteSettingsRes.site;
    // }

    return (
      <React.Fragment>
        {/* <div className="d-flex justify-content-center align-items-center filters-bar">
          <h5 className="mb-0 mx-4 text-muted">Verification email has been sent to your account, kindly verify it</h5>
          <div>
            <button className="btn btn-outline-verify-email ml-3 px-3">
              Resend
            </button>
            <button className="btn btn-verify-email ml-3 px-3">
              Verify
            </button>
          </div>
        </div> */}
        <header className="user-admin">
          <div className="main-header">
            <div className="container-fluid">
              <div className="menu-outsite">
                <NavbarDropdown
                  userID={this.state.userId}
                  siteSettings={this.state.settings}
                  subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                />

                <span className="group">
                  <span
                    className="headerUserName"
                  // data-id="edit_group"
                  // type="button"
                  // tabIndex="0"
                  // id="group_info"
                  >
                    {/* this.state.username.substr(0, 5).toUpperCase() */}
                    {this.state.username != "" ?
                      this.state.username.toUpperCase() :
                      ""
                    }
                  </span>
                </span>

                {/* TODO: add create bitlinks component */}
                {fromAccountDetails === "yes" ? (
                  ""
                ) : (
                    <NavbarCreateBitlink
                      // getChannelsParentCall={this.getChannelsParentCall}
                      createDashboardCampaign={this.createDashboardCampaign}
                      subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                    />
                  )}
                {(fromAccountDetails === "yes" || this.state.hideSearch === "off") ? (
                  ""
                ) : (
                    this.state.subscriptionDetails && this.state.subscriptionDetails.linkFiltering ?
                      <NavbarSearch
                        ref={this.manageChildSearchField}
                        searchFunc={this.getSearchQuery.bind(this)} //passing function to search child to perform his action
                        subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
                        changeLoaderStatus={this.props.changeLoaderStatus}
                      /> : ''
                  )}

                <div className="menu" onClick={this.clearStates}>
                  {" "}
                  <span />{" "}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="nav">
          <div
            className="main action-sheet--wrapper-MAIN data-state"
            style={{}}
            id="account_info"
          >
            <div
              className="action-sheet--ALTERNATE"
              data-selected-sheet="accountMain"
              style={{
                animation: "600ms ease 0ms 1 normal forwards running open-sheet"
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text"> Your Account</h4>
                <Link
                  className="action-sheet--upsell"
                  to={"/organization/subscriptions/" + userId}
                >
                  <span className="unlock-icon" alt="unlock-icon">
                    <FontAwesomeIcon icon="unlock" />
                  </span>
                  Upgrade
                </Link>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div
                  className="user-account--ALTERNATE checklist-menu-item"
                  data-id="edit_profile"
                >
                  <img
                    className="user-account--image"
                    src={require("../../images/sidebar-logo-icon.svg")}
                  />
                  <div className="user-account--user-info">
                    <div
                      title={this.state.fullName}
                      className="user-account--user-name text-truncate"
                    >
                      {this.state.fullName}
                    </div>
                    <div
                      title={this.state.email}
                      className="user-account--email text-truncate"
                    >
                      {this.state.email}
                    </div>
                  </div>
                  <div className="user-account--icon">
                    <span className="selector-icon" alt="selector-icon">
                      <FontAwesomeIcon icon="chevron-right" />
                    </span>
                  </div>
                </div>
                <div className="hide-desktop links">
                  <h5 className="action-sheet--brand-view-header">
                    FLOINK
                  </h5>
                  <Link to={'/dashboard'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">Dashboard</p>
                      <span className="clicks-icon" alt="clicks-icon" />
                    </div>
                  </Link>
                  <Link to={'/dashboard/stats'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">FLO.ink Management</p>
                      <span
                        className="no_bitlinks-icon"
                        alt="no_bitlinks-icon"
                      />
                    </div>
                  </Link>
                  <Link to={'/dashboard/campaigns'}>
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">Campaigns</p>
                      <span className="binoculars-icon" alt="binoculars-icon" />
                    </div>
                  </Link>
                  <Link to="/organization/domain">
                    <div className="select-item--ALTERNATE">
                      <p className="select-item--label">Custom Domain</p>
                      <span className="binoculars-icon" alt="binoculars-icon" />
                    </div>
                  </Link>
                </div>
                <div>
                  {/* <h5 className="action-sheet--brand-view-header">Options</h5> */}
                  <div className="checklist--wrapper ">
                    <div className="checklist--items">
                      <div
                        className="checklist-row--MAIN neutral checklist-menu-item"
                        data-id="settings"
                      >
                        <div className="checklist-row--text"> Settings</div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>
                      <div
                        className="checklist-row--MAIN neutral checklist-menu-item"
                        data-id="resources"
                        onClick={this.getResourcesData}
                      >
                        <div className="checklist-row--text"> Resources</div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="signout"
                  onClick={this.logOut}
                  style={{
                    border: "none",
                    backgroundColor: "#073e65",
                    color: "#eee"
                  }}
                >
                  <div className="select-item--ALTERNATE">
                    <p className="select-item--label">Sign Out</p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="none" alt="none" />
                  </div>
                </button>
                <div className="marketing-module--GHOST d-none">
                  <p className="marketing-module--text">
                    PRO TIP: Track real-time, daily &amp; monthly engagement
                    trends in FLO.ink.{" "}
                    <a target="_blank" href="#">
                      Learn how
                      <span className="arrow-icon" alt="arrow-icon">
                        <FontAwesomeIcon icon="arrow-right" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="0"
            style={{ display: "none" }}
            id="edit_profile"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="profile"
              style={{
                animation: "600ms ease 0ms 1 normal forwards running open-sheet"
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Edit Profile</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.handleFullnameSubmit}>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">Full Name</label>
                      <div className="input-field--input-wrapper">
                        <input
                          type="text"
                          className="input-field--input"
                          autoComplete="false"
                          maxLength="100"
                          placeholder="Enter Name"
                          name="fullName"
                          onChange={this.onChange}
                          value={this.state.fullName}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="edit_email"
                  >
                    <div className="checklist-row--text"> Email</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="change_password"
                  >
                    <div className="checklist-row--text"> Change Password</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="security"
                    onClick={this.callChildHistoryFunction}
                  >
                    <div className="checklist-row--text"> Security</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  {
                    this.state.subscriptionDetails && this.state.subscriptionDetails.integrations ?
                      <div className="checklist-row--MAIN neutral checklist-menu-item" data-id="token">
                        <div className="checklist-row--text">
                          Generic Access Token
                      </div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div> : ''
                  }
                  {/* {this.state.oauthAppList.length > 0 ? (  //Oauth Registration portion commented
                    <div
                      className="checklist-row--MAIN neutral checklist-menu-item"
                      data-id="app_list"
                    >
                      <div className="checklist-row--text">
                        Registered OAuth Applications
                      </div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon">
                          <FontAwesomeIcon icon="chevron-right" />
                        </span>
                      </span>
                    </div>
                  ) : (
                      <div
                        className="checklist-row--MAIN neutral checklist-menu-item"
                        data-id="registration_code"
                      >
                        <div className="checklist-row--text">
                          Registered OAuth Applications
                      </div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>
                    )} */}
                  {/* <div  //Api Default group
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="api_group"
                  >
                    <div className="checklist-row--text">
                      {" "}
                      Default API Group
                    </div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div> */}
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item"
                    data-id="notification"
                  >
                    <div className="checklist-row--text"> Notifications</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <div
                    className="checklist-row--MAIN neutral"
                    data-id="go_to_billing"
                    onClick={() => this.goToBilling()}
                  >
                    <div className="checklist-row--text"> Billing</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                  <span className="deactivate-account--link d-none">
                    <span>Request GDPR SAR Report</span>
                    <div className="tooltip--ALTERNATE">
                      <div className="tooltip--content tooltip--content-narrow">
                        Click to request a Subject Access Request (SAR) Report
                        of all your Personal Information stored by FLO.ink. Once
                        requested, in compliance with GDPR Article 12, section
                        3.1, we will reply to your request via email within 1
                        month of the request date.
                      </div>
                    </div>
                  </span>
                  <div className="text-center">
                    <span className="deactivate-account--link checklist-menu-item mt-4" data-id="delete_user">Delete Account</span>
                  </div>
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="button--SOLID-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      SAVE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="1"
            style={{ display: "none" }}
            id="edit_email"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="emailList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Email</h4>
                <span className="close-iconnn">
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text mb-3">
                  Only your <b>primary email address</b> will be used as your
                  login credentials and used to receive any account related
                  notifications
                </div>
                <div className="email-info--wrapper checklist-menu-item" data-id="email_status_primary">
                  <div>
                    <div
                      className="checklist-row--MAIN neutral">
                      <div className="checklist-row--text primaryEmailId mb-2">
                        {this.state.email}
                      </div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon">
                          <FontAwesomeIcon icon="chevron-right" />
                        </span>
                      </span>
                    </div>
                    <div className="email-info--primary">
                      Primary email used for login
                    </div>
                    <div className="email-status">
                      Your email address is
                      <span className="email-verified">
                        {" "}
                        {this.state.isVerified ? (
                          <span className="email-verified">Verified</span>
                        ) : (
                            <span className="email-unverified">Not Verified</span>
                          )}{" "}
                      </span>
                      .
                    </div>
                  </div>
                </div>
                <div className="moreAddedEmailIds">
                  {this.state.otherEmails &&
                    this.state.otherEmails.length > 0 &&
                    this.state.otherEmails.map((data, index) => (
                      <div key={index}>
                        <div
                          className="email-info--wrapper"
                          onClick={() =>
                            this.secondaryEmailHandler(
                              data.email,
                              data.isActivated
                            )
                          }
                        >
                          <div>
                            <div
                              className="checklist-row--MAIN neutral checklist-menu-item"
                              data-id={"email_status_secondary"}
                            >
                              <div className="checklist-row--text">
                                {data.email}
                              </div>
                              <span className="checklist-row--icon">
                                <span
                                  className="selector-icon"
                                  alt="selector-icon"
                                />
                              </span>
                            </div>
                            <div className="email-status">
                              Your email address is{" "}
                              {data.isActivated ? (
                                <span className="email-verified">Verified</span>
                              ) : (
                                  <span className="email-unverified">
                                    Not Verified
                                  </span>

                                )}
                            </div>
                            <span className="checklist-row--icon">
                              <span className="selector-icon" alt="selector-icon">
                                <FontAwesomeIcon icon="chevron-right" />
                              </span>
                            </span>

                          </div>
                        </div>
                      </div>
                    ))}
                </div>


                <div
                  className="checklist-row--MAIN neutral checklist-menu-item"
                  data-id="add_email"
                >
                  <div className="checklist-row--text"> Add new email</div>
                  <span className="checklist-row--icon">
                    <span className="selector-icon" alt="selector-icon">
                      <FontAwesomeIcon icon="chevron-right" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* ...///////////////////// */}

          {/* //////////////////////////// */}




          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="1"
            style={{ display: "none" }}
            id="bulk-import"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="emailList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Bulk Import</h4>
                <span className="close-iconnn">
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text mb-3">
                  Bulk Import
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="form-buttons btn-group d-block">
                        <p>Choose CSV file</p>
                        <div className="input-file-btn-holder mb-2">
                          <div className="input-file-btn-holder">
                            <input type="file" name="csvupload" className="" accept=".csv" ref={ref => this.csvupload = ref} onChange={(e) => this.onChangeFile(e)}></input>
                            <span className="input-file-btn btn-success">Browse</span>
                          </div>
                        </div>
                        {/* <span className="input-file-btn btn btn-success">Browse</span> */}
                      </div>
                      {
                        this.state.csvfile ?
                          <div style={{ marginBottom: '14px' }}><small className="text-white"> Selected file <strong>{this.state.csvfile.name}</strong> </small></div>
                          : ''
                      }
                      <div>
                        <div className="input-field--wrapper">
                          <div className="input-field--MAIN false undefined">
                            {/* <label className="input-field--label d-block"></label> */}
                            <div className="input-field--input-wrapper">
                              <div className="StripeElement StripeElement--empty">
                                <div className="__PrivateStripeElement"
                                  style={{
                                    margin: "0px !important", padding: "0px !important", border: "none !important",
                                    display: "block !important", background: "transparent !important", position: "relative !important",
                                    opacity: "1 !important"
                                  }}>
                                  <select className="my-select" name="selectedCampaign" value={this.state.selectedCampaign} onChange={this.onChange}>
                                    <option value="">Select Campaign</option>
                                    {this.state.data && this.state.data.length > 0 ? (
                                      this.state.data.map((data, index) => {
                                        return (
                                          <option key={index} value={data._id}>
                                            {data.title}
                                          </option>
                                        );
                                      })
                                    ) : (
                                        <option value="">No Record Found</option>
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        this.state.channelsData && this.state.channelsData.length > 0 ?
                          <div className="">
                            <div className="input-field--wrapper">
                              <div className="input-field--MAIN false undefined">
                                {/* <label className="input-field--label d-block"></label> */}
                                <div className="input-field--input-wrapper">
                                  <div className="StripeElement StripeElement--empty">
                                    <div className="__PrivateStripeElement"
                                      style={{
                                        margin: "0px !important", padding: "0px !important", border: "none !important",
                                        display: "block !important", background: "transparent !important", position: "relative !important",
                                        opacity: "1 !important"
                                      }}>
                                      {
                                        this.state.channelsData && this.state.channelsData.length > 0 &&
                                        <select className="my-select" name="selectedChannel" value={this.state.selectedChannel} onChange={this.onChange}>
                                          <option value="">Select Channel</option>
                                          {this.state.channelsData.length > 0 ? (
                                            this.state.channelsData.map((data, index) => {
                                              return (
                                                <option key={index} value={data._id}>
                                                  {data.title}
                                                </option>
                                              );
                                            })
                                          ) : (
                                              <option value="">No Record Found</option>
                                            )}
                                        </select>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          ""
                      }


                      <div className="leftButtonsGroup">
                        {this.state.uploading ?
                          <button type="button" disabled className="btn btnUpgrade">Upload</button>
                          :
                          <button type="button" onClick={this.onUplaod} className="btn btnUpgrade">Upload</button>
                        }

                        {
                          this.state.csvfile === '' ?
                            ''
                            :
                            this.state.uploading ?
                              <button type="button" disabled onClick={this.onCancel} className="btn btn-primary">Cancel</button>
                              :
                              <button type="button" onClick={this.onCancel} className="btn btn-primary">Cancel</button>
                        }
                        {
                          this.state.csvReport == false ?
                            ''
                            :
                            <Link to={{ pathname: '/csv/report', state: { csvLogs: this.state.csvLog } }}>Report</Link>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="action-sheet--button-wrapper"
                  style={{
                    animation:
                      "700ms ease 0ms 1 normal forwards running show-apply-button "
                  }}
                >
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    // onClick={}
                    tabIndex="0"
                    id=""
                  >
                    Import
                    </button>
                </div> */}


              </div>
            </div>
          </div>




          {/* //////// */}
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="email_status_primary"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="editEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Edit Email</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--text">{this.state.email}</div>
                <div className="email-status">
                  Your email address is{" "}
                  <span className="email-verified">
                    {this.state.isVerified ? (
                      <span className="email-verified">Verified</span>
                    ) : (
                        <span className="email-unverified">Not Verified</span>
                      )}
                  </span>
                  .
                </div>
                {this.state.isVerified ? (
                  ""
                ) : (
                    <div className="action-sheet--button-wrapper">
                      <button
                        className="email-verify-linkout"
                        onClick={() => this.verificationForm(this.state.email)}
                      >
                        Verify your email.
                  </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="email_status_secondary"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="editEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>
                </a>
                <h4 className="action-sheet--header-text">Edit Email</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                {/* <form onSubmit={this.verificationForm}> */}
                <div className="action-sheet--text">
                  {this.state.secondEmailToShow}
                </div>
                <div className="email-status">
                  Your email address is{" "}
                  {this.state.secondEmailToShowStatus
                    ?
                    <span className="email-verified">Verified</span>
                    :
                    <span className="email-unverified">Not Verified</span>
                  }
                </div>
                {this.state.secondEmailToShowStatus ? (
                  ""
                ) : (
                    <div className="action-sheet--button-wrapper">
                      <button
                        className="email-verify-linkout"
                        onClick={() =>
                          this.verificationForm(this.state.secondEmailToShow)
                        }
                      >
                        Verify your email.
                  </button>
                    </div>
                  )}
                <br />
                <div className="action-sheet--button-wrapper">
                  <button
                    className="email-verify-linkout"
                    onClick={() => this.deleteEmail(this.state.secondEmailToShow)}
                  >
                    Delete this email.
                </button>
                </div>
                <br />
                {this.state.secondEmailToShowStatus ? (
                  <div className="action-sheet--button-wrapper">
                    <button
                      className="email-verify-linkout"
                      onClick={() =>
                        this.makePrimaryEmail(this.state.secondEmailToShow)
                      }
                    >
                      Make this E-Mail Primary
                  </button>
                  </div>
                ) : (
                    ""
                  )}
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="2"
            style={{ display: "none " }}
            id="add_email"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="addEmail"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Add New Email</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.otherEmailFormHandler}>
                  <div>
                    <div className="input-field--wrapper">
                      <div className="input-field--MAIN   ">
                        <label className="input-field--label">Email</label>
                        <div className="input-field--input-wrapper">
                          <input
                            type="email"
                            name="otherEmail"
                            className="input-field--input"
                            autoComplete="false"
                            value={this.state.otherEmail}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="action-sheet--text">
                      <Link to="/resources/privacy" >
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                  <div className="action-sheet--button-wrapper">
                    <button className="button--SOLID-SECONDARY" tabIndex="0">
                      SAVE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="3"
            style={{ display: "none " }}
            id="change_password"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="password"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Change Password</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper button-visible">
                <form onSubmit={this.passwordHandle}>
                  {this.state.socialSigninPasswordCheck === true ?
                    <div style={{ "marginBottom": "1em" }}>
                      <span>You have not created password yet after Login with social account</span><br />
                      <span>Please create new password</span><br />
                      <span>(Leave Current Password field blank)</span><br />
                    </div>
                    :
                    <div></div>
                  }
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">
                        Current Password
                      </label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder="Current Password"
                          name="currentPassword"
                          type="password"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.currentPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">New Password</label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder="New Password"
                          name="newPassword"
                          type="password"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.newPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">
                        Confirm Password
                      </label>
                      <div className="input-field--input-wrapper">
                        <input
                          placeholder="Confirm Password"
                          type="password"
                          name="confirmPassword"
                          className="input-field--input"
                          autoComplete="false"
                          onChange={this.onChange}
                          value={this.state.confirmPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="action-sheet--button-wrapper"
                    style={{
                      animation:
                        "700ms ease 0ms 1 normal forwards running show-apply-button "
                    }}
                  >
                    <button
                      className="button--SOLID-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      SAVE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="4"
            style={{ display: "none " }}
            id="security"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="security"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Security</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <TwoWayAuth
                  delNumber={this.delNumber.bind(this)}
                  phoneNumber={this.state.phoneNumber}
                  userId={this.state.userId}
                />
                {/* TODO: Add connected apps component here */}
                <LoginHistory
                  ref={this.loginHistoryChild}
                  primaryEmail={email}
                />
              </div>
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="4"
            style={{ display: "none " }}
            id="security_phone"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="security"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Security</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form onSubmit={this.codeVerificationForm}>
                  <div>
                    <div>
                      <h5 className="action-sheet--content-header">Set up</h5>
                      <div className="action-sheet--help-text">
                        We sent a security code to your phone
                      </div>
                    </div>

                    <div className="input-field--wrapper">
                      <div className="input-field--MAIN   ">
                        <label className="input-field--label">
                          Code Number
                        </label>
                        <div className="input-field--input-wrapper">
                          <input
                            type="text"
                            className="input-field--input"
                            name="codeNumber"
                            value={this.state.codeNumber}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="action-sheet--ghost-button-wrapper">
                    <button
                      className="button--GHOST-SECONDARY"
                      type="submit"
                      tabIndex="0"
                      id=""
                    >
                      Enable
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="5"
            style={{ display: "none " }}
            id="token"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="accessToken"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              {/* .......................//Generic Access Token */}
              <GenericAccessToken
                userName={this.state.username}
                manageAppsFun={this.manageAppsFunc}
                ref={this.manageChildInGeneric}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="6"
            style={{ display: "none " }}
            id="User_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandList"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text">Select a Group</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* .......................//Select Grop Generic Access Token */}
              <SelectGroup
                name={this.state.username}
                type={this.state.accountType}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="7"
            style={{ display: "none " }}
            id="registration_code"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="getRegistrationCodeSheet"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Get Registration Code
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* .....................................Authhh Get Registration Code........... */}
              <GetRegistrationCode
                verified={this.state.verifiedEmails}
                email={this.state.email}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="add_app"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="createOrEditOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Register Oauth App
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................/ Register Oauth App Addddddddd.................. */}
              <RegisterOauthApp
                theOauthFunction={this.childToParent.bind(this)}
              />
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="app_list"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="registeredOauthApps"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Registered Oauth Apps
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................................../RegisterdOauth  Firsttttttttt.... */}
              <RegisteredOauthApps
                theOauthFunction={this.childToParent.bind(this)}
                registeredApps={this.state.oauthAppList}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="app_details"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="registeredOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Registered Oauth App
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................................/RegisterdOauth Second........... */}
              <RegisteredOauthAppSecond
                theOauthFunction={this.childToParent.bind(this)}
                appDetail={this.state.appDetails}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state=""
            style={{ display: "none " }}
            id="edit_app"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="createOrEditOauthApp"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Register Oauth App
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ...................../Register OauthApp Edittt.................. */}
              <RegisterOauthAppEdit
                theOauthFunction={this.childToParent.bind(this)}
                appDetail={this.state.appDetails}
              />
            </div>
          </div>

          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="8"
            style={{ display: "none " }}
            id="api_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandDefaultApi"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">
                  Set Default API Group
                </h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................../ Default Api Grop............... */}
              <SetDefaultApi
                userName={this.state.username}
                accountType={this.state.accountType.charAt(0).toUpperCase() + this.state.accountType.slice(1)}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="9"
            style={{ display: "none" }}
            id="notification"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="notifications"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Notifications</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              {/* ............................//Notification.................. */}
              <Notification verified={this.state.verifiedEmails} />
            </div>
          </div>

          {/* ............................//Personal Notification.. */}
          {/* {
                this.state.verifiedEmails
              } */}
          <Personal
            dataBind={this.updateData.bind(this)}
            verified={this.state.verifiedEmails}
          />
          {/* TODO: add settings tag here */}
          <Settings
            //I am passing permissions...
            userID={this.state.userId}
            // siteSettings={this.state.settings}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="12"
            style={{ display: "none" }}
            id="social_connect"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="sharingAccounts"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              {/* TODO: social settings components to be added here */}
              <SocialNetworks
                userID={this.state.userId}
                // siteSettings={this.state.settings}
                subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
              />
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="13"
            style={{ display: "none" }}
            id="domains"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="setDefaultDomain"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Domain Default</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <p className="action-sheet--text">
                  Your <strong>default domain</strong> will be used for any
                  FLO.ink created by this group
                </p>
                <div className="checklist--wrapper">
                  <div className="checklist--wrapper ">
                    <div className="checklist--items">
                      <div className="checklist-row--MAIN neutral back_btn-icon">
                        <div className="checklist-row--text"> FLO.ink</div>
                        <span className="checklist-row--icon">
                          <div className="radio-check--LARGE" id="bit.ly">
                            <input
                              id="bit.ly"
                              type="radio"
                              name="bit.ly"
                              className="checkbox--input"
                              defaultValue="bit.ly"
                              defaultChecked=""
                            />
                            <label htmlFor="bit.ly" className="checkmark-icon">
                              <FontAwesomeIcon icon="check" />
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="14"
            style={{ display: "none" }}
            id="advanced"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="advancedSettings"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Advanced</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="advanced-settings--domain-settings">
                  <div
                    className="checklist-row--MAIN neutral checklist-menu-item "
                    data-id="api_support"
                  >
                    <div className="checklist-row--text"> API Support</div>
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="action-sheet--content-header no-b">
                  For Developers
                </div>
                <div className="action-sheet--text">
                  API keys are being deprecated, we recommend you use{" "}
                  <a href="#">OAuth</a>.
                </div>
              </div>
            </div>
          </div>
          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="15"
            style={{ display: "none" }}
            id="api_support"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="apiSupport"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">API Support</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Login</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        autoComplete="false"
                        defaultValue="o_6a1v8urbrl"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Api Key</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        autoComplete="false"
                        defaultValue="R_184bcb4de5fc49a6b44b83325761461b"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <p className="action-sheet--text">
                  <a>Reset API Key</a>
                </p>
                <p className="action-sheet--text">
                  API Key authentication is deprecated in favor of OAuth. For
                  more information see{" "}
                  <a href="#" target="_blank">
                    API Documentation
                  </a>{" "}
                  or manage{" "}
                  <a href="#" target="_blank">
                    Generic Access Tokens
                  </a>
                  .
                </p>
                <p className="action-sheet--text">
                  FLO.ink created with this API key will be assigned to group
                  o_6a1v8urbrl (Personal Account)
                </p>
              </div>
            </div>
          </div>

          {/* TODO: Resources component    */}
          <ResourcesComponent
            ref={this.manageResourcesChild}
          />


          <div
            className="action-sheet--wrapper-MAIN data-state"
            data-state="17"
            style={{ display: "none " }}
            id="edit_group"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="brandSwitcher"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <h4 className="action-sheet--header-text">Select a Group</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <div className="action-sheet--organization">
                  <div className="action-sheet--content-header cust">
                    <span className="personal-icon" alt="personal-icon">
                      <FontAwesomeIcon icon="user" />
                    </span>
                    <span className="action-sheet--org-name">Personal</span>
                    <span className="action-sheet--text">
                      <Link to="/organization/detail">Manage</Link>
                    </span>
                  </div>
                  <div className="checklist--wrapper">
                    <div className="checklist--wrapper ">
                      <div className="checklist--items">
                        <div
                          className="checklist-row--MAIN neutral"
                          id="clossse"
                        >
                          <div className="checklist-row--text">
                            {" "}
                            <span className='account-type-name text-capital'>{this.state.accountType} Account</span>
                            <span className="checklist-row--secondary-label account-type-value">
                              {this.state.username}
                            </span>
                          </div>
                          <span className="checklist-row--icon">
                            <div
                              className="radio-check--LARGE"
                              id="Pro Account"
                            >
                              <input
                                id="Pro Account"
                                type="radio"
                                name="Bj17dCscESZ"
                                className="checkbox--input"
                                //defaultValue="Pro Account"
                                defaultChecked=""
                              />
                              <label
                                htmlFor="Pro Account"
                                className="checkmark-icon"
                              >
                                <FontAwesomeIcon icon="check" />
                              </label>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="marketing-module--GHOST"> Blog link
                  <p className="marketing-module--header">RETHINK THE LINK</p>
                  <p className="marketing-module--text">
                    Check out our blog to learn advanced ways to use FLO.ink!
                    <a href="#" target="_blank">
                      <span className="arrow-icon" alt="arrow-icon">
                        <FontAwesomeIcon icon="arrow-right" />
                      </span>
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          {
            <BulkTagLink
              ref={this.addBulkTagChild}
              updateDashBoard={this.updateDashBoard} />
          }
          {/* TODO: add create bitlink component here */}
          <CreateBitlink
            updateDashBoard={this.updateDashBoard}
            call={this.callUpdate}
            newLinkIdSet={this.newLinkIdSetFunction} />

          {/* TODO: add edit bitlink component here */}
          <EditLink
            shareLink={this.shareLink}
            nowThis={this.nowThis}
            updateDashBoard={this.updateDashBoard}
            newLinkIdSet={this.newLinkIdSetFunction}
            ref={this.editLinkChild}
            editQlinkId={this.state.createdQlinkId}
            manageBitlinkChannelsChildFun={this.manageBitlinkChannelsChildFun}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />

          {/* <div
            className="action-sheet--wrapper-MAIN data-state"
            style={{ display: "none" }}
            id="share_bitlink"
          >
            <div
              className="action-sheet--MAIN"
              data-selected-sheet="shareSecondSheet"
              style={{
                animation:
                  "600ms ease 0ms 1 normal forwards running open-sheet "
              }}
            >
              <div className="action-sheet--header">
                <a>
                  {" "}
                  <span className="back_btn-icon" alt="back_btn-icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>{" "}
                </a>
                <h4 className="action-sheet--header-text">Share FLO.ink</h4>
                <span>
                  <a>
                    <span className="close-icon" alt="close-icon" />
                  </a>
                </span>
              </div>
              <div className="action-sheet--content-wrapper">
                <form action="#">
                  <div className="checklist--wrapper">
                    <div className="checklist--wrapper ">
                      <div className="checklist--items">
                        <div
                          className="checklist-row--MAIN neutral checklist-menu-item"
                          data-id="social_connect"
                        >
                          <div className="checklist-row--text"> Twitter</div>
                          <span className="checklist-row--icon">
                            <div className="radio-check--LARGE" id="Twitter">
                              <input
                                id="Twitter"
                                type="radio"
                                className="checkbox--input"
                                defaultValue="Twitter"
                              />
                              <label
                                htmlFor="Twitter"
                                className="checkmark-icon"
                              />
                            </div>
                          </span>
                        </div>
                        <div
                          className="checklist-row--MAIN neutral checklist-menu-item"
                          data-id="social_connect"
                        >
                          <div className="checklist-row--text"> Facebook</div>
                          <span className="checklist-row--icon">
                            <div className="radio-check--LARGE" id="Facebook">
                              <input
                                id="Facebook"
                                type="radio"
                                className="checkbox--input"
                                defaultValue="Facebook"
                              />
                              <label
                                htmlFor="Facebook"
                                className="checkmark-icon"
                              />
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}

          {/* TODO: add create campaign componenet here */}
          <CreateCampaign
            history={this.props.history}
            createLink={this.createSidebarLink}
            reloadDashboard={this.reloadDashboard}
            ref={this.manageCampaignChild}
            manageChannelsFun={this.manageChannelsFun}
          />

          <CreateFloInkCampaign
            reloadDashboard={this.reloadDashboard}
            ref={this.createfloinkCampaign}
            newLinkIdSet={this.newLinkIdSetFunction}
          />

          <EditCampaign
            reloadDashboard={this.reloadDashboard}
            ref={this.editCampaignChild}
          />

          {/* TODO: add manage channels componenet here */}
          <ManageChannels
            changeLoaderStatus={this.props.changeLoaderStatus}
            ref={this.manageChannelsChild}
            updateChannelsFromChild={this.updateChannelsFromChild
            }
          />

          {/* TODO: add manage Apps componenet here */}
          <ManageApps
            changeLoaderStatus={this.props.changeLoaderStatus}
            ref={this.manageAppsChild}
            updateAppsFromChild={this.updateAppsFromChild}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />

          {/* TODO: add manage bitlink channels componenet here */}
          <ManagaeBitlinkChannel
            manageChannelsFun={this.manageChannelsFun}
            ref={this.manageBitlinkChannelsChild}
            linkChannelIdToCampaign={this.linkChannelIdToCampaign}
          />

          <ManageBitlinkCampaign
            updateDashBoard={this.updateDashBoard}
            newLinkIdSet={this.newLinkIdSetFunction}
            ref={this.manageBitlinkCampaignsChild}
            linkChannelIdToCampaignValue={
              this.state.manageBitlinkChannelsChildFun
            }
          />

          {/* TODO: add delete account componenet here */}
          <DeleteAccount userId={this.state.userId} logoutFun={this.logOut} />

          {/* TODO: add custom domain component */}
          <AddCustomDomain />

          {/* TODO: add share component component */}
          <ShareLink
            ref={this.manageShareChild}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          />
          {/* <ShareMedia
            ref={this.manageShareMediaChild}
            subscriptionDetails={this.state.subscriptionDetails ? this.state.subscriptionDetails : {}}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardNavbar;
// const mapDispatchToProps = {
//   getSiteSetting, beforeSiteSettings
// };

// const mapStateToProps = state => ({
//   settings: state.settings,
//   header: state.header,
//   errors: state.errors
// });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(withRouter(DashboardNavbar));
