import { GET_SITE_SETTINGS, BEFORE_SITE_SETTINGS } from '../../redux/types';

const initialState = {
    siteSettingsRes: {},
    siteSettingsAuth: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SITE_SETTINGS:
            return {
                ...state,
                siteSettingsRes: action.payload,
                siteSettingsAuth: true
            }
        case BEFORE_SITE_SETTINGS:
            return {
                ...state,
                siteSettingsAuth: false
            }
        default:
            return {
                ...state
            }
    }
}
