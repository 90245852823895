import React, { Component } from "react";
import { Link } from 'react-router-dom';

class EmailNotVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem("loginToken")) {
            this.setState({ session: true })
        }
    }
    render() {
        return (
            <div className="d-flex text-center paymentMessageParent">
                <div className="EmailMessageHolder notVerified">
                    <h2>Email is already Verified</h2>
                    <div className="icon-holder">
                        <div className="email-icon d-inline-block align-top">
                            <img className="img-fluid" src={require('../../../../src/images/email-not-verified.svg')} alt="" />
                        </div>
                    </div>
                    <div className="text-holder">
                        {/* <h3>Hello John</h3> */}
                        <p>Your email is alreafy verified.</p>
                    </div>
                    <div className="footerBtns text-center">
                        {this.state.session ?
                            <Link to="/dashboard" className="btn btn-cb">Dashboard</Link>
                            :
                            <Link to="/userLogin" className="btn btn-cb">Login</Link>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default EmailNotVerify;