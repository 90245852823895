import React, { Component } from "react";
import { Link } from "react-router-dom";

class WhyQuick extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<div className="staticPageHeader text-white">
					<div className="container">
						<h1>Why FLO.ink?</h1>
					</div>
				</div>
				<section className="contentSection">
					<div className="container">
						<div className="sectionHeadingBlock text-center">
							<h2>FLO.ink 101</h2>
							<small className="d-block">An Introduction to FLO.ink’s Features</small>
						</div>
						<div className="sectionIntroText">
							<h2>Best-in-Class Link Management</h2>
							<p>Create, track and optimize your promotion campaigns with industry-leading branded links and custom domains. FLO.ink is a powerful tool for digital marketers and customer support teams.</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle bgLightBlue">
								<h3>Convert Links into Marketing Assets</h3>
								<p>Get more organic shares and click-throughs with custom links. With FLO.ink, you can advertise to your audience directly and extract more value from the branded links you share. Get up to 35% more clicks.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Insights with Real-Time Analytics</h3>
								<p>Know what to share with the audience. FLO.ink brings real-time analytics to help you maximize campaign performance. Get rid of the guesswork and know which type of content is resonating with your customers, and how clicks, shares, demographics, geolocation and top referring channels are performing.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Integrate with Other Technologies</h3>
								<p>Connect your business and advertising tools to FLO.ink. It saves you time and hassle by powering up your work tools with branded links using the FLO.ink API. It gives you the freedom to share your branded links seamlessly from your favorite tools, apps and pages.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Designed to Protect Your Data</h3>
								<p>FLO.ink helps incorporate branded links into your existing workflow, tools and processes with ease and security. Protect your brand image with safe and reliable shortened links, encrypted with HTTPS to guard against counterfeits.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="contentSection bgLightBlue">
					<div className="container">
						<div className="sectionHeadingBlock text-center">
							<h2>Integrations &amp; API</h2>
							<small className="d-block">Connect Our System To Yours</small>
						</div>
						<div className="sectionIntroText">
							<h2>Beat the Workflow Odds</h2>
							<p>Integrate and activate your preferred apps and extensions with FLO.ink to speed up your workspace performance and teammates productivity.</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle">
								<h3>Collaborate with Cohesive Strategy</h3>
								<p>Share branded short links across teams, departments and regions with one cohesive strategy. Connecting FLO.ink to the tools you use every day will scale up your work efficiency. Setup database where team members can connect to view, manage and monitor the branded links, custom domains, social media, promotional campaigns and customer experience.</p>
							</article>
							<article className="contentArticle">
								<h3>High Volume Links with Less Hassle</h3>
								<p>Access dedicated API endpoints to securely create and manage high quantities of branded links at scale. Streamline your link shortening capabilities You can set links to auto-expire and benefit from a specific pricing model for maximum efficiency.</p>
							</article>
							<article className="contentArticle">
								<h3>All URLs Under HTTPS Protocol</h3>
								<p>FLO.ink’s enterprise-grade data security backed by 2FA and SSL security tools allows to confidently integrate your workflow with other technologies. Https short links and 100% secure click data are mandatory for proximity marketing and referral data in analytics.</p>
							</article>
							<article className="contentArticle">
								<h3>Deliver Short Links via SMS in Bulk</h3>
								<p>With FLO.ink API, you can deliver a bulk of short links via SMS. Integrate this powerful API with world’s leading SMS communication platforms like Twilio to send out branded messages in high volume to your target audience.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="contentSection">
					<div className="container">
						<div className="sectionHeadingBlock text-center">
							<h2>Enterprise Class</h2>
							<small className="d-block">Get More with Less</small>
						</div>
						<div className="sectionIntroText">
							<h2>Automate Complex Business Workflows</h2>
							<p>Businesses go through several inflection points, where an enterprise solution partner can provide valuable guidance and programs to take brand growth and expansion to the next level.</p>
						</div>
						<div className="contentArticlesHolder">
							<article className="contentArticle bgLightBlue">
								<h3>Powerful</h3>
								<p>FLO.ink API helps businesses personalize and track thousands of links per minute, at scale. You can run cross-channel campaigns using lots of branded links. No matter traffic volume your business generates, our system can handle it efficiently.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Reliable</h3>
								<p>Designed for ease of use, FLO.ink API delivers guaranteed service uptime. It is the most advanced technology, which functions as a world-class infrastructure that is set up to grow with your business.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>24/7 Security</h3>
								<p>FLO.ink’s support teams monitor the availability and performance of your branded short links and domains, round-the-clock. All the short links and clicks metrics is well-protected under https, SSL and 2FA. Internal audits and 24/7 human monitoring helps keep your data 100% private and secure.</p>
							</article>
							<article className="contentArticle bgLightBlue">
								<h3>Flexible</h3>
								<p>Create thousands of new branded links per second with rapid autoscaling server clusters. FLO.ink delivers a higher level of intelligence, security and customer experience when it comes to shortened links and custom domains. Hundreds of developers already using FLO.ink API to manage their dedicated projects.</p>
							</article>
							<div className="centerBtnsHolder text-center">
								<Link to="/get-quote">
									<button className="btn btn-cw">Get A Quote</button>
								</Link>
								<Link to="/pricing">
									<button className="btn btn-cb">Get Started</button>
								</Link>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default WhyQuick;
