import React, { Component } from "react";
import { Link } from "react-router-dom";
import { scrollTop } from '../../shared/common-functions';
import $ from 'jquery'

class Solutions extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// scrollTop();
		console.log(this.props);
		let hash = this.props.location.hash;
		console.log(this.props);
		if(hash!== ''){
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			});
		}
	}
	componentWillMount(){
		// scrollTop();
		$('html, body').animate({
			scrollTop: 0
		});
	}
	render() {
		return (
			<React.Fragment>
				<div className="staticPageHeader text-white">
					<div className="container">
						<h1>Solutions</h1>
					</div>
				</div>
				<div className="main-content">
					<section className="solutionContnetSection" id="socialMedia">
						<div className="solutionTextBlock bottomShadow">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-8">
										<div className="headingSectionStyle-02">
											<h2>Social Media</h2>
											<p>Increase Social Brand Worth</p>
										</div>
										<h3>A Shot of Innovation</h3>
										<p>FLO.ink helps you build your personal or business brand, make influential connections and win more customers. Create and share click-worthy links across every platform.</p>
										<h3>Key Features</h3>
										<ul className="list-unstyled solutionDetailList">
											<li>
												<strong className="d-block">Branded Links</strong>
												<span className="d-block">Ensure brand building as your custom links circulate around the web.</span>
											</li>
											<li>
												<strong className="d-block">Social Connectivity</strong>
												<span className="d-block">Reinforce your online presence by connecting social accounts with FLO.ink.</span>
											</li>
											<li>
												<strong className="d-block">Social Posting</strong>
												<span className="d-block">Promote content through social channels by posting your links directly from FLO.ink.</span>
											</li>
										</ul>
									</div>
									<div className="col-lg-4 text-center">
										<div className="solutionImageHolder">
											<img src={require("../../images/social-media.jpg")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="action-button centerButtonsHolder text-center">
											<Link className="btn btn-cw d-inline-block align-top" to="/get-quote">Get A Quote</Link>
											<Link className="btn btn-cb d-inline-block align-top" to="/pricing">Get Started</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="solutionDetailsBlock">
							<div className="container">
								<div className="sectionHeadingBlock text-center">
									<h2>The Most Dynamic Link Management Platform</h2>
									<small className="d-block">Get unprecedented control to optimize, manage and track your links</small>
								</div>
								<div className="solutionDetailsRow">
									<div className="row">
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Links Management</h3>
												<p>Monitor, modify and manage your links in real-time with FLO.ink. It allows bulk link tagging and filtering to categorize the links.</p>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/sport-team.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Custom Links</h3>
												<p>FLO.ink integrates into your daily workflow to help you extract more value from brandable custom short URLs with intelligent link routing.</p>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/secure.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Data Analytics</h3>
												<p>Derive meaningful business insights with real-time, actionable analytics. Make better decisions based on your links data.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="solutionContnetSection" id="digitalMarketing">
						<div className="solutionTextBlock bottomShadow bgLightBlue">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-8">
										<div className="headingSectionStyle-02">
											<h2>Digital Marketing</h2>
											<p>Drive Robust Engagement</p>
										</div>
										<h3>Turn Links to Leads</h3>
										<p>Enhance click-through rates with reliable links for increased online conversions.</p>
										<h3>Key Features</h3>
										<ul className="list-unstyled solutionDetailList">
											<li>
												<strong className="d-block">Link Shortening</strong>
												<span className="d-block">Encourage your audience to convert into potential clients using call-to-action links on web, mobile or email, etc.</span>
											</li>
											<li>
												<strong className="d-block">Branded Links</strong>
												<span className="d-block">Drive your audience toward digital experiences with powerful brand integrity using custom links.</span>
											</li>
											<li>
												<strong className="d-block">Campaign Management &amp; Analytics</strong>
												<span className="d-block">Track and optimize your digital initiatives to meet the unique requirements of each client.</span>
											</li>
										</ul>
									</div>
									<div className="col-lg-4 text-center">
										<div className="solutionImageHolder">
											<img src={require("../../images/digital-marketing.jpg")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="action-button centerButtonsHolder text-center">
											<Link className="btn btn-cw d-inline-block align-top" to="/get-quote">Get A Quote</Link>
											<Link className="btn btn-cb d-inline-block align-top" to="/pricing">Get Started</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="solutionDetailsBlock">
							<div className="container">
								<div className="sectionHeadingBlock text-center">
									<h2>Superlative Experience with Reliability &amp; Security</h2>
									<small className="d-block">FLO.ink is the world’s leading link management and tracking platform for digital marketers</small>
								</div>
								<div className="solutionDetailsRow">
									<div className="row">
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/chart.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Seamless Integrations</h3>
												<p>FLO.ink integrates with numerous industry-leading marketing platforms and tools for better results.</p>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/together.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Secure Connections</h3>
												<p>FLO.ink is built on the industry security standards. Our system has HTTPS protocol and data encryption.</p>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="solutionDetailBlock text-center bgLightBlue">
												<div className="iconHolder">
													<img src={require("../../images/support.svg")} alt="" className="img-fluid" />
												</div>
												<h3>Actionable Analytics</h3>
												<p>Advanced, predictive analytics help measure and analyze your link performance and campaigns metrics.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="solutionContnetSection" id="customerServices">
						<div className="solutionTextBlock bottomShadow">
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-8">
										<div className="headingSectionStyle-02">
											<h2>Customer Service</h2>
											<p>Interact with Consumers</p>
										</div>
										<h3>Leverage in Quick Solutions</h3>
										<p>Equip your customer service teams with powerful business links and analytics.</p>
										<h3>Key Features</h3>
										<ul className="list-unstyled solutionDetailList">
											<li>
												<strong className="d-block">Onboarding &amp; Setup</strong>
												<span className="d-block">Get your account up and running with the support of our experts offering personalized communication at scale.</span>
											</li>
											<li>
												<strong className="d-block">Account Management</strong>
												<span className="d-block">FLO.ink is a flexible system for account management. Our responsive team is always available for customer care.</span>
											</li>
											<li>
												<strong className="d-block">24/7 Support</strong>
												<span className="d-block">Dedicated teams to make sure you get the most out of your selected plan. Get instant support for all your queries.</span>
											</li>
										</ul>
									</div>
									<div className="col-lg-4 text-center">
										<div className="solutionImageHolder">
											<img src={require("../../images/customer-services.jpg")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="action-button centerButtonsHolder text-center">
											<Link className="btn btn-cw d-inline-block align-top" to="/get-quote">Get A Quote</Link>
											<Link className="btn btn-cb d-inline-block align-top" to="/pricing">Get Started</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="solutionDetailsBlock">
							<div className="container">
								<div className="sectionHeadingBlock text-center">
									<h2>Best-In-Class Support, Scalability and Security</h2>
									<small className="d-block">FLO.ink is the world’s leading link management platform for customer service teams</small>
								</div>
								<div className="solutionDetailsRow">
								<div className="row">
									<div className="col-lg-4">
										<div className="solutionDetailBlock text-center bgLightBlue">
											<div className="iconHolder">
												<img src={require("../../images/trust.svg")} alt="" className="img-fluid" />
											</div>
											<h3>Onboarding</h3>
											<p>Get the most out of FLO.ink with the help of our robust knowledge base to maximize your customer relationships.</p>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="solutionDetailBlock text-center bgLightBlue">
											<div className="iconHolder">
												<img src={require("../../images/api.svg")} alt="" className="img-fluid" />
											</div>
											<h3>Reliable API &amp; Integration</h3>
											<p>Improve your customer engagement with real-time and personalized communication at scale.</p>
										</div>
									</div>
									<div className="col-lg-4">
										<div className="solutionDetailBlock text-center bgLightBlue">
											<div className="iconHolder">
												<img src={require("../../images/secure.svg")} alt="" className="img-fluid" />
											</div>
											<h3>Robust security</h3>
											<p>FLO.ink follows the best industry practices in security solutions, including two-factor authentication and HTTPS on all links.</p>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}
}

export default Solutions;
