import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from "jquery";
import { createBrowserHistory } from "history";
import Select from 'react-select';
import { Redirect } from "react-router-dom";

class CreateBitlink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: JSON.parse(localStorage.getItem("LoginSession")).data._id,
      long_url: "",
      shortlinkDomain: "",
      domains: [],
      domainVerification: [],
      logOutRedirect: false,
      selectedOption: null,
      options: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount = () => {
    this.getDomains();
  };

  getDomains = () => {
    fetch(window.APIURL + "custom-domain/user-custom-domains?userId=" + this.state.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      return res.json();
    }).then(result => {
      if (result.status) {
        let options = [], selectedOption = {};
        result.domains.map((val, key) => {
          let obj = {
            value: val.name,
            label: val.isVerified ? `✔️ ${val.name}` : `❌ ${val.name}`,
            isdisabled: !val.isVerified
          }

          options.push(obj);
        });

        if (result.domains && !result.domains.length) {
          options = { value: null, label: 'No options available' };
          selectedOption = { value: null, label: 'No options available' };
        } else {
          selectedOption = options[options.length - 1];
        }

        this.setState({
          options, selectedOption,
          domains: result.domains,
          shortlinkDomain: result.domains && result.domains.length > 0 ? result.domains[result.domains.length - 1].name : 'flo.ink'
        });
      } else if (!result.status) {
        swal('Error', result.message, 'error');
      } else {
        swal('Error', 'Contact Admin', 'error');
      }
    })
  };

  handleSubmit = e => {
    e.preventDefault();

    const regexp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    var pattern = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
    // var pattern = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
    if (this.state.long_url.includes('http')) {
      if (pattern.test(this.state.long_url)) {
        this.createBitlink()
      } else {
        swal('Error', "Type a valid url", 'error')
      }
    } else {
      if (this.state.long_url.match(regexp)) {
        let theLink = "http://" + this.state.long_url + "/";
        if (pattern.test(theLink)) {
          this.setState({
            long_url: theLink
          }, () => {
            this.createBitlink()
          });
        } else {
          swal('Error', "Type a valid url", 'error')
        }
      } else {
        swal('Error', "Type a valid url", 'error')
      }
    }
  };

  createBitlink = () => {
    let history = createBrowserHistory();
    fetch(window.APIURL + "q-link/add", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": true,
        // "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify({
        domain: this.state.selectedOption.value, // this.state.shortlinkDomain,
        link: this.state.long_url,
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          this.props.newLinkIdSet(result.qlink.customizeBackHalf);
          let currentHash = window.location.hash.replace("#", "").split("&");
          history.push({
            pathname: "/web/dashboard",
            search: "?link=" + result.qlink.customizeBackHalf
          });
          // let value = 'true'
          this.props.updateDashBoard(true);
          this.setState({
            long_url: ""
          });
          currentHash[currentHash.length - 1] = "action=edit_bitlink";
          currentHash = currentHash.join("&");
          $("#edit_bitlink").show();
          swal('Success', result.message, 'success')
          $("#create_bitlink").hide();
          window.location.hash = currentHash;
        } else if (result.status === false) {
          if (result.redirection === true) {
            this.setState({ logOutRedirect: true });
            swal('Error', result.message, 'error')
          }
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  render() {
    const { selectedOption, options } = this.state;
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      return <Redirect to="/logout" />;
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="18"
          style={{ display: "none" }}
          id="create_bitlink"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="create"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <h4 className="action-sheet--header-text">Create FLO.ink</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <form onSubmit={this.handleSubmit} className="button-visible">
              <div className="bsd-tooltip--content-wrapper" />
              <div className="action-sheet--content-wrapper">
                {/* REACT SELECT COMMENTED TEMP. */}
                <Select
                  className="channelSelectDropdown cur-poi"
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  isOptionDisabled={(option) => option.isdisabled}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      color: state.isDisabled ? 'grey' : 'hsl(0,0%,20%)',
                      cursor: state.isDisabled ? 'not-allowed' : 'pointer'
                    })
                  }}
                />
                <div className="text-field--wrapper">
                  <div className="text-field--MAIN  ">
                    <label className="text-field--label">Paste Long URL</label>
                    <div className="text-field--textarea-wrapper">
                      <textarea
                        type="text"
                        rows="2"
                        value={this.state.long_url}
                        name="long_url"
                        onChange={this.onChange}
                        className="text-field--textarea"
                        maxLength="6144"
                        autoComplete="false"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="action-sheet--tooltip">
                  To create a Bitlink from your <br /> dashboard, press 'b'
                </div> */}
                <div
                  className="action-sheet--button-wrapper"
                  style={{
                    animation:
                      "700ms ease 0ms 1 normal forwards running show-apply-button "
                  }}
                >
                  <button
                    className="button--SOLID-SECONDARY"
                    // className="button--SOLID-SECONDARY checklist-menu-item"
                    // data-id="edit_bitlink"
                    type="submit"
                    tabIndex="0"
                    id="create_shortlink"
                  >
                    CREATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateBitlink;
