import React, { Component } from "react";
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import MainFooter from "../../components/layout-files/MainFooter";
import MainNavbar from "../../components/layout-files/MainNavbar";
import "bootstrap/dist/css/bootstrap.min.css";

class QRCodeComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: ''
		};
	}

	componentDidMount() {
		if (this.props.location.search) {
			var url = `${this.props.location.search.split('?shortUrl=')[1]}?scan=true`;
			if (url) this.setState({ url });
		}
	}

	downloadQR = () => {
		// console.log('canvas', this.state.url)
		let url = this.state.url;
		let final = "";
		if (url !== '') {
			let urlSplit = url.split('/')
			let otherSplit = urlSplit[1].split('?')
			final = otherSplit[0];
			// console.log('urlSplit', urlSplit)
		}
		// console.log('final', final)
		const canvas = document.getElementById("qrId");
		// console.log('canvas', downloadLink.href)
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = final !== "" ? final + ".png" : "QrImage.png";
		// console.log('downloadLink.href', downloadLink.download)
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	render() {
		return (
			<React.Fragment>
				<MainNavbar />
				<div className="main-content">
					<div className="solution-section bg-grey qr-code-main-content">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<div className="qrcode-block">
										<div className="qrcode-holder text-center">
											<p>Your QR Code for FLO.ink is Here!</p>
											<div className="qr-code-holder">
												<QRCode id="qrId" value={this.state.url} renderAs="canvas" size={180} level="H" includeMargin={true} />
											</div>
											<Link to="#" className="btn btnQRDownload mb-2 d-inline-block align-top" onClick={this.downloadQR}> Download QR </Link>
											<p>Scan this code to proceed further</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<MainFooter />
			</React.Fragment>
		);
	}
}

export default QRCodeComponent;
