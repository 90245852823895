import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes/routes";
import { ENV } from '../src/config/config';
import $ from 'jquery';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {}
    }
  }

  componentDidMount() {
    this.getSiteSetting();
  }

  getSiteSetting = () => {
    fetch(`${ENV.url}site-settings/get-site-settings`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token
      }
    })
      .then(res => { return res.json() }).then(data => {
        if (data.status) {
          // this.setState({
          //   settings: data.site
          // });

          // add to google analytics script
          $('body').append(data.site.googleAnalytics)
        } else
          console.log('Settings error', data)
      }).catch(errors => {
        console.log('Settings catch error', errors)
      })
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""} >
        <Switch>
          {/* <div> */}
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={
                    props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    }
                  }
                />
              );
            })}
          {/* </div> */}
        </Switch>
      </Router>
    )
  }
}

export default App;
