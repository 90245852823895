import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { ENV } from '../../config/config';
const queryString = require("query-string");
var CryptoJS = require("crypto-js");


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: "",
      redirect: false,
      redirectTo: false,
      isLoader: false,
      settings: {},
      custom: false,
      subscriptionId: "",
      userFromParam: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    var parsed = queryString.parse(this.props.location.search);
    let check = this.props.location.search;
    let params = new URLSearchParams(check);
    let subscriptionId = params.get('subscriptionId');
    let UserID = params.get('user');
    // let directAuth = params.get('da');
    let isCustomize = params.get('custom');
    if (isCustomize === 'true') {
      // console.log("isCustomize", isCustomize)
      // console.log("isCustomize", typeof (isCustomize));
      this.setState({ custom: isCustomize, subscriptionId: subscriptionId, userFromParam: UserID })
    }

    if (parsed.token !== undefined) {
      let tokenParam = JSON.parse(parsed.token);
      if (tokenParam.status) {
        if (tokenParam.data.defaultSubscriptionPackage) {
          let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(tokenParam.data.defaultSubscriptionPackage), ENV.TokSecCrpt);
          localStorage.setItem("spd", encSubscriptionPlanDetails);
        }
        localStorage.setItem("LoginSession", JSON.stringify(tokenParam));
        localStorage.setItem("loginToken", tokenParam.data.loginToken);
        localStorage.setItem("loginTime", new Date().getTime());
        this.setState({ redirect: true });
      } else {
        this.setState({ redirect: false });
        swal('Error', tokenParam.message, 'error');
      }
    }
    else if (localStorage.getItem("loginToken")) {
      var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
      var timeNow = new Date().getTime() / 1000;
      var timeDifference = timeNow - loginTime;

      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        if (isCustomize === 'true') {
          // alert('wwww')
          let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
          // console.log('id', id)
          // console.log('idType', typeof (id));
          // console.log('userId', UserID)
          // console.log('userType', typeof (UserID))
          if (UserID == id) {
            // alert('123')
            this.setState({ redirectTo: true });
          } else {
            // alert('456')
            // localStorage.removeItem('LoginEcrpSession');
            // localStorage.removeItem('accessToken');
            // localStorage.removeItem('loginTime');
            this.props.history.push('/dashboard');
          }
        } else {
          // alert('789')
          this.setState({ redirect: true });
        }
      } else {
        this.setState({ redirect: false });
      }
    } else {
      this.setState({ redirect: false });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: ''
    });
  };

  componentDidMount = () => {
    this.props.getSiteSetting();
  };

  handleSubmit = e => {
    const { email, password, userFromParam } = this.state;
    e.preventDefault();
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "") {
      this.setState({ errors: "Email is Required" });
      return;
    } else if (!email.match(regexp)) {
      this.setState({ errors: "Email is Invalid" });
      return;
    } else if (password === "") {
      this.setState({ errors: "Password is Required" });
      return;
    } else {
      this.setState({ errors: "" });
      fetch(window.APIURL + "accounts/member/login", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            if (result.data.isTwoStepVerification === true) {
              localStorage.setItem("_id", result.data._id);
              this.props.history.push("/verifyCode");
            } else {
              let subscriptionPlanDetails = result.subscriptionPlanDetails;
              delete result.subscriptionPlanDetails;
              let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
              localStorage.setItem("spd", encSubscriptionPlanDetails);
              localStorage.setItem("LoginSession", JSON.stringify(result));
              localStorage.setItem("loginToken", result.data.loginToken);
              localStorage.setItem("loginTime", new Date().getTime());
              if (this.state.custom) {
                // console.log('result.data._id', result.data._id)
                // console.log('result.data._idType', typeof (result.data._id));
                // console.log('userFromParam', userFromParam)
                // console.log('userFromParamType', typeof (userFromParam))
                if (result.data._id == userFromParam) {
                  this.setState({
                    redirectTo: true
                  })
                } else {
                  // localStorage.removeItem('LoginEcrpSession');
                  // localStorage.removeItem('accessToken');
                  // localStorage.removeItem('loginTime');
                  this.props.history.push('/dashboard');
                }
              } else {
                this.setState({ redirect: true });
              }
            }
          } else if (result.status === false) {
            swal('Error', result.message, 'error');
          } else {
            swal('Error', 'Contact Admin', 'error');
          }
        });
    }
  };

  render() {
    const { redirect, redirectTo, subscriptionId, } = this.state;
    if (redirect) {
      return <Redirect to="/dashboard" />;
    }

    if (redirectTo) {
      return <Redirect to={`/organization/subscription?subscriptionId=${subscriptionId}&custom=true`} />;
    }

    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <div style={{ height: '100vh', display: 'flex', padding: '25px 0', overflowY: 'auto' }}>
        <div className="container-box" style={{ margin: 'auto' }}>
          <h1 className="header-logo">
            <Link to="/">
              {
                settings ?
                  <img src={settings.siteLogo}
                    alt="Site Logo"
                  /> : ''
              }
            </Link>
          </h1>
          <form id="sign-in" onSubmit={this.handleSubmit}>
            <div className="header">
              <h3 className="tagline">Sign in &amp; Start Sharing</h3>
            </div>

            <div className="susi-fields-wrapper">
              <fieldset>
                <label htmlFor="email">
                  <input
                    className="text"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Email"
                  />
                  <span className="error-message" />
                </label>
                <label htmlFor="password" className="password-block">
                  <input
                    className="text"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <Link
                    className="forgot"
                    title="Password Reset"
                    to="/forgotPassword"
                    tabIndex="6"
                  >
                    Forgot Password?
                </Link>
                  <span className="error-message" />
                </label>

                {Object.keys(this.state.errors).length > 0 &&
                  this.state.errors ? (
                    <div className="alert alert-danger">{this.state.errors}</div>
                  ) : (
                    ""
                  )}
                <input
                  type="submit"
                  className="button button-primary sign-up-in"
                  value="Sign In"
                  tabIndex="5"
                />
              </fieldset>
            </div>
          </form>
          <p className="separator t-center">
            <span>Or</span>
          </p>
          <div className="social-sign-in">
            <span className="susi-with">SIGN IN WITH:</span>
            <a
              rel="nofollow"
              href={window.APIURL + "auth/google"}
              className="susi-btn google-susi-btn button"
              data-network="google"
            >
              <img
                src={require("../../images/google.svg")}
                alt="Google"
                width="20"
              />
            </a>
            <a
              rel="nofollow"
              href={window.APIURL + "auth/facebook"}
              className="susi-btn facebook-susi-btn button"
              data-network="facebook"
            >
              <img
                src={require("../../images/facebook.svg")}
                alt="Facebook"
                width="20"
              />
            </a>
            <a
              rel="nofollow"
              href={window.APIURL + "auth/twitter"}
              className="susi-btn twitter-susi-btn button"
              data-network="twitter"
            >
              <img
                src={require("../../images/twitter.svg")}
                alt="Twitter"
                width="22"
              />
            </a>
          </div>
          <div className="text-center">
            <div className="switch to-sign-up">
              <span className="gray-link">Don't have an account?</span>{" "}
              <Link to="/userRegister">Sign Up Now</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Login));