import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';

class RegisterOauthApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationCode: '',
            appName: '',
            appLink: '',
            redirectURIs: '',
            appDescription: '',
            userId: '',
            clientId: '',
            clientSecret: '',
            errors: '',
            redirect: false,

        }
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('registrationCode');
        if (foo) {
            this.setState({
                registrationCode: foo
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentWillUnmount() {
        this.setState({
            registrationCode: '',
        })
    }

    onRegisterOauth = (e) => {
        e.preventDefault();
        const { appName, appLink, redirectURIs, appDescription, registrationCode, userId } = this.state;
        // if (registrationCode === '') {
        //     this.setState({ errors: 'Registration Code is required' });
        //     return;
        // }
        if (appName === '') {
            this.setState({ errors: 'Application name is required' });
            return;
        } else if (appLink === '') {
            this.setState({ errors: 'Application link is required' });
            return;
        }
        else if (redirectURIs === '') {
            this.setState({ errors: 'Application URI is required' });
            return;
        }
        else if (appDescription === '') {
            this.setState({ errors: 'Application Description is required' });
            return;
        }
        else {
            fetch(window.APIURL + 'register-oauth-app/create', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "registrationCode": registrationCode,
                    "appName": appName,
                    "appLink": appLink,
                    "redirectURIs": redirectURIs,
                    "appDescription": appDescription,
                    "userId": JSON.parse(localStorage.getItem("LoginSession")).data._id
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {
                if (result.status === true) {
                    swal('Success', result.message,  'success')
                    this.setState({
                        redirect: true,
                    })
                    // let from ="registerApp";
                    // this.props.theOauthFunction(from, "null")
                } else if (result.status === false) {
                    swal('Error', result.message, 'error')
                } else {
                    swal('Error', 'Contact Admin', 'error')
                }
            });
        }
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/' />;
        }
        return (
            <React.Fragment>
                <div className="action-sheet--content-wrapper">
                    <p className="action-sheet--text">
                        For documentation on using FLO.ink OAuth visit the{" "}
                        <a target="_blank" href="#">
                            API Documentation
                        </a>
                    </p>
                    <form>
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    Registration Code
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.registrationCode}
                                        name="registrationCode"
                                        type="text"
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "38px " }}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    Application Name
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appName}
                                        onChange={this.onChange}
                                        name="appName"
                                        type="text"
                                        placeholder="Required"
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "38px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            Please provide a unique name for your application. This
                            value will be displayed to users when they authenticate to
                            FLO.ink.
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    Application Link
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appLink}
                                        onChange={this.onChange}
                                        name="appLink"
                                        type="text"
                                        placeholder="Required"
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "56px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            The URL of your application. This value will be displayed to
                            users when they authenticate to FLO.ink. If your application
                            link is a root domain or a directory (ie, a link that does
                            not end in .html or .php), it must end with a forward slash.
                            Example: http://your-application.com/
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">Redirect URIS</label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.redirectURIs}
                                        onChange={this.onChange}
                                        name="redirectURIs"
                                        type="text"
                                        placeholder="Required"
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "56px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-sheet--help-text">
                            For multiple, enter one per line. Example:
                            <br />
                            <span>
                                https://your-application.com/callback/
                                <br />
                                http://dev.your-application.com/redirect.php
                            </span>
                        </div>
                        <br />
                        <div className="text-field--wrapper">
                            <div className="text-field--MAIN  ">
                                <label className="text-field--label">
                                    Application Description
                                </label>
                                <div className="text-field--textarea-wrapper">
                                    <textarea
                                        value={this.state.appDescription}
                                        onChange={this.onChange}
                                        name="appDescription"
                                        type="text"
                                        placeholder="Required"
                                        rows="2"
                                        className="text-field--textarea"
                                        maxLength="500"
                                        autoComplete="false"
                                        style={{ height: "38px " }}
                                    />
                                </div>
                            </div>
                        </div>
                        {(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div> : ''}
                        <div className="action-sheet--button-wrapper">
                            <button
                                className="button--SOLID-SECONDARY"
                                // data-id="app_list"
                                type="button"
                                tabIndex="0"
                                onClick={this.onRegisterOauth}
                                id=""
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}
export default RegisterOauthApp;