import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import React, { Component } from "react";
// import MainFooter from "../components/layout-files/MainFooter";
import DashboardNavbar from "../components/layout-files/DashboardNavbar";
import AccountsNavbar from "../components/layout-files/accounts-navbar/accountsNavbar";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
library.add(far, fas)
class AccountNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render()
  {
    return(
      <React.Fragment>
        <DashboardNavbar
          history={this.props.history}
          fromAccountDetails={"yes"} />
        <div className="container">
          <div className="row layout--wrapper">
            {/* <div className="col-md-3">
              <AccountsNavbar />
            </div> */}
            <div className="col-md-12">
              {this.props.children}
            </div>
          </div>
        </div>
        {/* <MainFooter /> */}
      </React.Fragment>
    )
  }
}
// const AccountNav = ({ children }) => (
//   <React.Fragment>
//     <DashboardNavbar
//       fromAccountDetails={"yes"} />
//     <div className="container">
//       <div className="row layout--wrapper">
//         {/* <div className="col-md-3">
//           <AccountsNavbar />
//         </div> */}
//         <div className="col-md-12">
//           {children}
//         </div>
//       </div>
//     </div>
//     {/* <MainFooter /> */}
//   </React.Fragment>
// );

export default AccountNav;
