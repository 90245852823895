import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { scrollTop } from '../../shared/common-functions';
import CircleLoader from 'react-spinners/CircleLoader';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from '@emotion/core';
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import $ from "jquery";
import NavLink from "react-bootstrap/NavLink";

const override = css`
    display: block;
	position:absolute;
    left:50%;
    top:50%;
    margin:-60px 0 0 -60px;
    border-color: #0a6eb4;
`;

const blueOverride = css`
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  border-color: #0a6eb4;
`;
class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			urlLink: '',
			shortner: {},
			url:"",
			create: false,
			mainLoader: false,
			smallLoader: false
		};
	}

	componentWillMount() {
		scrollTop();
		let token = this.props.match.params.token;
		if (token !== undefined && token !== "undefined") {
			localStorage.setItem("loginToken", token);
			localStorage.setItem("loginTime", new Date().getTime());
			this.setState({ redirect: true });
		} else if (localStorage.getItem("loginToken")) {
			this.setState({ redirect: true });
		} else {
			this.setState({ redirect: false, mainLoader: true });
			// let URL = window.location.href;
			// console.log('url.....', URL)
			// var n = URL.includes("/a");
			// if (n) {
			// 	// console.log('yes......')
			// 	// let resultUrl = URL.split("/a")
			// 	this.props.history.push("")
			// 	// console.log('rrrrrrrrrrr',resultUrl)
			// } else {
			// 	console.log('noooo.......')
			// }
			this.timeOut();
		}
	}

	timeOut = () => {
		setTimeout(() => {
			this.setState({
				mainLoader: false
			})
		}, 1000);
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	urlShortner = () => {
		this.setState({ smallLoader: true });
		const regexp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
		var pattern = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$');
		let link = this.state.urlLink;
		if (link.includes('http')) {
			if (pattern.test(link)) {
				fetch(window.APIURL + "guest-url", {
					method: "post",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						link: this.state.urlLink
					})
				})
					.then(res => {
						if (res.status === 200) {
							return res.json();
						} else if (res.status === 400) {
							return res.json();
						} else if (res.status === 403) {
							return res.json();
						} else if (res.status === 422) {
							return res.json();
						} else if (res.status === 404) {
							return res.json();
						} else {
							return res.json();
						}
					})
					.then(result => {
						if (result.status === true) {
							this.setState({ shortner: result.guestUrl, create: true, smallLoader: false, url: `https://${result.guestUrl.subDomain}${result.guestUrl.backHalf}` });
						} else if (result.status === false) {
							this.setState({ smallLoader: false })
							swal('Error', result.message, 'error')
						} else {
							this.setState({ smallLoader: false })
							swal('Error', 'Contact Admin', 'error')
						}
					})
			} else {
				this.setState({ smallLoader: false })
				swal('Error', "Type a valid url", 'error')
			}
		} else {
			if (link.match(regexp)) {
				let theLink = "http://" + link + "/";
				if (pattern.test(theLink)) {
					this.setState({
						urlLink: theLink
					}, () => {
						fetch(window.APIURL + "guest-url", {
							method: "post",
							headers: {
								"Content-Type": "application/json"
							},
							body: JSON.stringify({
								link: this.state.urlLink
							})
						})
							.then(res => {
								if (res.status === 200) {
									return res.json();
								} else if (res.status === 400) {
									return res.json();
								} else if (res.status === 403) {
									return res.json();
								} else if (res.status === 422) {
									return res.json();
								} else if (res.status === 404) {
									return res.json();
								} else {
									return res.json();
								}
							})
							.then(result => {
								if (result.status === true) {
									this.setState({ shortner: result.guestUrl, create: true, smallLoader: false, url: `https://${result.guestUrl.subDomain}${result.guestUrl.backHalf}` });
								} else if (result.status === false) {
									this.setState({ smallLoader: false })
									swal('Error', result.message, 'error')
								} else {
									this.setState({ smallLoader: false })
									swal('Error', 'Contact Admin', 'error')
								}
							})
					})
				}
				else {
					this.setState({ smallLoader: false })
					swal('Error', "Type a valid url", 'error')
				}
			} else {
				this.setState({ smallLoader: false })
				swal('Error', "Type a valid url", 'error')
			}
		}
		// if (pattern.test(this.state.urlLink)) {
		// } else {
		// 	swal('Error', "Type a valid url", 'error')
		// }
		// fetch(window.APIURL + "guest-url", {
		// 	method: "post",
		// 	headers: {
		// 		"Content-Type": "application/json"
		// 	},
		// 	body: JSON.stringify({
		// 		link: this.state.urlLink
		// 	})
		// })
		// 	.then(res => {
		// 		if (res.status === 200) {
		// 			return res.json();
		// 		} else if (res.status === 400) {
		// 			return res.json();
		// 		} else if (res.status === 403) {
		// 			return res.json();
		// 		} else if (res.status === 422) {
		// 			return res.json();
		// 		} else if (res.status === 404) {
		// 			return res.json();
		// 		} else {
		// 			return res.json();
		// 		}
		// 	})
		// 	.then(result => {
		// 		if (result.status === true) {
		// 			this.setState({ shortner: result.guestUrl, create: true });
		// 		} else if (result.status === false) {
		// 			swal('Error', result.message, 'error')
		// 		} else {
		// 			swal('Error', 'Contact Admin', 'error')
		// 		}
		// 	});
	}

	copyLink = link => {
		var fullLink = document.createElement("input");
		document.body.appendChild(fullLink);
		fullLink.value = link;
		fullLink.select();
		document.execCommand("copy");
		$(".clipboard").show();
		setTimeout(() => {
			$(".clipboard").hide();
		}, 1000
		)
		fullLink.remove();
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			this.urlShortner();
		}
	}

	goToSolutions(navigationPath) {
		this.setState({
			mainLoader: true
		},
			() => {
				$('html, body').animate({
					scrollTop: 0
				},
					() => {
						this.props.history.push(navigationPath);
					}
				);
			}
		)
	}

	render() {
		const { redirect, shortner } = this.state;
		if (redirect) {
			return <Redirect to="/dashboard" />;
		}
		// setTimeout(() => {
		// 	this.setState({
		// 		mainLoader: false,
		// 	})
		// }, 2000);
		return (
			<React.Fragment>
				{
					this.state.mainLoader === true ?
						<div className="main-loader" >
							<CircleLoader
								css={override}
								sizeUnit={"px"}
								size={120}
								color={'#007bff'}
								loading={this.state.mainLoader}
							/>
						</div>
						:
						<div className="main-content">
							<section className="top-banner">
								<div className="container">
									<div className="row">
										<div className="banner-content">
											<h1 className="banner-title">Think BIG & Ace<br />Smart Solutions</h1>
											<p>Build and boost a whole new brand identity using powerful, recognizable, click-worthy short links. Increase your links click-through rates by 35% or more.</p>
										</div>
										<div className="banner-bottom">
											<div className="down-arrow">
												<span>
													<img src={require("../../images/arrow-down.png")} alt="" />
												</span>
											</div>
											<div className="search-field d-flex flex-column flex-md-row align-items-center">
												<div className="flex-fill w-100">
													<form className="d-flex flex-column flex-sm-row">
														<input type="text" name="urlLink" onKeyPress={this.handleKeyPress} value={this.state.urlLink} onChange={this.onChange} />
														<button type="button" className="genrate" onClick={this.urlShortner}>Shorten</button>
													</form>
													<p>By clicking SHORTEN, you are agreeing to FLO.ink’s <Link to='/resources/privacy'>Privacy Policy</Link> and <Link to='/resources/terms-of-services'>Terms of Service</Link></p>
													<div id="shorten_actions" style={{ display: this.state.create === true ? '' : 'none' }} >
														<ul id="most_recent_links" className="anon_history">
															<li className="link">
																{/* <span className="long-link">{shortner.subDomain + "" + shortner.backHalf}</span> */}
																<span className="bitlink--copyable-text">
																	<span className="clipboard">Copied to cilpboard!</span>
																	<span className="short-link">
																		<span className="link-prev-text">You shortlink is:&nbsp;</span>
																		{
																			this.state.smallLoader ?
																				<BeatLoader
																					css={blueOverride}
																					size={10}
																					margin={2}
																					color={"#0a6eb4"}
																					loading={this.state.smallLoader}
																				/>
																				:
																				<a
																					href={"http://" + shortner.subDomain + shortner.backHalf}
																					target="_blank">
																					{shortner.subDomain + shortner.backHalf}
																				</a>
																		}
																	</span>
																	<span className="copy">
																		<button
																			className="button button-secondary"
																			onClick={() =>
																				this.copyLink(
																					shortner.subDomain + shortner.backHalf
																				)
																			}
																		>
																			Copy
																</button>
																	</span>
																</span>
															</li>
														</ul>
													</div>
												</div>
												<div className="generated-qr-box" style={{ display: this.state.create === true ? '' : 'none' }}>
													<QRCode id="qrId" value={this.state.url} renderAs="canvas" size={150} level="H" includeMargin={true} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="first-section">
								<div className="container">
									<div className="row first">
										<span className="heading bk">
											<h2>Stay One step ahead</h2>
										</span>

										<div className="col-md-6">
											<div className="content-title">
												<span>FLO.ink</span><br />Go Beyond What’s Possible
									</div>
											<div className="content-info">
												<p>Create, monitor, manage secure and reliable links. Every link you create with FLO.ink, increases your brand engagement and exposure. You can share your branded links to get more clicks; making your audience more likely to engage with your brand and its content. </p>
												<p>This flexible URL shortening platform brings you value-based solutions where you can create a seamless user experience while tracking and optimizing every touchpoint.</p>
											</div>
											<div className="action-button">
												<Link to="/userRegister">
													<button className="btn btn-cw">Free Sign Up</button>
												</Link>
												<Link to="/pricing">
													<button className="btn btn-cb">Get Enterprise</button>
												</Link>
											</div>

										</div>
										<div className="col-md-6">
											<div className="image">
												<img src={require("../../images/first-section.png")} alt="" className="img-fluid" />
											</div>
										</div>
									</div>
									<div className="row second">
										<span className="heading">
											<h2>Leverage the best-in-class, most flexible URL shortening platform</h2>
											<p>Increase your brand recognition by creating customized,
										short URLs you share online on social platforms or send through sms or email. </p>
										</span>

										<div className="col-lg-4">
											<div className="item">
												<div className="image">
													<img src={require("../../images/amplify.png")} alt="" className="img-fluid" />
												</div>
												<span className="title">Amplify Brand Worth</span>
												<p>On top of audience engagement and improved click-through rates, the auto-branding enhances your brand awareness.
											You can also get key insights into your shared content and communications. </p>
												<div className="action-button">
													<span onClick={() => this.goToSolutions('/solutions#socialMedia')} className="btn btn-cb cur-poi">Social Media</span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="item">
												<div className="image">
													<img src={require("../../images/optimize.png")} alt="" className="img-fluid" />
												</div>
												<span className="title">Optimize Your Campaigns</span>
												<p>You can easily manage multiple campaigns and make smart marketing decisions.
											Our analytics provide actionable data to help you improve your marketing efforts to yield better results. </p>
												<div className="action-button">
													<span onClick={() => this.goToSolutions('/solutions#digitalMarketing')} className="btn btn-cb cur-poi">Digital Marketing</span>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="item">
												<div className="image">
													<img src={require("../../images/inspire.png")} alt="" className="img-fluid" />
												</div>
												<span className="title">Inspire your customers</span>
												<p>As an advanced link management platform, FLO.ink allows you to take your brand recognition to the next level by customizing each branded link.
											Audience will be more confidently connect with your brand. </p>
												<div className="action-button">
													<span onClick={() => this.goToSolutions('/solutions#customerServices')} className="btn btn-cb cur-poi">Customer Service</span>
												</div>
											</div>
										</div>
									</div>
									{/*<div className="row third">
								<span className="heading">
									<h2> <span className="clr">Flo.</span>ink is loved by world’s leading brands</h2>
									<p>Turn your brand links into monetizable assets. </p>
								</span>

								 <div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-1.png")} className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-2.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-3.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-4.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-5.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-6.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-7.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>

							</div>
							<div className="row fourth">
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-8.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>

								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-9.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-10.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-11.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-12.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-13.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="col-3 col-sm col-md">
									<div className="item">
										<div className="image">
											<img src={require("../../images/logo-4.png")} alt="" className="img-fluid" />
										</div>
									</div>
								</div> 
							</div>*/}
								</div>
							</section>
							<section className="before-footer">
								<div className="container">
									<span>Start Sharing Powerful, Customized Brand Links</span><br />
									<Link className="btn btn-cw" to="/userRegister">
										Get Started
							</Link>
								</div>
							</section>
						</div>
				}
			</React.Fragment>
		);
	}
}

export default LandingPage;
