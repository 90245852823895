import React, { Component } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from "moment";
import { Redirect } from "react-router-dom";
// import { createBrowserHistory } from "history";
import $ from "jquery";
import Select from 'react-select';
import Notifications, { notify } from 'react-notify-toast';
require('bootstrap-select');
var suggest = require('suggestion');


const queryString = require("query-string");

class EditLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      qLinkData: "",
      title: "",
      customizeBackHalf: "",
      domain: "",
      qLinkId: "",
      createdDate: "",
      isHidden: "",
      campaignId: "",
      campaignTitle: "",
      channelId: "",
      channelTitle: "",
      fromCampaign: false,
      logOutRedirect: false,
      mainUrl: "",
      suggestionItems: [],
      currentSuggestion: ""

    };
    this.showHideFunction = this.showHideFunction.bind(this);
  }

  componentWillMount = () => {
    var parsed = queryString.parse(window.location.search);
    if (parsed.link !== undefined && parsed.link !== "undefined") {
      let link = parsed.link;
      this.getLinkDataHash(link);
    }

    // var parsed = queryString.parse(window.location.search);
    if (parsed.campaign !== undefined && parsed.campaign !== "undefined") {
      this.setState({
        fromCampaign: true,
      })
    } else {
      this.setState({ fromCampaign: false })
    }
  };

  getLinkDataHash = hash => {
    fetch(window.APIURL + "q-link/searchHash?customizeBackHalf=" + hash, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          if (result.qlink != undefined) {
            this.getLinkData(result.qlink._id);
          }
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  getLinkData = linkId => {
    fetch(window.APIURL + "q-link/get?_id=" + linkId, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          this.setState({
            title: result.qLink.title ? result.qLink.title : "",
            customizeBackHalf: result.qLink.customizeBackHalf,
            suggestionItems: [],
            tags: result.qLink.tags,
            domain: result.qLink.domain,
            qLinkId: result.qLink._id,
            createdDate: result.qLink.createdAt,
            isHidden: result.qLink.hideLink,
            mainUrl: result.qLink.link
          }, () => {
            this.makesuggesions()
          });
          if (result.qLink.channel.length > 0) {
            this.setState({
              campaignId: result.qLink.campaignData[0] && result.qLink.campaignData[0]._id ? result.qLink.campaignData[0]._id : null, //This is not a good sollution
              campaignTitle: result.qLink.campaignData[0] && result.qLink.campaignData[0].title ? result.qLink.campaignData[0].title : null,  //This is not a good sollution
              channelId: result.qLink.channel[0]._id,
              channelTitle: result.qLink.channel[0].title
            });
          } else {
            this.setState({
              campaignTitle: null,
              channelId: null,
              campaignId: null,
              channelTitle: null
            });
          }
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  handleChange = t => {
    this.setState({ tags: t });
  };

  handleChangee = e => {
    this.setState({
      [e.target.name]: e.target.value,
      customizeBackHalf: e.target.value
    });
    // this.setState({ currentSuggestion, customizeBackHalf: currentSuggestion });
  };

  makesuggesions = () => {
    let thiss = this;
    let url = this.state.mainUrl;
    var reURLInformation = new RegExp([
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$' // hash
    ].join(''));
    if (url !== "") {

      var match = url.match(reURLInformation);
      var array = [];
      var name, firstPart, secondPart, finalName;
      var path, firstPath, secondPath;
      var search, firstSearch, secondSearch, thirdSearch, fourthSearch, fifthSearch, sixthSearch, finalSearch;

      if (match !== null && match.length > 0) {

        let sitname = match[2];
        let pathname = match[5];
        let searchname = match[6];

        if (sitname !== undefined) {

          let check1 = sitname.search('.com');

          if (check1 !== -1) {
            name = sitname.split('.com')
            firstPart = name[0];
          } else {
            firstPart = sitname;
          }

          let check2 = firstPart.search('www.');

          if (check2 !== -1) {
            finalName = firstPart.split('www.')
            finalName = finalName[1];
          } else {
            finalName = firstPart
          }

          let check3 = finalName.search(':');
          if (check3 !== -1) {
            secondPart = finalName.split(':')
            finalName = secondPart[0];
          }

          array.push(finalName)
          console.log('x', finalName)
        }

        if (pathname !== undefined) {

          let check1 = pathname.search('/');

          if (check1 !== -1) {
            path = pathname.split('/');

            if (path.length > 0) {
              for (let i = 0; i < path.length; i++) {

                if (path[i] !== "") {
                  if (path[i].length > 4) {
                    let subchar = path[i].substring(0, 4);
                    array.push(subchar);
                  }
                }
              }
            }

          }
        }

        // if (searchname !== undefined) {

        //   let check1 = searchname.search('/?');

        //   if (check1 !== -1) {
        //     search = searchname.split('?');
        //     firstSearch = search[1];
        //     console.log("firstSearch", firstSearch)
        //     let check2 = firstSearch.search('&oq=');
        //     if (check2 !== -1) {
        //       secondSearch = firstSearch.split('&oq=');
        //       thirdSearch = secondSearch[1];
        //       console.log('thirdSearch', thirdSearch)
        //       let check3 = thirdSearch.search('&gs_lcp=');
        //       if (check3 !== -1) {
        //         fourthSearch = thirdSearch.split('&gs_lcp=');
        //         console.log('fourthSearch', fourthSearch[0])
        //         fifthSearch = fourthSearch[0];
        //         let check4 = fifthSearch.search('/+');
        //         if (check4 !== -1) {
        //           sixthSearch = fifthSearch.split('+');
        //           console.log('sixthSearch..', sixthSearch)
        //         } else {
        //           finalSearch = fifthSearch
        //         }
        //       }
        //       console.log('secondSearch', secondSearch)
        //     }
        //     // console.log("firstSearch", firstSearch)
        //   }

        // }



        var num = Math.floor((Math.random() * 100) + 1);
        array.push(num);

        // this.shuffle(array)
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }

        let finalString;
        // console.log('arrLength', array.length)
        if (array.length > 1)
          finalString = array[0] + " " + array[1];
        else
          finalString = array[0];

        let theArr = [];
        // console.log('before...', finalString)
        suggest(finalString, { levels: 1 }, function (err, suggestions) {
          if (err) {
            console.log('error', err)
          }
          // console.log('suggggggg', suggestions);
          suggestions.map(function (item, i) {
            // console.log('item...', item);
            var str = item
            str = str.replace(/\s/g, '');
            // console.log('str...', str);
            theArr.push(str);
            // console.log('suggggggg', str);
          })
          if (theArr && theArr.length > 0) {
            thiss.setState({ suggestionItems: theArr }, () => {
              // setTimeout(() => {
              $('.my-select').selectpicker();
              // }, 1000)
            })
          }

          console.log('theArrrrrr....', theArr)
          /*
          [ 'dog training',
            'dog training tips',
            'dog training collars',
            'dog training classes',
            'dog training videos',
            'dog training houston',
            'dog training certification',
            'dog training books',
            'dog training chicago',
            'dog training clicker' ]
          */
        })
        // console.log('finalString...//', finalString)
        // console.log('finalString...//', typeof (finalString));
        // console.log('array.../', array)
        // console.log('match...', match)
        // console.log('Inn.')
      } else {
        console.log('Out...')
      }

    }
  }

  handleEditSubmit = e => {
    // e.preventDefault();
    // let history = createBrowserHistory();
    if (!this.state.customizeBackHalf || this.state.customizeBackHalf === "") {
      swal('Error', "Back half can not be empty.", 'error')
      return;
    }
    if (this.state.customizeBackHalf !== "") {
      var format = /[^a-zA-Z0-9]/;
      if (this.state.customizeBackHalf.match(format)) {
        swal('Error', "Customized-back-half cannot have special characters", 'error')
        return;
      }
    }

    if (this.state.customizeBackHalf.toLocaleLowerCase() === "admin") {
      swal('Error', "Customized-back-half already taken", 'error')
      return;
    }

    if (this.state.customizeBackHalf.toLocaleLowerCase() === "web") {
      swal('Error', "Customized-back-half already taken", 'error')
      return;
    }

    fetch(window.APIURL + "q-link/edit", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        _id: this.state.qLinkId,
        tags: this.state.tags,
        customizeBackHalf: this.state.customizeBackHalf,
        title: this.state.title,
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          // let currentHash = window.location.hash.replace("#", "").split("&");
          // history.push({
          //   pathname: "/a/dashboard",
          //   search: "?link=" + result.qlink.customizeBackHalf
          // });
          this.setState({
            title: result.qlink.title ? result.qlink.title : "",
            customizeBackHalf: result.qlink.customizeBackHalf,
            tags: result.qlink.tags,
            domain: result.qlink.domain
          });

          let updateData = {
            title: result.qlink.title ? result.qlink.title : "",
            customizeBackHalf: result.qlink.customizeBackHalf,
            tags: result.qlink.tags,
            domain: result.qlink.domain,
            _id: result.qlink._id
          };

          this.props.updateDashBoard(true, updateData);
          this.props.newLinkIdSet();
          // currentHash[currentHash.length - 1] = "#";
          // console.log('current',currentHash)
          // $("#edit_bitlink").hide();
          // window.location.hash = "";
          swal('Success', result.message, 'success')
        } else if (result.status === false) {


          if (result.redirection === true) {
            this.setState({ logOutRedirect: true });
            swal('Error', result.message, 'error')
          }

          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  showHideFunction = showHide => {
    fetch(window.APIURL + "q-link/edit", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        hideLink: !this.state.isHidden,
        _id: this.state.qLinkId,
        customizeBackHalf: this.state.customizeBackHalf,
        userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          if (result.qlink.hideLink === true) {
            swal('Success', 'FLO.ink added to hidden links', 'success');
          } else {
            swal('Success', 'FLO.ink removed from hidden links', 'success');
          }
          this.setState({
            title: result.qlink.title,
            customizeBackHalf: result.qlink.customizeBackHalf,
            tags: result.qlink.tags,
            domain: result.qlink.domain,
            isHidden: result.qlink.hideLink
          });
          this.props.updateDashBoard();
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };
  removeCampaign = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete campaign from FLO.ink',
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes  '
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "q-link/edit", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            _id: this.state.qLinkId,
            customizeBackHalf: this.state.customizeBackHalf,
            campaignId: null,
            channelId: null,
            userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
          })
        }).then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        }).then(result => {
          if (result.status === true) {
            this.setState({
              campaignTitle: null,
              channelId: null,
              campaignId: null,
              channelTitle: null
            });
            this.props.updateDashBoard(true);
            this.props.newLinkIdSet();
            swal('Success', 'Campaign deleted from FLO.ink successfully', 'success')
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        })
      }
    });
  }

  copyLink = link => {
    var fullLink = document.createElement("input");
    document.body.appendChild(fullLink);
    fullLink.value = `https://${link}/`;
    fullLink.select();
    document.execCommand("copy");
    let myColor = { background: '#cce5ff', text: "##004085" };
    notify.show('Copied to clipboard!', "custom", 3000, myColor);
    // $(".clipboard").show();
    // setTimeout(() => {
    //   $(".clipboard").hide();
    // }, 1800
    // )
    fullLink.remove();
  };

  shareLinkEdit = (hash) => {
    this.props.shareLink(hash, false)

  }

  render() {
    const { subscriptionDetails } = this.props;
    const { logOutRedirect, suggestionItems, currentSuggestion } = this.state;
    if (logOutRedirect) {
      return <Redirect to="/logout" />;
    }
    return (
      <React.Fragment>
        {/* <Notifications /> */}
        <div
          className="action-sheet--wrapper-MAIN data-state"
          style={{ display: "none" }}
          id="edit_bitlink"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="edit"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <h4 className="action-sheet--header-text">Edit FLO.ink</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <form className="button-visible">
              <div className="bitlink--copy-wrapper">
                <div className="bitlink--copy-interface">
                  <div className="d-block">
                    <a
                      href={'http://' + this.state.domain + "/" + this.state.customizeBackHalf}
                      className="bitlink--copyable-text"
                      target="_blank"
                    >
                      <span className="bitlink--MAIN" tabIndex="-1">
                        {this.state.domain + "/"}
                        <span className="bitlink--hash">
                          {this.state.customizeBackHalf}
                        </span>
                      </span>
                    </a>
                  </div>
                  <div className="item--action-buttons">
                    <button
                      className="button--ACTION"
                      type="button"
                      tabIndex="0"
                      data-clipboard-text={this.state.domain + "/" + this.state.customizeBackHalf}
                      id=""
                      onClick={() =>
                        this.copyLink(
                          this.state.domain + "/" + this.state.customizeBackHalf
                        )
                      }
                    >
                      COPY
                    </button>
                    {
                      subscriptionDetails.socialPosting ?
                        <button className="button--ACTION" type="button" tabIndex="0" id="" onClick={() => this.shareLinkEdit(this.state.customizeBackHalf)}>
                          SHARE
                      </button>
                        : ''
                    }
                    <span className="clipboard nav-clipboard">Copied to cilpboard!</span>
                  </div>
                </div>
              </div>
              <div className="action-sheet--content-wrapper">

                {
                  this.state.createdDate != "" ?
                    <span style={{ fontSize: "14px" }} >
                      {
                        "Created - " + moment(this.state.createdDate).format("MMM DD hh:mm A").toUpperCase()
                      }
                    </span>
                    :
                    ""
                }
                <span
                  onClick={() => this.showHideFunction(this.state.isHidden)}
                  className="action-sheet--text"
                >
                  {this.state.isHidden ? (
                    <a className="archive-link" title="Show Link">
                      Show Link
                    </a>
                  ) : (
                      <a className="archive-link" title="Hide Link">
                        Hide Link
                      </a>
                    )}
                </span>
                <div className="title-wrapper">
                  <div className="input-field--wrapper">
                    <div className="input-field--MAIN   ">
                      <label className="input-field--label">Title</label>
                      <div className="input-field--input-wrapper">
                        <input
                          type="text"
                          name="title"
                          placeholder="Add a title."
                          className="input-field--input"
                          value={this.state.title}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {
                  subscriptionDetails.customBackHalf ?
                    <React.Fragment>
                      <div className="coachmark-wrapper">
                        <div className="input-field--wrapper">
                          <div className="input-field--CUSTOM   ">
                            <label className="input-field--label">
                              Customize BACK-HALF
                            </label>
                            <div className="input-field--static-value">
                              <span className="d-inline-block align-top">{this.state.domain}</span>
                              <span className="d-inline-block align-top mx-1">/</span>
                              <div className="input-field--input-wrapper d-inline-block align-top" style={{ width: '155px' }}>
                                <input
                                  type="text"
                                  className="input-field--input"
                                  autoComplete="false"
                                  tabIndex="0"
                                  value={this.state.customizeBackHalf}
                                  name="customizeBackHalf"
                                  onChange={this.onChange}
                                  style={{ width: '100%' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="marketing-module--MAIN">
                        <p className="marketing-module--text">
                          Did you know custom links get 34% more clicks?
                      </p>
                      </div>
                      {
                        this.state.suggestionItems && this.state.suggestionItems.length > 0 ?
                          <div className="dynamic-search-list--ALTERNATE whiteColoredSelect">
                            <h5 className="dynamic-search-list--header">
                              SUGGESTIONS
                            </h5>
                            <div className="select-item--MAIN">

                              <select className="my-select" name="currentSuggestion" value={this.state.currentSuggestion} onChange={this.handleChangee}>
                                <option value="">Select suggestion</option>
                                {
                                  this.state.suggestionItems.map((data, index) => {
                                    return (
                                      <option key={index} value={data}>
                                        {data}
                                      </option>
                                    );
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          :
                          ""
                        // <div className="dynamic-search-list--ALTERNATE whiteColoredSelect">
                        //   <h5 className="dynamic-search-list--header">
                        //     SUGGESTIONS
                        //   </h5>
                        //   <div className="select-item--MAIN">
                        //     <select className="my-select" name="currentSuggestion" value={this.state.currentSuggestion} onChange={this.handleChangee}>
                        //       <option value="">Select suggestion</option>
                        //       <option value="">No suggestions found</option>
                        //     </select>
                        //   </div>
                        // </div>
                      }
                    </React.Fragment> : ''
                }

                {this.state.channelTitle != null ? (
                  <div className="associated light">
                    <span className="title">Channel: </span>
                    <span> {this.state.channelTitle} </span> <br />
                    <span className="title">Campaign: </span>
                    <span> {this.state.campaignTitle} </span>
                    <span className="cross" onClick={() => this.removeCampaign()}> X </span>
                  </div>
                ) : (
                    ""
                  )}
                {
                  subscriptionDetails.tags ?
                    <React.Fragment>
                      <div className="dynamic-search-list--MAIN">
                        <div className="search--ALTERNATE   ">
                          <TagsInput
                            value={this.state.tags}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="dynamic-search-list--filter-pills " />
                      </div>
                      <span style={{ fontSize: "14px" }} >
                        {"*type, then press 'enter' or 'tab' to add tag"}
                      </span>
                      <hr></hr>
                    </React.Fragment> : ''
                }

                {
                  subscriptionDetails.campaigns && !this.state.fromCampaign ?
                    <div className="checklist-row--MAIN neutral ">
                      <div className="checklist-row--text link" onClick={this.props.manageBitlinkChannelsChildFun}>
                        + Add to Campaign
                      </div>
                      <span className="checklist-row--icon" />
                    </div>
                    :
                    ""
                }
              </div>
              <div
                className="action-sheet--button-wrapper"
                style={{
                  animation:
                    "700ms ease 0ms 1 normal forwards running show-apply-button "
                }}
              >
                <button className="button--SOLID-SECONDARY" type="button" onClick={this.handleEditSubmit} tabIndex="0" id="">
                  SAVE
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditLink;
