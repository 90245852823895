import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('LoginSession'));
        let name = user.data.username;
        this.setState({ userName: name })
    }

    render() {
        let { userName } = this.state;
        return (
            <React.Fragment>
                <div className="action-sheet--content-wrapper">
                    <div className="action-sheet--help-text">
                        Select which verified email address you would like to use to
                        receive notifications for your accounts.
                </div>
                    <div>
                        <div className="action-sheet--content-header">PERSONAL</div>
                        <div
                            className="select-item--MAIN-b checklist-menu-item"
                            data-id="personal"
                        >
                            {this.props.verified &&
                                this.props.verified.length > 0 &&
                                this.props.verified.map((data, index) => (
                                    (data.isNotification === true ?
                                        <p className="select-item--label" key={index + 1}>{data.email}</p>
                                        :
                                        ''

                                    )
                                ))}
                            <span className="select-item--secondary-label" />
                            <span className="select-item--third-label " />
                            <span className="select-item--fourth-label" />
                            <span className="checklist-row--icon">
                                <span className="selector-icon" alt="selector-icon">
                                    <FontAwesomeIcon icon="chevron-right" />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Notification;