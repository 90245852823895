import React, { Component } from "react";

class TermCondition extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-content">
          <div className="cms-content">
            <div className="container">
              <h1 className="title">Terms & Conditions </h1>
              <div className="text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque accumsan enim efficitur, facilisis augue in,
                  dapibus metus. Integer volutpat, turpis a lacinia efficitur,
                  risus sapien ultrices leo, non faucibus purus elit ut metus.
                  Cras nunc leo, hendrerit at urna quis, vestibulum varius
                  lectus. Pellentesque facilisis interdum suscipit. Cras sed
                  pellentesque nibh. Aenean vel dolor sit amet eros finibus
                  vulputate non ac tellus. Curabitur cursus id velit eget
                  sodales. Nam vel eros cursus, consectetur nulla sit amet,
                  ornare erat.
                </p>

                <p>
                  Vivamus tincidunt arcu eget tellus suscipit posuere. Mauris
                  gravida volutpat tellus. In nec dolor neque. Aenean aliquet ex
                  orci, eget viverra ligula molestie at. Cras tempus tincidunt
                  ultricies. Integer volutpat ultrices lacus eu lobortis. Proin
                  quam lorem, facilisis at ligula consectetur, rutrum finibus
                  augue. In vel viverra ex. Pellentesque interdum sit amet velit
                  id cursus. Nullam nec auctor nisi, eget posuere risus. Donec
                  iaculis arcu quis dui fringilla, at accumsan magna aliquam.
                  Morbi non malesuada massa.
                </p>
              </div>
            </div>
          </div>

          <section className="before-footer">
            <div className="container">
              <span>Start sharing powerful links</span>
              <br />
              <button className="btn btn-cw">
                Start sharing powerful links
              </button>
            </div>
          </section>
        </div>
            </React.Fragment>
        );
    }
}

export default TermCondition;