import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";

class NavbarDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // item: 'FLOINKS',
    }
    // this.onHandleClick = this.onHandleClick.bind(this);
  }
  componentDidMount = () => { }

  onClickItem = () => {
    window.location.href = '/web/dashboard?all=true';
  }

  render() {
    const { userID, subscriptionDetails } = this.props;
    return (
      <React.Fragment>
        <Link className="logo" onClick={() => window.location.href = '/web/dashboard'}>
          {
            this.props.siteSettings ?
              <img src={this.props.siteSettings.siteLogo}
                width="100"
              /> : ''
          }
        </Link>

        <div className="links">
          <ul>
            <li className="nav-item dropdown">
              <a
                style={{ marginLeft: " 15px" }}
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
              >
                <Link className="dropdown-item" id={"DASHBOARD"}
                  data-id="user_dashboard" to={subscriptionDetails.dashboardView ? "/dashboard/stats/" : "/organization/subscriptions/" + userID}>
                  DASHBOARD
                  {
                    subscriptionDetails.dashboardView ?
                      <span className="fas fa-chart-line float-right"></span> :
                      <span className="badge badge-secondary float-right">Upgrade</span>
                  }
                </Link>
                <Link className="dropdown-item" id={"FLOINKS"}
                  onClick={() => window.location.href = '/web/dashboard'}
                >
                  FLOINKS{" "}
                  <span className="fas fa-link float-right"></span>
                </Link>
                <Link className="dropdown-item" id={"CAMPAIGNS"}
                  data-id="create_campaign" to={subscriptionDetails.campaigns ? "/dashboard/campaigns/" : "/organization/subscriptions/" + userID}>
                  CAMPAIGNS
                  {
                    subscriptionDetails.campaigns ?
                      <span className="fas fa-binoculars float-right"></span> :
                      <span className="badge badge-secondary float-right">Upgrade</span>
                  }
                </Link>
                <Link className="dropdown-item" id={"CUSTOM_DOMAINS"}
                  data-id="custom_domains" to={subscriptionDetails.customDomains && parseInt(subscriptionDetails.customDomains) > 0 ? "/organization/domain" : "/organization/subscriptions/" + userID}>
                  CUSTOM DOMAINS{" "}
                  {
                    subscriptionDetails.customDomains && parseInt(subscriptionDetails.customDomains) > 0 ?
                      <span className="fas fa-globe float-right"></span> :
                      <span className="badge badge-secondary float-right">Upgrade</span>
                  }
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default NavbarDropdown;