import React, { Component } from 'react';

class ErrorPage extends Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render() {
        return (
            <React.Fragment>
                <div id="notfound">
                    <div className="notfound">
                        <div className="notfound-404">
                            <h1>Oops!</h1>
                        </div>
                        <h2>404 - Page not found</h2>
                        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <a href="http://FLO.ink/">Go To Homepage</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ErrorPage;