import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addQuote, beforeQuote } from './action/contact-page.action';
import countriesCities from "countries-cities";
import { scrollTop } from '../../shared/common-functions';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from 'jquery'

require('bootstrap-select');

class GetQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            countries: [],
            // payload
            name: "",
            organization: "",
            location: "",
            website: "",
            strength: "",
            department: "",
            designation: "",
            email: "",
            message: "",
        }
    }

    componentDidMount() {
        scrollTop();
        let Contry = countriesCities.getCountries();
        this.setState({
            countries: Contry,
        },
            (() => {
                setTimeout(() => {
                    $('.my-select').selectpicker();
                }, 1000);
            })
        )
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactUs.getQuoteAuth) {
            this.reset();
            let result = nextProps.contactUs.getQuoteRes;
            swal('Success', result.message, 'success');
            this.props.beforeQuote();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: "" });
    }

    reset = () => {
        this.setState({
            name: "",
            organization: "",
            location: "",
            website: "",
            strength: "",
            department: "",
            designation: "",
            email: "",
            message: "",
        }, () => {
            $('#quote-location').siblings('button').children().find('div.filter-option-inner-inner').html('Select Country');
        });
    }

    submit = (e) => {
        e.preventDefault();

        let { name, organization, location, strength, website, department, designation, email, message } = this.state;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (name === "") {
            this.setState({ errors: "Name is required" });
            return
        }
        else if (organization === "") {
            this.setState({ errors: "Company name is required" });
            return;
        } else if (location === "") {
            this.setState({ errors: "Select your country" });
            return;
        }
        else if (strength === "") {
            this.setState({ errors: "Strength is Required" });
            return;
        }
        else if (department === "") {
            this.setState({ errors: "Department is Required" });
            return;
        }
        // else if (designation === "") {
        //     this.setState({ errors: "Designation is Required" });
        //     return;
        // }
        else if (email === "") {
            this.setState({ errors: "Email is Required" });
            return;
        }
        else if (!email.match(regexp)) {
            this.setState({ errors: "Email is Invalid" });
            return;
        }
        // else if (message === "") {
        //     this.setState({ errors: "Message is Required" });
        //     return;
        // }
        else {
            this.setState({ errors: '' }, () => {
                let body = { name, organization, location, strength, department, website, designation, email, message };

                this.props.addQuote(body);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="staticPageHeader text-white">
                    <div className="container">
                        <h1>Get A Quote</h1>
                    </div>
                </div>
                <div className="solutionTextBlock">
                    <div className="container">
                        <div className="row justify-content-center">
                            {/* <div className="col-lg-6">
                                <div class="map" style={{ 'height': '100%' }}>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.2564770379877!2d74.27327261450283!3d31.462130181388947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919017cdc86aba3%3A0x1ab82d275b76913!2sArhamSoft%20(Pvt.)%20Ltd.!5e0!3m2!1sen!2s!4v1568875375516!5m2!1sen!2s" style={{ 'width': '100%', 'height': '100%', 'border': 'none' }} allowfullscreen=""></iframe>
                                </div>
                            </div> */}
                            <div className="media-container-column col-12" data-form-type="formoid">
                                <form className="mbr-form" autoComplete="off">
                                    <input type="hidden" data-form-email="true" value="zdGVBcNRThcdWUUfI+3PsLnnLtHc8JIdEhub3sFJZu3mJPkE6oxv5FmU79KVvaLjKSmEfsOBbKYj1Cps8hm2o1hmKtkhiOdHe4oDrPZiHdR0Yvomv7W9ZOtj6J4piMeE" />
                                    <div className="row row-sm-offset">
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="name">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">Name</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.name} className="form-control" name="name" data-form-field="Name" placeholder="John Doe" required id="name-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="organization">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="organization-form1-2w">Company Name</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.organization} className="form-control" name="organization" data-form-field="Organization" placeholder="Company Name" required id="organization-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal input-field--MAIN mb-0" data-for="location">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="location-form1-2w">Location </label>
                                                {/* <label className="input-field--label d-block">Country</label> */}
                                                <select id="quote-location" className="my-select" style={{ width: "100%" }} name="location" value={this.state.location} onChange={(e) => this.onChange(e)}>
                                                    <option value="">Select Country</option>
                                                    {
                                                        this.state.countries.length > 0 ?
                                                            this.state.countries.map((val, key) => {
                                                                return (
                                                                    <option key={key} value={val}>{val}</option>
                                                                )
                                                            }) : <option value="">No Record Found</option>
                                                    }

                                                </select>
                                                {/* <input onChange={(e) => this.onChange(e)} type="text" value={this.state.location} className="form-control" name="location" data-form-field="Location" placeholder="e.g. John" required id="location-form1-2w" autoComplete="off" /> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="website">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7" for="website-form1-2w">Company Website</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.website} className="form-control" name="website" data-form-field="Website" placeholder="e.g. www.xyz.com" required id="website-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="strength">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">Company's Strength</label>
                                                <input onChange={(e) => this.onChange(e)} type="number" value={this.state.strength} className="form-control" name="strength" data-form-field="Strength" placeholder="e.g. 10" required id="strength-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="department">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="department-form1-2w">Your Department</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.department} className="form-control" name="department" data-form-field="Department" placeholder="Department" required id="department-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="designation">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7" for="designation-form1-2w">Your Designation</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.designation} className="form-control" name="designation" data-form-field="Designation" placeholder="e.g. Manager" required id="designation-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 multi-horizontal" data-for="email">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="email-form1-2w">Email</label>
                                                <input onChange={(e) => this.onChange(e)} type="email" value={this.state.email} className="form-control" name="email" data-form-field="Email" placeholder="xyz@email.com" required id="email-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group" data-for="message">
                                        <label className="form-control-label mbr-fonts-style display-7" for="message-form1-2w">Message</label>
                                        <textarea onChange={(e) => this.onChange(e)} type="text" value={this.state.message} className="form-control" name="message" rows="7" placeholder="e.g. I need help in understanding the features" data-form-field="Message" id="message-form1-2w" autoComplete="off"></textarea>
                                    </div>

                                    {
                                        this.state.errors ?
                                            <div className="alert alert-danger">{this.state.errors}</div>
                                            : ''
                                    }
                                    <span className="input-group-btn float-right">
                                        <button type="button" className="btn btn-blue btn-form" onClick={this.submit}>Submit</button>
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    addQuote, beforeQuote
};

const mapStateToProps = state => ({
    contactUs: state.contactUs,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(GetQuote);