import { combineReducers } from 'redux';
import contactUsReducer from '../components/contact-page/reducer/contact-page.reducer';
import invoiceReducer from '../components/manage-accounts/invoice/reducer/invoice.reducer';
import settingsReducer from '../shared/site-settings/site-settings.reducer';
import customDomainReducer from '../components/navbar-components/custom-domain/reducer/custom-domain.reducer';
import errorReducer from '../components/error/error.reducer';

export default combineReducers({
    contactUs: contactUsReducer,
    invoice: invoiceReducer,
    errors: errorReducer,
    settings: settingsReducer,
    customDomain: customDomainReducer
});
