import React, { Component } from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
class Settings extends Component {
  state = {};
  render() {
    const { userID, subscriptionDetails } = this.props;
    // console.log('subscriptionDetails........', subscriptionDetails)
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="11"
          style={{ display: "none" }}
          id="settings"
        >
          <div
            className="action-sheet--ALTERNATE"
            data-selected-sheet="settings"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <a>
                {" "}
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>{" "}
              </a>
              <h4 className="action-sheet--header-text">Settings</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              <div className="action-sheet--list-content-wrapper">
                <div className="checklist--wrapper ">
                  <div className="checklist--items text-right mb-2">
                    {subscriptionDetails.socialPosting ?
                      <div
                        className="checklist-row--MAIN neutral checklist-menu-item"
                        data-id="social_connect"
                      >
                        <div className="checklist-row--text">
                          {" "}
                      Social Networks
                    </div>
                        <span className="checklist-row--icon">
                          <span className="selector-icon" alt="selector-icon">
                            <FontAwesomeIcon icon="chevron-right" />
                          </span>
                        </span>
                      </div>
                      :
                      <Link className="btn btnUpgrade" id={"DASHBOARD"} data-id="user_dashboard" to={"/organization/subscriptions/" + userID}>Upgrade</Link>
                    }

                    {/* <div
                      className="checklist-row--MAIN neutral checklist-menu-item"
                      data-id="domains"
                    >
                      <div className="checklist-row--text"> Domain Default</div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon" />
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </div> */}
                    {/* <div
                      className="checklist-row--MAIN neutral checklist-menu-item"
                      data-id="advanced"
                    >
                      <div className="checklist-row--text">
                        {" "}
                        Advanced Settings
                      </div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon">
                          <FontAwesomeIcon icon="chevron-right" />
                        </span>
                      </span>
                    </div> */}
                  </div>
                  <div
                      className="checklist-row--MAIN neutral checklist-menu-item"
                      data-id="bulk-import"
                    >
                      <div className="checklist-row--text">Bulk Import</div>
                      <span className="checklist-row--icon">
                        <span className="selector-icon" alt="selector-icon" />
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
