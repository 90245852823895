import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SetDefaultApi extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { userName, accountType } = this.props;
        return (
            <React.Fragment>
                <form action="#" className="button-visible">
                    <div className="action-sheet--content-wrapper">
                        <div className="action-sheet--organization">
                            <div className="action-sheet--content-header">
                                <span className="personal-icon" alt="personal-icon">
                                    <FontAwesomeIcon icon="user" />
                                </span>
                                <span className="action-sheet--org-name">Personal</span>
                            </div>
                            <div className="checklist--wrapper">
                                <div className="checklist--wrapper ">
                                    <div className="checklist--items">
                                        <div className="checklist-row--MAIN neutral">
                                            <div className="checklist-row--text">
                                                {" "}
                                                <span className="account-type-name text-capital">{accountType} Account</span>
                                                <span className="checklist-row--secondary-label account-type-value">
                                                    {userName}
                                                </span>
                                            </div>
                                            <span className="checklist-row--icon">
                                                <div
                                                    className="radio-check--LARGE"
                                                    id="Free-Account"
                                                >
                                                    <input
                                                        id="Free Account"
                                                        type="radio"
                                                        name="Bj7f8bfydVC"
                                                        className="checkbox--input"
                                                        defaultValue="Free Account"
                                                        defaultChecked=""
                                                    />
                                                    <label
                                                        style={{ cursor: "pointer" }}
                                                        htmlFor="Free Account"
                                                        className="checkmark-icon"
                                                    >
                                                        <FontAwesomeIcon icon="check" />
                                                    </label>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

export default SetDefaultApi;