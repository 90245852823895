import React, { Component } from "react";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterResults from "react-filter-search";
import $ from "jquery";

class ManageChannels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      channels: [],
      selectedChannel: "",
      manageBitlinkLoader: true,
    };
  }

  componentWillMount = () => {
    // let hash = window.location.hash;
    // if (hash !== "" && hash.split("&").length > 0) {
    //   let splited = hash.split("&");
    //   let last = splited[splited.length - 1].split("=")[1];
    //   if (last === "manage_bitlink_channels") {
    //     this.getChannelsFunction();
    //   }
    // }

    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "manage_bitlink_channels") {
        splited.pop();
        let newHash = splited.join("&").replace("#", "");
        window.location.hash = newHash;
        $("#manage_bitlink_channels").hide();
      }
    }
  };

  getChannelsFunction() {
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "channel/user-channels?userId=" + userId)
      .then(response => response.json())
      .then(result => {
        this.setState({ manageBitlinkLoader: false, channels: result.channels });
      });
  }

  handleChangeChannels = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  handleLinksChannelForm = e => {
    e.preventDefault();
    if (this.state.selectedChannel === "") {
      Swal.fire({ title: 'Error', text: "Please select a channel", customClass: "dangered" })
    } else {
      let currentLocation = window.location.hash;
      let newHash = currentLocation + "&action=manage_bitlink_campaign";
      $("#manage_bitlink_campaign").show();
      $("#manage_bitlink_channels").hide();
      window.location.hash = newHash;
      this.props.linkChannelIdToCampaign(this.state.selectedChannel);
    }
  };

  radioChangeHandler = (id, event) => {
    this.setState({ selectedChannel: id });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="20"
          style={{ display: "none " }}
          id="manage_bitlink_channels"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="channelsList"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <a>
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>
              </a>
              <h4 className="action-sheet--header-text">Select Channels</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              <form onSubmit={this.handleLinksChannelForm}>
                <div className="dynamic-checklist--MAIN">
                  <div className="search--ALTERNATE">
                    <input
                      type="search"
                      maxLength="100"
                      className="search--input-field"
                      placeholder="Filter channels"
                      value={this.state.value}
                      onChange={this.handleChangeChannels}
                    />
                    <span>
                      A Channel can't be changed or deleted once selected.
                    </span>
                    <br />
                    <a
                      className="checklist-menu-item cutsom-a"
                      data-id="manage_channels"
                      onClick={this.props.manageChannelsFun}
                    >
                      {" "}
                      Manage Channels{" "}
                    </a>
                    <FilterResults
                      value={this.state.value}
                      data={this.state.channels}
                      renderResults={results => (
                        <div>
                          {results.length > 0 && this.state.manageBitlinkLoader !== true ? (
                            results.map((el, index) => {
                              return (
                                <div className="radio this" key={index}>
                                  <label className="cur-poi">
                                    <input
                                      type="radio"
                                      value={el._id}
                                      checked={
                                        this.state.selectedChannel === el._id
                                      }
                                      onChange={event =>
                                        this.radioChangeHandler(el._id, event)
                                      }
                                    />
                                    {el.title}
                                  </label>
                                </div>
                              );
                            })
                          ) :
                            this.state.manageBitlinkLoader == true ?
                              <div className="text-center">
                                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                              </div>
                              :
                              (
                                <div>
                                  <span>No channel found</span>
                                </div>
                              )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="submit"
                    tabIndex="0"
                    id=""
                  >
                    Configure Campaign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageChannels;
