import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagesData: "",
            resourceLoader: true,
        }
    }

    componentWillMount = () => {
        let hash = window.location.hash;
        if (hash !== "" && hash.split("&").length > 0) {
            let splited = hash.split("&");
            let last = splited[splited.length - 1].split("=")[1];
            if (last === "resources") {
                this.getData();
            }
        }
    };

    getData = () => {
        fetch(window.APIURL + "cms-pages/list", {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                return res.json();
            })
            .then(result => {
                if (result.status === true) {
                    this.setState({
                        pagesData: result.result,
                        resourceLoader: false
                    })
                } else {
                    swal('Error', 'Contact Admin', 'error')
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <div
                    className="action-sheet--wrapper-MAIN data-state"
                    data-state="16"
                    style={{ display: "none " }}
                    id="resources"
                >
                    <div
                        className="action-sheet--ALTERNATE"
                        data-selected-sheet="resources"
                        style={{
                            animation:
                                "600ms ease 0ms 1 normal forwards running open-sheet "
                        }}
                    >
                        <div className="action-sheet--header">
                            <a>
                                {" "}
                                <span className="back_btn-icon" alt="back_btn-icon">
                                    <FontAwesomeIcon icon="chevron-left" />
                                </span>{" "}
                            </a>
                            <h4 className="action-sheet--header-text">Resources</h4>
                            <span>
                                <a>
                                    <span className="close-icon" alt="close-icon" />
                                </a>
                            </span>
                        </div>
                        <div className="action-sheet--content-wrapper">
                            <div className="action-sheet--list-content-wrapper">
                                {this.state.pagesData && this.state.pagesData.length > 0 && this.state.resourceLoader !== true ?
                                    this.state.pagesData.map((data, index) => (
                                        <Link to={"/resources/" + data.slug}>
                                            <div key={index} className="select-item--ALTERNATE stl">
                                                <p className="select-item--label">{data.title}</p>
                                                <span className="select-item--secondary-label" />
                                                <span className="select-item--third-label " />
                                                <span className="select-item--fourth-label" />
                                                <span
                                                    className="external-link-icon"
                                                    alt="external-link-icon"
                                                >
                                                    <FontAwesomeIcon icon="external-link-alt" />
                                                </span>
                                            </div>
                                        </Link>
                                    )
                                    )
                                    :
                                    this.state.resourceLoader == true ?
                                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        :
                                        'No resource pages found.'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Resources;