import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { throws } from "assert";
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import { createBrowserHistory } from "history";
import $ from "jquery";
import { ENV } from '../../config/config';
import Notifications, { notify } from 'react-notify-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from 'react-spinners/ClipLoader';
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/core';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const CryptoJS = require("crypto-js");
const queryString = require("query-string");
var _ = require("lodash");

const override = css`
display: block;
position:absolute;
left:50%;
top:50%;
margin:-60px 0 0 -60px;
border-color: blue;
`;

class CampaignListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // elems for date range picker call
      campChannels: [],
      itemEvent: null,

      linkHistory: '',
      startDate: moment(),
      endDate: moment(),
      query: '',
      search: false,
      userId: "",
      redirect: false,
      data: [],
      numberOfChannels: 0,
      numberOfLinks: "",
      channelData: [],
      channelName: "",
      campaignName: "",
      channelClicks: "",
      showSingle: false,
      campaignNoLinks: 0,
      campaignId: "",
      activeChannelId: "",
      activeCampaign: "",
      activeChannel: "",
      showPieChart: false,
      theLoader: false,
      chartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: 'Clicks per channel - Last 7 Days'
        },
        subtitle: {
          text: document.ontouchstart === undefined ?
            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'No. of clicks'
          },
          min: 0,
          tickInterval: 2
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },

        series: [{
          type: 'area',
          cursor: 'pointer',
          name: 'No. of Clicks',
          data: []
        }],
      },
      hoverData: null,
      pirChartOptions: {
        chart: {
          type: "pie",
          size: 300
        },
        title: {
          text: 'Clicks per channel'
        },
        series: [
          {
            name: 'Clicks',
            cursor: 'pointer',
            colorByPoint: true,
            data: []
          }
        ]
      },
      locationChartOptions: {
        chart: {
          type: "pie",
          size: 300
        },
        title: {
          text: 'Locations'
        },
        series: [
          {
            name: 'Clicks',
            cursor: 'pointer',
            colorByPoint: true,
            data: []
          }
        ]
      },
      referrerChartOptions: {
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        chart: {
          type: "pie",
          size: 300
        },
        title: {
          text: 'Referrers'
        },
        series: [
          {
            name: 'Clicks',
            cursor: 'pointer',
            colorByPoint: true,
            data: []
          }
        ]
      },
      showLocations: false,
      showReferrers: false,
      start: true,
      hasMore: false,
      clipLoader: false,
      total: 0,
      page: 1,
      offset: 0,
      limit: 0,
      pages: 0,
      linkLimit: 0,
      linkPages: 0,
      linkTotal: 0,
      linkPage: 1,
      linkOffset: 0,
      linkStart: true,
      linkHasMore: false,
      linkClipLoader: false,
      campaignDataExport: false,
      showPaddingWhileLoader: false,

      // advanced analytics 
      topLocationOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Top Locations'
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'No. of clicks'
          },
          min: 0,
          tickInterval: 2
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.y}'
            }
          }
        },

        tooltip: {
          headerFormat: '<span style="font-size:14px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> clicks<br/>'
        },

        series: [
          {
            name: "Location",
            cursor: 'pointer',
            colorByPoint: true,
            data: []
          }
        ]
      },
      showTopLocations: false, // show top locations chart
      topDevices: [],
      topSocialPlatforms: []
    };
  }

  componentWillMount = () => {
    let subscriptionPlanDetails = localStorage.getItem("spd");
    if (!subscriptionPlanDetails) {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("LoginSession");
      localStorage.removeItem("loginTime");
      window.location.href = "/web/userLogin";
      return;
    }
    let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
    decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
    if (!decryptedSubscriptionPlanDetails.campaigns) {
      this.props.history.push("/organization/detail");
      return;
    }

    let linkHistory = parseInt(decryptedSubscriptionPlanDetails.linkHistory) - 1;

    let startDate = moment().subtract(linkHistory, 'days');
    let endDate = moment();

    this.setState({
      linkHistory,
      startDate, endDate,
      campaignDataExport: decryptedSubscriptionPlanDetails.campaignDataExport,
      socialPosting: decryptedSubscriptionPlanDetails.socialPosting,
      qrCodeGenerate: decryptedSubscriptionPlanDetails.qrCodeGenerate,
      campaignsTracking: decryptedSubscriptionPlanDetails.campaignsTracking
    });
    if (localStorage.getItem("loginToken")) {
      var loginTime = parseInt(localStorage.getItem("loginTime")) / 1000;
      var timeNow = new Date().getTime() / 1000;
      var timeDifference = timeNow - loginTime;

      var timeDifferenceInMinutes = timeDifference / 60;
      if (timeDifferenceInMinutes < window.loginExpiresAfter) {
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        this.setState({
          redirect: false,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        }, () => {
          this.checkUserSession(this.state.userId)
        });
      } else {
        this.setState({ redirect: true });
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  componentDidMount = () => {
    this.getData();
  };

  checkUserSession = (id) => {
    fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
        } else if (result.status === false) {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', result.message, 'error')
        } else {
          localStorage.removeItem("loginToken");
          localStorage.removeItem("LoginSession");
          localStorage.removeItem("loginTime");
          this.setState({ redirect: true });
          // swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  getData = (value, updateData = undefined, fromInfinite = 0) => {
    if (fromInfinite) {
      this.setState({ showPaddingWhileLoader: true });
    }
    if (updateData) {
      let campaignsCurrent = this.state.data;
      let updateIndex = campaignsCurrent.findIndex((obj => obj._id.toString() === updateData._id.toString()));
      if (updateIndex > -1) {
        campaignsCurrent[updateIndex].title = updateData.title;
        campaignsCurrent[updateIndex].channels = updateData.channels;
        this.setState({ data: campaignsCurrent, campaignName: updateData.title, numberOfChannels: updateData.channels.length, activeCampaign: updateData.title });
      }
      return;
    }
    if (this.state.start || (value && value !== undefined)) {
      if (!fromInfinite) {
        this.props.changeLoaderStatus(true);
      }
    }
    this.setState({
      // offset: this.state.start ? this.state.offset : (value && value !== undefined) ? 0 : this.state.offset + 10,
      page: this.state.start ? this.state.page : (value && value !== undefined) ? 1 : this.state.page + 1,
      start: (value && value !== undefined) ? true : false,
      hasMore: true,
      clipLoader: true,
    }, () => {
      fetch(
        window.APIURL + "campaign/dashboard-data?userId=" + this.state.userId + "&size=" + this.state.limit + "&pageNo=" + this.state.page,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(res => {
          return res.json();
        })
        .then(result => {
          this.setState({ showPaddingWhileLoader: false })
          if (result.status === true) {
            if (result.campaignsData && result.campaignsData.length < 10) {
              this.setState({ hasMore: false })
            }
            let allCampaignsArray = this.state.data;
            let final;
            if (this.state.start) {
              final = result.campaignsData
            }
            else {
              final = allCampaignsArray.concat(result.campaignsData);
            }
            this.setState({ data: final, start: false, clipLoader: false });
            if (final && final.length > 0) {
              var parsed = queryString.parse(window.location.search);
              if (parsed.campaign !== undefined) {
                let validationIndex = final.findIndex((obj => obj._id === parsed.campaign))
                if (validationIndex >= 0) {
                  if (final[validationIndex].channels && final[validationIndex].channels.length > 0) {
                    this.setState({ numberOfChannels: final[validationIndex].channels.length });
                    this.getFirstData(final[validationIndex].channels, final[validationIndex]._id, final[validationIndex].title);
                  }
                  this.setState({ activeCampaign: final[validationIndex].title })
                  let history = createBrowserHistory();
                  history.push({
                    pathname: "/web/dashboard/campaigns",
                    search: "?campaign=" + final[validationIndex]._id
                  });
                } else {
                  if (final[0].channels && final[0].channels.length > 0) {
                    this.setState({ numberOfChannels: final[0].channels.length });
                    this.getFirstData(final[0].channels, final[0]._id, final[0].title);
                    let history = createBrowserHistory();
                    history.push({
                      pathname: "/web/dashboard/campaigns",
                      search: "?campaign=" + final[0]._id
                    });
                    this.setState({ activeCampaign: final[0].title })
                  }
                }
              } else {
                if (final[0].channels && final[0].channels.length > 0) {
                  this.setState({ numberOfChannels: final[0].channels.length });
                  this.getFirstData(final[0].channels, final[0]._id, final[0].title);

                  let history = createBrowserHistory();
                  history.push({
                    pathname: "/web/dashboard/campaigns",
                    search: "?campaign=" + final[0]._id
                  });
                  this.setState({ activeCampaign: final[0].title })
                }
              }
            }
            this.props.changeLoaderStatus(false);
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    });
  }

  getFirstData = (channels, campaign, campaignName) => {
    this.props.changeLoaderStatus(true);

    this.setState({ showSingle: false, campaignName: campaignName, campaignId: campaign, campChannels: channels });

    let { startDate, endDate } = this.state;
    fetch(window.APIURL + "q-link/user-channel-links", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        channels: channels,
        campaignId: campaign,
        startDate, endDate
      })
    })
      .then(res => {
        return res.json();
      })
      .then(result => {
        if (result.status === true) {
          this.setState({ numberOfLinks: result.data.length });
          var groupedData = _.mapValues(_.groupBy(result.data, 'channelId.title'));
          // this.setState({ numberOfChannels: Object.keys(groupedData).length })
          let total = 0
          Object.keys(groupedData).forEach(element => {
            total = total + groupedData[element].length;
          });
          this.setState({ campaignNoLinks: total }); // displaying total number of links.
          let channel_click = [];
          Object.keys(groupedData).forEach((element, index) => {
            let temp = 0;
            groupedData[element].forEach((key, index) => {
              key.linkStats.forEach((key2, index) => {
                temp = temp + key2.count;
              })
            })
            channel_click.push(temp);
          });
          let keys = Object.keys(groupedData);
          let orignal_clicks = channel_click; //.reverse();

          // let chartData = [];
          // keys.forEach((element, index) => {
          //   let key = element;
          //   let value = orignal_clicks[index];
          //   let temp = { name: [key], y: value };
          //   if (value > 0) {
          //     this.setState({
          //       showPieChart: true
          //     })
          //   }
          //   chartData.push(temp);
          // });

          var pieChartOptions = { ...this.state.pirChartOptions };
          pieChartOptions.series[0].data = result.clicksPerChannel;

          var topLocationOptions = { ...this.state.topLocationOptions };
          topLocationOptions.series[0].data = result.topLocations;

          this.setState({
            topLocationOptions,
            showTopLocations: result.topLocations && result.topLocations.length ? true : false,
            topLinks: result.topLinks,
            topDevices: result.topDevices,
            topSocialPlatforms: result.topSocialPlatforms,

            pirChartOptions: pieChartOptions, showPieChart: result.clicksPerChannel && result.clicksPerChannel.length ? true : false
          });
          this.props.changeLoaderStatus(false);
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  channelClickHandler = (channelId, campaignId, channelName, campaignName, value, isDateSet = false) => {
    var { startDate, endDate } = this.state;

    if (!isDateSet) {
      startDate = moment().subtract(this.state.linkHistory, 'days');
      endDate = moment();
    }
    this.setState({ activeCampaign: campaignName, campaignName, activeChannel: channelId + '$$' + campaignName, campaignId: campaignId, activeChannelId: channelId });
    this.setState({
      showSingle: value && value !== undefined ? false : true,
      theLoader: value && value !== undefined ? true : false,
    });
    this.setState({ channelName: channelName });
    this.setState({
      startDate, endDate,
      linkPage: this.state.linkStart ? this.state.linkPage : (value && value !== undefined) ? 1 : this.state.linkPage + 1,
      linkStart: (value && value !== undefined) ? true : false,
      linkHasMore: true,
      linkClipLoader: true,
    }, () => {
      fetch(window.APIURL + "q-link/channel-links", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          channelId: channelId,
          campaignId: campaignId,
          userId: this.state.userId,
          size: this.state.linkLimit,
          pageNo: this.state.linkPage,
          startDate, endDate
        })
      })
        .then(res => {
          return res.json();
        })
        .then(result => {
          this.setState({ theLoader: false })
          if (result.status === true) {
            if (result.data && result.data.length < 10) {
              this.setState({ linkHasMore: false })
            }
            // COMMENTED TEMP ('cause we're fetching it from backend now)
            // this.updateChart(result.data);
            var chartOptions = { ...this.state.chartOptions };
            chartOptions.xAxis.categories = result.weekClicks ? result.weekClicks.datesForChart : [];
            chartOptions.series[0].data = result.weekClicks ? result.weekClicks.dataForChart : [];

            var locationChartOptions = { ...this.state.locationChartOptions };
            locationChartOptions.series[0].data = result.locations;

            var referrerChartOptions = { ...this.state.referrerChartOptions };
            referrerChartOptions.series[0].data = result.referrers;

            // let data = result.data;
            let allchannelsArray = this.state.channelData;
            let final;
            if (this.state.linkStart) {
              final = result.data
            }
            else {
              final = allchannelsArray.concat(result.data);
            }

            this.setState({
              showLocations: result.locations && result.locations.length > 0 ? true : false,
              showReferrers: result.referrers && result.referrers.length > 0 ? true : false,
              showSingle: true,
              channelData: final,
              // linkTotal: result.total,
              linkStart: false,
              linkClipLoader: false,
              channelClicks: _.sumBy(result.clicks, 'totalClicks'),
              chartOptions,
              locationChartOptions,
              referrerChartOptions
            });

            let history = createBrowserHistory();
            history.push({
              pathname: "/web/dashboard/campaigns",
              search: "?campaign=" + campaignId
            });
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    });
    // }
  }

  setHoverData = e => {
    this.setState({ hoverData: e.target.category });
  };

  updateChart = (param) => {
    let data = param;
    let clickPerDay = [];
    data.forEach(element => {
      element.linkstats.forEach(element1 => {
        let keyVar = moment(element1.createdAt).format("MMMM DD YYYY");
        let valueVar = element1.count;
        let temp = {};
        let objIndex = clickPerDay.findIndex((obj => obj[keyVar] == keyVar));
        if (objIndex > -1) {
          clickPerDay[objIndex].keyVar = clickPerDay[objIndex].keyVar + valueVar;
        } else {
          temp[keyVar] = valueVar;
        }
        clickPerDay.push(temp);
      })
    });
    let chartDays = 7;
    let dataForChart = [];
    let datesForChart = [];
    let totalClicks = 0;
    for (var i = chartDays; i >= 0; i--) {
      dataForChart[i] = 0;
      datesForChart.push(
        moment()
          .subtract(i, "days")
          .format("MMMM DD YYYY")
      );
    }
    datesForChart.forEach(function (element, index) {
      clickPerDay.forEach(element2 => {
        if (element2[element] != undefined) {
          dataForChart[index] = element2[element];
        }
      })
    });
    var chartOptions = { ...this.state.chartOptions };
    chartOptions.xAxis.categories = datesForChart;
    chartOptions.series[0].data = dataForChart;
    this.setState({ channelClicks: _.sum(dataForChart) });
  }

  editClick = (hash) => {
    window.location.hash = "#?action=edit_bitlink";

    this.props.editClick(hash);
  }

  generateQR = (shortUrl) => {
    window.open(`/web/qr-code?shortUrl=${shortUrl}`, '_blank');
  }

  // get advanced report
  getReport = (link, campaignId, channelId) => {
    window.open(`/web/reports?link=${link}&campaign=${campaignId}&channel=${channelId}`, '_blank');
  }

  gotoReport = (link) => {
    const { campaignId, activeChannel } = this.state;

    let channelId = activeChannel.split('$$')[0];
    window.open(`/web/reports?link=${link}&campaign=${campaignId}&channel=${channelId}`, '_blank');
  }

  getChannelsData = (e, channels, campaign, campaignName, isDateSet = false) => {
    let value = $(e.target).hasClass('prevent-campaign-name-span');
    if (!value && (this.state.campaignId !== campaign || this.state.activeChannel.includes(this.state.activeCampaign)) || isDateSet) {
      var { startDate, endDate } = this.state;

      if (!isDateSet) {
        startDate = moment().subtract(this.state.linkHistory, 'days');
        endDate = moment();
      }

      this.setState({
        startDate, endDate,
        activeCampaign: campaignName, activeChannel: '', numberOfChannels: channels.length, showPieChart: false,
        showSingle: false, campaignName: campaignName, campaignId: campaign, theLoader: true, campChannels: channels,
        itemEvent: e
      }, () => {
        let { startDate, endDate } = this.state;
        fetch(window.APIURL + "q-link/user-channel-links", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            channels: channels,
            campaignId: campaign,
            startDate, endDate
          })
        })
          .then(res => {
            return res.json();
          })
          .then(result => {
            this.setState({ theLoader: false })
            if (result.status === true) {
              this.setState({ numberOfLinks: result.data.length });
              var groupedData = _.mapValues(_.groupBy(result.data, 'channelId.title'));
              // this.setState({ numberOfChannels: Object.keys(groupedData).length })
              let total = 0
              Object.keys(groupedData).forEach(element => {
                total = total + groupedData[element].length;
              });
              this.setState({ campaignNoLinks: total }); // displaying total number of links.
              let channel_click = [];
              Object.keys(groupedData).forEach((element, index) => {
                let temp = 0;
                groupedData[element].forEach((key, index) => {
                  key.linkStats.forEach((key2, index) => {
                    temp = temp + key2.count;
                  })
                })
                channel_click.push(temp);
              });
              let keys = Object.keys(groupedData);
              let orignal_clicks = channel_click; //.reverse();

              // let chartData = [];
              // keys.forEach((element, index) => {
              //   let key = element;
              //   let value = orignal_clicks[index];
              //   let temp = { name: [key], y: value };
              //   if (value > 0) {
              //     this.setState({
              //       showPieChart: true
              //     })
              //   }
              //   chartData.push(temp);
              // });

              var pieChartOptions = { ...this.state.pirChartOptions };
              pieChartOptions.series[0].data = result.clicksPerChannel;

              var topLocationOptions = { ...this.state.topLocationOptions };
              topLocationOptions.series[0].data = result.topLocations;

              this.setState({
                topLocationOptions,
                showTopLocations: result.topLocations && result.topLocations.length ? true : false,
                topLinks: result.topLinks,
                topDevices: result.topDevices,
                topSocialPlatforms: result.topSocialPlatforms,

                pirChartOptions: pieChartOptions,
                showPieChart: result.clicksPerChannel && result.clicksPerChannel.length ? true : false
              });

              let history = createBrowserHistory();
              history.push({
                pathname: "/web/dashboard/campaigns",
                search: "?campaign=" + campaign
              });
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      });
    }
  }

  shareLink = (hash) => {
    this.props.shareLink(hash);
  }

  copyLink = (index, link) => {
    var fullLink = document.createElement("input");
    document.body.appendChild(fullLink);
    fullLink.value = `https://${link}/`;
    fullLink.select();
    document.execCommand("copy");
    let myColor = { background: '#cce5ff', text: "##004085" };
    notify.show('Copied to clipboard!', "custom", 3000, myColor);
    // $(".clipboard-" + index).show();
    // setTimeout(() => {
    //   $(".clipboard-" + index).hide();
    // }, 1800
    // )
    fullLink.remove();
  };

  editCampaign = () => {
    this.props.editCampaign(this.state.campaignId);
  }

  exportCampaign = () => {
    fetch(window.APIURL + "campaign/export-data?campaignId=" + this.state.campaignId, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(result => {
        this.setState({
          mainLoader: false
        })
        if (result.status) {
          window.open(result.filePath, '_blank');
        } else if (!result.status) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  }

  CreateFloinkCamp = () => {
    this.props.createFloinkCampaign(this.state.campaignId);
  }

  sendSearchQueryToCampaign = searchValue => {
    if (searchValue !== "") {
      this.setState(
        {
          query: searchValue,
          search: true
        },
        () => {
          this.getAllFilterCampaigns();
        }
      );
    } else {
      this.setState(
        {
          search: false
        },
        () => {
          this.clearSearch();
        }
      );
    }
  }

  clearSearch = () => {
    this.props.changeLoaderStatus(true);
    setTimeout(() => {
      this.props.clearSearchInput();
      this.setState(
        {
          search: false,
          query: "",
        },
        () => {
          this.getAllFilterCampaigns();
        }
      );
    }, 1000);
  };

  getAllFilterCampaigns = () => {
    let history = createBrowserHistory();
    fetch(window.APIURL + "campaign/search/user-campaigns", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: this.state.userId,
        query: this.state.query === "" ? undefined : this.state.query,
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        this.props.changeLoaderStatus(false);
        if (result.status === true) {
          this.setState({ data: result.campaigns });
          if (result.campaigns && result.campaigns.length > 0) {

            if (result.campaigns[0].channels && result.campaigns[0].channels.length > 0) {
              this.setState({ numberOfChannels: result.campaigns[0].channels.length });
              this.getFirstData(result.campaigns[0].channels, result.campaigns[0]._id, result.campaigns[0].title);

              let history = createBrowserHistory();
              history.push({
                pathname: "/web/dashboard/campaigns",
                search: "?campaign=" + result.campaigns[0]._id
              });
              this.setState({ activeCampaign: result.campaigns[0].title })
            }
          }
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  createCampaign = () => {
    this.props.createCampaign();
  }

  handleEvent = (e, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate
    }, () => {
      const { itemEvent, campaignName, campaignId, campChannels, activeChannel, activeChannelId, channelName } = this.state;

      if (activeChannel)
        this.channelClickHandler(activeChannelId, campaignId, channelName, campaignName, true, true);
      else if (itemEvent)
        this.getChannelsData(itemEvent, campChannels, campaignId, campaignName, true);
      else
        this.getFirstData(campChannels, campaignId, campaignName);
    });
  };

  render() {
    const { linkHistory, redirect, topLinks, showTopLocations, topLocationOptions, topDevices, topSocialPlatforms } = this.state;
    if (redirect) {
      return <Redirect to="/userLogin" />;
    }

    let totalDays = linkHistory ? linkHistory : 60;
    let minDate = moment().subtract(totalDays, "days");
    let maxDate = moment();

    let dateRange = {
      'Today': [moment(), moment()]
    };

    if (moment(minDate).isBefore(moment().subtract(1, 'days'), 'day'))
      dateRange['Yesterday'] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];

    if (moment(minDate).isBefore(moment().subtract(6, 'days'), 'day'))
      dateRange['Last 7 Days'] = [moment().subtract(6, 'days'), moment()];

    if (moment(minDate).isBefore(moment().subtract(29, 'days'), 'day'))
      dateRange['Last 30 Days'] = [moment().subtract(29, 'days'), moment()];

    if (moment(minDate).isBefore(moment().startOf('month'), 'month'))
      dateRange['This Month'] = [moment().startOf('month'), maxDate];

    if (moment(minDate).isBefore(moment().subtract(1, 'month'), 'month'))
      dateRange['Last Month'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

    return (
      <React.Fragment>
        <Notifications />
        <div className="main-content">
          {
            this.state.search ? (
              <div className="filters-bar campaignsFiltersBar">
                <div className="filter-list--FILTER">
                  <div className="filter-tobbar-holder">
                    <div className="filter-pill--ALTERNATE">
                      <div className="filter-pill--label">{this.state.query}</div>
                      <a
                        onClick={() => this.clearSearch()}
                        className="close-icon"
                        alt="close-icon"
                      >
                        <i className="fa fa-times" />
                      </a>
                    </div>
                    <span className="cur-poi filter-list--clear" onClick={this.clearSearch}>
                      Clear All
                    </span>
                  </div>
                </div>
              </div>
            )
              :
              (
                ""
              )
          }
          <section className="links-list campaign">
            <div className="row">
              <div className={`col-md-4 infinteScrollParent ${this.state.showPaddingWhileLoader ? '' : 'allDataLoaded'}`}>
                <InfiniteScroll
                  dataLength={this.state.data.length}
                  next={() => this.getData(false, undefined, 1)}
                  hasMore={this.state.hasMore}
                  scrollableTarget="v-pills-tab"
                  loader={
                    <ClipLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={'#123abc'}
                      loading={this.state.clipLoader}
                    />
                  }
                >
                  <div
                    className="nav flex-column nav-pills scroller"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <div className="all-info">
                      <span className="float-left">{this.state.data.length > 0 ? this.state.data.length + " Campaigns" : "0" + " Campaigns"}</span>
                      <span className="float-right">Sorted by Date </span>
                    </div>

                    {this.state.data && this.state.data.length > 0
                      ?
                      // <div className="dsbhrd-left-content-holder"> //By Uzair
                      //   {
                      this.state.data.map((data, index) => {
                        return (
                          <a key={index}
                            onClick={(e) => this.getChannelsData(e, data.channels, data._id, data.title)}
                            className={
                              data.title === this.state.activeCampaign ? "nav-link active" : "nav-link"
                            }
                            style={{ cursor: 'pointer' }}
                            id={data.title.replace(/\s+/g, "") + "-tab"}
                            data-toggle="pill"
                            href={"#" + data.title.replace(/\s+/g, "")}
                            role="tab"
                            aria-controls={data.title.replace(/\s+/g, "")}
                            aria-selected="true"
                          >
                            <span onClick={(e) => this.getChannelsData(e, data.channels, data._id, data.title)}
                              className={
                                data.title === this.state.activeCampaign ? `campaign-title active this${index}` : `campaign-title this${index}`
                              }
                            >
                              {data.title}
                            </span>
                            <span style={{ fontSize: "10px", float: "right" }}>
                              {moment(data.createdAt).format("DD-MMM-YY").toUpperCase()}
                            </span>
                            <div className="campaign-channels-list">

                              {data.channel && data.channel.length > 0
                                ?
                                data.channel.map((data1, index) => {
                                  return (
                                    <div key={index} className={data1._id + '$$' + data.title === this.state.activeChannel ? `item active disabled  ` : `item`}
                                    >
                                      <span onClick={data1._id + '$$' + data.title !== this.state.activeChannel ? () => this.channelClickHandler(data1._id, data._id, data1.title, data.title, true) : ""}
                                        className="name text-capitalize prevent-campaign-name-span">{data1.title}</span>
                                      <br />
                                    </div>
                                  );
                                })
                                :
                                "No Channel Found"}
                            </div>
                          </a>
                        );
                      })
                      //   }
                      // </div> //by uzair
                      : <div className="no-link-found text-center">No Campaign Found</div>
                    }
                  </div>
                </InfiniteScroll>
              </div>
              <div className="col-md-8">
                {/* date range picker - start */}
                <>
                  <div className="m-2">
                    <a style={{ display: 'block' }}>
                      <DateRangePicker
                        id="selectDate"
                        autoApply={false}
                        locale={{ format: 'MMMM D, YYYY' }}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onApply={this.handleEvent}
                        minDate={minDate}
                        maxDate={maxDate}
                        style={{ display: 'block' }}
                        ranges={dateRange}
                        opens={"right"}
                      >
                        <input
                          type="button"
                          name="daterange"
                          className="form-control"
                          value={
                            this.state.startDate.format("MMMM D, YYYY") +
                            " - " +
                            this.state.endDate.format("MMMM D, YYYY")
                          }
                          className="date-input"
                          style={{ display: 'block', width: '100%' }}
                        />
                      </DateRangePicker>
                    </a>
                  </div>
                </>
                {/* date range picker - end */}
                <div className="dsbhrd-right-content-holder scroller" id="channel-scroll">
                  {
                    this.state.showSingle === true &&
                      this.state.campaignsTracking ?
                      <React.Fragment>
                        <div className="campaign-details">
                          <div>
                            <span className="title text-capitalize">{this.state.channelName}</span>
                          </div>
                          <React.Fragment>
                            <div>
                              <span>Total Clicks: {" " + this.state.channelClicks} </span>
                            </div>
                            <div className="col-md-12">
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={this.state.chartOptions}
                              />
                            </div>
                            <div className="row m-0">
                              <div className="col-12">
                                {
                                  this.state.showLocations ?
                                    <PieChart highcharts={Highcharts} options={this.state.locationChartOptions} />
                                    : <div className="noChannelHistoryDataOuter text-center text-capitalize d-table w-100">
                                      <div className="noChannelHistoryDataInner d-table-cell align-middle">
                                        <p>no data for locations</p>
                                      </div>
                                    </div>
                                }
                              </div>
                              <div className="col-12">
                                {
                                  this.state.showReferrers ?
                                    <PieChart highcharts={Highcharts} options={this.state.referrerChartOptions} />
                                    : <div className="noChannelHistoryDataOuter text-center text-capitalize d-table w-100">
                                      <div className="noChannelHistoryDataInner d-table-cell align-middle">
                                        <p>No data for referrers</p>
                                      </div>
                                    </div>
                                }
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </React.Fragment>
                      : ''
                  }



                  {
                    this.state.showSingle === true && this.state.campaignsTracking &&
                      this.state.channelData.length > 0 ?
                      <div className="campaignChannelsBlock">
                        <InfiniteScroll
                          dataLength={this.state.channelData.length}
                          next={() => this.channelClickHandler(this.state.activeChannelId, this.state.campaignId, this.state.channelName, this.state.activeCampaign, false)}
                          hasMore={this.state.linkHasMore}
                          scrollableTarget="channel-scroll"
                          loader={
                            <ClipLoader
                              css={override}
                              sizeUnit={"px"}
                              size={50}
                              color={'#123abc'}
                              loading={this.state.linkClipLoader}
                            />
                          }
                        >

                          {/* </InfiniteScroll> */}
                          {
                            this.state.channelData.map((data, index) => (
                              <div key={index}>
                                <div>
                                  <time
                                    className="item-detail--created-date"
                                    dateTime="2019-07-30"
                                  >
                                    <span>CREATED </span>
                                    {moment(data.createdAt)
                                      .format("MMM DD hh:mm A")
                                      .toUpperCase()}
                                  </time>
                                  <span className="float-right total-clicks">Total Clicks: {" " + data.totalClicks} </span>
                                  <div className="item-detail--title text-truncate ml-0">
                                    {data.title === undefined || data.title === ""
                                      ? data.link
                                      : data.title}
                                  </div>
                                  <div>
                                    <a
                                      className="item-detail--url ml-0"
                                      href={data.link}
                                      target="_blank"
                                    >
                                      {data.link}
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  {data.channel.length > 0 ? (
                                    <div className="associated">
                                      <span className="title">Associated Channel: </span>
                                      <span> {data.channel[0].title} </span> <br />
                                      <span className="title">Associated Campaign: </span>
                                      <span> {data.campaignData[0].title} </span>
                                    </div>
                                  ) : (
                                      ""
                                    )}
                                </div>
                                <div className="bitlink--copy-wrapper ml-0">
                                  <div className="bitlink--copy-tooltip">
                                    <input
                                      tabIndex="-1"
                                      readOnly=""
                                      value={data.customizeBackHalf}
                                    />
                                    <span className="bitlink--copy-tooltip-text">
                                      Press Ctrl-c to copy
                                </span>
                                  </div>
                                  <div className="bitlink--copy-interface pl-0">
                                    {/* // TODO: getting name from db
                                    // href={"https://" + val.domain + "/" + val.customizeBackHalf} */}
                                    <div className="d-block">
                                      <a
                                        href={window.APIURL + data.customizeBackHalf}
                                        className="bitlink--copyable-text"
                                        target="_blank"
                                      >
                                        <span className={"clipboard clipboard-" + index}>Copied to cilpboard!</span>
                                        <span className="bitlink--MAIN" tabIndex="-1">
                                          {data.domain + "/"}
                                          <span className="bitlink--hash">
                                            {data.customizeBackHalf}
                                          </span>
                                        </span>
                                      </a>
                                    </div>
                                    <div className="item--action-buttons">
                                      <button className="button--ACTION" type="button" tabIndex="0"
                                        data-clipboard-text={data.domain + "/" + data.customizeBackHalf} id=""
                                        onClick={() =>
                                          this.copyLink(
                                            index, data.domain + "/" + data.customizeBackHalf
                                          )
                                        }
                                      >
                                        COPY
                                </button>
                                      {
                                        this.state.socialPosting ?
                                          <button
                                            className="button--ACTION" type="button" tabIndex="0" id=""
                                            onClick={() =>
                                              this.shareLink(data.customizeBackHalf)
                                            }>
                                            SHARE
                                </button>
                                          :
                                          ""
                                      }

                                      <button className="button--ACTION" type="button" tabIndex="0" id=""
                                        onClick={() =>
                                          this.editClick(data.customizeBackHalf)
                                        }>
                                        EDIT
                                </button>
                                      {
                                        this.state.qrCodeGenerate ?
                                          <button
                                            className="button--ACTION"
                                            type="button"
                                            tabIndex="0"
                                            id=""
                                            onClick={() =>
                                              this.generateQR(`${data.domain}/${data.customizeBackHalf}`)
                                            }
                                          >
                                            QR CODE
                                    </button>
                                          :
                                          ""
                                      }
                                      <button
                                        className="button--ACTION"
                                        type="button"
                                        tabIndex="0"
                                        onClick={() =>
                                          this.gotoReport(data._id)
                                        }
                                      >
                                        REPORT
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </InfiniteScroll>
                      </div>
                      :
                      this.state.showSingle === false ?
                        this.state.data && this.state.data.length > 0 ?
                          <div className="campaign-details">
                            <span className="title text-capitalize">{this.state.campaignName}</span> <br />
                            <span> {this.state.numberOfChannels === 0 ? 0 : this.state.numberOfChannels} Channels</span>
                            <span> {" | "}  </span>
                            <span> {this.state.campaignNoLinks === 0 ? 0 : this.state.campaignNoLinks} FLO.inks</span>
                            <div className="actions-button-small">
                              <button
                                className="button--ACTION btn-light"
                                type="button"
                                tabIndex="0"
                                id=""
                                onClick={() => this.CreateFloinkCamp()}
                              >
                                Add FLO.ink
                        </button>
                              <button className="button--ACTION btn-light"
                                onClick={() => this.editCampaign()}
                                type="button" tabIndex="0">
                                Edit
                        </button>
                              {
                                this.state.campaignDataExport ?
                                  <button className="button--ACTION btn-light"
                                    onClick={() => this.exportCampaign()}
                                    type="button"
                                    disabled={!this.state.campaignNoLinks ? true : false}
                                  >
                                    Export
                                  </button>
                                  :
                                  ''
                              }
                            </div>
                            <div>

                              {
                                this.state.showPieChart === true && this.state.theLoader !== true ?

                                  <PieChart highcharts={Highcharts} options={this.state.pirChartOptions} />
                                  :
                                  this.state.theLoader == true ?
                                    <div className="circleLoaderHolder" style={{ height: "80vh", width: "100%" }}>
                                      <CircleLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={120}
                                        color={'#007bff'}
                                        loading={this.state.mainLoader}
                                      />
                                    </div>
                                    :
                                    <h3 className="text-center mt-5">
                                      <span className="fas fa-exclamation" style={{ fontSize: "150px", color: "#bbbbbb" }}>
                                      </span>
                                      <br />
                                      <br />
                                    NO CLICKS<br />
                                      <span style={{ fontSize: "20px" }}>Start Sharing your FLO.ink</span>
                                    </h3>

                              }
                              {
                                this.state.theLoader == false ?
                                  <div className="floInkDetailBlock">
                                    <div className="info-wrapper--DARK">
                                      <div className="row mb-3">
                                        <div className="col-12">
                                          <div className="topThreeFloinkHolder h-100">
                                            <div className="info-wrapper--heading-holder">
                                              <h4 className="info-wrapper--header">Top Performing FLO.inks</h4>
                                            </div>
                                            {
                                              topLinks && topLinks.length ?
                                                topLinks.map((elem, index) => {
                                                  let data = elem._id;

                                                  return (
                                                    <div key={index}>
                                                      <div>
                                                        <time
                                                          className="item-detail--created-date"
                                                          dateTime="2019-07-30"
                                                        >
                                                          <span>CREATED </span>
                                                          {moment(data.createdAt)
                                                            .format("MMM DD hh:mm A")
                                                            .toUpperCase()}
                                                        </time>
                                                        <span className="float-right total-clicks">Total Clicks: {" " + elem.clicks} </span>
                                                        <div className="item-detail--title text-truncate ml-0">
                                                          {data.title ? data.link
                                                            : data.title}
                                                        </div>
                                                        <div>
                                                          <a
                                                            className="item-detail--url ml-0"
                                                            href={data.link}
                                                            target="_blank"
                                                          >
                                                            {data.link}
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div className="associated">
                                                          <span className="title">Associated Channel: </span>
                                                          <span> {data.channelName} </span>
                                                          {/* COMMENTED TEMP. */}
                                                          {/* <br />
                                                          <span className="title">Associated Campaign: </span>
                                                          <span> {data.campaignName} </span> */}
                                                        </div>
                                                      </div>
                                                      <div className="bitlink--copy-wrapper ml-0">
                                                        <div className="bitlink--copy-tooltip">
                                                          <input
                                                            tabIndex="-1"
                                                            readOnly=""
                                                            value={data.customizeBackHalf}
                                                          />
                                                          <span className="bitlink--copy-tooltip-text">
                                                            Press Ctrl-c to copy
                                                          </span>
                                                        </div>
                                                        <div className="bitlink--copy-interface pl-0">
                                                          {/* // TODO: getting name from db
                       // href={"https://" + val.domain + "/" + val.customizeBackHalf} */}
                                                          <div className="d-block">
                                                            <a
                                                              href={window.APIURL + data.customizeBackHalf}
                                                              className="bitlink--copyable-text"
                                                              target="_blank"
                                                            >
                                                              <span className={"clipboard clipboard-" + index}>Copied to cilpboard!</span>
                                                              <span className="bitlink--MAIN" tabIndex="-1">
                                                                {data.domain + "/"}
                                                                <span className="bitlink--hash">
                                                                  {data.customizeBackHalf}
                                                                </span>
                                                              </span>
                                                            </a>
                                                          </div>
                                                          <div className="item--action-buttons">
                                                            <button className="button--ACTION" type="button" tabIndex="0"
                                                              data-clipboard-text={data.domain + "/" + data.customizeBackHalf} id=""
                                                              onClick={() =>
                                                                this.copyLink(
                                                                  index, data.domain + "/" + data.customizeBackHalf
                                                                )
                                                              }
                                                            >
                                                              COPY
                   </button>
                                                            {
                                                              this.state.socialPosting ?
                                                                <button
                                                                  className="button--ACTION" type="button" tabIndex="0" id=""
                                                                  onClick={() =>
                                                                    this.shareLink(data.customizeBackHalf)
                                                                  }>
                                                                  SHARE
                   </button>
                                                                :
                                                                ""
                                                            }

                                                            <button className="button--ACTION" type="button" tabIndex="0" id=""
                                                              onClick={() =>
                                                                this.editClick(data.customizeBackHalf)
                                                              }>
                                                              EDIT
                   </button>
                                                            {
                                                              this.state.qrCodeGenerate ?
                                                                <button
                                                                  className="button--ACTION"
                                                                  type="button"
                                                                  tabIndex="0"
                                                                  id=""
                                                                  onClick={() =>
                                                                    this.generateQR(`${data.domain}/${data.customizeBackHalf}`)
                                                                  }
                                                                >
                                                                  QR CODE
                       </button>
                                                                :
                                                                ""
                                                            }
                                                            <button
                                                              className="button--ACTION"
                                                              type="button"
                                                              tabIndex="0"
                                                              onClick={() =>
                                                                this.getReport(data._id, data.campaignId, data.channelId)
                                                              }
                                                            >
                                                              REPORT
                           </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })
                                                :
                                                <div className="noDataHolder text-center">
                                                  <div className="noDataInner">
                                                    <span className="d-inline-block my-3">No Record Found</span>
                                                  </div>
                                                </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className="topThreeFloinkHolder mb-3">
                                        <div className="info-wrapper--heading-holder">
                                          <h4 className="info-wrapper--header">Top Locations</h4>
                                        </div>
                                        {
                                          showTopLocations ?
                                            <HighchartsReact highcharts={Highcharts} options={topLocationOptions} />
                                            :
                                            <div className="noDataHolder text-center">
                                              <div className="noDataInner">
                                                <span className="d-inline-block my-3">No Location Found</span>
                                              </div>
                                            </div>
                                        }
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-12">
                                          <div className="topThreeFloinkHolder mb-3 h-100">
                                            <div className="info-wrapper--heading-holder">
                                              <h4 className="info-wrapper--header">Top Devices</h4>
                                            </div>
                                            <div className="topDevicesHolder">
                                              {
                                                topDevices && topDevices.length ?
                                                  topDevices.map((device, key) => {
                                                    let deviceColor = device._id === 'desktop' ? 'turquoise' : device._id === 'mobile' || device._id === 'phone' ? 'amethyst' : device._id === 'tablet' ? 'nephritis' : 'turquoise';

                                                    let deviceImage = device._id === 'desktop' ? 'computer' : device._id === 'mobile' || device._id === 'phone' ? 'smartphone' : device._id === 'tablet' ? 'tablet' : 'computer';

                                                    return (
                                                      <div key={key} className={`deviceHolder text-white ${deviceColor}`}>
                                                        <div className="iconHolder text-center">
                                                          <img src={require(`../../images/${deviceImage}.svg`)} alt="Device Image" className="img-fluid" />
                                                        </div>
                                                        <div className="detailHolder">
                                                          <strong className="deviceName text-capitalize">{device._id}</strong>
                                                          <hr></hr>
                                                          <p>No. of Clicks: <strong>{device.clicks}</strong></p>
                                                        </div>
                                                      </div>
                                                    )
                                                  })
                                                  :
                                                  <div className="noDataHolder text-center">
                                                    <div className="noDataInner">
                                                      <span className="d-inline-block my-3">No Device Found</span>
                                                    </div>
                                                  </div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                          <div className="topThreeFloinkHolder mb-3 h-100">
                                            <div className="info-wrapper--heading-holder">
                                              <h4 className="info-wrapper--header">Top Performing Social Platforms</h4>
                                            </div>
                                            <div className="topDevicesHolder">
                                              {
                                                topSocialPlatforms && topSocialPlatforms.length ?
                                                  topSocialPlatforms.map((platform, key) => {
                                                    let platformColor = platform._id === 'Facebook' ? 'facebook' : platform._id === 'Twitter' ? 'twitter' : '';

                                                    let platformImage = platform._id === 'Facebook' ? 'facebook-f' : platform._id === 'Twitter' ? 'twitter' : '';

                                                    return (
                                                      <div key={key} className={`deviceHolder text-white ${platformColor}`}>
                                                        <div className="iconHolder text-center">
                                                          <i className={`fab fa-${platformImage}`}></i>
                                                        </div>
                                                        <div className="detailHolder">
                                                          <strong className="deviceName text-capitalize">{platform._id}</strong>
                                                          <hr></hr>
                                                          <p>No. of Clicks: <strong>{platform.clicks}</strong></p>
                                                        </div>
                                                      </div>
                                                    )
                                                  })
                                                  :
                                                  <div className="noDataHolder text-center">
                                                    <div className="noDataInner">
                                                      <span className="d-inline-block my-3">No social platform found</span>
                                                    </div>
                                                  </div>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  ""
                              }
                              {/* Advanced analytics - start */}

                              {/* Advanced analytics - end */}
                            </div>
                          </div>
                          :
                          <React.Fragment>
                            <div className="tab-content">
                              <div className="no-content-holder">
                                <div>
                                  <h3 className="text-center mt-5">
                                    <span className="fas fa-exclamation" style={{ fontSize: "150px", color: "#bbbbbb" }}>
                                    </span>
                                    <br />
                                    <br />
                                  No campaign record found
                            </h3>
                                  <hr></hr>
                                  <div className="text-center">
                                    <button
                                      className="btn btn-solid"
                                      type="button"
                                      tabIndex="0"
                                      id=""
                                      onClick={() => this.createCampaign()}
                                    >
                                      Create Campaign
                            </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        :

                        !this.state.campaignsTracking ?
                          <React.Fragment>
                            <div className="tab-content">
                              <div className="no-content-holder">
                                <div>
                                  <h3 className="text-center mt-5">
                                    <span className="fas fa-exclamation" style={{ fontSize: "150px", color: "#bbbbbb" }}>
                                    </span>
                                    <br />
                                    <br />
                              No campaigns tracking available for your plan
                        </h3>
                                  <hr></hr>
                                  <div className="text-center">
                                    <Link to={"/organization/subscriptions/" + this.state.userId} className="admin-section--cta">
                                      <button className="btn btn-solid" tabIndex="0" id="">
                                        UPGRADE
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                          :
                          ""
                  }
                  {/* </InfiniteScroll> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment >
    );
  }
}

export default CampaignListing;
