import React from "react";
import MainFooter from "../components/layout-files/MainFooter";
import MainNavbar from "../components/layout-files/MainNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "../App.css";

const DefaultLayout = ({ children }) => (
  <React.Fragment>
    <MainNavbar />
    {children}
    <MainFooter />
  </React.Fragment>
);

export default DefaultLayout;
