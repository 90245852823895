import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addContact, beforeContact } from './action/contact-page.action';
import { scrollTop } from '../../shared/common-functions';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from 'jquery'

 require('bootstrap-select');

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',

            // payload
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            category: '',
        }
    }

    componentDidMount() {
        scrollTop();
        $('.my-select').selectpicker();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactUs.contactUsAuth) {
            this.reset();
            let result = nextProps.contactUs.contactUsRes;
            swal('Success', result.message, 'success');
            this.props.beforeContact();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: "" });
    }

    reset = () => {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            category: ''
        }, () => {
            $('#contact-category').siblings('button').children().find('div.filter-option-inner-inner').html('Select Category');
        });
    }

    submit = (e) => {
        e.preventDefault();

        let { firstName, category, lastName, email, phone, message } = this.state;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (category === "") {
            this.setState({ errors: "Category is required" });
            return
        }
        else if (firstName === "") {
            this.setState({ errors: "First Name is Required" });
            return;
        } else if (lastName === "") {
            this.setState({ errors: "Last Name is Required" });
            return;
        }
        else if (email === "") {
            this.setState({ errors: "Email is Required" });
            return;
        } else if (!email.match(regexp)) {
            this.setState({ errors: "Email is Invalid" });
            return;
        }
        // else if (phone === "") {
        //     this.setState({ errors: "Phone is Required" });
        //     return;
        // }
        else if (message === "") {
            this.setState({ errors: "Message is Required" });
            return;
        }
        else {
            this.setState({ errors: '' }, () => {
                let body = { category, firstName, lastName, email, phone, message };

                this.props.addContact(body);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* <div class="top-bar-bk pt-5 pb-3 mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12" data-form-type="formoid">
                                <div className="title before-footer">
                                    <span>Contact Us</span>
                                    <h5>Got a burning question, want to share some feedback or just want to know more? Feel free to knock our door!</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="staticPageHeader text-white">
					<div className="container">
						<h1>Contact Us</h1>
					</div>
				</div>
                <div className="solutionTextBlock">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div class="map" style={{ 'height': '100%' }}>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.2564770379877!2d74.27327261450283!3d31.462130181388947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919017cdc86aba3%3A0x1ab82d275b76913!2sArhamSoft%20(Pvt.)%20Ltd.!5e0!3m2!1sen!2s!4v1568875375516!5m2!1sen!2s" style={{ 'width': '100%', 'height': '100%', 'border': 'none' }} allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="media-container-column col-lg-6" data-form-type="formoid">
                                <form className="mbr-form contactUsform" autoComplete="off">
                                    <input type="hidden" data-form-email="true" value="zdGVBcNRThcdWUUfI+3PsLnnLtHc8JIdEhub3sFJZu3mJPkE6oxv5FmU79KVvaLjKSmEfsOBbKYj1Cps8hm2o1hmKtkhiOdHe4oDrPZiHdR0Yvomv7W9ZOtj6J4piMeE" />
                                    <div className="row row-sm-offset">
                                        <div className="col-md-6">
                                            <div className="input-field--wrapper">
                                                <div className="input-field--MAIN false undefined py-0">
                                                    <label className="input-field--label d-block require">Category</label>
                                                    <div className="input-field--input-wrapper">
                                                        <div className="StripeElement StripeElement--empty">
                                                            <div className="__PrivateStripeElement"
                                                                style={{
                                                                    margin: "0px !important", padding: "0px !important", border: "none !important",
                                                                    display: "block !important", background: "transparent !important", position: "relative !important",
                                                                    opacity: "1 !important"
                                                                }}>
                                                                <select id="contact-category" className="my-select" name="category" value={this.state.category} onChange={(e) => this.onChange(e)}>
                                                                    <option value="">Select Category</option>
                                                                    <option value="help">Help</option>
                                                                    <option value="complaint">Complaint</option>
                                                                    <option value="feedback">Feedback</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 multi-horizontal" data-for="firstname">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">First Name</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.firstName} className="form-control" name="firstName" data-form-field="Name" placeholder="e.g. John" required id="name-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 multi-horizontal" data-for="lastname">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="name-form1-2w">Last Name</label>
                                                <input onChange={(e) => this.onChange(e)} type="text" value={this.state.lastName} className="form-control" name="lastName" data-form-field="Name" placeholder="e.g. Doe" required id="name-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 multi-horizontal" data-for="email">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7 require" for="email-form1-2w">Email</label>
                                                <input onChange={(e) => this.onChange(e)} type="email" value={this.state.email} className="form-control" name="email" data-form-field="Email" placeholder="e.g. abc@email.com" required id="email-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 multi-horizontal" data-for="phone">
                                            <div className="form-group">
                                                <label className="form-control-label mbr-fonts-style display-7" for="phone-form1-2w">Phone</label>
                                                <input onChange={(e) => this.onChange(e)} type="number" value={this.state.phone} className="form-control" name="phone" data-form-field="Phone" placeholder="e.g. +923211212121" id="phone-form1-2w" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group" data-for="message">
                                        <label className="form-control-label mbr-fonts-style display-7 require" for="message-form1-2w">Message</label>
                                        <textarea onChange={(e) => this.onChange(e)} type="text" value={this.state.message} className="form-control" name="message" rows="7" placeholder="e.g. I need help in understanding the features" data-form-field="Message" id="message-form1-2w" autoComplete="off"></textarea>
                                    </div>

                                    {
                                        this.state.errors ?
                                            <div className="alert alert-danger">{this.state.errors}</div>
                                            : ''
                                    }
                                    <span className="input-group-btn float-right">
                                        <button type="button" className="btn btn-blue btn-form" onClick={this.submit}>Submit</button>
                                    </span>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    addContact, beforeContact
};

const mapStateToProps = state => ({
    contactUs: state.contactUs,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);