import React, { Component } from 'react';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import moment from 'moment';
import { connect } from 'react-redux';
import { downloadInvoice, beforeDownloadInvoice } from './action/invoice.action';
import { scrollTop } from '../../../shared/common-functions';

class Invoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invoiceDetail: {},
            siteDetail: {}
        }
    }

    componentDidMount() {
        scrollTop();

        let paymentId = this.props.match.params.paymentId;
        this.getPaymentDetails(paymentId);
    }

    getPaymentDetails = (id) => {
        fetch(window.APIURL + 'invoice/' + id, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.status === 200) {
                return res.json();
            } else if (res.status === 400) {
                return res.json();
            } else if (res.status === 422) {
                return res.json();
            } else {
                return res.json();
            }
        }).then((result) => {
            if (result.status === true) {
                let data = result.invoice;
                let data1 = result.site;
                this.setState({ invoiceDetail: data, siteDetail: data1 });
            } else if (result.status === false) {
                swal('Error', result.message, 'error')
            } else {
                swal('Error', 'Contact Admin', 'error')
            }
        });
    }

    downloadInvoice = () => {
        let { invoiceDetail, siteDetail } = this.state;
        this.props.downloadInvoice({ invoiceDetail, siteDetail });
    }

    componentDidUpdate() {
        if (this.props.invoice.downloadInvoiceAuth) {
            let result = this.props.invoice.downloadInvoiceRes;
            this.props.beforeDownloadInvoice();
            window.open(result.filePath, '_blank');
        }
    }

    render() {
        const { invoiceDetail, siteDetail } = this.state;

        return (
            <React.Fragment>
                <div className="single-invoice-summary--layout">
                    <div>
                        <div className="invoice-detail--title">
                            <div style={{ overflow: 'hidden', marginBottom: '5px' }}>
                                <div className="w-50 float-left">
                                    <span className="invoice-detail-icon--color">
                                        <span className="bitly-icon">
                                            <img style={{ width: "100px" }} src={require("../../../images/logo.png")} alt="logo" />
                                        </span>
                                    </span>
                                </div>
                                <div className="w-50 float-right text-right">
                                    <button type="button" className="btn btn-primary btn-download" onClick={this.downloadInvoice}><i class="fa fa-download"></i></button>
                                </div>
                            </div>
                            INVOICE
			            </div>
                        {/* <div className="invoice-detail-bitly-address--section">
                            <ul className="invoice-detail--list">

                                 <li>&nbsp;{siteDetail.address}</li>
                                 <li>139 5th Avenue, Floor 5</li>
                                <li>New York, NY 10010</li> 
                            </ul>
                        </div> */}
                        <div className="invoice-detail--column-large">
                            <ul className="invoice-detail--list">
                                <li>{siteDetail.address}</li>
                                <li><strong>Phone:</strong>&nbsp;{siteDetail.phoneNumber}</li>
                                <li><strong>Website:</strong><span>&nbsp;{siteDetail.domain}</span></li>
                                <li><strong>Email:</strong>&nbsp;{siteDetail.email}</li>
                            </ul>
                        </div>
                        <div className="invoice-detail--column-small">
                            <ul className="invoice-detail--list">
                                <li><strong>Payment Date:</strong>&nbsp;{moment(invoiceDetail.createdAt).format("DD/MM/YYYY")}</li>
                                <li><strong>Invoice #:</strong>&nbsp;{invoiceDetail.invoiceId}</li>
                            </ul>
                        </div>
                        <hr />
                        <div className="invoice-detail--column-large">
                            <ul className="invoice-detail--list invoice-detail-billing--section">
                                <li><strong>Bill To:</strong></li>
                                <li><strong>Transaction ID: </strong>{invoiceDetail.customerId}</li>
                                <li><strong>Customer Name: </strong>{invoiceDetail.fullName}</li>
                                {/* <li>12 Bradgate Road Altrincham</li>
                                <li>Altrinchsam, Cheshire WA14 4QU</li> */}
                            </ul>
                        </div>
                        <div className="invoice-detail-charge-info--section">
                            <span className="invoice-detail--column-large"><strong>CHARGE DETAIL</strong></span>
                            <span className="invoice-detail--column-small"><strong>PAYMENT TOTAL</strong></span>
                            <hr /><span className="invoice-detail--column-large">{invoiceDetail.description}</span>
                            <span className="invoice-detail--column-small">${invoiceDetail.amount}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    downloadInvoice, beforeDownloadInvoice
};

const mapStateToProps = state => ({
    invoice: state.invoice,
    errors: state.errors
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);