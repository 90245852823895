import React, { Component } from "react";
import { Link } from 'react-router-dom';

class PaymentCancel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            session: false
        }
    }

    componentDidMount() {
        if (localStorage.getItem("loginToken")) {
            this.setState({ session: true })
        }
    }
    render() {
        return (
            <div className="d-flex text-center paymentMessageParent error">
                <div className="messageBlock">
                    <h2>Oops!</h2>
                    <div className="icon-holder">
                        <i className="fas fa-frown"></i>
                    </div>
                    <div className="text-holder">
                        <p>Oh no! something went wrong.</p>
                        <Link className="btn-paymentMessage" to={this.state.session == true ? "/dashboard" : "/userLogin"}>
                            <span className="float-left">Try Again</span>
                            <span className="float-right"><i class="fas fa-arrow-left"></i></span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentCancel