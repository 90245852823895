import React, { Component } from "react";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

// library.add(fab , FontAwesomeIcon);
class SocialNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      twitterAccounts: ""
    };
    this.deleteTwitter = this.deleteTwitter.bind(this);
  }

  componentWillMount = () => {
    this.setState({
      userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
    });
  };
  componentDidMount = () => {
    this.getTwitterAccounts();
  };
  deleteTwitter = id => {
    let twitterAccountsBackup = this.state.twitterAccounts;

    Swal.fire({
      title: 'Are you sure?',
      text: "You want to disconnect this account?",
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {


        fetch(window.APIURL + "accounts/member/delete-twitter-accounts", {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              const index = twitterAccountsBackup.findIndex(
                item => item._id === id
              );
              twitterAccountsBackup.splice(index, 1);
              this.setState({
                twitterAccounts: twitterAccountsBackup
              });
              swal('Success', result.message, 'success')
            } else if (result.status === false) {
              swal('Error', result.message, 'error')
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      }
    })
  };
  getTwitterAccounts = () => {
    fetch(window.APIURL + "accounts/member/get-twitter-accounts", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        _id: this.state.userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        if (result.status === true) {
          this.setState({
            twitterAccounts: result.data
          });
        } else if (result.status === false) {
          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  facebookDisabled = () => {
    swal('Error', 'Facebook account are disabled for a short time.', 'error');
  }

  render() {
    const { subscriptionDetails } = this.props;
    let totalPlatforms = parseInt(subscriptionDetails.socialPlatforms);
    // console.log('..//totalPlatforms', totalPlatforms)
    // console.log('...////', subscriptionDetails)
    // console.log('subscriptionDetails.socialPlatforms', typeof (subscriptionDetails.socialPlatforms))
    // console.log('twitterr...', this.state.twitterAccounts.length)
    // const { userID, subscriptionDetails } = this.props;
    return (
      <React.Fragment>
        <div className="action-sheet--header">
          <a>
            {" "}
            <span className="back_btn-icon" alt="back_btn-icon">
              <FontAwesomeIcon icon="chevron-left" />
            </span>{" "}
          </a>
          <h4 className="action-sheet--header-text">connect to social</h4>
          <span>
            <a>
              <span className="close-icon" alt="close-icon" />
            </a>
          </span>
        </div>
        <div className="action-sheet--content-wrapper">
          <div className="connected-account--sub-header">
            Connected Accounts
          </div>
          {this.state.twitterAccounts.length > 0
            ? this.state.twitterAccounts.map((data, index) => (
              <div key={index} className="connected-account--MAIN">
                <span className="social-icon"> <img className="user-account--image" width="25" src={require("../../../../images/twitter.svg")} /></span>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => this.deleteTwitter(data._id)}
                  className="close-icon"
                  alt="close-icon"
                >
                  <FontAwesomeIcon icon="times-circle" />
                </a>
                <div className="connected-account--account-name">
                  {data.displayName}
                </div>
                <div className="connected-account--date">
                  connected on {Date(data.createdAt).slice(4, 15)}
                </div>
              </div>
            ))
            : " "}
          {
            this.state.twitterAccounts.length < totalPlatforms ?
              <React.Fragment>
                <a
                  className="connected-account--link"
                  href={
                    window.APIURL + "add/twitter?id=" + this.state.userId
                  }
                >
                  <div className="select-item--MAIN-b b">
                    <p className="select-item--label">Connect to a Twitter account</p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </a>

                <a onClick={this.facebookDisabled} className="connected-account--link">
                  <div className="select-item--MAIN-b b">
                    <p className="select-item--label">
                      Connect to a Facebook account
                </p>
                    <span className="select-item--secondary-label" />
                    <span className="select-item--third-label " />
                    <span className="select-item--fourth-label" />
                    <span className="checklist-row--icon">
                      <span className="selector-icon" alt="selector-icon">
                        <FontAwesomeIcon icon="chevron-right" />
                      </span>
                    </span>
                  </div>
                </a>
              </React.Fragment>
              :
              ""
          }
        </div>
      </React.Fragment>
    );
  }
}

export default SocialNetworks;
