import React, { Component } from "react";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      selection: "",
      reason: "",
      confirmation: "",
      displayPre: true,
      redirect: false
    };
  }
  componentWillMount = () => {
    this.setState({ userId: this.props.userId });
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  cancelDeletion = () => {
    this.setState({
      displayPre: !this.state.displayPre,
      selection: "",
      reason: "",
      confirmation: "",
    });
  };
  preReasonSubmit = e => {
    e.preventDefault();
    if (this.state.selection) {
      if (this.state.selection === "other" && this.state.reason === "") {
        swal('Error', 'Please provide the reason message', 'error')
      } else {
        this.setState({ displayPre: !this.state.displayPre });
      }
    } else {
      swal('Error', 'Please provide the reason message', 'error')
    }
  };
  deleteSubmission = e => {
    e.preventDefault();
    if (this.state.confirmation === "DELETE ACCOUNT") {
      fetch(window.APIURL + "accounts/member/delete-accounts", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId: this.state.userId,
          selection: this.state.selection,
          reason: this.state.reason
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            this.props.logoutFun();
          } else if (result.status === false) {
            if (result.redirection === true) {
              this.setState({ redirect: true });
              swal('Error', result.message, 'error')
            }
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    } else {
      swal('Error', `Please type the "DELETE ACCOUNT" properly.`, 'error')
    }
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/userLogin" />;
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          style={{ display: "none" }}
          id="delete_user"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="deactivate"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet"
            }}
          >
            <div className="action-sheet--header">
              <a>
                {" "}
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>{" "}
              </a>
              <h4 className="action-sheet--header-text">Delete User</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div
              className={
                this.state.displayPre
                  ? "action-sheet--content-wrapper"
                  : "action-sheet--content-wrapper d-none"
              }
            >
              <form>
                <h2 className="deactivate-account--header">
                  We're sorry to see you go!
                </h2>
                <span className="action-sheet--help-text">
                  Before you delete your account:
                </span>
                <p className="action-sheet--text">
                  Deleting will prevent access to your FLO.ink account, dissociate
                  your username from any FLO.ink created by your account, and
                  remove all your personally identifying information from FLO.ink,
                  including connections to third-party authentication systems.
                </p>
                <p className="action-sheet--text">
                  <strong>
                    Deleting your account will cancel any FLO.ink paid plans you've
                    upgraded to.
                  </strong>
                </p>
                <p className="action-sheet--text">
                  Deleting your account will not delete FLO.ink themselves. All
                  unbranded FLO.ink will continue to function. All branded
                  FLO.ink will continue to function as long as the branded
                  short domain DNS records point to FLO.ink's servers.
                </p>
                <p className="action-sheet--text">
                  {" "}
                  <strong>
                    Are you sure you want to leave FLO.ink? No problem. Please tell
                    us why.
                  </strong>
                </p>
                <div>
                  <div className="radio-button--ALTERNATE">
                    <label className="radio-button--item false cur-poi" htmlFor="account">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="account"
                          onChange={this.onChange}
                          value="I have another FLO.ink account"
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          I have another FLO.ink account
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="privacy">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="privacy"
                          onChange={this.onChange}
                          value="I have privacy concerns using FLO.ink"
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          I have privacy concerns using FLO.ink
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="useful">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="useful"
                          onChange={this.onChange}
                          value="I no longer find FLO.ink useful"
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">
                          I no longer find FLO.ink useful
                        </span>
                      </span>
                    </label>
                    <label className="radio-button--item false cur-poi" htmlFor="other">
                      <span className="radio-button--wrapper">
                        <input
                          type="radio"
                          className="radio-button--input"
                          name="selection"
                          id="other"
                          onChange={this.onChange}
                          value="other"
                        />
                        <span className="radio-button--button"> </span>
                        <span className="radio-button--label">Other</span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Specify Reason</label>
                    <div className="input-field--input-wrapper">
                      <input
                        className="input-field--input"
                        autoComplete="false"
                        tabIndex="0"
                        onChange={this.onChange}
                        value={this.state.reason}
                        name="reason"
                      />
                    </div>
                  </div>
                </div>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    tabIndex="0"
                    id="preReasonSubmit"
                    onClick={this.preReasonSubmit}
                  >
                    SAVE
                  </button>
                </div>
              </form>
            </div>
            <div
              className={
                this.state.displayPre
                  ? "action-sheet--content-wrapper d-none"
                  : "action-sheet--content-wrapper"
              }
            >
              <form onSubmit={this.deleteSubmission}>
                <p className="action-sheet--text">To delete your account:</p>
                <ol className="action-sheet--list">
                  <li className="action-sheet--text action-sheet--list-item">
                    Recognize there is no going back. This will permanently
                    delete your data.
                  </li>
                  <li className="action-sheet--text action-sheet--list-item">
                    Type DELETE ACCOUNT in the box below.
                  </li>
                  <li className="action-sheet--text action-sheet--list-item">
                    Click Confirm &amp; Delete.
                  </li>
                </ol>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Confirmation</label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder="Type 'DELETE ACCOUNT'"
                        className="input-field--input"
                        autoComplete="false"
                        tabIndex="0"
                        value={this.state.confirmation}
                        id="confirmation"
                        name="confirmation"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <p className="action-sheet--text">
                  Remember, this is permanent.
                </p>
                <div className="action-sheet--button-wrapper">
                  <button
                    className="button--SOLID-SECONDARY"
                    type="submit"
                    tabIndex="0"
                    id="confirmationButton"
                    name="confirmationButton"
                  >
                    CONFIRM &amp; DELETE
                  </button>
                  <button
                    className="button--SOLID-SECONDARY"
                    type="button"
                    tabIndex="0"
                    id="cancelDeletion"
                    onClick={this.cancelDeletion}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DeleteAccount;
