import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";

import '../App.css'


const NoNavbar = ({ children }) => (
  <React.Fragment>
    {children}
  </React.Fragment>
);

export default NoNavbar;
