// CONTACT US
export const ADD_CONTACT = 'ADD_CONTACT';
export const BEFORE_CONTACT = 'BEFORE_CONTACT';

export const ADD_QUOTE = 'ADD_QUOTE';
export const BEFORE_QUOTE = 'BEFORE_QUOTE';

// ERRORS
export const GET_ERRORS = 'GET_ERRORS';
export const EMPTY_ERRORS = 'EMPTY_ERRORS';

// INVOICE
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';
export const BEFORE_DOWNLOAD_INVOICE = 'BEFORE_DOWNLOAD_INVOICE';

// SITE SETTINGS
export const GET_SITE_SETTINGS = 'GET_SITE_SETTINGS';
export const BEFORE_SITE_SETTINGS = 'BEFORE_SITE_SETTINGS';

// CUSTOM DOMAINS
export const ADD_CUSTOM_DOMAIN = 'ADD_CUSTOM_DOMAIN';
export const EDIT_CUSTOM_DOMAIN = 'EDIT_CUSTOM_DOMAIN';
export const DELETE_CUSTOM_DOMAIN = 'DELETE_CUSTOM_DOMAIN';
export const GET_CUSTOM_DOMAINS = 'GET_CUSTOM_DOMAINS';
export const GET_CUSTOM_DOMAIN = 'GET_CUSTOM_DOMAIN';
export const BEFORE_CUSTOM_DOMAIN = 'BEFORE_CUSTOM_DOMAIN';
