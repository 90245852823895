import React, { Component } from "react";
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterResults from "react-filter-search";
import { Redirect } from "react-router-dom";


class ManageChannels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      channels: [],
      logOutRedirect: false,
      manageChannelLoader: true,
    };
  }

  componentWillMount = () => {
    let hash = window.location.hash;
    if (hash !== "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "manage_channels") {
        this.getChannelsFunction();
      }
    }
  };

  getChannelsFunction() {
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "channel/user-channels?userId=" + userId)
      .then(response => response.json())
      .then(result => {
        this.setState({ manageChannelLoader: false, channels: result.channels });
      });
  }

  handleChangeChannels = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  addChannel = () => {
    this.props.changeLoaderStatus(true);
    let { value } = this.state;
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    this.setState({ manageChannelLoader: true })
    fetch(window.APIURL + "channel/create", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        title: value,
        userId: userId
      })
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        } else if (res.status === 403) {
          return res.json();
        } else if (res.status === 422) {
          return res.json();
        } else if (res.status === 404) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then(result => {
        this.setState({ manageChannelLoader: false })
        if (result.status === true) {
          let createdChannel = { title: result.channel.title, _id: result.channel._id };
          this.setState({
            channels: [...this.state.channels, createdChannel],
            value: "",
          });
          this.props.updateChannelsFromChild(createdChannel);
          swal('Success', result.message, 'success')
          this.props.changeLoaderStatus(false);
        } else if (result.status === false) {

          if (result.redirection === true) {
            this.setState({ logOutRedirect: true });
            swal('Error', result.message, 'error')
          }

          swal('Error', result.message, 'error')
        } else {
          swal('Error', 'Contact Admin', 'error')
        }
      });
  };

  deleteChannel = _id => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this channel?",
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.props.changeLoaderStatus(true);

        let channelsBackup = this.state.channels;
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        this.setState({ manageChannelLoader: true })
        fetch(window.APIURL + "channel/delete?_id=" + _id + "&userId=" + userId)
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            this.setState({ manageChannelLoader: false })
            if (result.status === true) {
              swal('Success', result.message, 'success')
              const index = channelsBackup.findIndex(
                item => item._id === _id
              );
              channelsBackup.splice(index, 1);
              this.setState({
                channels: channelsBackup
              });
              this.props.changeLoaderStatus(false);

            }
            else {
              swal('Error', result.message, 'error')
            }
          });
      }
    })
  };

  dynamicChannels = (_id, index, e) => {
    if (e.target.value === "") {
      swal('Error', "Channel name is required", 'error');
      return;
    }
    else {
      let channels = [...this.state.channels];
      channels[index].title = e.target.value;
      this.setState({ channels });
      let title = e.target.value;
      setTimeout(() => {
        fetch(window.APIURL + "channel/edit", {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: _id,
            title: title,
            userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === false) {
              if (result.redirection === true) {
                this.setState({ logOutRedirect: true });
                swal('Error', result.message, 'error')
              }
              swal('Error', result.message, 'error')
            }
          });
      }, 500);
    }
  };

  render() {
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      return <Redirect to="/userLogin" />;
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="20"
          style={{ display: "none " }}
          id="manage_channels"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="channelsList"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <a>
                {" "}
                <span className="back_btn-icon" alt="back_btn-icon">
                  <FontAwesomeIcon icon="chevron-left" />
                </span>{" "}
              </a>
              <h4 className="action-sheet--header-text">Manage Channels</h4>
              <span>
                <a>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <div className="action-sheet--content-wrapper">
              <div className="dynamic-checklist--MAIN">
                <div className="search--ALTERNATE   ">
                  <input
                    type="search"
                    maxLength="100"
                    className="search--input-field"
                    placeholder="Search and create channels"
                    value={this.state.value}
                    onChange={this.handleChangeChannels}
                  />

                  <FilterResults
                    value={this.state.value}
                    data={this.state.channels}
                    renderResults={results => (
                      <div>
                        {results.length > 0 && this.state.manageChannelLoader !== true ? (
                          results.map((el, index) => {
                            return (
                              <div
                                key={index}
                                className="dynamic-checklist--item "
                              >
                                <div className="input-field--wrapper">
                                  <div className="input-field--ALTERNATE   ">
                                    <label className="input-field--label" />
                                    <div className="input-field--input-wrapper">
                                      <input
                                        type="text"
                                        className="input-field--input"
                                        maxLength="100"
                                        value={el.title}
                                        disabled
                                        // onChange={event =>
                                        //   this.dynamicChannels(
                                        //     el._id,
                                        //     index,
                                        //     event
                                        //   )
                                        // }
                                      />
                                      <span style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.deleteChannel(el._id)
                                        }
                                      >
                                        X
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) :
                          this.state.manageChannelLoader == true ?
                            <div className="text-center">
                              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            </div>
                            :
                            (
                              <div>
                                {this.state.value === "" ? (
                                  ""
                                ) : (
                                    <div>
                                      <span>
                                        {"There is no result against '" +
                                          this.state.value +
                                          "'"}
                                      </span>
                                      <br />
                                      <a className="create-suggestion" onClick={this.addChannel}>
                                        <span> Create '{this.state.value}' </span>
                                      </a>
                                    </div>
                                  )}
                              </div>
                            )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageChannels;
