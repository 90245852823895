import { ADD_CUSTOM_DOMAIN, BEFORE_CUSTOM_DOMAIN, EMPTY_ERRORS, GET_ERRORS, GET_CUSTOM_DOMAINS } from '../../../../redux/types';
import { ENV } from '../../../../config/config';

export const emptyError = () => {
    return {
        type: EMPTY_ERRORS
    }
}

export const beforeCustomDomain = () => {
    return {
        type: BEFORE_CUSTOM_DOMAIN
    }
}

export const addCustomDomain = (body) => dispatch => {
    dispatch(emptyError());
    fetch(ENV.url + 'custom-domain/create', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: ADD_CUSTOM_DOMAIN,
                payload: data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getCustomDomains = (page = 1, limit = 10) => dispatch => {
    dispatch(emptyError());
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;

    let url = `${ENV.url}custom-domain/user-custom-domains`;

    if (userId)
        url += `?userId=${userId}`;

    if (page)
        url += `&page=${page}`;

    if (limit)
        url += `&limit=${limit}`;

    fetch(url, {
        method: 'GET',
        headers: { "Content-Type": "application/json" }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: GET_CUSTOM_DOMAINS,
                payload: data
            });
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}
