import React, { Component } from 'react';
import countriesCities from "countries-cities";
import { Input } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ENV } from './../../../config/config';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery'
var CryptoJS = require("crypto-js");
require('bootstrap-select');

class UpgradePackage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isCustomize: false,
         packageId: "",
         packageDetail: {},
         //....................
         billingMethod: '1',
         fullName: '',
         country: '',
         zipCode: '',
         email: '',
         phoneNumber: '',
         cardNumber: '',
         expiryMonth: '',
         expiryYear: '',
         CVV: '',
         cardType: 1,
         //........................
         packageType: '',
         annualPayment: '',
         savingRatio: 0,
         monthlyPayment: '',
         countries: [],
         years: [],
         isChecked: false,
         isClicked: false,
         redirect: false,
         userID: '',
         errors: '',
         token: '',
         custom: false,
         paypalClicked: false
      }
      this.handleChange = this.handleChange.bind(this);
   }

   componentDidMount() {
      let session = JSON.parse(localStorage.getItem("LoginSession"));
      let sessionToken = session.data.accessToken;
      let check = this.props.location.search;
      let params = new URLSearchParams(check);
      let subscriptionId = params.get('subscriptionId');
      let check2 = params.get('type');
      // let directAuth = params.get('da');
      let isCustomize = params.get('custom');
      this.setState({
         token: sessionToken
      })

      if (isCustomize) {
         isCustomize = true
         this.setState({ isCustomize: true });
      }

      if (!session) {
         if (isCustomize) {
            // this.autoLogin(directAuth);
         }
         else {
            localStorage.setItem('redirectionURL', '/organization/subscription?subscriptionId=' + subscriptionId + "&type=" + check2)
            this.props.history.push('/userLogin');
         }
      } else {
         let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
         this.setState({
            billingMethod: check2
         })

         let Contry = countriesCities.getCountries();
         var start = moment(new Date()).format("YYYY");
         let yearArray = [];
         for (var year = 1; year < 40; year++) {
            yearArray.push(start);
            start = parseInt(start) + 1;
         }
         this.setState({
            years: yearArray,
            countries: Contry,
            packageId: subscriptionId,
            userID: id,
         },
            (() => {
               setTimeout(() => {
                  $('.my-select').selectpicker();
               }, 1000);
            })
         );

         if (!isCustomize)
            fetch(window.APIURL + 'accounts/member/check-account-type?id=' + id, {
               method: 'get',
               headers: { 'Content-Type': 'application/json' },
            }).then((res) => {
               if (res.status === 200) {
                  return res.json();
               } else if (res.status === 400) {
                  return res.json();
               } else if (res.status === 422) {
                  return res.json();
               } else {
                  return res.json();
               }
            }).then((result) => {
               if (result.status === true) {
                  let data;
                  if (result.userAcc[0].userAccount.length > 0) {
                     data = result.userAcc[0].userAccount[0]._id;
                  }
                  // let data = result.userAcc[0].userAccount[0]._id;
                  if (data === subscriptionId) {
                     this.props.history.push('/organization/subscriptions/' + id)
                  } else {
                     this.getPlannDetail(subscriptionId);
                  }
               } else if (result.status === false) {
                  swal('Error', result.message, 'error')
               } else {
                  swal('Error', 'Contact Admin', 'error')
               }
            });
         else
            this.getPlannDetail(subscriptionId, true);
      }
   }

   autoLogin(directAuth) {
      fetch(window.APIURL + 'accounts/member/auto-login?directAuth=' + directAuth, {
         method: 'get',
         headers: { 'Content-Type': 'application/json' },
      }).then((res) => {
         return res.json();
      }).then((result) => {
         if (result.status) {
            localStorage.setItem("LoginSession", JSON.stringify(result));
            localStorage.setItem("loginToken", result.data.loginToken);
            localStorage.setItem("loginTime", new Date().getTime());
         } else if (!result.status) {
            swal('Error', result.message, 'error')
         } else {
            swal('Error', 'Contact Admin', 'error')
         }
      });
   }

   handleChange(event) {
      this.setState({
         billingMethod: event.target.value
      });
   }

   toggleChange = () => {
      this.setState({
         isChecked: !this.state.isChecked,
      });
   }

   onChange = (e) => {
      let regex = new RegExp("^[0-9]*$");
      let name = e.target.name;
      let value = e.target.value;
      if (name === 'CVV') {
         if (!regex.test(value)) {
            // valid = false;
            e.preventDefault()
            e.nativeEvent.preventDefault()
         } else {
            this.setState({
               [e.target.name]: e.target.value
            });
         }
      } else {
         this.setState({
            [e.target.name]: e.target.value
         });
      }
   }

   getPlannDetail = (id, isCustomize = false) => {
      // let { isCustomize } = this.state;

      let url = window.APIURL + 'subscription-package/get/from-upgrade?_id=' + id;
      if (isCustomize)
         url += '&isCustomize=true';

      fetch(url, {
         method: 'get',
         headers: { 'Content-Type': 'application/json' }
      }).then((res) => {
         if (res.status === 200) {
            return res.json();
         } else if (res.status === 400) {
            return res.json();
         } else if (res.status === 422) {
            return res.json();
         } else {
            return res.json();
         }
      }).then((result) => {
         if (result.status === true) {
            let data = result.subscriptionPackage;
            let annualPrice = Math.round((data.price - (data.price * (data.savingRatio / 100)))) * 12;
            this.setState({
               custom: result.custom,
               savingRatio: data.savingRatio,
               packageType: data.type,
               packageDetail: data,
               annualPayment: annualPrice,
               monthlyPayment: data.price
            });
         } else if (result.status === false) {
            swal('Error', result.message, 'error')
         } else {
            swal('Error', 'Contact Admin', 'error')
         }
      });
   }

   completPurchase = () => {
      let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
      this.setState({ errors: '' });
      const { billingMethod, fullName, phoneNumber, country, zipCode, email, cardNumber, expiryMonth, expiryYear, CVV, cardType, packageType, packageId, isChecked, custom } = this.state;

      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (fullName === '') {
         this.setState({ errors: 'Full Name is required' })
         return;
      }
      else if (country === '') {
         this.setState({ errors: 'Country is required' });
         return;
      }
      else if (zipCode === '') {
         this.setState({ errors: 'Zip Code is required' });
         return;
      }
      // else if (zipCode && zipCode.length !== 5) {
      //    this.setState({ errors: 'Zip Code must be five digits' });
      //    return;
      // }
      else if (!email.match(regexp)) {
         this.setState({ errors: 'Email is Invalid' });
         return;
      }
      else if (phoneNumber === '') {
         this.setState({ errors: 'Phone Number is required' })
         return
      }
      else if (cardNumber === '') {
         this.setState({ errors: 'Card Number is required' })
         return
      }
      else if (cardNumber.replace(/\s/g, '').length !== 16) {
         this.setState({ errors: 'Card Number is not valid' })
         return
      }
      else if (cardType === '') {
         this.setState({ errors: 'Card Type is required' })
         return
      }
      else if (expiryMonth === '') {
         this.setState({ errors: 'Expire Month is required' })
         return
      }
      else if (expiryYear === '') {
         this.setState({ errors: 'Expire Year is required' })
         return
      }
      else if (CVV === '') {
         this.setState({ errors: 'CVV is required' })
         return
      }
      else if (!isChecked) {
         this.setState({ errors: 'Check the terms and services' })
         return
      }
      else {
         this.setState({ isClicked: true, errors: '' });
         let url = window.APIURL + 'payment/create';

         let { isCustomize } = this.state;
         if (isCustomize)
            url += '?isCustomize=true';

         fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
               "billingMethod": billingMethod,
               "userId": id,
               "fullName": fullName,
               "country": country,
               "zipCode": zipCode,
               "email": email,
               "phoneNumber": phoneNumber,
               "cardNumber": cardNumber.replace(/\s/g, ''),
               "expiryMonth": expiryMonth,
               "expiryYear": expiryYear,
               "CVV": CVV,
               "cardType": cardType,
               "amount": billingMethod === '1' ? this.state.annualPayment.toString() : this.state.monthlyPayment.toString(),
               "accountType": packageType,
               "packageId": packageId,
               "custom": custom
            })
         }).then((res) => {
            if (res.status === 200) {
               return res.json();
            } else if (res.status === 400) {
               return res.json();
            } else if (res.status === 422) {
               return res.json();
            } else {
               return res.json();
            }
         }).then((result) => {
            this.setState({ isClicked: false })
            if (result.status === true) {
               let subscriptionPlanDetails = result.updatedPackage;
               let encSubscriptionPlanDetails = CryptoJS.AES.encrypt(JSON.stringify(subscriptionPlanDetails), ENV.TokSecCrpt);
               localStorage.setItem("spd", encSubscriptionPlanDetails);
               this.setState({ redirect: true });
               swal('Success', 'Your package has been changed successfully', 'success');
            } else if (result.status === false) {
               swal('Error', result.message, 'error')
            } else {
               swal('Error', 'Contact Admin', 'error')
            }
         });
      }
   }

   onSubmitPaypal(e) {
      e.preventDefault();
      let { packageType, packageDetail, annualPayment, monthlyPayment, billingMethod, token, userID, packageId, custom } = this.state;
      // console.log('.../Billing', billingMethod)
      const data = {
         billingMethod: billingMethod,
         packageType: packageType,
         packageDetail: packageDetail,
         annualPayment: annualPayment,
         monthlyPayment: monthlyPayment,
         custom: custom,
         // token: token,
         userID: userID,
         packageId: packageId,
      }
      let url = window.APIURL + 'payment/paypal/authenticate';

      let { isCustomize } = this.state;
      if (isCustomize)
         url += '?isCustomize=true';
      this.setState({ paypalClicked: true })
      fetch(url, {
         method: 'post',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({ data })
      }).then((res) => {
         if (res.status === 200) {
            return res.json();
         } else if (res.status === 400) {
            return res.json();
         } else if (res.status === 422) {
            return res.json();
         } else {
            return res.json();
         }
      }).then((result) => {
         if (result.status === true) {
            // let link = result.link;
            // link = link + '&subscriptionId=' + packageId;
            // console.log('link', link)
            // if (isCustomize) {
            //    window.location.href = result.link + '&subscriptionId=' + packageId + '&isCustomize=true';
            // } else {
            window.location.href = result.link;
            this.setState({ paypalClicked: true })
            // }
         } else if (result.status === false) {
            swal('Error', result.message, 'error')
         } else {
            swal('Error', 'Contact Admin', 'error')
         }
      });
   }

   payWithMollie(e) {
      e.preventDefault();
      let { packageType, packageDetail, annualPayment, monthlyPayment, billingMethod, token, userID, packageId, custom } = this.state;

      let url = `${window.APIURL}payment/mollie/create`;

      let { isCustomize } = this.state;
      if (isCustomize)
         url += '?isCustomize=true';

      this.setState({ isMollieClicked: true });
      fetch(url, {
         method: 'post',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({
            accountType: packageType,
            amount: billingMethod === '1' ? annualPayment.toString() : monthlyPayment.toString(),
            userId: userID,

            billingMethod: billingMethod,
            packageType: packageType,
            packageDetail: packageDetail,
            custom: custom,
            packageId: packageId,
         })
      }).then((res) => {
         return res.json();
      }).then((result) => {
         if (result.status) {
            window.location.href = result.payment._links.checkout.href;
            this.setState({ isMollieClicked: false });
         } else if (!result.status) {
            swal('Error', result.message, 'error')
         } else {
            swal('Error', 'Contact Admin', 'error')
         }
      });
   }

   render() {
      let { packageDetail, billingMethod, monthlyPayment, annualPayment, redirect, userID } = this.state;
      if (redirect) {
         return <Redirect to={'/organization/detail'} />;
      }
      return (
         <React.Fragment>
            <div className="container">
               <div className="subscription-payment--layout">
                  {
                     !this.state.isCustomize ?
                        <Link to={"/organization/subscriptions/" + userID} className="go-back">
                           <span className="back_btn-icon" alt="back_btn-icon">
                              <i className="fas fa-chevron-left"></i>
                           </span> Back to Plans
                        </Link>
                        :
                        ''
                  }
                  <form>
                     <div className="row">
                        <div className="col-md-8">
                           {
                              !this.state.isCustomize ?
                                 <React.Fragment>
                                    <h3>Select Billing</h3>
                                    <section className="options options-billing">
                                       <div className="radio-button--MAIN">
                                          <label className="radio-button--item active" htmlFor="month">
                                             <span className="radio-button--wrapper">
                                                <Input type="radio" className="radio-button--input" name="billingMethod" id="month" value="2" checked={this.state.billingMethod === "2"} onChange={this.handleChange} />
                                                <span className="radio-button--button"> </span>
                                                <span className="radio-button--label">Monthly</span>
                                             </span>
                                          </label>
                                          <label className="radio-button--item false" htmlFor="annual">
                                             <span className="radio-button--wrapper">
                                                <Input type="radio" className="radio-button--input" name="billingMethod" id="annual" value="1" checked={this.state.billingMethod === "1"} onChange={this.handleChange} />
                                                <span className="radio-button--button"></span>
                                                <span className="radio-button--label">
                                                   Yearly
                                       {
                                                      this.state.savingRatio ? ` (Save up to ${this.state.savingRatio} %)` : ''
                                                   }
                                                </span>
                                             </span>
                                          </label>

                                       </div>
                                    </section>
                                 </React.Fragment>
                                 : ''
                           }
                           <div className="subscription-payment--left-panel">
                              <h3>Enter Payment Details</h3>
                              <section className="options options-contact row">
                                 <div className="row col-12">
                                    <div className="col">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN   ">
                                             <label className="input-field--label">Full Name</label>
                                             <div className="input-field--input-wrapper">
                                                <input
                                                   className="input-field--input"
                                                   type="text"
                                                   name="fullName"
                                                   value={this.state.fullName}
                                                   onChange={this.onChange}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row col-12">
                                    <div className="col-md-6">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN   ">
                                             <label className="input-field--label d-block">Country</label>
                                             <select className="my-select" style={{ width: "100%" }} name="country" value={this.state.country} onChange={this.onChange}>
                                                <option value="">Select Country</option>
                                                {
                                                   this.state.countries.length > 0 ?
                                                      this.state.countries.map((val, key) => {
                                                         return (
                                                            <option key={key} value={val}>{val}</option>
                                                         )
                                                      }) : <option value="">No Record Found</option>
                                                }

                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN   ">
                                             <label className="input-field--label">Zip Code</label>
                                             <div className="input-field--input-wrapper">
                                                <input
                                                   className="input-field--input"
                                                   autoComplete="false"
                                                   type="text"
                                                   min="0"
                                                   maxLength="8"
                                                   name="zipCode"
                                                   value={this.state.zipCode}
                                                   onChange={this.onChange}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row col-12">
                                    <div className="col-md-6">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN   ">
                                             <label className="input-field--label">Email Address</label>
                                             <div className="input-field--input-wrapper">
                                                <input
                                                   className="input-field--input"
                                                   autoComplete="false"
                                                   type="text"
                                                   name="email"
                                                   value={this.state.email}
                                                   onChange={this.onChange}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN   ">
                                             <label className="input-field--label">Phone Number</label>
                                             <div className="input-field--input-wrapper">
                                                <input
                                                   className="input-field--input"
                                                   autoComplete="false"
                                                   type="text"
                                                   name="phoneNumber"
                                                   value={this.state.phoneNumber}
                                                   onChange={this.onChange}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </section>
                              <h3>Enter Credit Card Information</h3>
                              <section className="row options options-cc ">
                                 <div className="row col-12">
                                    <div className=" col-sm-12 col-md-6">
                                       <div className="col pl-0 pr-0">
                                          <div className="input-field--wrapper">
                                             <div className="input-field--MAIN false undefined">
                                                <label className="input-field--label">Credit Card Number</label>
                                                <div className="input-field--input-wrapper">
                                                   <div className="StripeElement StripeElement--empty">
                                                      <div className="__PrivateStripeElement" style={{ margin: "0px !important", padding: "0px !important", border: "none !important", display: "block !important", background: "transparent !important", position: "relative !important", opacity: "1 !important" }}>


                                                         <InputMask
                                                            mask="9999 9999 9999 9999"
                                                            maskChar={null}
                                                            alwaysShowMask={false}
                                                            name="cardNumber"
                                                            value={this.state.cardNumber}
                                                            onChange={this.onChange}
                                                         />

                                                         {/* <input
                                                            className="__PrivateStripeElement-input"
                                                            aria-hidden="true"
                                                            type="text"
                                                            // maxLength="16"
                                                            name="cardNumber"
                                                            value={this.state.cardNumber}
                                                            onChange={this.onChange}
                                                            // style={{
                                                            //    border: "none !important", display: "block !important",
                                                            //    position: "absolute !important", height: "1px !important", top: "0px !important",
                                                            //    left: "0px !important", padding: "0px !important", margin: "0px !important",
                                                            //    width: "100% !important", opacity: "0 !important", background: "transparent !important",
                                                            //    pointerEvents: "none !important", fontSize: "16px !important"
                                                            // }} 
                                                            /> */}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-6">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN false undefined">
                                             <label className="input-field--label d-block">Card Type</label>
                                             <div className="input-field--input-wrapper">
                                                <div className="StripeElement StripeElement--empty">
                                                   <div className="__PrivateStripeElement"
                                                      style={{
                                                         margin: "0px !important", padding: "0px !important", border: "none !important",
                                                         display: "block !important", background: "transparent !important", position: "relative !important",
                                                         opacity: "1 !important"
                                                      }}>
                                                      <select className="my-select" name="cardType" value={this.state.cardType} onChange={this.onChange}>
                                                         <option value="">Select type of card</option>
                                                         <option value="1">Visa</option>
                                                         <option value="2">Mastercard</option>
                                                         <option value="3">American Express</option>
                                                         <option value="4">Discover</option>
                                                      </select>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row col-12">

                                    <div className="col-md-4  ">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN false undefined">
                                             <label className="input-field--label">Exp Month</label>
                                             <div className="input-field--input-wrapper">
                                                <div className="StripeElement StripeElement--empty">
                                                   <div className="__PrivateStripeElement"
                                                      style={{
                                                         margin: "0px !important", padding: "0px !important", border: "none !important",
                                                         display: "block !important", background: "transparent !important", position: "relative !important",
                                                         opacity: "1 !important"
                                                      }}>
                                                      <select className="my-select" type="select" id="exampleSelect" name="expiryMonth" value={this.state.expiryMonth} onChange={this.onChange}>
                                                         <option value="">MM</option>
                                                         <option value="01">01</option>
                                                         <option value="02">02</option>
                                                         <option value="03">03</option>
                                                         <option value="04">04</option>
                                                         <option value="05">05</option>
                                                         <option value="06">06</option>
                                                         <option value="07">07</option>
                                                         <option value="08">08</option>
                                                         <option value="09">09</option>
                                                         <option value="10">10</option>
                                                         <option value="11">11</option>
                                                         <option value="12">12</option>
                                                      </select>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-4 pr-0 pl-0">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN false undefined">
                                             <label className="input-field--label">Exp Year</label>
                                             <div className="input-field--input-wrapper">
                                                <div className="StripeElement StripeElement--empty">
                                                   <div className="__PrivateStripeElement"
                                                      style={{
                                                         margin: "0px !important", padding: "0px !important", border: "none !important",
                                                         display: "block !important", background: "transparent !important", position: "relative !important",
                                                         opacity: "1 !important"
                                                      }}>
                                                      <select className="my-select" type="select" name="expiryYear" value={this.state.expiryYear} onChange={this.onChange}>
                                                         <option value="">YY</option>
                                                         {
                                                            this.state.years.length > 0 ?
                                                               this.state.years.map((val, key) => {
                                                                  return (
                                                                     <option key={key} value={val}>{val}</option>
                                                                  )
                                                               }) : <option value="">No Year Available</option>
                                                         }
                                                      </select>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="input-field--wrapper">
                                          <div className="input-field--MAIN false undefined">
                                             <label className="input-field--label">CVV <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Your CVV code is 3-digit number on the back of your card."></i></label>

                                             <ReactTooltip />

                                             <div className="input-field--input-wrapper">
                                                <div className="StripeElement StripeElement--empty">
                                                   <div className="__PrivateStripeElement"
                                                      style={{
                                                         margin: "0px !important", padding: "0px !important", border: "none !important",
                                                         display: "block !important", background: "transparent !important", position: "relative !important",
                                                         opacity: "1 !important"
                                                      }}>
                                                      <input
                                                         className="__PrivateStripeElement-input"
                                                         aria-hidden="true"
                                                         aria-label=""
                                                         type="text"
                                                         // max="9999"
                                                         // pattern="\d*"
                                                         // pattern="[0-9]+"
                                                         maxLength="3"
                                                         min="0"
                                                         name="CVV"
                                                         value={this.state.CVV}
                                                         onChange={this.onChange}
                                                      // autoComplete="false"  pattern="\d*" maxLength="4" style={{
                                                      //    border: "none !important", display: "block !important",
                                                      //    position: "absolute !important", height: "1px !important", top: "0px !important", left: "0px !important",
                                                      //    padding: "0px !important", margin: "0px !important", width: "100% !important", opacity: "0 !important",
                                                      //    background: "transparent !important", pointerEvents: "none !important", fontSize: "16px !important"
                                                      // }} 
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </section>
                              <div className="btn-paypal-holder">
                                 <div className="or-divider text-center">
                                    <span className="d-inline-block align-top">OR</span>
                                 </div>
                                 <h3>Pay With Paypal</h3>
                                 <button className="btn-paypal" disabled={this.state.paypalClicked} type="button" onClick={(e) => this.onSubmitPaypal(e)} >
                                    <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" alt="Check out with PayPal" />
                                 </button>
                                 {/* <div className="or-divider text-center">
                                    <span className="d-inline-block align-top">OR</span>
                                 </div>
                                 <h3>Pay With Mollie</h3>
                                 <button className="btn btn-pink" disabled={this.state.isMollieClicked} type="button" onClick={(e) => this.payWithMollie(e)}>
                                    Pay with Mollie
                                 </button> */}
                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="subscription-payment--right-panel">
                              <div>
                                 <h3>{packageDetail.type}</h3>
                                 <p className="subscription-payment--description">{packageDetail.description}</p>
                                 <h4>What's included in <span className="text-capitalize">{packageDetail.type}</span>?</h4>
                                 <ul className="subscription-payment--tier-features">
                                    {
                                       packageDetail.customDomains ?
                                          <li><span className="checkmark-icon text-capitalize" alt="checkmark-icon"><i className="fa fa-check"></i> {packageDetail.customDomains}</span>Custom Domains</li>
                                          : ''
                                    }
                                    {
                                       packageDetail.linksPerMonth ?
                                          <li><span className="checkmark-icon text-capitalize" alt="checkmark-icon"><i className="fa fa-check"></i> {packageDetail.linksPerMonth}</span>Links</li>
                                          : ''
                                    }
                                    {
                                       packageDetail.brandedLinkLimit ?
                                          <li><span className="checkmark-icon text-capitalize" alt="checkmark-icon"><i className="fa fa-check"></i> {packageDetail.brandedLinkLimit}</span>Custom Links</li>
                                          : ''
                                    }
                                 </ul>
                              </div>
                              <div>
                                 {
                                    billingMethod === '1' ?
                                       <div>
                                          <p className="subscription-payment--price">Billed Now: ${annualPayment}</p>
                                       </div>
                                       :
                                       <div>
                                          <p className="subscription-payment--price">Billed Now: ${monthlyPayment}</p>
                                          <p className="subscription-payment--price-msg">You'll be charged <strong>${monthlyPayment}</strong> monthly until you cancel your subscription.</p>
                                       </div>
                                 }
                                 <div className="subscription-payment--legal">
                                    <div className="subscription-payment--legal-checkbox ">
                                       <div className="checkbox--ALTERNATE" id="legal">
                                          <input id="legal" type="checkbox" className="checkbox--input" checked={this.state.isChecked} onChange={this.toggleChange} />
                                          <label tabIndex="7" htmlFor="legal" className="checkmark-icon checkbox-icon"></label>
                                       </div>
                                       <p>By clicking here, you agree to the FLO.ink
                                          <Link to='/resources/terms-of-services' target="_blank" > Terms of Service</Link>, <Link to='/resources/privacy' target="_blank" >Privacy Policy</Link> . If you do not agree to these properties, you may not access the FLO.ink paid service offerings.</p>
                                    </div>
                                 </div>

                                 {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                                    <span className="alert alert-danger">
                                       {this.state.errors}
                                    </span> : ''}
                                 {/* {this.state.isClicked ?
                                    <div className="complete-purchase-loader">
                                       <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                    </div>
                                    :
                                    ""
                                 } */}
                                 {
                                    this.state.isChecked ?
                                       <button disabled={this.state.isClicked} className="button--SOLID-SECONDARY my-4 complete-purchase-btn" type="button" onClick={this.completPurchase} tabIndex="11" id="">
                                          {this.state.isClicked ?
                                             <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                             :
                                             ""}
                                          <span className="purchase-btn-text">Complete Purchase</span>
                                       </button>
                                       :
                                       <button disabled={true} className="button--SOLID-SECONDARY my-4 complete-purchase-btn" type="button" onClick={this.completPurchase} tabIndex="11" id="">
                                          <span className="purchase-btn-text">Complete Purchase</span>
                                       </button>

                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </React.Fragment>
      );
   }
}

export default UpgradePackage