import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getSiteSetting, beforeSiteSettings } from '../../shared/site-settings/site-settings.action';

// import Swal from 'sweetalert2';
import swal from 'sweetalert';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      errors: "",
      redirect: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const token = this.props.match.params.resetoken;
    if (token) {
      const { confirmpassword, password } = this.state;
      e.preventDefault();

      //const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (password === "") {
        this.setState({ errors: "Password is Required" });
        return;
      } else if (confirmpassword === "") {
        this.setState({ errors: "Confirm Password is Required" });
        return;
      } else if (password !== confirmpassword) {
        this.setState({ errors: "Password not match" });
        return;
      } else {
        this.setState({ errors: "" });
        fetch(window.APIURL + "accounts/member/reset-password", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            password: password,
            resetToken: token
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 400) {
              console.log(res);
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              swal('Success', result.message, 'success')
              this.setState({ redirect: true });
            } else if (result.status === false) {
              swal('Error', result.message, 'error')
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      }
    } else {
      this.setState({ errors: "Error" });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount = () => {
    this.props.getSiteSetting();
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/userLogin" />;
    }

    let settings;

    if (this.props.settings.siteSettingsAuth) {
      settings = this.props.settings.siteSettingsRes.site;
    }

    return (
      <React.Fragment>
        <div style={{height:'100vh',display:'flex', padding:'25px 0', overflowY:'auto'}}>
          <div className="container-box">
          <h1 className="header-logo">
            <Link to="/" rel="nofollow">
              {
                settings ?
                  <img src={settings.siteLogo}
                    alt="Site Logo"
                  /> : ''
              }
            </Link>
          </h1>
          <div className="header">
            <h3 className="tagline">Reset Password?</h3>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="susi-fields-wrapper">
              <fieldset>
                <label className="gray-link" htmlFor="password">
                  Password:
                </label>
                <div className="authFormFieldContainer">
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    id="password"
                    name="password"
                    size="30"
                    className="text"
                  />
                </div>
                <label className="gray-link" htmlFor="confirmpassword">
                  Confirm Password:
                </label>
                <div className="authFormFieldContainer">
                  <input
                    type="password"
                    value={this.state.confirmpassword}
                    onChange={this.handleChange}
                    id="confirmpassword"
                    name="confirmpassword"
                    size="30"
                    className="text"
                  />
                </div>
                {Object.keys(this.state.errors).length > 0 &&
                  this.state.errors ? (
                    <div className="alert alert-danger">{this.state.errors}</div>
                  ) : (
                    ""
                  )}
                <input
                  type="submit"
                  id="password_reset_button"
                  className="button button-primary submit"
                  name="submit"
                  value="Reset"
                />
              </fieldset>
            </div>
          </form>
          <p className="separator t-center">
            <span>Or</span>
          </p>
          <div className="text-center">
            <div className="switch to-sign-up">
              <span className="gray-link">Back To</span>{" "}
              <Link to="/userLogin">Sign In</Link>
            </div>
          </div>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getSiteSetting, beforeSiteSettings
};

const mapStateToProps = state => ({
  settings: state.settings,
  header: state.header,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResetPassword));
