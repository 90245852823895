import React, { Component } from "react";
import { Tabs, Tab } from 'react-bootstrap';
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/core';
import { scrollTop } from '../../shared/common-functions';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip'
import $ from 'jquery';

const override = css`
	display: block;
	position:absolute;
	left:50%;
	top:50%;
	margin:-60px 0 0 -60px;
	border-color: blue;
	`;


class Pricing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			packageType: '',
			allPackages: [],
			monthlyPackages: [],
			annualPackages: [],
			userID: '',
			redirect: false,
			mainLoader: true,
			userCheck: false,
			eventKey: '' // tab
		};
	}

	componentWillMount() {
		let user = JSON.parse(localStorage.getItem('LoginSession'));
		if (user && user !== undefined) {
			this.props.history.push("/organization/subscriptions/" + user.data._id);
		}
	}

	componentDidMount() {
		$("body").on('click', '.more-less-btn span', function () {
			$(this).parents('.tier-summary-panel--body').toggleClass('active');
		});
		scrollTop();
		this.getPlannDetails();
	}

	getPlannDetails = () => {
		fetch(window.APIURL + 'subscription-package/all', {
			method: 'get',
			headers: { 'Content-Type': 'application/json' }
		}).then((res) => {
			if (res.status === 200) {
				return res.json();
			} else if (res.status === 400) {
				return res.json();
			} else if (res.status === 422) {
				return res.json();
			} else {
				return res.json();
			}
		}).then((result) => {
			if (result.status === true) {
				let data = result.subscriptionPackages;
				let monthlyPackages = data.filter((elem) => { return elem.isMonthly });
				let annualPackages = data.filter((elem) => { return elem.isAnnual });
				let allPackages = monthlyPackages; // assigning monthly packages by default

				this.setState({ allPackages, monthlyPackages, annualPackages, mainLoader: false });
			} else if (result.status === false) {
				swal('Error', result.message, 'error')
			} else {
				swal('Error', 'Contact Admin', 'error')
			}
		});
	}

	toBilling = (id, type) => {
		this.props.history.push('/userRegister');
	}

	contactUs = () => {
		this.props.history.push('/contact-us');
	}

	toggleMobileDescription = (e) => {
		let parentP = $(e.target).hasClass("tier-comparison-mobile--section-title") ? $(e.target) : $(e.target).parents('.tier-comparison-mobile--section-title');
		if (!parentP.next().is(":visible")) {
			$(".mobile-tier-section-url").hide();
			parentP.next().show();
			parentP.find(".selector-icon").removeClass("fa-chevron-right");
			parentP.find(".selector-icon").addClass("fa-chevron-down");
		}
		else {
			$(".mobile-tier-section-url").hide();
			$(".selector-icon").removeClass("fa-chevron-down");
			$(".selector-icon").addClass("fa-chevron-right");
		}
	}

	changeTab(e) {
		let eventKey = e.target.innerHTML;

		let { annualPackages, monthlyPackages } = this.state;

		eventKey === 'Pay Monthly' ? this.setState({ eventKey: 'monthlyPackages', allPackages: monthlyPackages }) : this.setState({ eventKey: 'annualPackages', allPackages: annualPackages });
	}

	render() {
		return (
			<React.Fragment>
				<div className="staticPageHeader text-white">
					<div className="container">
						<h1>Pricing</h1>
					</div>
				</div>
				{
					this.state.mainLoader === true ?
						<div className="main-loader" >
							<CircleLoader
								css={override}
								sizeUnit={"px"}
								size={120}
								color={'#007bff'}
								loading={this.state.mainLoader}
							/>
						</div>
						:
						<div className="main-content">
							<div className="container">
								<div className="subscription-pricing--layout">
									<div className="subscription-pricing--tiers">
										<div className="sectionHeadingBlock text-center">
											<h2>Choose a plan</h2>
											<small className="d-block">FLO.ink’s services come with competitive price to quality ratio. We do our best to deliver you cost-efficient branded link management solutions. Pick the best suitable pricing plan for your business needs.</small>
										</div>
										<div className="tab-content" id="pills-tabContent">
											<Tabs defaultActiveKey="payMonthly" transition={false} id="uncontrolled-tab-example" onClick={(e) => this.changeTab(e)}>
												<Tab eventKey="payMonthly" title="Pay Monthly">
													{/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"> */}
													<div className="subscription-pricing-panel--row row">
														{
															this.state.monthlyPackages.length > 0 ?
																this.state.monthlyPackages.map((data, index) => {
																	return (
																		<div className="subscription-pricing-summary--panel subscription-5-panel col-lg-4 col-sm-6 col-12">
																			<div className="tier-summary--panel">
																				<div className="tier-summary-panel--header text-center">
																					<p className="tier-summary--title">
																						<span className="text-uppercase">{data.type}</span>
																					</p>
																					{
																						data.isEnterprise ?
																							<p className="tier-summary--price">
																								<span className="packag-entprz">$$$</span><span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
																							</p>
																							:
																							<p className="tier-summary--price">
																								${data.monthlyPrice ? data.monthlyPrice : 0}<span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
																							</p>
																					}
																					{
																						data.isEnterprise ?
																							<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.contactUs()}>Contact Us</button>
																							:
																							<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.toBilling(data._id, data.type)}>Get started</button>
																					}
																				</div>
																				<div className="tier-summary-panel--body">
																					<p className="tier-summary--description">{data.description}</p>
																					<div className="short-details-holder">
																						<ul className="tier-summary-consumables--list text-center">
																							{
																								!data.customDomain ?
																									''
																									:
																									<li>
																										<strong className="feature-value d-block text-capitalize">{data.customDomains}</strong>
																										<span className="feature-name d-block text-uppercase">Custom Domains</span>
																									</li>
																							}
																							<li>
																								<strong className="feature-value d-block text-capitalize">{data.linksPerMonth}</strong>
																								<span className="feature-name d-block text-uppercase">Links</span>
																							</li>
																							{
																								!data.customBackHalfLimit ?
																									''
																									:
																									<li>
																										<strong className="feature-value d-block text-capitalize">{data.customBackHalfLimit}</strong>
																										<span className="feature-name d-block text-uppercase">Custom Back-Half Links</span>
																									</li>
																							}

																						</ul>
																						{/* <ul className="singlePackageDetails list-unstyled">
													<li className="section--title"><strong>Link Management</strong></li>
													<li><i className="fa fa-check"></i>1000 Links per month</li>
													<li><i className="fa fa-check"></i>Tags per month</li>
													<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
													<li><i className="fa fa-check"></i>Link Filtering</li>
													
													<li><i className="fa fa-check"></i>1000 Links per month</li>
													<li><i className="fa fa-check"></i>Tags per month</li>
													<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
													<li><i className="fa fa-check"></i>Link Filtering</li>
													<li><i className="fa fa-check"></i>1000 Links per month</li>
													<li><i className="fa fa-check"></i>Tags per month</li>
													<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
													<li><i className="fa fa-check"></i>Link Filtering</li>
													<li><i className="fa fa-check"></i>1000 Links per month</li>
													<li><i className="fa fa-check"></i>Tags per month</li>
													<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
													<li><i className="fa fa-check"></i>Link Filtering</li>
													<li><i className="fa fa-check"></i>1000 Links per month</li>
													<li><i className="fa fa-check"></i>Tags per month</li>
													<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
													<li><i className="fa fa-check"></i>Link Filtering</li>
												</ul>
												<span className="more-less-btn">
													<span className="show-more-text cur-poi">Show More</span>
													<span className="show-less-text cur-poi">Show Less</span>
												</span> */}
																					</div>
																					{/* {
												data.isEnterprise ?
												<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.contactUs()}>Contact Us</button>
												:
												<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.toBilling(data._id, data.type)}>Get started</button>
											} */}
																				</div>
																			</div>
																			{data.isPopular === true ?
																				<div className="elementor-price-table__ribbon popular">
																					<div className="elementor-price-table__ribbon-inner">Popular</div>
																				</div>
																				:
																				""
																			}
																		</div>
																	)
																}) : <option value="">No Record Found</option>
														}
													</div>
													{/* </div> */}
												</Tab>
												<Tab eventKey="payAnnualy" title="Pay Annually">
													{/* <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"> */}
													<div className="subscription-pricing-panel--row row">
														{
															this.state.annualPackages.length > 0 ?
																this.state.annualPackages.map((data, index) => {
																	return (
																		<div className="subscription-pricing-summary--panel subscription-5-panel col-lg-4 col-sm-6 col-12">
																			<div className="tier-summary--panel">
																				<div className="tier-summary-panel--header text-center">
																					<p className="tier-summary--title">
																						<span className="text-uppercase">{data.type}</span>
																					</p>
																					{
																						parseInt(data.savingRatio) ?
																							<span className="savedPrcntValue d-block">{"Save " + data.savingRatio + " %"}</span>
																							:
																							""
																					}
																					{
																						data.isEnterprise ?
																							<p className="tier-summary--price">
																								<span className="packag-entprz">$$$</span><span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
																							</p>
																							:
																							<p className="tier-summary--price">
																								{
																									parseInt(data.savingRatio) ?
																										<del className="withOutDscntPrice">{" $" + data.price}</del>
																										: ''
																								}
														${data.annualPrice ? data.annualPrice : 0}<span className="tier-summary-month-descriptor">&nbsp;/mo&nbsp;</span>
																							</p>
																					}
																					{/* {
													parseInt(data.savingRatio) ?
													<p className="">{"Save " + data.savingRatio + " %"}<del>{" $" + data.price}</del></p>
													: <p className="">&nbsp;</p>
												} */}
																					{
																						data.isEnterprise ?
																							<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.contactUs()}>Contact Us</button>
																							:
																							<button className="button--SOLID-TERTIARY" tabindex="0" onClick={() => this.toBilling(data._id, data.type)}>Get started</button>
																					}
																				</div>
																				<div className="tier-summary-panel--body">
																					<p className="tier-summary--description">{data.description}</p>
																					<div className="short-details-holder">
																						<ul className="tier-summary-consumables--list text-center">
																							{
																								data.customDomains === '' ?
																									''
																									:
																									<li>
																										<strong className="feature-value d-block text-capitalize">{data.customDomains}</strong>
																										<span className="feature-name d-block text-uppercase">Custom Domains</span>
																									</li>
																							}
																							<li className="text-capitalize">
																								<strong className="feature-value d-block text-capitalize">{data.linksPerMonth}</strong>
																								<span className="feature-name d-block text-uppercase">Links</span>
																							</li>
																							{
																								data.customBackHalfLimit === '' ?
																									''
																									:
																									<li className="text-capitalize">
																										<strong className="feature-value d-block text-capitalize">{data.customBackHalfLimit}</strong>
																										<span className="feature-name d-block text-uppercase">Custom Links</span>
																									</li>
																							}
																						</ul>
																						{/* <ul className="singlePackageDetails list-unstyled">
														<li className="section--title"><strong>Link Management</strong></li>
														<li><i className="fa fa-check"></i>1000 Links per month</li>
														<li><i className="fa fa-check"></i>Tags per month</li>
														<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
														<li><i className="fa fa-check"></i>Link Filtering</li>
														
														<li><i className="fa fa-check"></i>1000 Links per month</li>
														<li><i className="fa fa-check"></i>Tags per month</li>
														<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
														<li><i className="fa fa-check"></i>Link Filtering</li>
														<li><i className="fa fa-check"></i>1000 Links per month</li>
														<li><i className="fa fa-check"></i>Tags per month</li>
														<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
														<li><i className="fa fa-check"></i>Link Filtering</li>
														<li><i className="fa fa-check"></i>1000 Links per month</li>
														<li><i className="fa fa-check"></i>Tags per month</li>
														<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
														<li><i className="fa fa-check"></i>Link Filtering</li>
														<li><i className="fa fa-check"></i>1000 Links per month</li>
														<li><i className="fa fa-check"></i>Tags per month</li>
														<li><i className="fa fa-check"></i>5 Bulk Links Tagging</li>
														<li><i className="fa fa-check"></i>Link Filtering</li>
													</ul>
													<span className="more-less-btn">
														<span className="show-more-text cur-poi">Show More</span>
														<span className="show-less-text cur-poi">Show Less</span>
													</span> */}
																					</div>
																				</div>
																			</div>
																			{data.isPopular === true ?
																				<div className="elementor-price-table__ribbon popular">
																					<div className="elementor-price-table__ribbon-inner">Popular</div>
																				</div>
																				:
																				""

																			}
																		</div>
																	)
																}) : <option value="">No Record Found</option>
														}
													</div>
													{/* </div> */}
												</Tab>
											</Tabs>
											<header className="subscription-title--container">
												<h1 className="subscription-title--title"></h1>
												<p className="subscription-title--blurb">Detailed Comparison</p>
											</header>
											<ReactTooltip />

											<div className="subscription-pricing--hidden-mobile floink-pricing-block">
												<div className="subscription-pricing-comparison-matrix floink-pricing-row">
													<div className="subscription-pricing-comparison-label-column floink-pricing-col">
														<ul>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Link Management</strong></li>
																<div>
																	<li>Links per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Number of links you can shorten in one month through your FLO.ink account."></i></li>
																</div>
																<div>
																	<li>Tags <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create tags to group and categorize your shortened links."></i></li>
																</div>
																<div>
																	<li>Bulk Link Tagging <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Add tags to your links in bulk."></i></li>
																</div>
																<div>
																	<li>Link Filtering <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Separate the links you want to view based on type, tags and date, etc."></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Custom Links</strong></li>
																<div>
																	<li>Branded Link Limit per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create thousands of branded links every month based on your subscription plan."></i></li>
																</div>
																<div>
																	<li>Custom Back-Half Limit per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create unlimited custom-back half links every month based on your subscription plan."></i></li>
																</div>
																<div>
																	<li>Custom Link History <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View and manage the history of your custom-back half links for a specific time period."></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Branding</strong></li>
																<div>
																	<li>Custom Domains <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Dedicated domains tailored to your branding needs that help you build and optimize your branded links."></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Campaign Management</strong></li>
																<div>
																	<li>Campaigns <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Group and manage your links in bulk with easily trackable analytics."></i></li>
																</div>
																<div>
																	<li>Campaign Tracking <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Track and monitor your campaign performance in real-time."></i></li>
																</div>
																<div>
																	<li>Export of Campaign Data <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Export your campaigns data for offline use."></i></li>
																</div>
																<div>
																	<li>Social Posting <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Connect your FLO.ink account to your social media accounts (facebook, twitter, etc.) and post your links directly from FLO.ink."></i></li>
																</div>
																<div>
																	<li>Social Platforms Connectivity <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Connect your FLO.ink account to your social media accounts (facebook, twitter, etc.)"></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Data Analytics</strong></li>
																<div>
																	<li>Link History <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View and manage the links history for a specific time period."></i></li>
																</div>
																<div>
																	<li>Dashboard View <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View all your shortened links analytics and associated campaigns metrics."></i></li>
																</div>
																<div>
																	<li>Data Export <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Download your data (total clicks, top referrers, organic shares, locations, etc.) out of your FLO.ink account."></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Data Delivery</strong></li>
																<div>
																	<li>API <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Our open API allows you to share your data between FLO.ink and other apps you use."></i></li>
																</div>
																<div>
																	<li>Integrations <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Integrate FLO.ink with your web/mobile application to shorten your links more quickly."></i></li>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>Customer Success</strong></li>
																<div>
																	<div>
																		<li>Account Management <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Don’t worry about the account management, when you have support from our experts."></i></li>
																	</div>
																	<div>
																		<li>Onboarding and Setup <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Get your account up and running with the support of our experts."></i></li>
																	</div>
																	<div>
																		<li>Email Support <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Need help? We are here! Connect with our dedicated team for assistance."></i></li>
																	</div>
																</div>
															</div>
															<div className="tier-comparison-label--section">
																<li className="section--title"><strong>QR Codes</strong></li>
																<div>
																	<div>
																		<li>Generate QR Codes <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Automatically generate QR codes for your shortened links."></i></li>
																	</div>
																</div>
															</div>
														</ul>
													</div>
													{
														this.state.allPackages.length > 0 ?
															this.state.allPackages.map((data, index) => {
																return (
																	<div className="subscription-pricing-comparison-column subscription-pricing-col">
																		<ul>
																			<li className="tier-comparison-column--title"><strong>{data.type}</strong></li>
																			<div className="tier-comparison-column--section">
																				<div>
																					<li><span className="text-capitalize"><strong>{data.linksPerMonth}</strong>&nbsp;</span></li>
																				</div>
																				<div>
																					{
																						data.tags === true ?
																							<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																							:
																							<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{
																						data.bulkLinkTagging ?
																							<li>
																								<span className="tier-comparison-value text-capitalize"><strong>{data.bulkLinkTagging}</strong>&nbsp;</span>
																							</li>
																							:
																							<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{
																						data.linkFiltering === true ?
																							<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																							:
																							<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{
																						!data.brandedLinkLimit ?
																							<li><span className="tier-comparison-column--emdash">—</span></li>
																							:
																							<li><span><strong>{data.brandedLinkLimit}</strong>&nbsp;</span></li>
																					}
																				</div>
																				<div>
																					{!data.customBackHalfLimit ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span className="text-capitalize"><strong>{data.customBackHalfLimit}</strong>&nbsp;</span></li>
																					}
																				</div>
																				<div>
																					{!data.customLinkHistory ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.customLinkHistory}</strong>&nbsp;</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{!data.customDomains ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.customDomains}</strong>&nbsp;</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{data.campaigns === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.campaignsTracking === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.campaignDataExport === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.socialPosting === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{!data.socialPlatforms ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.socialPlatforms}</strong>&nbsp;</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{data.linkHistory === "" ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.linkHistory}</strong>&nbsp;</span></li>
																					}
																				</div>
																				<div>
																					{data.dashboardView === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.analyticsDataExport === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{data.API === "" ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.API}</strong>&nbsp;</span></li>
																					}
																				</div>
																				<div>
																					{data.integrations === "" ?
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																						:
																						<li><span><strong>{data.integrations}</strong>&nbsp;</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{data.accountManagement === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.onboardingAndSetup === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																				<div>
																					{data.emailSupport === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																			</div>
																			<div className="tier-comparison-column--section">
																				<div>
																					{data.qrCodeGenerate === true ?
																						<li><span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span></li>
																						:
																						<li><span className="tier-comparison-column--emdash">—</span></li>
																					}
																				</div>
																			</div>
																		</ul>
																		<div className="sticky-upgrade-button--column">
																			{
																				data.isEnterprise ?
																					<div className="sticky-upgrade-button--column text-center">
																						<button className="button--SOLID-TERTIARY" onClick={() => this.contactUs()}>Contact Us</button>
																					</div>
																					:
																					<span>
																						{
																							this.state.eventKey === 'monthlyPackages' ?
																								data._id === this.state.planType && this.state.billingMethod === 2 ?
																									<div className="sticky-upgrade-button--column"><span className="current-plan--text"><strong>YOUR CURRENT PLAN</strong></span></div>
																									:
																									<div className="sticky-upgrade-button--column text-center">
																										{
																											parseInt(data.annualPrice) !== 0 ?
																											<button className="button--SOLID-TERTIARY" onClick={() => this.toBilling(data._id, data.type)}>SUBSCRIBE </button>
																												// <Link className="button--SOLID-TERTIARY" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>SUBSCRIBE </Link>
																												:
																												<button className="button--SOLID-TERTIARY" onClick={() => this.downgradeToFree(data.type.toLowerCase(), data._id)}>SUBSCRIBE </button>
																										}
																									</div>
																								: ''
																						}
																						{
																							this.state.eventKey === 'annualPackages' || this.state.eventKey === '' ?
																								data._id === this.state.planType && this.state.billingMethod === 1 ?
																									<div className="sticky-upgrade-button--column"><span className="current-plan--text"><strong>YOUR CURRENT PLAN</strong></span></div>
																									:
																									<div className="sticky-upgrade-button--column text-center">
																										{
																											parseInt(data.annualPrice) !== 0 ?
																												<button className="button--SOLID-TERTIARY" onClick={() => this.toBilling(data._id, data.type)}>SUBSCRIBE </button>
																												// <Link className="button--SOLID-TERTIARY" to={"/organization/subscription?subscriptionId=" + data._id + "&type=" + this.state.annualyBilling}>SUBSCRIBE </Link>
																												:
																												<button className="button--SOLID-TERTIARY" onClick={() => this.downgradeToFree(data.type.toLowerCase(), data._id)}>SUBSCRIBE </button>
																										}
																									</div>
																								: ''
																						}
																					</span>
																			}
																		</div>
																	</div>
																)
															}) : <option value="">No Record Found</option>
													}
												</div>
											</div>
											<div className="subscription-pricing--hidden-desktop">
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Link Management&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Links per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Number of links you can shorten in one month through your FLO.ink account."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		<span className="tier-comparison-value text-capitalize"><strong>{pack.linksPerMonth}</strong>&nbsp;</span>
																	</span>
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Tags <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create tags to group and categorize your shortened links."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.tags ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
											</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Bulk Link Tagging <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Add tags to your links in bulk."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.bulkLinkTagging ?
																			<span>
																				<span className="tier-comparison-value text-capitalize"><strong>{pack.bulkLinkTagging}</strong>&nbsp;</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
											</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Link Filtering <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Separate the links you want to view based on type, tags and date, etc."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.linkFiltering ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
											</span>
																			</span>
																	}
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Custom Links&nbsp;</strong></span><span className="selector-icon-expanded tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Branded Link Limit per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create thousands of branded links every month based on your subscription plan."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.brandedLinkLimit) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.brandedLinkLimit}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Custom Back-Half Limit per month <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Create unlimited custom-back half links every month based on your subscription plan."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.customBackHalfLimit) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.customBackHalfLimit}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Custom Link History <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View and manage the history of your custom-back half links for a specific time period."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.customLinkHistory) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.customLinkHistory}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Branding&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Custom Domains <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Dedicated domains tailored to your branding needs that help you build and optimize your branded links."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.customDomains) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.customDomains}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Campaign Management&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Campaigns <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Group and manage your links in bulk with easily trackable analytics."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.campaigns ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Campaigns Tracking <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Track and monitor your campaign performance in real-time."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.campaignsTracking ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Export of Campaign Data <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Export your campaigns data for offline use."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.campaignDataExport ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Social Posting <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Connect your FLO.ink account to your social media accounts (facebook, twitter, etc.) and post your links directly from FLO.ink."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.socialPosting ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Social Platforms Connectivity <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Connect your FLO.ink account to your social media accounts (facebook, twitter, etc.)"></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.socialPlatforms) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.socialPlatforms}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Data Analytics&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Link History <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View and manage the links history for a specific time period."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.linkHistory) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.linkHistory}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Dashboard View <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="View all your shortened links analytics and associated campaigns metrics."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.dashboardView ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Data Export <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Download your data (total clicks, top referrers, organic shares, locations, etc.) out of your FLO.ink account."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.analyticsDataExport ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Data Delivery&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>API <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Our open API allows you to share your data between FLO.ink and other apps you use."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.API) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.API}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Integrations <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Integrate FLO.ink with your web/mobile application to shorten your links more quickly."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">{pack.type}</span>
																	<span>
																		{
																			parseInt(pack.integrations) > 0 ?
																				<span className="tier-comparison-value"><strong>{pack.integrations}</strong>&nbsp;</span>
																				:
																				<span><span className="tier-comparison-value tier-comparison-column--emdash">—</span></span>
																		}
																	</span>
																</li>)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>Customer Success&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Account Management <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Don’t worry about the account management, when you have support from our experts."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (
																	<li className="tier-comparison-mobile--item-detail">
																		<span className="tier-comparison-mobile--item-label text-capitalize">
																			{pack.type}
																		</span>
																		{
																			pack.accountManagement ?
																				<span>
																					<span className="tier-comparison-value">
																						<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																					</span>
																				</span>
																				:
																				<span>
																					<span className="tier-comparison-value tier-comparison-column--emdash">
																						—
																					</span>
																				</span>
																		}
																	</li>
																)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Onboarding And Setup <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Get your account up and running with the support of our experts."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (
																	<li className="tier-comparison-mobile--item-detail">
																		<span className="tier-comparison-mobile--item-label text-capitalize">
																			{pack.type}
																		</span>
																		{
																			pack.onboardingAndSetup ?
																				<span>
																					<span className="tier-comparison-value">
																						<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																					</span>
																				</span>
																				:
																				<span>
																					<span className="tier-comparison-value tier-comparison-column--emdash">
																						—
																					</span>
																				</span>
																		}
																	</li>
																)
															})
														}
														<li className="tier-comparison-mobile--item-title"><strong>Email Support <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Need help? We are here! Connect with our dedicated team for assistance."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (
																	<li className="tier-comparison-mobile--item-detail">
																		<span className="tier-comparison-mobile--item-label text-capitalize">
																			{pack.type}
																		</span>
																		{
																			pack.emailSupport ?
																				<span>
																					<span className="tier-comparison-value">
																						<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																					</span>
																				</span>
																				:
																				<span>
																					<span className="tier-comparison-value tier-comparison-column--emdash">
																						—
																					</span>
																				</span>
																		}
																	</li>
																)
															})
														}
													</ul>
												</div>
												<div className="tier-comparison-mobile">
													<p className="tier-comparison-mobile--section-title" onClick={(e) => this.toggleMobileDescription(e)}><span><strong>QR Codes&nbsp;</strong></span><span className="tier-comparison-mobile--icon"><strong><span className="selector-icon fas fa-chevron-right" alt="selector-icon"></span></strong></span></p>
													<ul className="tier-comparison-mobile-hide mobile-tier-section-url">
														<li className="tier-comparison-mobile--item-title"><strong>Generate QR Codes <i className="fa fa-question-circle cur-poi" data-effect="float" data-tip="Automatically generate QR codes for your shortened links."></i></strong></li>
														{
															this.state.allPackages.map((pack, index) => {
																return (<li className="tier-comparison-mobile--item-detail">
																	<span className="tier-comparison-mobile--item-label text-capitalize">
																		{pack.type}
																	</span>
																	{
																		pack.qrCodeGenerate ?
																			<span>
																				<span className="tier-comparison-value">
																					<span className="checkmark-icon fa fa-check" alt="checkmark-icon fa fa-check"></span>
																				</span>
																			</span>
																			:
																			<span>
																				<span className="tier-comparison-value tier-comparison-column--emdash">
																					—
																				</span>
																			</span>
																	}
																</li>)
															})
														}
													</ul>
												</div>
											</div>
											<div className="subscription-pricing--disclaimer-text">
												<p>Your purchase is subject to sales tax and will auto renew with each billing period</p>
												<p>*Both domain and back half must be customized to redirect a custom branded link</p>
												{/* <p>**First custom domain registration included with subscription (up to $30)</p> */}
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
				}

				<ReactTooltip />
			</React.Fragment>
		);
	}
}

export default Pricing;
