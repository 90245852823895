import React, { Component } from "react";
import { Link } from "react-router-dom";


class Resources extends Component {
state = {};

render() {
	return (
	<React.Fragment>
		<div className="staticPageHeader text-white">
			<div className="container">
				<h1>Resources</h1>
			</div>
		</div>
		<section className="faqsHolder">
			<div className="container">
				<div className="sectionHeadingBlock text-center">
					<h2>Frequently Asked Questions</h2>
					<small className="d-block">Hello, How Can We Help</small>
				</div>
				<div id="accordionExample" className="faqsBlock accordion">
					<div className="card bgLightBlue">
						<div className="card-header" id="headingOne">
							<h2 className="mb-0">
								<button className="d-block btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
									What is FLO.ink?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>FLO.ink is a URL Shortener with custom domains and link management platform. You can create, share, manage and monitor your link performance.</p>
								<p>Harness the power of fully secured, recognizable branded links to maximize the impact of every digital initiative you need for brand promotion. You can optimize shortened links and measure each touchpoint at one place, making it convenient to meet the needs of your internal teams and customers alike.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingTwo">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
									How to Create Links with FLO.ink?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Let’s create your branded links with FLO.ink!</p>
								<ol className="floin-content-o-points">
									<li>Log into your FLO.ink account and click on ‘Create’ button in the dashboard (top-right corner).</li>
									<li>Paste your long URL into the ‘Enter Long URL’ box and click ‘Create’.</li>
									<li>You can edit your link in the next screen such as title, add custom keyword back-half, copy and share the link. You can even add a link to your promotion campaign.</li>
									<li>All links will be created with "FLO.ink" domain, by default.</li>
								</ol>
								<h6><strong>Notes:</strong></h6>
								<ul className="floin-content-o-points">
									<li>All links will be created with "FLO.ink" domain, by default.</li>
									<li>If you are a paid subscriber you can select the ‘custom domain’ option to brand your shortened.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingThree">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
									Does FLO.ink offer paid subscription plans?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Yes, each FLO.ink’s paid plan includes different features and limitations. For example, the number of links you can shorten in a specific time period (usually a month), or how much data you can view and download.</p>
								<p>Check the subscription plans and features on our <Link to="/pricing">pricing page</Link>. You can upgrade your paid plan any time; from pricing page or your dashboard.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingFour">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
									The FLO.ink Dashboard
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Once registered with FLO.ink, you will have a complete overview of your shortened links, top performing links and campaign activities.</p>
								<p>Access your dashboard to view all key metrics against your branded links, custom domains and promotion campaigns across multiple channels. You can set a date range for custom data analysis, export data and drill down to specific information. The key metrics on displayed in your dashboard includes, total clicks, unique clicks, organic shares, top links, top referrers, and top locations, etc.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingFive">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
									What is a Custom Domain?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
							<p>Custom Domain added to your FLO.ink account helps create short links that include your brand name instead of ‘FLO.ink domain’. When people see your brand name in short links they recognize and trust your links to click. These branded links containing your custom domain results in higher click-through rates.</p>
							<p>Your custom domain in FLO.ink should contain 15 characters or less including dot.</p>
							<p>For example, when eBay shortens a link through FLO.ink, instead of being branded with FLO.ink 's domain (FLO.ink/xRahgtr) the link displays their own custom domain (e.Bay/xRahgtr).</p>
							<p>You can also edit the end of your short link - termed as back half. You can further customize your short link to make it even more recognizable Custom Link; e.g., (e.Bay/iPhone11).</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingSix">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
									What is a Custom Link?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Custom Links or Custom Branded Links includes your custom domain at the start of the short link and custom keywords at the end. It means your short URL does not start with FLO.ink and close with a random string of characters, but easily identifiable brand title with custom back-half.</p>
								<div className="table-responsive">
									<table className="table floink-table">
										<thead>
											<tr>
												<th>Link Type</th>
												<th>Example</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>FLO.ink short link</td>
												<td>http://FLO.ink/hiec4K</td>
											</tr>
											<tr>
												<td>Short link with a custom back-half</td>
												<td>http://FLO.ink/iPhoneX</td>
											</tr>
											<tr>
												<td>Branded Link with a custom domain</td>
												<td>https://e.Bay/xRahgt</td>
											</tr>
											<tr>
												<td>Custom Link with a back-half</td>
												<td>http://e.Bay/iPhoneX</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingSeven">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
									What characters FLO.ink supports for custom links?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>FLO.ink supports a variety of characters for custom links and their back-halves including,</p>
								<ul className="floin-content-o-points">
									<li>Letters A-Z (both upper and lowercase)</li>
									<li>Numbers 0-9</li>
									<li>Dashes (-)</li>
									<li>Underscores ( _ )</li>
									<li>Most Unicode Characters</li>
								</ul>
								<p><strong>Note:</strong> FLO.ink doesn’t support punctuation marks for custom links.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingEight">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
									Can I delete my short link(s)?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>You cannot delete the links. Links can be hidden only within the dashboard.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingNine">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
									How do I hide/unhide my short links?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>You can hide/unhide the short links by selecting the ‘Edit’ button against target link and then click ‘Hide’ and ‘Confirm’ in the FLO.ink section accessible from the drop-down menu.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingTen">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
									Can I download my FLO.ink account data?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Yes, you can download your FLO.ink links and data as a .csv file from the dashboard. The data (total clicks, top referrers, top locations, etc.) export is available for both the Date Created and Top Performing views. </p>
								<h6><strong>Steps to Export:</strong></h6>
								<ol className="floin-content-o-points">
									<li>Apply specific filters such as date range for data you want to export.</li>
									<li>Select ‘Export’ button, next to the date selector located on the top-right of the page.</li>
									<li>Selected email address where you want to receive your .csv export file.</li>
									<li>Download the file by clicking on the link you receive in the email.</li>
								</ol>
								<h6><strong>Notes:</strong></h6>
								<ul className="floin-content-o-points">
									<li>Exports files with high volume of data may take up to 30 minutes for download</li>
									<li>Check your spam/another folder if you haven’t received the .csv export file from FLO.ink</li>
									<li>FLO.ink delivers export files to verified email addresses only – email IDs of signed-in users to ensure data protection</li>
									<li>Export files can be downloaded for up to 30 days from the date requested. Once expired, you need to re-trigger the report following the same steps</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingEleven">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
									What are Organic Shares?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>Organic Shares are any links created outside of your FLO.ink account. It means some third-party has created FLO.ink links with your domain and were not created with your FLO.ink account either by you or your team members.</p>
								<p>For instance, someone shortens a Forbes article using his personal FLO.ink account, that short link will be displayed in the FLO.ink account of Forbes under Organic Shares section.</p>
								<p>You can view Organic Shares in your dashboard.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingtwelve">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsetwelve" aria-expanded="false" aria-controls="collapsetwelve">
									Does FLO.ink ever reuse links?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapsetwelve" className="collapse" aria-labelledby="headingtwelve" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>No. Each link is unique and cannot be reused. Since FLO.ink issues a unique link that cannot be reprocessed or reclaimed, so you can use them confidently since they will always navigate users to the desired destination. </p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingthirteen">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsethirteen" aria-expanded="false" aria-controls="collapsethirteen">
									Does FLO.ink count unique clicks?
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapsethirteen" className="collapse" aria-labelledby="headingthirteen" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>FLO.ink doesn’t segregate unique as well as multiple clicks from the same location. However, you can track your unique audience from your FLO.ink Enterprise account that provides a suite of enhanced analytics and reporting features, which you cannot do otherwise from your free FLO.ink account.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingfourteen">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsefourteen" aria-expanded="false" aria-controls="collapsefourteen">
									How safe is FLO.ink? 
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapsefourteen" className="collapse" aria-labelledby="headingfourteen" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>We take the most advanced security measures to ensure the protection of your FLO.ink account and data with us. All short links are secured with HTTPS encryption. This added layer of security provides top-notch safety besides the government compliance safety regulations.</p>
								<p>Our security team along with our trusted tech partners strive to prevent counterfeit actors from misusing our link management platform, monitor and block the spammers from spreading suspicious links.</p>
								<p>Moreover, we encourage our users to employ strong passwords and two-factor authentication while signing up for FLO.ink account.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingfifteen">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsefifteen" aria-expanded="false" aria-controls="collapsefifteen">
									What is 2-Factor Authentication? 
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapsefifteen" className="collapse" aria-labelledby="headingfifteen" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>2-Factor Authentication (aka 2-step verification) adds an additional security layer to your FLO.ink account. You must enter your password and a one-time security code sent to your mobile every time you log into your account from any device.</p>
								<p>2-Factor Authentication, once enabled, doesn’t allow any unauthorized access to your account unless they have access to your mobile phone.</p>
							</div>
						</div>
					</div>
					<div className="card bgLightBlue">
						<div className="card-header" id="headingsixteen">
							<h2 className="mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsesixteen" aria-expanded="false" aria-controls="collapsesixteen">
									Are FLO.ink links private? 
									<span className="toggleIcon text-center"></span>
								</button>
							</h2>
						</div>
						<div id="collapsesixteen" className="collapse" aria-labelledby="headingsixteen" data-parent="#accordionExample">
							<div className="blankSpacer"></div>
							<div className="card-body">
								<p>All FLO.ink short links are public, but anonymous. It means neither the identity of the user who originally created the links nor the third-party identity who created links to the same content through Organic Shares will be disclosed on the FLO.ink Info Page.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div className="main-content">
			{/* <div className="cms-content">
				<div className="container">
					<h1 className="title">Resources</h1>
				</div>
			</div> */}
			<section className="floink-content-section">
				<div className="container">
					<div className="accordion floink-faqs" id="accordionExample">
						
						
					</div>
				</div>
			</section>
			<section className="before-footer">
				<div className="container">
					<span>Start sharing powerful links</span>
					<Link to="/userRegister" className="btn btn-cw">Start sharing powerful links</Link>
				</div>
			</section>
		</div>
	</React.Fragment>
	);
}
}

export default Resources;
