import React, { Component } from "react";
import ReactTags from "react-tag-autocomplete";
import { Redirect } from "react-router-dom";
// import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const queryString = require("query-string");

class CreateCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignTitle: "",
      description: "",
      suggestions: [],
      tags: [],
      channelCount: 0,
      completeChannelData: "",
      campaignId: 0,
      viewAllChannels: false,
      errors: '',
      logOutRedirect: false,

    };
  }

  channelData = (param) => {
    this.setState({ campaignId: param });
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "channel/user-campaign-channels", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        campaignId: param,
        userId: userId
      })
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          this.getChannelsForCampaign();
          this.setState({
            campaignTitle: result.channels.title,
            description: result.channels.description === undefined ? "" : result.channels.description,
          });
          let temp = [];
          result.channels.channels.forEach(element => {
            temp.push({ name: element.title })
          });
          this.setState({ tags: temp, channelCount: temp.length });
        }
      });
  };

  getChannelsForCampaign = () => {
    let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
    fetch(window.APIURL + "channel/user-channels?userId=" + userId)
      .then(response => response.json())
      .then(result => {
        let channelNames = [];
        result.channels.forEach(element => {
          channelNames.push({ name: element.title });
        });
        this.setState({
          suggestions: channelNames,
          completeChannelData: result.channels
        });
        console.log(this.state.completeChannelData);
      });
  };



  handleDelete = (i) => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
    this.setState({ channelCount: tags.length });
  }

  handleAddition(tag) {
    let objIndex = this.state.tags.findIndex((obj => obj.name == tag.name));
    if (objIndex === -1) {
      const tags = [].concat(this.state.tags, tag);
      this.setState({ tags });
      this.setState({ channelCount: tags.length });
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillMount = () => {
    let hash = window.location.hash;
    if (hash != "" && hash.split("&").length > 0) {
      let splited = hash.split("&");
      let last = splited[splited.length - 1].split("=")[1];
      if (last === "edit_campaign") {
        var parsed = queryString.parse(window.location.search);
        if (parsed.campaign !== undefined && parsed.campaign !== "undefined") {
          let campaign = parsed.campaign;
          this.channelData(campaign);
        }
      }
    }
  };

  componentDidMount = () => {

  }


  campaignForm = e => {
    if (this.state.campaignTitle === "") {
      this.setState({
        errors: "Campaign title is required"
      })
      return;
    }
    else if (this.state.tags.length === 0) {
      this.setState({
        errors: "Atleast one channel is required"
      })
      return;
    }
    else {

      e.preventDefault();
      let idsChannel = [];
      this.state.tags.forEach(element => {
        let obj = this.state.completeChannelData.find(
          o => o.title === element.name
        );
        idsChannel.push(obj._id);
      });

      fetch(window.APIURL + "campaign/edit", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          _id: this.state.campaignId,
          title: this.state.campaignTitle,
          description: this.state.description,
          channels: idsChannel,
          userId: JSON.parse(localStorage.getItem("LoginSession")).data._id
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            let updateData = {
              title: result.result && result.result.title ? result.result.title : "",
              _id: result.result ? result.result._id : undefined,
              channels: result.result ? result.result.channels : undefined
            }
            this.props.reloadDashboard(true, updateData);

            swal('Success', result.message, 'success')
          } else if (result.status === false) {

            if (result.redirection === true) {
              this.setState({ logOutRedirect: true });
              swal('Error', result.message, 'error')
            }

            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  clearStates = () => {
    this.setState({
      campaignTitle: '',
      description: '',
      tags: [],
      suggestions: [],
      errors: '',
      channelCount: 0
    })
  }

  viewAllChannelsFun = () => {
    this.setState({ viewAllChannels: !this.state.viewAllChannels });
  };

  handleClickAddition = (tag) => {
    let temp = { name: tag };
    let objIndex = this.state.tags.findIndex((obj => obj.name == temp.name));
    if (objIndex === -1) {
      const tags = [].concat(this.state.tags, temp);
      this.setState({ tags });
      this.setState({ channelCount: tags.length });
    }
  }


  render() {
    const { logOutRedirect } = this.state;
    if (logOutRedirect) {
      return <Redirect to="/logout" />;
    }
    return (
      <React.Fragment>
        <div
          className="action-sheet--wrapper-MAIN data-state"
          data-state="19"
          style={{ display: "none" }}
          id="edit_campaign"
        >
          <div
            className="action-sheet--MAIN"
            data-selected-sheet="createCampaign"
            style={{
              animation: "600ms ease 0ms 1 normal forwards running open-sheet "
            }}
          >
            <div className="action-sheet--header">
              <h4 className="action-sheet--header-text">Edit Campaign</h4>
              <span>
                <a onClick={this.clearStates}>
                  <span className="close-icon" alt="close-icon" />
                </a>
              </span>
            </div>
            <form
            // onSubmit={this.campaignForm}
            >
              <div className="action-sheet--content-wrapper">
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Title</label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder="Campaign Title"
                        className="input-field--input"
                        autoComplete="false"
                        name="campaignTitle"
                        value={this.state.campaignTitle}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Description</label>
                    <div className="input-field--input-wrapper">
                      <input
                        placeholder="Campaign Description"
                        className="input-field--input"
                        autoComplete="false"
                        name="description"
                        value={this.state.description}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="dynamic-search-list--ALTERNATE">
                  <h5 className="dynamic-search-list--header">
                    SELECT CHANNELS ( {this.state.channelCount} )
                  </h5>
                  <a
                    className="search-view-all"
                    onClick={this.viewAllChannelsFun}
                  >
                    {
                      this.state.viewAllChannels === true ? (
                        <span> Hide All </span>
                      ) : (
                          <span> Show All </span>
                        )
                    }

                  </a>
                  <div className="campaign-tags-selected-list">
                    {
                      this.state.tags &&
                        this.state.tags.length > 0 ?
                        this.state.tags.map((item, index) => (
                          <div className="campaign-tag-item" key={index}>
                            <span className="tag_name">
                              {item.name}
                            </span>
                            <span className="tag_close" onClick={() => this.handleDelete(index)}>
                              <FontAwesomeIcon icon="times" />
                            </span>
                          </div>
                        ))
                        :
                        ""
                    }

                  </div>
                  <div className="search--ALTERNATE   ">
                    <ReactTags
                      placeholder={"Add channel"}
                      tags={this.state.tags}
                      suggestions={this.state.suggestions}
                      handleDelete={this.handleDelete.bind(this)}
                      handleAddition={this.handleAddition.bind(this)}
                    />
                  </div>
                  <div className="dynamic-search-list--filter-pills " />

                </div>

                {this.state.viewAllChannels === true ?
                  this.state.completeChannelData.length > 0 ?
                    <div style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginBottom: "5px"

                    }}>
                      {
                        <div>
                          <span style={{ fontWeight: "bold" }} > All Channels </span><br />
                          {this.state.completeChannelData.map((el, index) => {
                            return (
                              <div key={index} onClick={() => this.handleClickAddition(el.title)} >

                                <span style={{ width: "100%", cursor: "pointer", display: "inline-block" }}>
                                  <FontAwesomeIcon icon="arrow-circle-right" /> {el.title}
                                </span>
                                <br />

                              </div>
                            )
                          })
                          }
                        </div>
                      }
                    </div>
                    :
                    <span>{"No Channels Created"}</span>
                  :
                  ""
                }

                <div className="text-center mt-5">
                  {
                    Object.keys(this.state.errors).length > 0 && this.state.errors ?
                      <div className="alert alert-danger">
                        {this.state.errors}
                      </div> : ''
                  }
                  <button className="button--SOLID-SECONDARY" type="button" onClick={this.campaignForm}>
                    Save Campaign
                </button>
                  {/* <div className="action-sheet--button-wrapper-visible">
                    <button className="button--SOLID-SECONDARY checklist-menu-item" type="button" tabIndex="0" data-id="create_bitlink" >
                      ADD FLO.ink
                    </button>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreateCampaign;
