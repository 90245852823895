import React, { Component } from "react";
import Select from 'react-select';
import { countryCodes } from './data';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import $ from "jquery";

class TwoWayAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCountry: "",
      phoneNumber: "",
      phoneNumberInput: "",
      userId: "",
      selectedOption: null
    };
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption, userCountry: selectedOption.value });
  };

  componentWillReceiveProps = () => {
    this.setState({ userId: this.props.userId, phoneNumber: this.props.phoneNumber });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  phoneNumberForm = e => {
    e.preventDefault();

    let { phoneNumberInput, userCountry } = this.state;
    var reg = new RegExp("^[0-9]+$");

    if (phoneNumberInput === "") {
      swal('Error', 'Phone number is required', 'error');
      return;
    } else if (!phoneNumberInput.match(reg)) {
      swal('Error', 'Invalid phone number, Please use only digits.', 'error');
    } else if (userCountry === "") {
      swal('Error', 'Country is required', 'error');
      return;
    } else {
      fetch(window.APIURL + "accounts/member/send-mobile-token", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          phoneNumber: "+" + userCountry + this.state.phoneNumberInput,
          _id: this.state.userId
        })
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 403) {
            return res.json();
          } else if (res.status === 422) {
            return res.json();
          } else if (res.status === 404) {
            return res.json();
          } else {
            return res.json();
          }
        })
        .then(result => {
          if (result.status === true) {
            $("#security").hide();
            $("#security_phone").show();
          } else if (result.status === false) {
            swal('Error', result.message, 'error')
          } else {
            swal('Error', 'Contact Admin', 'error')
          }
        });
    }
  };

  deleteNumber = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove this number",
      showCancelButton: true,
      // customClass: "conformations",
      confirmButtonColor: '#0a6eb4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        fetch(window.APIURL + "accounts/member/delete-mobile-number", {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            _id: this.state.userId
          })
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            } else if (res.status === 403) {
              return res.json();
            } else if (res.status === 422) {
              return res.json();
            } else if (res.status === 404) {
              return res.json();
            } else {
              return res.json();
            }
          })
          .then(result => {
            if (result.status === true) {
              if (this.props.delNumber) {
                this.props.delNumber("true");
              }
              this.setState({
                phoneNumber: "",
                phoneNumberInput: "",
                codeNumber: ""
              });
              swal('Success', result.message, 'success')
            } else if (result.status === false) {
              swal('Error', result.message, 'error')
            } else {
              swal('Error', 'Contact Admin', 'error')
            }
          });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.phoneNumberForm}>
          <div>
            <div>
              <h5 className="action-sheet--content-header">
                Two-step Verification
              </h5>
              <div className="action-sheet--help-text">
                We'll send you a security code to this phone whenever you sign
                in to your FLO.ink account
              </div>
            </div>

            {this.props.phoneNumber === "" ? (
              <div>
                <div className="select-item--MAIN">
                  <Select
                    placeholder="Select Country Code"
                    className="channelSelectDropdown cur-poi"
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                    options={countryCodes}
                    isOptionDisabled={(option) => option.isdisabled}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? 'grey' : 'hsl(0,0%,20%)',
                        cursor: state.isDisabled ? 'not-allowed' : 'pointer'
                      })
                    }}
                  />
                  <span className="select-item--secondary-label" />
                  <span className="select-item--third-label " />
                  <span className="select-item--fourth-label" />
                  <span className="selector-icon" alt="selector-icon" />
                </div>
                <div className="input-field--wrapper">
                  <div className="input-field--MAIN   ">
                    <label className="input-field--label">Mobile Number</label>
                    <div className="input-field--input-wrapper">
                      <input
                        type="text"
                        className="input-field--input"
                        name="phoneNumberInput"
                        value={this.state.phoneNumberInput}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
                <div>
                  <div className="action-sheet--text">Status: Enabled</div>
                  <div className="action-sheet--text">
                    Mobile: {this.props.phoneNumber}
                  </div>
                  <button
                    type="button"
                    onClick={this.deleteNumber}
                    className="button--GHOST-SECONDARY btn-deleteNumber"
                  >
                    Delete Mobile Number
                </button>
                </div>
              )}
          </div>

          {this.props.phoneNumber === "" ? (
            <div className="action-sheet--ghost-button-wrapper">
              <button
                className="button--GHOST-SECONDARY"
                type="submit"
                tabIndex="0"
                id=""
              >
                Enable
              </button>
            </div>
          ) : (
              ""
            )}
        </form>
      </React.Fragment>
    );
  }
}

export default TwoWayAuth;
