import React, { Component } from "react";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
var CryptoJS = require("crypto-js");

class PaymentSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            session: false
        }
    }

    componentDidMount() {
        var url = this.props.location.search;
        if (localStorage.getItem("loginToken")) {
            this.setState({ session: true })
        }
        if (!url.includes('mollie')) {
            const Data = this.props.match.params.secret;
            var reb64 = CryptoJS.enc.Hex.parse(Data);
            var bytes = reb64.toString(CryptoJS.enc.Base64);
            var decrypt = CryptoJS.AES.decrypt(bytes, "NowThis");
            var decryptedpaymentDetails = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));

            var array = url.split("?");
            var payerIdcollect = array[1].split('token=');
            var paymentIdCollect = payerIdcollect[1].split("&", 1);
            var payerIdCollect = url.split("&PayerID=");
            const token = paymentIdCollect[0];
            const payerId = payerIdCollect[1];

            if (token != '' && payerId != '') {
                let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                let url;
                if (decryptedpaymentDetails.subscriptionPayment == true) {
                    url = window.APIURL + 'payment/paypal/subscription';
                }
                else if (decryptedpaymentDetails.subscriptionPayment == false) {
                    url = window.APIURL + 'custom-domain/paypal/domain-payment';
                }
                fetch(url, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "token": token,
                        "payerId": payerId,
                        "userId": userId,
                        "data": Data,
                        // "firstName":"",
                        // "countryCode":"",
                        // "phoneNumber":"",
                        // "lastName":"",
                        // "email":"",
                        // "city":"",
                        // "zipCode":"",
                        // "paymentIdUser": decryptedpaymentDetails.paymentId,
                        // "packageId": decryptedpaymentDetails.packageId,
                        // "isCustomize": decryptedpaymentDetails.isCustomize,
                        // "description": decryptedpaymentDetails.description,
                        // "finalPrice": decryptedpaymentDetails.finalPrice,
                        // "billingMethod": decryptedpaymentDetails.billingMethod,
                        // "custom": decryptedpaymentDetails.custom,
                        // "packageType": decryptedpaymentDetails.packageType
                    })
                }).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                }).then((result) => {

                    if (result.status === "error") {
                        swal('Error', "Something went wrong try Again Later!", 'error')
                    }
                    if (result.status === "success") {
                        swal('Success', result.message, 'success')
                    }
                    if (result.status === "error") {
                        // this.setState({ logout: true });
                    }
                })
            }
        } else if (url.includes('mollie')) {
            let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;

            const Data = this.props.match.params.secret;
            var reb64 = CryptoJS.enc.Hex.parse(Data);
            var bytes = reb64.toString(CryptoJS.enc.Base64);
            var decrypt = CryptoJS.AES.decrypt(bytes, "NowThis");
            var decryptedpaymentDetails = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));

            debugger
            fetch(window.APIURL + 'payment/mollie/subscription', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "userId": userId,
                    "data": Data,
                })
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 400) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else {
                    return res.json();
                }
            }).then((result) => {

                if (result.status === "error") {
                    swal('Error', "Something went wrong try Again Later!", 'error')
                }
                if (result.status === "success") {
                    swal('Success', result.message, 'success')
                }
                if (result.status === "error") {
                    // this.setState({ logout: true });
                }
            })
        }
    }

    render() {
        return (
            <div className="d-flex text-center paymentMessageParent success">
                <div className="messageBlock">
                    <h2>Success!</h2>
                    <div className="icon-holder">
                        <i className="fas fa-smile"></i>
                    </div>
                    <div className="text-holder">
                        <p>Oh Yeah, the payment was successful</p>
                        <Link className="btn-paymentMessage" to={this.state.session == true ? "/dashboard" : "/userLogin"}>
                            <span className="float-left">Continue</span>
                            <span className="float-right"><i class="fas fa-arrow-right"></i></span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentSuccess