import React, { Component } from 'react';
import $ from 'jquery';
import { ENV } from './../../../config/config';
import swal from 'sweetalert';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from 'react-spinners/ClipLoader';
import { Redirect } from 'react-router-dom';
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/core';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
var CryptoJS = require("crypto-js");

// const override = css`
// display: block;
// position:absolute;
// left:50%;
// top:50%;
// margin:-60px 0 0 -60px;
// border-color: blue;
// `;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
`;

class CustomDomains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            mainLoader: true,
            domainsList: [],
            allowedCustomDomains: 0,
            total: 0,
            page: 1,
            limit: 0,
            pages: 0,
            hasMore: true,
            redirect: false
        };
    }

    componentDidMount() {
        let userId = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        if (userId) {
            this.setState({
                userId
            }, () => {
                this.getDomainList();
                this.checkUserSession(userId);
            });
        }

        if (this.props.location.search) {
            var isDomainCreated = this.props.location.search.split('?created=')[1];

            if (isDomainCreated) {
                // COMMENTED TEMP.
                // $("#add_domain").show();
                this.props.history.push('/organization/domain');
                var span = document.createElement("span");
                span.innerHTML = "<h4>It will be activated and available after verification.</h4><p><strong>NOTE:</strong> Domain verification may take upto 24 - 48 hours. So you'll be able to use it once it is verified.</p>";
                // swal('Success', span, 'success');
                swal('Success', 'Custom domain added successfully', 'success')
                    .then(() => {
                        swal({
                            content: span,
                            closeOnClickOutside: false,
                        });
                    });
            }
        }

    }
    componentWillMount() {
        let subscriptionPlanDetails = localStorage.getItem("spd");
        if (!subscriptionPlanDetails) {
            localStorage.removeItem("loginToken");
            localStorage.removeItem("LoginSession");
            localStorage.removeItem("loginTime");
            window.location.href = "/web/userLogin";
            return;
        }
        let decryptedSubscriptionPlanDetails = CryptoJS.AES.decrypt(subscriptionPlanDetails.toString(), ENV.TokSecCrpt);
        decryptedSubscriptionPlanDetails = JSON.parse(decryptedSubscriptionPlanDetails.toString(CryptoJS.enc.Utf8));
        if (!parseInt(decryptedSubscriptionPlanDetails.customDomains)) {
            this.props.history.push("/organization/detail");
            return;
        }
        this.setState({ allowedCustomDomains: parseInt(decryptedSubscriptionPlanDetails.customDomains), customLinkHistory: parseInt(decryptedSubscriptionPlanDetails.customLinkHistory) });
        let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
        this.setState({
            userId: id
        }, () => {
            this.getDomainList();
        })
    }

    checkUserSession = (id) => {
        fetch(window.APIURL + "accounts/member/user-session-check?id=" + id, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 403) {
                    return res.json();
                } else if (res.status === 422) {
                    return res.json();
                } else if (res.status === 404) {
                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then(result => {
                if (result.status === true) {
                } else if (result.status === false) {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal('Error', result.message, 'error')
                } else {
                    localStorage.removeItem("loginToken");
                    localStorage.removeItem("LoginSession");
                    localStorage.removeItem("loginTime");
                    this.setState({ redirect: true });
                    // swal('Error', 'Contact Admin', 'error')
                }
            });
    }



    getDomainList() {
        let { userId } = this.state;
        this.setState({
            limit: this.state.limit + 10
        }, () => {
            fetch(window.APIURL + "custom-domain/user-custom-domains-list?userId=" + userId + "&page=" + this.state.page + '&limit=' + this.state.limit, {
                method: "get",
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 403) {
                        return res.json();
                    } else if (res.status === 422) {
                        return res.json();
                    } else if (res.status === 404) {
                        return res.json();
                    } else {
                        return res.json();
                    }
                })
                .then(result => {
                    this.setState({
                        mainLoader: false
                    })
                    if (result.status === true) {
                        if (result.domains.length === result.data.total) {
                            this.setState({
                                hasMore: false
                            })
                        }
                        this.setState({
                            // hasMore: false,
                            domainsList: result.domains,
                            page: result.data.page,
                            pages: result.data.pages,
                            limit: result.data.limit,
                            total: result.data.total,
                        });
                    } else if (result.status === false) {
                        swal('Error', result.message, 'error')
                    } else {
                        swal('Error', 'Contact Admin', 'error')
                    }
                });
        })
    }

    goToPurchaseDomain() {
        $("#myModal").modal('hide');
        this.props.history.push("/organization/purchase/");
    }
    closeModal() {
        $("#myModal").modal('hide');
    }

    deleteDomain(domainId) {
        swal({
            title: "Are you sure?",
            text: "You will not be able recover it again!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let id = JSON.parse(localStorage.getItem("LoginSession")).data._id;
                    fetch(window.APIURL + "custom-domain/delete?_id=" + domainId + "&userId=" + id, {
                        method: "get",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(res => {
                            if (res.status === 200) {
                                return res.json();
                            } else if (res.status === 403) {
                                return res.json();
                            } else if (res.status === 422) {
                                return res.json();
                            } else if (res.status === 404) {
                                return res.json();
                            } else {
                                return res.json();
                            }
                        })
                        .then(result => {
                            if (result.status === true) {
                                // this.setState({ domainsList: result.domains });
                                this.getDomainList();
                                swal('Success', result.message, 'success');
                            } else if (result.status === false) {
                                swal('Error', result.message, 'error')
                            } else {
                                swal('Error', 'Contact Admin', 'error')
                            }
                        });
                }
            });
    }

    render() {
        let { redirect } = this.state;
        if (redirect) {
            return <Redirect to="/userLogin" />;
        }
        return (
            <React.Fragment>
                <LoadingOverlay
                    active={this.state.mainLoader}
                    spinner
                >
                    <div className="admin-section--MAIN">
                        <div className="admin-section--top">
                            <div className="mb-2" style={{ overflow: 'hidden' }}>
                                <h2 className="admin-section--header">Custom Domains</h2>
                                <a className="admin-section--cta">
                                    <button className="button--SOLID-PRIMARY" id="" data-toggle="modal" data-target="#myModal">Add Custom Domain</button>
                                </a>
                            </div>
                            {/* COMMENTED TEMP. */}
                            {/* <div className="admin-section--item">
                                    <p className="marketing-module--text">
                                        <span className="marketing-module--upsell-text">
                                            Using&nbsp;
                                    <span className="used-seats">
                                                {
                                                    " " + this.state.domainsList ? this.state.domainsList.length + " " : 0 + " "
                                                }
                                            </span>
                                    of
                                    {
                                                " " + this.state.allowedCustomDomains + " "
                                            } Custom Domains
                                </span>
                                        <br />
                                    </p>
                                </div> */}
                        </div>
                        <div className="customDomainLoaderHolder" style={{ position: 'relative' }}>
                            <InfiniteScroll
                                dataLength={this.state.total}
                                next={() => this.getDomainList()}
                                hasMore={this.state.hasMore}
                                loader={
                                    // <CircleLoader
                                    //     css={override}
                                    //     sizeUnit={"px"}
                                    //     size={120}
                                    //     color={'#007bff'}
                                    //     loading={this.state.hasMore}
                                    // />
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.hasMore}
                                    />
                                }
                            >
                                <div className="empty-state--BITLINKS">
                                    {
                                        this.state.domainsList && this.state.domainsList.length ?
                                            this.state.domainsList.map((domain, key) => {
                                                return (
                                                    <div key={key} className="avlbl-domain-row" style={{ overflow: 'hidden' }}>
                                                        <h4 className="float-left pt-2">
                                                            {domain.name}
                                                            {
                                                                !domain.isVerified ?
                                                                    <small style={{ fontSize: '14px' }}> <span className="text-danger">(Not Verified)</span> </small> : <small style={{ fontSize: '14px' }}> <span className="text-success">(Verified)</span> </small>
                                                            }
                                                        </h4>
                                                        <span className="float-right">
                                                            {
                                                                this.state.customLinkHistory && domain.isVerified ?
                                                                    <Link className="button--SOLID-PRIMARY d-inline-block align-top" to={"/dashboard?dd=" + btoa(domain.name)}>View Link History</Link> : ''
                                                            }

                                                            {
                                                                domain.isVerified ?
                                                                    ''
                                                                    :
                                                                    <button className="btn btn-danger" onClick={() => this.deleteDomain(domain._id)}>Delete</button>
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            }) :
                                            <div className="empty-state--gradient-wrapper">
                                                <span className="no-bitlinks-icon" alt="no-bitlinks-icon"><i className="fas fa-link"></i></span>
                                                <h4 className="empty-state--header">NO CUSTOM DOMAINS SET UP</h4>
                                                <a href="#" className="empty-state--link" data-toggle="modal" data-target="#myModal">Add a Custom Domain</a>
                                            </div>
                                    }
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div className="modal" id="myModal">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title">&nbsp;</div>
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body text-center">
                                    <div className="modal-title">ADD A CUSTOM DOMAIN</div>
                                    <div className="modal-text">Purchase or add an existing Custom Domain to your FLO.ink Account.</div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button className="button--GHOST-SECONDARY checklist-menu-item" tabIndex="0" data-id="add_domain" onClick={() => this.closeModal()}>ADD DOMAIN</button><button className="button--SOLID-SECONDARY" tabIndex="0" id="" onClick={() => this.goToPurchaseDomain()}>PURCHASE DOMAIN</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </React.Fragment>
        )
    }
}

export default CustomDomains;