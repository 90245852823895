import React, { Component } from 'react';


class CsvReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggs: {}
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state !== undefined) {
            this.setState({
                loggs: this.props.location.state.csvLogs
            })
        }
    }

    render() {
        const { loggs } = this.state;

        // console.log('csvLogs', csvLogs) // "bar"
        return (
            <React.Fragment>
                <div className="empty-state--BITLINKS">
                    <div className="table-responsive">
                        <table class="table table-borderless reportTable">
                            <thead>
                                <tr>
                                    <th>URL</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loggs && loggs.length ?
                                        loggs.map((csvLog, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{csvLog.qlink ? csvLog.qlink : 'N/A'}</td>
                                                    <td>{csvLog.message ? csvLog.message : 'N/A'}</td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="5" className="text-center">No Record Found</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default CsvReport;